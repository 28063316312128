import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from "styled-components";
import { ArrowDropUp, ArrowDropDown } from "@material-ui/icons";
import CalendarIcon from "../../assests/images/icon-calendar.png";
import ReactDatePicker from "react-datepicker";
import { addDays } from "date-fns";
import PrintReportIcon from "../../assests/images/icon-print.png";
import Topnav from "../Topnav";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
// import { DateRangePicker } from "react-date-range";

import { DateRangePicker } from 'rsuite';
import "../daterangecss/style.css";
import 'rsuite/dist/rsuite.min.css';

import moment from "moment";

import ChevronDownIcon from "../../assests/images/chevronDown.svg";
import ChevronUpIcon from "../../assests/images/chevronUp.svg";
import "../../../src/App.css";

import { CSVLink } from "react-csv";

import axios from "axios";
import { createHashHistory } from "history";
import { API_URL } from "../../commonFunctions/ApiUrl";

import DataTable, { defaultThemes } from 'react-data-table-component';

import { Formik, Form, Field } from "formik";
import { param } from "jquery";
import { object } from "yup";
import Modal from 'react-modal';

const MainContainer = styled.section`
  width: 100%;
  margin: 0 auto;
  background-color: #f9f9f9;
  border: 1px solid #d5d5d5;
  color: #333;
`;
const Input = styled.input`
  height: 30px;
  width: 80%;
  margin: 10px auto;
  border: 1px solid #d5d5d5;
  border-radius: 2px;
  padding-left: 8px;
  ::placeholder {
    opacity: 0.8;
  }
`;
const TableContainer = styled.div`
  overflow: auto;
  width: 100%;
  margin: 0 auto;
  background-color: #f9f9f9;
  padding:0px;

`;

const Table = styled.table`
  border: none;
  overflow: auto;
  th,
  td {
    white-space: nowrap;
  }
  th {
    background-color: #e9e9e9;
  }
  width: 100%;
`;

const TableHead = styled.thead`
 

`;

const TableHeadRow = styled.tr``;

const TableHeadRowColumnVal = styled.th`
  border: 1px solid #d5d5d5;
  backgroundColor:red;
  tr {
    padding: 20 0px;
    min-width:100px;
  }
`;


const customStyles = {
    content: {
        top: '10%',
        // left: '10%',
        // right: '10%',
        bottom: '0%',
        zIndex: 100000,
        // width:"80%",
        // marginRight: '-50%',
        // transform: 'translate(-50%, -50%)',
    },
};

const InputHolder = styled.div`
  display: flex;
  justify-content: center;
`;

const TableBody = styled.tbody`
  tr:nth-child(odd) {
    background-color: #f9f9f9;
  }
  tr:nth-child(even) {
    background-color: #fdfcf5;
  }
  td {
    border: 1px solid #d5d5d5;
    border-bottom: none;
    border-top: none;
    padding-left: 8px;
    padding-right: 20px;
    height: 40px;
  }
  // height:400px;
  // overflow: scroll;
 
`;

const Button = styled.button`
  border: none;
  cursor: pointer;
  outline: none;
  background-color: #7bb731;
  color: #fff;
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
`;

const FilterDate = styled.div`
  width: 180px;
  border: 1px solid #cbcbcb;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  justify-content: center;
  margin-right: 10px;
  span {
    margin-right: 5px;
  }
  img {
    cursor: pointer;
  }
`;

const Header = styled.div`
  width: 98%;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 10px;
  display: flex;
  justify-content: end;
  background-color: #f9f9f9;
`;

const Title = styled.div`
  width: 50%;
  height: initial;
  display: flex;
  align-items: center;
`;

// const ModelTitle = styled.div`
//   margin: 0 auto;
//   padding-top: 20px;
//   padding-bottom: 10px;
//   display: flex;
//   justify-content: space-between;
//   // background-color: #f9f9f9;
//   background-color: #89bc3b;

//   font-size: 20px;
//   font-weight: bold;
//   // position:fixed;
// `;



const DatePickerContainer = styled.div`
  margin-top: 10px;
  margin-left: 50%;
  display: flex;
//   justify-content: end;
justify-content: flex-start;

  
`;

const Mycustomdiv = styled.div`
box-sizing:border-box;
  margin-top: 10px;
  width:98%;
  display: flex;
  justify-content: flex-end

`;

const PrintReport = styled.div`
width: 100%;
padding:0px 0px 0px 15px;
display: flex;
justify-content: end;
align-items: center;
height: 40px;

padding-right: 16px;
background-color: #efefef;
span {
  color: #7bb731;
  margin-left: 8px;
}
img {
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

// Code By ashraf Start

const PageHeading = styled.div`
  width: 100%;
  padding:10px 0px 0px 0px;
  display: flex;
  justify-content: Center;
  align-items: center;
  height: 40px;

  padding-right: 16px;
  background-color: #efefef;
  span {
    color: #7bb731;
    margin-left: 8px;
  }
  // img {
  //   width: 20px;
  //   height: 20px;
  //   cursor: pointer;
  // }
`;




// Code By ashraf End



const DateContainer = styled.div`
  display: ${(props) => (props.openDateModal ? "block" : "none")};
`;

const tableHeaderValues = [
    "GRPA Client ID",
    "Entry Date",
    "Full Name",
    "Date Of Birth",
    "Gender",
    "Primary Race",
    "Ethincity",
    "Total Monthly Income",
    "Any alcohol",
    "Cocaine/Crack",
    "Benzodia",
];

const PageHeadingStyle = styled.div`
    margin:"0px auto";
    display:"block";
    width:100%;
    background-color:#efefef;

    `;


const printreportPageContainer = styled.div`
    margin:"0px auto";
    display:"block";
    background-color:#efefef;
    width:100% !important;


`;
const CustomModalContainer = styled.div`
    position:fixed;
    top:100px;
    background-color:grey;
    color:white;
    height:800px;
    overflow: scroll;
    
`


function ReferralMonthReport() {
    let subtitle;
    const [date, setDate] = useState("");
    const [getRcrData, setRcrData] = useState([]);
    const wrapperRef = React.useRef(null);

    const [startDate, setstartDate] = useState('2022-01-01');
    const [endDate, setendDate] = useState('2022-12-31');
    const [modalIsOpen, setIsOpen] = React.useState(false);

    const [isModalOpen, setIsmodalopen] = useState(false);
    const [selectedColumn, setselectedColumn] = useState([]);
    const [csvData, setCsvData] = useState([])
    const [viewcolumn, setViewcolumn] = useState(
        [
            {
                id: "1",
                column: "User",
                name: "user",
                default_show: true
            },
            {
                id: "2",
                column: "User ID",
                name: "user_id",
                default_show: true
            },
            {
                id: "3",
                column: "Client ID",
                name: "client_id",
                default_show: true
            },
            {
                id: "4",
                column: "GPRA Client ID",
                name: "gpra_client_id",
                default_show: true
            },
            {
                id: "5",
                column: "Entry Date",
                name: "entry_date",
                default_show: false,
            },
            {
                id: "6",
                column: "Day of the week",
                name: "day_of_the_week",
                default_show: false
            },
            {
                id: "7",
                column: "Name",
                name: "name",
                default_show: false
            },
            {
                id: "8",
                column: "Date of birth",
                name: "date_Of_birth",
                default_show: false
            },
            {
                id: "9",
                column: "Gender",
                name: "gender",
                default_show: false
            },
            {
                id: "10",
                column: "Primary Race",
                name: "primary_race",
                default_show: false
            },
            {
                id: "11",
                column: "Ethnicity",
                name: "ethnicity",
                default_show: false
            },
            {
                id: "12",
                column: "Current ZIP",
                name: "current_zip",
                default_show: false
            },
            {
                id: "13",
                column: "Total Monthly Income",
                name: "total_monthly_income",
                default_show: false
            },
            {
                id: "14",
                column: "Any alcohol",
                name: "any_alcohol",
                default_show: false
            },
            {
                id: "15",
                column: "Alcohol to intoxication (5+)",
                name: "alcohol_to_intoxication_5",
                default_show: false
            },
            {
                id: "16",
                column: "Alcohol to intoxication",
                name: "alcohol_to_intoxication",
                default_show: false
            },
            {
                id: "17",
                column: "Cocaine/Crack",
                name: "cocaine_crack",
                default_show: false
            },
            {
                id: "18",
                column: "Cocaine/Crack (route)",
                name: "cocaine_crack_route",
                default_show: false
            },
            {
                id: "19",
                column: "Benzodiazepines:",
                name: "benzodiazepines",
                default_show: false
            },
            {
                id: "20",
                column: "Benzodiazepines (route)",
                name: "benzodiazepines_route",
                default_show: false
            },
            {
                id: "21",
                column: "Barbiturates",
                name: "barbiturates",
                default_show: false
            },
            {
                id: "22",
                column: "Barbiturates (route)",
                name: "barbiturates_route",
                default_show: false
            },
            {
                id: "23",
                column: "Marijuana/Hashish",
                name: "marijuana_hashish",
                default_show: false
            },
            {
                id: "24",
                column: "Marijuana/Hashish (route)",
                name: "marijuana_hashish_route",
                default_show: false
            },
            {
                id: "25",
                column: "Hallucinogens/psychedelics",
                name: "hallucinogens_psychedelics",
                default_show: false
            },
            {
                id: "26",
                column: "Hallucinogens/psychedelics (route)",
                name: "hallucinogens_psychedelics_route",
                default_show: false
            },
            {
                id: "27",
                column: "Methamphetamine or other amphetamines",
                name: "methamphetamine_or_other_amphetamines",
                default_show: false
            },
            {
                id: "28",
                column: "Methamphetamine or other amphetamines (route)",
                name: "methamphetamine_or_other_amphetamines_route",
                default_show: false
            },
            {
                id: "29",
                column: "Inhalants",
                name: "inhalants",
                default_show: false
            },
            {
                id: "30",
                column: "Inhalants (route)",
                name: "inhalants_route",
                default_show: false
            },
            {
                id: "31",
                column: "Non-prescription GHB",
                name: "non_prescription_ghb",
                default_show: false
            },
            {
                id: "32",
                column: "Non-prescription GHB (route)",
                name: "non_prescription_ghb_route",
                default_show: false
            },
            {
                id: "33",
                column: "Ketamine",
                name: "ketamine",
                default_show: false
            },
            {
                id: "34",
                column: "Ketamine (route)",
                name: "ketamine_route",
                default_show: true,
            },
            {
                id: "35",
                column: "Other tranquilizers, downers, sedatives, or hypnotics",
                name: "other_tranquilizers_downers_sedatives_or_hypnotics",
                default_show: false
            },
            {
                id: "36",
                column: "Other tranquilizers, downers, sedatives, or hypnotics (route)",
                name: "other_tranquilizers_downers_sedatives_or_hypnotics_route",
                default_show: false
            },
            {
                id: "37",
                column: "Other",
                name: "other",
                default_show: true,
            },
            {
                id: "38",
                column: "Other illegal drugs (Specify)",
                name: "other_illegal_drugs_specify",
                default_show: false
            },
            {
                id: "39",
                column: "Other illegal drugs (route)",
                name: "other_illegal_drugs_route",
                default_show: false
            },
            {
                id: "40",
                column: "Heroin",
                name: "heroin",
                default_show: false
            },
            {
                id: "41",
                column: "Heroin (route)",
                name: "heroin_route",
                default_show: false
            },
            {
                id: "42",
                column: "Morphine",
                name: "morphine",
                default_show: false
            },
            {
                id: "43",
                column: "Morphine (route)",
                name: "morphine_route",
                default_show: false
            },
            {
                id: "44",
                column: "Dilaudid",
                name: "dilaudid",
                default_show: false
            },
            {
                id: "45",
                column: "Dilaudid (route)",
                name: "dilaudid_route",
                default_show: false
            },
            {
                id: "46",
                column: "Demerol",
                name: "demerol",
                default_show: false
            },
            {
                id: "47",
                column: "Demerol (route)",
                name: "demerol_route",
                default_show: false
            },
            {
                id: "48",
                column: "Percocet",
                name: "percocet",
                default_show: false
            },
            {
                id: "49",
                column: "Percocet (route)",
                name: "percocet_route",
                default_show: false
            },
            {
                id: "50",
                column: "Darvon",
                name: "darvon",
                default_show: false
            },
            {
                id: "51",
                column: "Darvon (route)",
                name: "darvon_route",
                default_show: false
            },
            {
                id: "52",
                column: "Codeine",
                name: "codeine",
                default_show: false
            },
            {
                id: "53",
                column: "Codeine (route)",
                name: "codeine_route",
                default_show: false
            },
            {
                id: "54",
                column: "Tylenol 2, 3, 4",
                name: "tylenol",
                default_show: false
            },
            {
                id: "55",
                column: "Tylenol 2, 3, 4 (route)",
                name: "tylenol_2",
                default_show: false
            },
            {
                id: "56",
                column: "OxyContin/Oxycodone",
                name: "oxyContin_oxycodone",
                default_show: false
            },
            {
                id: "57",
                column: "OxyContin/Oxycodone (route)",
                name: "oxyContin_oxycodone_route",
                default_show: false
            },
            {
                id: "58",
                column: "Non-prescription methadone",
                name: "non_prescription_methadone",
                default_show: false
            },
            {
                id: "59",
                column: "Non-prescription methadone (route)",
                name: "non_prescription_methadone_route",
                default_show: false
            },
            {
                id: "60",
                column: "Opioid",
                name: "opioid",
                default_show: false
            },
            {
                id: "61",
                column: "Referral Source",
                name: "referral_source",
                default_show: false
            },
            {
                id: "62",
                column: "Specific Referral Source",
                name: "specific_referral_source",
                default_show: false
            },
            {
                id: "63",
                column: "UAB SAMHSA Client ID",
                name: "uab_samhsa_client_id",
                default_show: false
            },
            {
                id: "64",
                column: "Ref. Target",
                name: "ref_target",
                default_show: false
            },
            {
                id: "65",
                column: "Agency Target",
                name: "agency_target",
                default_show: false
            },
            {
                id: "66",
                column: "If family/friends were involved, were they referred to any services?",
                name: "if_family_friends_were_involved_were_they_referred_to_any_services",
                default_show: false
            },
            {
                id: "67",
                column: "Was the ASAM completed?",
                name: "was_the_asam_completed",
                default_show: false
            },
            {
                id: "68",
                column: "Assigned Peer Support Specialist",
                name: "assigned_peer_support_specialist",
                default_show: false
            },
            {
                id: "69",
                column: "Current County",
                name: "current_county",
                default_show: false
            },
            {
                id: "70",
                column: "Have you ever overdosed?",
                name: "have_you_ever_overdosed",
                default_show: false
            },
            {
                id: "71",
                column: "New/Old",
                name: "new_old",
                default_show: false
            },
            {
                id: "72",
                column: "Trauma?",
                name: "trauma",
                default_show: false
            },
            {
                id: "73",
                column: "Suicide Attempt",
                name: "suicide_attempt",
                default_show: false
            },
            {
                id: "74",
                column: "Primary Phone Number",
                name: "primary_phone_number",
                default_show: false
            },
            {
                id: "75",
                column: "Address",
                name: "address",
                default_show: false
            },
            {
                id: "76",
                column: "Timestamp",
                name: "timestamp",
                default_show: false
            }
        ]
    )
    //   setIsmodalopen(!isModalOpen)

    // const OrgId = sessionStorage.getItem("organization_id");
    const OrgId = 5030;
    const styles = { width: 260, display: 'block', marginBottom: 10, height: 50, borderRadius: "2px",zIndex:-10000 };

    useEffect(() => {
        fetchApi(startDate, endDate);
    }, [startDate, endDate]);

    const fetchApi = async (startDate, endDate) => {
        let final_data = { OrgId: OrgId }
        axios.get(API_URL + `rrc_monthly_report?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&startDate=${startDate}&endDate=${endDate}`)
            .then((response) => {
                setRcrData(response.data.data);
            })
            .catch((error) => { });
        let display_rrc_column_res = await axios.post(API_URL + `display_rrc_column?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}`);
        if (display_rrc_column_res.status == 200) {
            console.log("display_rrc_column_res ", display_rrc_column_res.data.data.length)
            let temp_arr = [];
            if (display_rrc_column_res.data.data.length != 0) {
                display_rrc_column_res.data.data.filter((val) => { return (val.default_show == true) }).map((val) => { temp_arr.push(val.id) })
                setselectedColumn(temp_arr)
                setViewcolumn(display_rrc_column_res.data.data);
            } else {
                setViewcolumn(
                    [
                        {
                            id: "1",
                            column: "User",
                            name: "user",
                            default_show: true
                        },
                        {
                            id: "2",
                            column: "User ID",
                            name: "user_id",
                            default_show: true
                        },
                        {
                            id: "3",
                            column: "Client ID",
                            name: "client_id",
                            default_show: true
                        },
                        {
                            id: "4",
                            column: "GPRA Client ID",
                            name: "gpra_client_id",
                            default_show: true
                        },
                        {
                            id: "5",
                            column: "Entry Date",
                            name: "entry_date",
                            default_show: false,
                        },
                        {
                            id: "6",
                            column: "Day of the week",
                            name: "day_of_the_week",
                            default_show: false
                        },
                        {
                            id: "7",
                            column: "Date of birth",
                            name: "date_Of_birth",
                            default_show: false
                        },
                        {
                            id: "8",
                            column: "Gender",
                            name: "gender",
                            default_show: false
                        },
                        {
                            id: "9",
                            column: "Primary Race",
                            name: "primary_race",
                            default_show: false
                        },
                        {
                            id: "10",
                            column: "Ethnicity",
                            name: "ethnicity",
                            default_show: false
                        },
                        {
                            id: "11",
                            column: "Current ZIP",
                            name: "current_zip",
                            default_show: false
                        },
                        {
                            id: "12",
                            column: "Total Monthly Income",
                            name: "total_monthly_income",
                            default_show: false
                        },
                        {
                            id: "13",
                            column: "Any alcohol",
                            name: "any_alcohol",
                            default_show: false
                        },
                        {
                            id: "14",
                            column: "Alcohol to intoxication (5+)",
                            name: "alcohol_to_intoxication_5",
                            default_show: false
                        },
                        {
                            id: "15",
                            column: "Alcohol to intoxication",
                            name: "alcohol_to_intoxication",
                            default_show: false
                        },
                        {
                            id: "16",
                            column: "Cocaine/Crack",
                            name: "cocaine_crack",
                            default_show: false
                        },
                        {
                            id: "17",
                            column: "Cocaine/Crack (route)",
                            name: "cocaine_crack_route",
                            default_show: false
                        },
                        {
                            id: "18",
                            column: "Benzodiazepines:",
                            name: "benzodiazepines",
                            default_show: false
                        },
                        {
                            id: "19",
                            column: "Benzodiazepines (route)",
                            name: "benzodiazepines_route",
                            default_show: false
                        },
                        {
                            id: "20",
                            column: "Barbiturates",
                            name: "barbiturates",
                            default_show: false
                        },
                        {
                            id: "21",
                            column: "Barbiturates (route)",
                            name: "barbiturates_route",
                            default_show: false
                        },
                        {
                            id: "22",
                            column: "Marijuana/Hashish",
                            name: "marijuana_hashish",
                            default_show: false
                        },
                        {
                            id: "23",
                            column: "Marijuana/Hashish (route)",
                            name: "marijuana_hashish_route",
                            default_show: false
                        },
                        {
                            id: "24",
                            column: "Hallucinogens/psychedelics",
                            name: "hallucinogens_psychedelics",
                            default_show: false
                        },
                        {
                            id: "25",
                            column: "Hallucinogens/psychedelics (route)",
                            name: "hallucinogens_psychedelics_route",
                            default_show: false
                        },
                        {
                            id: "26",
                            column: "Methamphetamine or other amphetamines",
                            name: "methamphetamine_or_other_amphetamines",
                            default_show: false
                        },
                        {
                            id: "27",
                            column: "Methamphetamine or other amphetamines (route)",
                            name: "methamphetamine_or_other_amphetamines_route",
                            default_show: false
                        },
                        {
                            id: "28",
                            column: "Inhalants",
                            name: "inhalants",
                            default_show: false
                        },
                        {
                            id: "29",
                            column: "Inhalants (route)",
                            name: "inhalants_route",
                            default_show: false
                        },
                        {
                            id: "30",
                            column: "Non-prescription GHB",
                            name: "non_prescription_ghb",
                            default_show: false
                        },
                        {
                            id: "31",
                            column: "Non-prescription GHB (route)",
                            name: "non_prescription_ghb_route",
                            default_show: false
                        },
                        {
                            id: "32",
                            column: "Ketamine",
                            name: "ketamine",
                            default_show: false
                        },
                        {
                            id: "33",
                            column: "Ketamine (route)",
                            name: "ketamine_route",
                            default_show: true,
                        },
                        {
                            id: "34",
                            column: "Other tranquilizers, downers, sedatives, or hypnotics",
                            name: "other_tranquilizers_downers_sedatives_or_hypnotics",
                            default_show: false
                        },
                        {
                            id: "35",
                            column: "Other tranquilizers, downers, sedatives, or hypnotics (route)",
                            name: "other_tranquilizers_downers_sedatives_or_hypnotics_route",
                            default_show: false
                        },
                        {
                            id: "36",
                            column: "Other",
                            name: "other",
                            default_show: true,
                        },
                        {
                            id: "37",
                            column: "Other illegal drugs (Specify)",
                            name: "other_illegal_drugs_specify",
                            default_show: false
                        },
                        {
                            id: "38",
                            column: "Other illegal drugs (route)",
                            name: "other_illegal_drugs_route",
                            default_show: false
                        },
                        {
                            id: "39",
                            column: "Heroin",
                            name: "heroin",
                            default_show: false
                        },
                        {
                            id: "40",
                            column: "Heroin (route)",
                            name: "heroin_route",
                            default_show: false
                        },
                        {
                            id: "41",
                            column: "Morphine",
                            name: "morphine",
                            default_show: false
                        },
                        {
                            id: "42",
                            column: "Morphine (route)",
                            name: "morphine_route",
                            default_show: false
                        },
                        {
                            id: "43",
                            column: "Dilaudid",
                            name: "dilaudid",
                            default_show: false
                        },
                        {
                            id: "44",
                            column: "Dilaudid (route)",
                            name: "dilaudid_route",
                            default_show: false
                        },
                        {
                            id: "45",
                            column: "Demerol",
                            name: "demerol",
                            default_show: false
                        },
                        {
                            id: "46",
                            column: "Demerol (route)",
                            name: "demerol_route",
                            default_show: false
                        },
                        {
                            id: "47",
                            column: "Percocet",
                            name: "percocet",
                            default_show: false
                        },
                        {
                            id: "48",
                            column: "Percocet (route)",
                            name: "percocet_route",
                            default_show: false
                        },
                        {
                            id: "49",
                            column: "Darvon",
                            name: "darvon",
                            default_show: false
                        },
                        {
                            id: "50",
                            column: "Darvon (route)",
                            name: "darvon_route",
                            default_show: false
                        },
                        {
                            id: "51",
                            column: "Codeine",
                            name: "codeine",
                            default_show: false
                        },
                        {
                            id: "52",
                            column: "Codeine (route)",
                            name: "codeine_route",
                            default_show: false
                        },
                        {
                            id: "53",
                            column: "Tylenol 2, 3, 4",
                            name: "tylenol",
                            default_show: false
                        },
                        {
                            id: "54",
                            column: "Tylenol 2, 3, 4 (route)",
                            name: "tylenol_2",
                            default_show: false
                        },
                        {
                            id: "55",
                            column: "OxyContin/Oxycodone",
                            name: "oxyContin_oxycodone",
                            default_show: false
                        },
                        {
                            id: "56",
                            column: "OxyContin/Oxycodone (route)",
                            name: "oxyContin_oxycodone_route",
                            default_show: false
                        },
                        {
                            id: "57",
                            column: "Non-prescription methadone",
                            name: "non_prescription_methadone",
                            default_show: false
                        },
                        {
                            id: "58",
                            column: "Non-prescription methadone (route)",
                            name: "non_prescription_methadone_route",
                            default_show: false
                        },
                        {
                            id: "59",
                            column: "Opioid",
                            name: "opioid",
                            default_show: false
                        },
                        {
                            id: "60",
                            column: "Referral Source",
                            name: "referral_source",
                            default_show: false
                        },
                        {
                            id: "61",
                            column: "Specific Referral Source",
                            name: "specific_referral_source",
                            default_show: false
                        },
                        {
                            id: "62",
                            column: "UAB SAMHSA Client ID",
                            name: "uab_samhsa_client_id",
                            default_show: false
                        },
                        {
                            id: "63",
                            column: "Ref. Target",
                            name: "ref_target",
                            default_show: false
                        },
                        {
                            id: "64",
                            column: "Agency Target",
                            name: "agency_target",
                            default_show: false
                        },
                        {
                            id: "65",
                            column: "If family/friends were involved, were they referred to any services?",
                            name: "if_family_friends_were_involved_were_they_referred_to_any_services",
                            default_show: false
                        },
                        {
                            id: "66",
                            column: "Was the ASAM completed?",
                            name: "was_the_asam_completed",
                            default_show: false
                        },
                        {
                            id: "67",
                            column: "Assigned Peer Support Specialist",
                            name: "assigned_peer_support_specialist",
                            default_show: false
                        },
                        {
                            id: "68",
                            column: "Current County",
                            name: "current_county",
                            default_show: false
                        },
                        {
                            id: "69",
                            column: "Have you ever overdosed?",
                            name: "have_you_ever_overdosed",
                            default_show: false
                        },
                        {
                            id: "70",
                            column: "New/Old",
                            name: "new_old",
                            default_show: false
                        },
                        {
                            id: "71",
                            column: "Trauma?",
                            name: "trauma",
                            default_show: false
                        },
                        {
                            id: "72",
                            column: "Suicide Attempt",
                            name: "suicide_attempt",
                            default_show: false
                        },
                        {
                            id: "73",
                            column: "Primary Phone Number",
                            name: "primary_phone_number",
                            default_show: false
                        },
                        {
                            id: "74",
                            column: "Address",
                            name: "address",
                            default_show: false
                        },
                        {
                            id: "75",
                            column: "Timestamp",
                            name: "timestamp",
                            default_show: false
                        }
                    ]
                );
                viewcolumn.filter((val) => { return (val.default_show == true) }).map((val) => { temp_arr.push(val.id) })
                setselectedColumn(temp_arr);
            }
        }

        let download_csv_res = await axios.get(API_URL + `download_rrc_monthly_report?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&startDate=${startDate}&endDate=${endDate}`);
        setCsvData(download_csv_res.data.data)


    }

    const headers_for_csvfile = [
        { label: "User", key: "user" },
        { label: "User ID", key: "user_id" },
        { label: "Client ID", key: "client_id" },
        { label: "GPRA Client ID", key: "gpra_client_id" },
        { label: "Entry Date", key: "entry_date" },
        { label: "Day of the week", key: "day_of_the_week" },
        { label: "Name", key: "name" },
        { label: "Date of birth", key: "date_of_birth" },
        { label: "Gender", key: "gender" },
        { label: "Primary Race", key: "primary_race" },
        { label: "Ethnicity", key: "ethnicity" },
        { label: "Current ZIP", key: "current_zip" },
        { label: "Total Monthly Income", key: "total_monthly_income" },
        { label: "a. Any alcohol", key: "any_alcohol" },
        { label: "b1. Alcohol to intoxication (5+)", key: "alcohol_to_intoxication_5" },
        { label: "b2. Alcohol to intoxication", key: "alcohol_to_intoxication" },
        { label: "a. Cocaine/Crack", key: "cocaine_crack" },
        { label: "Cocaine/Crack (route)", key: "cocaine_crack_route" },
        { label: "g. 1. Benzodiazepines:", key: "benzodiazepines" },
        { label: "Benzodiazepines (route)", key: "benzodiazepines_route" },
        { label: "2. Barbiturates", key: "barbiturates" },
        { label: "Barbiturates (route)", key: "barbiturates_route" },
        { label: "b. Marijuana/Hashish", key: "marijuana_hashish" },
        { label: "Marijuana/Hashish (route)", key: "marijuana_hashish_route" },

        { label: "e. Hallucinogens/psychedelics", key: "hallucinogens_psychedelics" },
        { label: "Hallucinogens/psychedelics (route)", key: "hallucinogens_psychedelics_route" },
        { label: "f. Methamphetamine or other amphetamines", key: "methamphetamine_or_other_amphetamines" },
        { label: "Methamphetamine or other amphetamines (route)", key: "methamphetamine_or_other_amphetamines_route" },
        { label: "h. Inhalants", key: "inhalants" },
        { label: "Inhalants (route)", key: "inhalants_route" },
        { label: "3. Non-prescription GHB", key: "non_prescription_ghb" },
        { label: "Non-prescription GHB (route)", key: "non_prescription_ghb_route" },
        { label: "4. Ketamine", key: "ketamine" },
        { label: "Ketamine (route)", key: "ketamine_route" },
        { label: "5. Other tranquilizers, downers, sedatives, or hypnotics", key: "other_tranquilizers_downers_sedatives_or_hypnotics" },
        { label: "Other tranquilizers, downers, sedatives, or hypnotics (route)", key: "other_tranquilizers_downers_sedatives_or_hypnotics_route" },
        { label: "g. Other", key: "other" },

        { label: "i. Other illegal drugs (Specify)", key: "other_illegal_drugs_specify" },
        { label: "Other illegal drugs (route)", key: "other_illegal_drugs_route" },
        { label: "1. Heroin", key: "heroin" },
        { label: "Heroin (route)", key: "heroin_route" },
        { label: "2. Morphine", key: "morphine" },
        { label: "Morphine (route)", key: "morphine_route" },
        { label: "3. Dilaudid", key: "dilaudid" },
        { label: "Dilaudid (route)", key: "dilaudid_route" },
        { label: "4. Demerol", key: "demerol" },
        { label: "Demerol (route)", key: "demerol_route" },
        { label: "5. Percocet", key: "percocet" },
        { label: "Percocet (route)", key: "percocet_route" },
        { label: "6. Darvon", key: "darvon" },

        { label: "Darvon (route)", key: "darvon_route" },
        { label: "7. Codeine", key: "codeine" },
        { label: "Codeine (route)", key: "codeine_route" },
        { label: "8. Tylenol 2, 3, 4", key: "tylenol_2_3_4" },
        { label: "Tylenol 2, 3, 4 (route)", key: "tylenol_2_3_4_route" },
        { label: "9. OxyContin/Oxycodone", key: "oxycontin_oxycodone" },
        { label: "OxyContin/Oxycodone (route)", key: "oxycontin_oxycodone_route" },
        { label: "d. Non-prescription methadone", key: "non_prescription_methadone" },
        { label: "Non-prescription methadone (route)", key: "non_prescription_methadone_route" },
        { label: "Opioid", key: "opioid" },
        { label: "Referral Source", key: "referral_source" },
        { label: "Specific Referral Source", key: "specific_referral_source" },
        { label: "UAB SAMHSA Client ID", key: "uab_samhsa_client_id" },
        { label: "Ref. Target", key: "ref_target" },
        { label: "Agency Target", key: "agency_target" },
        { label: "If family/friends were involved, were they referred to any services?", key: "if_family_friends_were_involved" },
        { label: "Was the ASAM completed?", key: "was_the_asam_completed" },
        { label: "Assigned Peer Support Specialist:", key: "assigned_peer_support_specialist" },
        { label: "Current County", key: "current_county" },
        { label: "Have you ever overdosed?", key: "have_you_ever_overdosed" },
        { label: "New/Old", key: "new_old" },
        { label: "Trauma?", key: "trauma" },
        { label: "Suicide Attempt", key: "suicide_attempt" },
        { label: "Primary Phone Number", key: "primary_phone_number" },
        { label: "Address", key: "address" },
        { label: "Timestamp", key: "timestamp" },

    ];





    const handleOnchange = (e) => {
        let temporaryarray = viewcolumn.slice();
        temporaryarray[e.target.id - 1]["default_show"] = e.target.checked;
        setViewcolumn(temporaryarray);

    }

    const handleModal = (e) => {
        setIsmodalopen(!isModalOpen)
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        const headers = {
            'Content-Type': 'application/json'
        }
        let res = await axios.post(API_URL + `rrc_column?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}`, JSON.stringify(viewcolumn), { headers: headers });
        if (res.status == 200) {
            let display_rrc_column_res = await axios.post(API_URL + `display_rrc_column?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}`);
            if (display_rrc_column_res.status == 200) {
                setViewcolumn(display_rrc_column_res.data.data);
                let temp_arr = [];
                display_rrc_column_res.data.data.filter((val) => { return (val.default_show == true) }).map((val) => { temp_arr.push(val.id) })
                setselectedColumn(temp_arr)
                setIsOpen(false);

                toast.success('Display saved successfully!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })

                let rrc_monthly_report_res = await axios.get(API_URL + `rrc_monthly_report?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&startDate=${startDate}&endDate=${endDate}`);
                // console.log("rrc_monthly_report_res: ", rrc_monthly_report_res);
                // console.log("rrc_monthly_report_res: ", rrc_monthly_report_res.status, rrc_monthly_report_res.data.status);
                if (rrc_monthly_report_res.status == 200 && rrc_monthly_report_res.data.status) {
                    setRcrData(rrc_monthly_report_res.data.data);
                }

            }
        }
    }
    const afterOpenModal = () => {
        subtitle.style.color = '#f00';
    }
    function closeModal() {
        setIsOpen(false);
    }

    const resetModal = (e) => {
        e.preventDefault();
        let tempArr = [];
        viewcolumn.map((val) => {
            return tempArr.push({
                id: val.id,
                column: val.column,
                name: val.name,
                default_show: false
            })
        })
        setViewcolumn(tempArr)
    }

    const selectAllColumn = (e) => {
        e.preventDefault();
        let tempArr = [];
        viewcolumn.map((val) => {
            return tempArr.push({
                id: val.id,
                column: val.column,
                name: val.name,
                default_show: true
            })
        })
        setViewcolumn(tempArr)
    }

    return (
        <>
            <MainContainer>
                <Topnav dropdown={true} />{" "}

                <PageHeadingStyle>
                    <h3 className="py-2" style={{ color: "#89BC3B", textAlign: "center" }}>Org Monthly Report</h3>
                </PageHeadingStyle>


                <printreportPageContainer style={{ width: "100%" }}>
                    <div className="py-2" style={{ color: "#89BC3B", textAlign: "right", display: "inlineBlock" }} >
                        <img className="mr-2" style={{ cursor: "pointer" }} src={PrintReportIcon} alt="print" onClick={() => window.print()} />
                        <span className="mr-3" style={{ cursor: "pointer" }} onClick={() => window.print()}>Print Report</span>
                    </div>
                </printreportPageContainer>

                {/* <Header>
                    <Title>
                        <button style={{ display: "inlineBlock", width: "100px" }} className="btn btn-success" onClick={() => setIsOpen(true)}>Edit column</button>
                    </Title>

                    <DatePickerContainer style={{ border: "1px solid red" }}>

                       
                        <DateRangePicker
                            size="lg" placeholder="2022-01-01 ~ 2022-12-31" style={styles}
                            onChange={(e) => {
                                return ((e) ? (setstartDate(moment(e[0]).format("YYYY-MM-DD")), setendDate(moment(e[1]).format("YYYY-MM-DD"))) : (setstartDate('2022-01-01'), setendDate('2022-12-31')));
                            }}
                            className="RRCDatepickerRange"

                        />
                        <Button>
                            {" "}
                            <CSVLink
                                filename="org_monthly_report.csv"
                                data={csvData}
                                headers={headers_for_csvfile}
                                target="_blank"
                                style={{
                                    color: "#ffffff",
                                    width: "120px",
                                    height: "50px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                Download CSV{" "}
                            </CSVLink>
                        </Button>

                     

                    </DatePickerContainer>{" "}

                </Header>{" "} */}

                <div className="row " style={{width:"99.8%"}}>
                    <div className="col-6 col-lg-5">
                        {/* < Mycustomdiv style={{ justifyContent: "flex-start"  }}> */}
                            <button style={{ display: "block", width: "auto", padding: "15px 40px", backgroundColor: "#7BB731", color: "white" }} className="btn" onClick={() => setIsOpen(true)}>Edit column</button>
                        {/* </Mycustomdiv> */}
                    </div>

                    <div className=" col-6 col-lg-7" >
                        <div className="row">
                            <div className="col-xl-5"></div>
                            <div className="col-xl-4 col-lg-6">
                                {!modalIsOpen &&
                                 <DateRangePicker
                                 size="md" placeholder="2022-01-01 ~ 2022-12-31" style={styles}
                                 onChange={(e) => {
                                     return ((e) ? (setstartDate(moment(e[0]).format("YYYY-MM-DD")), setendDate(moment(e[1]).format("YYYY-MM-DD"))) : (setstartDate('2022-01-01'), setendDate('2022-12-31')));
                                 }}
                                 className="RRCDatepickerRange"
                                />
                                }
                               
                               
                            </div>
                            <div className="col-xl-3 col-lg-6">
                                <Button>
                                    {" "}
                                    <CSVLink
                                        filename="org_monthly_report.csv"
                                        data={csvData}
                                        headers={headers_for_csvfile}
                                        target="_blank"
                                        style={{
                                            color: "#ffffff",
                                            width: "100%",
                                            height: "50px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            textAlign: "center"
                                        }}
                                    >
                                        Download CSV{" "}
                                    </CSVLink>
                                </Button>
                            </div>



                        </div>

                    </div>

                    <div className=" col-6">

                    </div>
                    <div className=" col-6">

                    </div>


                </div>


                {/* ===================================== data table start =======================================*/}

                {/* New design start */}
                <div className="row">
                    <div className="col-2">
                        <TableContainer style={{ marginLeft: "0px" }} className="table-responsive">
                            <Table>
                                <TableHead>
                                    <TableHeadRow>
                                        {" "}
                                        <TableHeadRowColumnVal>
                                            <tr
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    padding: "10px 0px"

                                                }}
                                            >
                                                <th style={{ paddingLeft: "8px" }}> NAME </th>
                                                <td style={{ display: "flex", flexDirection: "column" }}>
                                                    <img
                                                        src={ChevronUpIcon}
                                                        style={{ marginBottom: "5px" }}
                                                        alt="ChevronUpIcon"
                                                    />
                                                    <img src={ChevronDownIcon} alt="ChevronDownIcon" />
                                                </td>{" "}
                                            </tr>
                                            <InputHolder>
                                                {/* <Input placeholder="Search" /> */}
                                            </InputHolder>{" "}
                                        </TableHeadRowColumnVal>
                                        {" "}
                                    </TableHeadRow>
                                </TableHead>
                                <TableBody>
                                    {getRcrData.map((e, key) => {
                                        return (
                                            <>
                                                <TableHeadRow style={{ borderBottom: "1px solid #dbd4d4" }}>
                                                    <td>  {e.name} </td>
                                                </TableHeadRow>
                                            </>
                                        )
                                    })}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div className="col-10">

                        <TableContainer style={{ marginLeft: "-15px" }}>
                            <Table >
                                <TableHead>
                                    <TableHeadRow>
                                        {" "}
                                        {viewcolumn.filter((val) => { return val.default_show == true && val.id != 7 }).map((col) => {
                                            return (
                                                // <th scope="col">{col.name.toUpperCase()}</th>
                                                <TableHeadRowColumnVal>
                                                    <tr
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                            padding: "10px 0px"
                                                        }}
                                                    >
                                                        <th style={{ paddingLeft: "8px" }}> {col.name.toUpperCase()} </th>
                                                        <td style={{ display: "flex", flexDirection: "column" }}>
                                                            <img
                                                                src={ChevronUpIcon}
                                                                style={{ marginBottom: "5px" }}
                                                                alt="ChevronUpIcon"
                                                            />
                                                            <img src={ChevronDownIcon} alt="ChevronDownIcon" />
                                                        </td>{" "}
                                                    </tr>
                                                    <InputHolder>
                                                        {/* <Input placeholder="Search" /> */}
                                                    </InputHolder>{" "}
                                                </TableHeadRowColumnVal>
                                            )
                                        })}

                                        {" "}
                                    </TableHeadRow>{" "}
                                </TableHead>
                                <TableBody>
                                    {/* {getRcrData.filter((val)=>{return val.id==7}).map((e, key)} */}
                                    {/* // {getRcrData.map((e, key) => { */}
                                    {getRcrData.filter((val) => { return val.name != "name" }).map((e, key) => {
                                        return (
                                            <>
                                                <TableHeadRow style={{ borderBottom: "1px solid #dbd4d4" }}>
                                                    {(selectedColumn?.includes('1') && <td>  {e.user} </td>)}
                                                    {(selectedColumn?.includes("2") && <td>{e.user_id}</td>)}
                                                    {(selectedColumn?.includes('3') && <td>  {e.client_id} </td>)}
                                                    {(selectedColumn?.includes('4') && <td>  {e.grpa_client_id} </td>)}
                                                    {(selectedColumn?.includes('5') && <td>  {e.entry_date} </td>)}
                                                    {(selectedColumn?.includes('6') && <td>  {e.day_of_the_week} </td>)}
                                                    {/* {(selectedColumn?.includes('7') && <td>  {e.name} </td>)} */}
                                                    {(selectedColumn?.includes('8') && <td>  {e.date_of_birth} </td>)}
                                                    {(selectedColumn?.includes('9') && <td>  {e.gender} </td>)}
                                                    {(selectedColumn?.includes('10') && <td>  {e.primary_race} </td>)}
                                                    {(selectedColumn?.includes('11') && <td>  {e.ethnicity} </td>)}
                                                    {(selectedColumn?.includes('12') && <td>  {e.current_zip} </td>)}
                                                    {(selectedColumn?.includes('13') && <td>  {e.total_monthly_income} </td>)}
                                                    {(selectedColumn?.includes('14') && <td>  {e.any_alcohol} </td>)}
                                                    {(selectedColumn?.includes('15') && <td>  {e.alcohol_to_intoxication_5} </td>)}
                                                    {(selectedColumn?.includes('16') && <td>  {e.alcohol_to_intoxication} </td>)}
                                                    {(selectedColumn?.includes('17') && <td>  {e.cocaine_crack} </td>)}
                                                    {(selectedColumn?.includes('18') && <td>  {e.Cocaine_crack_route} </td>)}
                                                    {(selectedColumn?.includes('19') && <td>  {e.benzodiazepines} </td>)}
                                                    {(selectedColumn?.includes('20') && <td>  {e.benzodiazepines_route} </td>)}
                                                    {(selectedColumn?.includes('21') && <td>  {e.barbiturates} </td>)}
                                                    {(selectedColumn?.includes('22') && <td>  {e.barbiturates_route} </td>)}
                                                    {(selectedColumn?.includes('23') && <td>  {e.marijuana_hashish} </td>)}
                                                    {(selectedColumn?.includes('24') && <td>  {e.marijuana_hashish_route} </td>)}
                                                    {(selectedColumn?.includes('25') && <td>  {e.hallucinogens_psychedelics} </td>)}
                                                    {(selectedColumn?.includes('26') && <td>  {e.hallucinogens_psychedelics_route} </td>)}
                                                    {(selectedColumn?.includes('27') && <td>  {e.methamphetamine_or_other_amphetamines} </td>)}
                                                    {(selectedColumn?.includes('28') && <td>  {e.methamphetamine_or_other_amphetamines_route} </td>)}
                                                    {(selectedColumn?.includes('29') && <td>  {e.inhalants} </td>)}
                                                    {(selectedColumn?.includes('30') && <td>  {e.inhalants_route} </td>)}
                                                    {(selectedColumn?.includes('31') && <td>  {e.non_prescription_ghb} </td>)}
                                                    {(selectedColumn?.includes('32') && <td>  {e.non_prescription_ghb_route} </td>)}
                                                    {(selectedColumn?.includes('33') && <td>  {e.ketamine} </td>)}
                                                    {(selectedColumn?.includes('34') && <td>  {e.ketamine_route} </td>)}
                                                    {(selectedColumn?.includes('35') && <td>  {e.other_tranquilizers_downers_sedatives_or_hypnotics} </td>)}
                                                    {(selectedColumn?.includes('36') && <td>  {e.other_tranquilizers_downers_sedatives_or_hypnotics_route} </td>)}
                                                    {(selectedColumn?.includes('37') && <td>  {e.other} </td>)}
                                                    {(selectedColumn?.includes('38') && <td>  {e.other_illegal_drugs_specify} </td>)}
                                                    {(selectedColumn?.includes('39') && <td>  {e.other_illegal_drugs_route} </td>)}
                                                    {(selectedColumn?.includes('40') && <td>  {e.heroin} </td>)}
                                                    {(selectedColumn?.includes('41') && <td>  {e.heroin_route} </td>)}
                                                    {(selectedColumn?.includes('42') && <td>  {e.morphine} </td>)}
                                                    {(selectedColumn?.includes('43') && <td>  {e.morphine_route} </td>)}
                                                    {(selectedColumn?.includes('44') && <td>  {e.dilaudid} </td>)}
                                                    {(selectedColumn?.includes('45') && <td>  {e.dilaudid_route} </td>)}
                                                    {(selectedColumn?.includes('46') && <td>  {e.demerol} </td>)}
                                                    {(selectedColumn?.includes('47') && <td>  {e.demerol_route} </td>)}
                                                    {(selectedColumn?.includes('48') && <td>  {e.percocet} </td>)}
                                                    {(selectedColumn?.includes('49') && <td>  {e.percocet_route} </td>)}
                                                    {(selectedColumn?.includes('50') && <td>  {e.darvon} </td>)}
                                                    {(selectedColumn?.includes('51') && <td>  {e.darvon_route} </td>)}
                                                    {(selectedColumn?.includes('52') && <td>  {e.codeine} </td>)}
                                                    {(selectedColumn?.includes('53') && <td>  {e.codeine_route} </td>)}
                                                    {(selectedColumn?.includes('54') && <td>  {e.tylenol} </td>)}
                                                    {(selectedColumn?.includes('55') && <td>  {e.tylenol_2} </td>)}
                                                    {(selectedColumn?.includes('56') && <td>  {e.oxyContin_Oxycodone} </td>)}
                                                    {(selectedColumn?.includes('57') && <td>  {e.oxyContin_Oxycodone_route} </td>)}
                                                    {(selectedColumn?.includes('58') && <td>  {e.non_prescription_methadone} </td>)}
                                                    {(selectedColumn?.includes('59') && <td>  {e.non_prescription_methadone_route} </td>)}
                                                    {(selectedColumn?.includes('60') && <td>  {e.opioid} </td>)}
                                                    {(selectedColumn?.includes('61') && <td>  {e.referral_source} </td>)}
                                                    {(selectedColumn?.includes('62') && <td>  {e.specific_referral_source} </td>)}
                                                    {(selectedColumn?.includes('63') && <td>  {e.uab_samhsa_client_id} </td>)}
                                                    {(selectedColumn?.includes('64') && <td>  {e.ref_target} </td>)}
                                                    {(selectedColumn?.includes('65') && <td>  {e.agency_target} </td>)}
                                                    {(selectedColumn?.includes('66') && <td>  {e.if_family_friends_were_involved_were_they_referred_to_any_services} </td>)}
                                                    {(selectedColumn?.includes('67') && <td>  {e.was_the_asam_completed} </td>)}
                                                    {(selectedColumn?.includes('68') && <td>  {e.assigned_peer_support_specialist} </td>)}
                                                    {(selectedColumn?.includes('69') && <td>  {e.current_county} </td>)}
                                                    {(selectedColumn?.includes('70') && <td>  {e.have_you_ever_overdosed} </td>)}
                                                    {(selectedColumn?.includes('71') && <td>  {e.new_Old} </td>)}
                                                    {(selectedColumn?.includes('72') && <td>  {e.trauma} </td>)}
                                                    {(selectedColumn?.includes('73') && <td>  {e.suicide_attempt} </td>)}
                                                    {(selectedColumn?.includes('74') && <td>  {e.primary_phone_number} </td>)}
                                                    {(selectedColumn?.includes('75') && <td>  {e.address} </td>)}
                                                    {(selectedColumn?.includes('76') && <td>  {e.timestamp} </td>)}
                                                </TableHeadRow>
                                            </>
                                        )
                                    })}

                                </TableBody>
                            </Table>
                        </TableContainer>{" "}
                        {/* New design end */}
                    </div>
                </div>

            </MainContainer>


            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Edit Column"
                ariaHideApp={false}
            // className="modal-xl"
            >
                <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>

                {/* <CustomModalContainer> */}
                <form id="">
                    <h3 style={{ color: "#89BC3B", padding: "10px 0px 10px 0px" }}>Edit Column</h3>
                    <div className="row">
                        {viewcolumn.map((val) => {
                            return (<div className="col-4">
                                {val.id == 7 ? <>
                                    <input type="checkbox" disabled id={val.id} name={val.column} checked={true} style={{ marginRight: "10px" }} />
                                </> : <>
                                    <input type="checkbox" id={val.id} name={val.column} checked={val.default_show} style={{ marginRight: "10px" }} onChange={(e) => { handleOnchange(e) }} />
                                </>}

                                <label for="coding"> {val.name.toUpperCase()}</label>
                            </div>)
                        })}
                    </div>

                    <div className="row">
                        <div className="col-10">
                            <button onClick={closeModal} className="btn btn-danger" >Close</button>
                            <button onClick={resetModal} className="btn btn-warning" style={{ marginLeft: "20px" }}>Reset</button>
                            <button onClick={selectAllColumn} className="btn btn-primary" style={{ marginLeft: "20px" }}>Select all</button>

                        </div>
                        <div className="col-2">
                            <button type="submit" style={{ float: "right" }} onClick={(e) => handleOnSubmit(e)} className="btn btn-success">Submit form</button>
                        </div>
                    </div>
                </form>
                {/* </CustomModalContainer> */}
            </Modal>




        </>
    );
}

export default ReferralMonthReport;
