import React, { Component } from 'react';
import $ from 'jquery';
import Topnav from './Topnav';
import Footer from './Footer';
import Patientinfoheader from './Patientinfoheader';
import Sidenav from './Sidenav';
import DatetimeRangePicker from 'react-bootstrap-datetimerangepicker';
import DateTime from 'react-datetime';
import moment from 'moment';
import { validationParser, disable_button } from '../ReusableComponents/validationParser2';
import { selectOptionsGenerator } from '../ReusableComponents/OptionsGenerator';
import {
  Button,
} from 'react-bootstrap';
import { can_edit } from '../ReusableComponents/urlGenerator';

import { InputTextField, LabelField } from '../ReusableComponents/LabelnInput'
import { ConfirmBeforeLeave } from './ConfirmBeforeLeave';
import { Alert } from "./toastify";
import ReactTooltip from 'react-tooltip';
import { encryptStorage } from './LocalStorageInterceptor';
import TextAreaAutosize from 'react-textarea-autosize';
import {  toast } from 'react-toastify';

var validationObj = {};
var context = '';

const axios = require('axios').default;
class Homelessness extends Component {
  constructor() {
    super();
    context = this;
    this.handleId = this.handleId.bind(this);
    this.handleDataChange = this.handleDataChange.bind(this);

    //this.handleApply = this.handleApply.bind(this);
    //this.handleCancel = this.handleCancel.bind(this);
    this.handleEvent = this.handleEvent.bind(this);

    this.getTimeStamp = this.getTimeStamp.bind(this);


    this.state = {

      startDate: moment().subtract(29, 'days'),
      endDate: moment(),
      homelessnessdata: this.getModal(),
      required: this.getModal(),
      labelText: this.getModal(),
      tooltips: this.getModal(),
      isHomelessOptions: [],
      agencyOptions: [],
      regularlyStayShelterOptions: [],
      tooltipVisibility: this.getModal(),
      unSaved: false,
      maxLength: this.getModal(),
    };
  }

  getModal() {
    var homelessnessModal = {
      is_homeless: '',

      agency: '',
      known_worker_name: '',
      // add code by raj start
      other_regularly_stay_shelter: '',
      other_agency: "",
      // add code by raj end
      regularly_stay_shelter: '',
      stay_on_street_outdoor: '',
      stay_anywhere_else: '',
      where_buy_necessities: '',
      which_library: '',
      at_library_since: '',
      at_library_till: '',
    };

    return homelessnessModal;
  }


  componentWillMount() {
    //for validation

    console.log(localStorage.getItem('__TOKEN__'));

    axios.get(global.restApiURL + 'webservice/rest/class/id/6?apikey=' + sessionStorage.getItem('__CL_TOKEN__'))
      .then(res => {
        var responce = res.data;

        validationObj = validationParser(responce.data.layoutDefinitions.childs[0].childs);
        this.setState({
          required: validationObj.mandatory,
          labelText: validationObj.title,
          tooltips: validationObj.tooltip,
          maxLength: validationObj.columnLength,
          tooltipVisibility: validationObj.tooltipVisibility,
        });

        this.createSelectOptions();

      })
      .catch(err => { // log request error and prevent access to undefined state
        this.setState({ loading: false, error: true });
        console.error(err);
      })
  }
  componentDidMount() {
    disable_button('homelessnessinfoform');
    var prevExist = false;
    sessionStorage.setItem('responceId', '');
    // for client info
    if (sessionStorage.getItem('clientId') !== '') {

      axios.get(global.restApiURL + 'webservice/rest/object-list?apikey=' + localStorage.getItem('__TOKEN__') + '&limit=1&objectClass=HomelessnessDetails&q={"client_id":"' + sessionStorage.getItem('clientId') + '"}')
        .then(res => {
          const responce = res.data.data; // get the data array instead of object

          if (responce[0].id !== '') {
            prevExist = true;
            axios.get(global.restApiURL + 'webservice/rest/object/id/' + responce[0].id + '?apikey=' + localStorage.getItem('__TOKEN__'))
              .then(res => {

                sessionStorage.setItem('responceId', res.data.data.id);
                const responce = res.data.data.elements;

                this.datamapper(responce)


              })
              .catch(err => { // log request error and prevent access to undefined state
                this.setState({ loading: false, error: true });
                console.error(err);
              })
          } else {
            prevExist = false;
          }
        })
        .catch(err => { // log request error and prevent access to undefined state
          this.setState({ loading: false, error: true });
          console.error(err);
        })
    }



    $.validator.setDefaults({
      submitHandler: function () {

        var jsonData = '';
        if (prevExist === true) {
          jsonData = { 'className': 'HomelessnessDetails', 'key': sessionStorage.getItem('clientId'), 'id': sessionStorage.getItem('responceId'), 'published': true };
        } else {
          jsonData = { 'className': 'HomelessnessDetails', 'key': sessionStorage.getItem('clientId'), 'published': true };
        }

        jsonData.elements = [];
        var formData = $("#homelessnessinfoform").serializeArray();
        $.each(formData, function () {
          jsonData.elements.push({ name: this.name, value: this.value });
        });
        var json = JSON.stringify(jsonData);

        axios.post(sessionStorage.getItem('hangout_save_url'), json)
          .then(function (res) {
            var responce = res.data;
            sessionStorage.setItem('success_msg', "Homelessness Details saved successfully")

            if ($('#clicked').val() === 'proceed') {
              window.location.href = global.domain + global.traversArray['homelessness'];
            } else {
              context.setState({ unSaved: false })
              if (!sessionStorage.getItem('responceId')) {
                sessionStorage.setItem('responceId', responce.id)
              }
              prevExist = true;
              Alert("success", "Homelessness Details saved successfully")
            }
          })
          .catch(function (err) {
            console.log(err.message);
          });
      }
    });
  }

  createSelectOptions() {
    if (validationObj !== "") {
      this.setState({
        isHomelessOptions: selectOptionsGenerator(validationObj.options.is_homeless, this.state.homelessnessdata.is_homeless),
        agencyOptions: selectOptionsGenerator(validationObj.options.agency, this.state.homelessnessdata.agency),
        regularlyStayShelterOptions: selectOptionsGenerator(validationObj.options.regularly_stay_shelter, this.state.homelessnessdata.regularly_stay_shelter),
      });
    }
  }

  datamapper(arr) {
    //console.log(arr);
    let obj = {
      is_homeless: '',

      agency: '',
      known_worker_name: '',
      // add code by raj start
      other_agency: '',
      other_regularly_stay_shelter: '',
      // add code by raj end
      regularly_stay_shelter: '',
      stay_on_street_outdoor: '',
      stay_anywhere_else: '',
      where_buy_necessities: '',
      which_library: '',
      at_library_since: '',
      at_library_till: '',
    }
    $.each(arr, function (index, value) {
      //console.log(value.name+"==="+value.value)
      if (value.name === 'is_homeless') {
        obj.is_homeless = value.value;
      }
      if (value.name === 'agency') {
        obj.agency = value.value;
      }
      if (value.name === 'other_agency') {
        obj.other_agency = value.value;
      }
      if (value.name === 'known_worker_name') {
        obj.known_worker_name = value.value;
      }
      if (value.name === 'regularly_stay_shelter') {
        obj.regularly_stay_shelter = value.value;
      }
      if (value.name === 'other_regularly_stay_shelter') {
        obj.other_regularly_stay_shelter = value.value;
      }
      if (value.name === 'at_library_since') {
        obj.at_library_since = value.value;
        obj.at_library_since = value.value !== '' && value.value !== null && value.value !== undefined ? moment(value.value).format("MM-DD-YYYY HH:mm:ss") : '';

      }
      if (value.name === 'stay_on_street_outdoor') {
        obj.stay_on_street_outdoor = value.value;
      }
      if (value.name === 'stay_anywhere_else') {
        obj.stay_anywhere_else = value.value;
      }
      if (value.name === 'where_buy_necessities') {
        obj.where_buy_necessities = value.value;
      }
      if (value.name === 'which_library') {
        obj.which_library = value.value;
      }
      if (value.name === 'at_library_till') {
        //obj.at_library_till= value.value;
        obj.at_library_till = value.value !== '' && value.value !== null && value.value !== undefined ? moment(value.value).format("MM-DD-YYYY HH:mm:ss") : '';
      }

    });

    this.setState({
      homelessnessdata: obj,
    });
    this.setState({
      startDate: moment(this.state.homelessnessdata.at_library_since, 'MM/DD/YYYY HH:mm:ss'),
      endDate: moment(this.state.homelessnessdata.at_library_till, 'MM/DD/YYYY HH:mm:ss'),

    });

    this.createSelectOptions();

  }
  getTimeStamp(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  handleId = (e) => {
    const formData = $("#homelessnessinfoform").serializeArray();
    const isFormValid = formData.some(field => field.name !== "client_id" && field.value !== "");
    if ( !isFormValid) {
      toast.error('Empty Information is not saved!', { toastId: "Empty Information is not saved!" }, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      e.preventDefault();
      return false;
    }
    $('#clicked').val(e.target.value);
    $('#clicked').val(e.currentTarget.value);
  }

  saveChanges() {
    const formData = $("#homelessnessinfoform").serializeArray();
    const isFormValid = formData.some(field => field.name !== "client_id" && field.value !== "");
    if ( !isFormValid) {
      toast.error('Empty Information is not saved!', { toastId: "Empty Information is not saved!" }, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
        return false;
    }
    $('#clicked').val('save');
    $('#homelessnessinfoform').submit();

  }

  renderYear(props, year, selectedDate) {
    return <td {...props}>{year % 100}</td>;
  }

  validToDate(current) {
    return current.isAfter(moment(context.state.homelessnessdata.at_library_since));
  };

  /*validFromDate( current ){
      return current.isBefore( moment(context.state.homelessnessdata.at_library_till) );
  };*/
  handleDataChange = (event) => {
    $(".saveButtonProceed1").attr("disabled", false);
    $(".saveButton1").attr("disabled", false);
    $(".saveButtonProceed2").attr("disabled", false);
    $(".saveButton2").attr("disabled", false);
    event.persist();
    console.log('data change', event)
    var stateCopy = Object.assign({}, this.state);
    stateCopy.homelessnessdata[event.target.name] = event.target.value;
    stateCopy['unSaved'] = true;
    this.setState(stateCopy);
  }

  handleEvent(event, picker) {
    this.setState({
      startDate: picker.startDate,
      endDate: picker.endDate,
      unSaved: true,
    });

  }

  handleTimer(date, field) {
    console.log("timer", date, field, typeof date._id);
    let homelessnessdata_temp = this.state.homelessnessdata;

    homelessnessdata_temp[field] = date._d !== undefined ? moment(date._d).format('MM/DD/YYYY hh:mm A') : date;

    context.setState({ homelessnessdata: homelessnessdata_temp, unSaved: true });
  };

  // handleTimeronBlur(date, field) {

  //   // date = context.state.homelessnessdata[field];
  //   console.log("timer 2", date, field);
  //   let homelessnessdata_temp = this.state.homelessnessdata;

  //   homelessnessdata_temp[field] = typeof date !== 'string' ? moment(date._d).format('MM/DD/YYYY hh:mm A') : moment().format('MM/DD/YYYY hh:mm A');

  //   context.setState({ homelessnessdata: homelessnessdata_temp, unSaved: true });
  // };
  handleTimeronBlur(date, field) {
    let homelessnessdata_temp = this.state.homelessnessdata;
    if (homelessnessdata_temp.at_library_since === "" || homelessnessdata_temp.at_library_since === "") {
      this.setState((prevState) => ({ ...prevState, homelessnessdata: { ...prevState.homelessnessdata, [field]: "" } }));
      return
    }
    const formattedDate = moment(date, "MM/DD/YYYY", true);
    if (!formattedDate.isValid() && date !== "Invalid date") {
      alert("Invalid Date");
      this.setState((prevState) => ({ ...prevState, homelessnessdata: { ...prevState.homelessnessdata, [field]: "" } }));
      return false;
    }
    if (homelessnessdata_temp.at_library_till === "" && date._i !== "") {
      homelessnessdata_temp.at_library_till = ""
    } else if (homelessnessdata_temp.at_library_since === "" && date === "") {
      homelessnessdata_temp.at_library_since = ""
    } else {
      homelessnessdata_temp[field] = typeof date !== 'string' ? moment(date._d).format('MM/DD/YYYY hh:mm A') : moment().format('MM/DD/YYYY hh:mm A');
    }
    context.setState({ homelessnessdata: homelessnessdata_temp, unSaved: true });
  };

  render() {
    sessionStorage.setItem('formname', 'Homelessness Details')

    let start = this.state.startDate.format('MM/DD/YYYY HH:mm A');

    let end = this.state.endDate.format('MM/DD/YYYY HH:mm A');
    let label = start + ' - ' + end;
    if (start === end) {
      label = start;
    }

    let locale = {
      format: 'YYYY-MM-DD',
      separator: ' - ',
      applyLabel: 'Apply',
      cancelLabel: 'Clear',
      weekLabel: 'W',
      customRangeLabel: 'Custom Range',
      daysOfWeek: moment.weekdaysMin(),
      monthNames: moment.monthsShort(),
      firstDay: moment.localeData().firstDayOfWeek(),
    };




    $(document).ready(function () {

      $('#homelessnessinfoform').validate({
        rules: {

        },
        errorElement: 'span',
        errorPlacement: function (error, element) {
          error.addClass('invalid-feedback');
          element.closest('.form-group').append(error);
        },
        highlight: function (element, errorClass, validClass) {
          $(element).addClass('is-invalid');
        },
        unhighlight: function (element, errorClass, validClass) {
          $(element).removeClass('is-invalid');
        }
      });
    });

    return (
      <div className="hold-transition sidebar-mini text-sm">
        <ConfirmBeforeLeave onRef={ref => (this.childForm = ref)} unSaved={context.state.unSaved} onSaveRequest={() => context.saveChanges()} />
        <ReactTooltip place="right" type="dark" effect="solid" />
        <div className="wrapper">
          <Topnav />
          <div className="content-wrapper">

            <Patientinfoheader clientId={sessionStorage.getItem('clientId')} dob={encryptStorage.getItem('dob')} email={encryptStorage.getItem('email')} phone={encryptStorage.getItem('phone')} peer_support_specialist={encryptStorage.getItem('peer_support_specialist')} next_followup_date={encryptStorage.getItem('month_1_followup_date')} intake_date={encryptStorage.getItem('intake_date')} initiated_by={encryptStorage.getItem('last_updated_by')} formname={sessionStorage.getItem('formname')} client_name={encryptStorage.getItem('client_first_name') + " " + encryptStorage.getItem('client_middle_initial') + " " + encryptStorage.getItem('client_last_name')} />

            <section className="content">
              <form method="post" id="homelessnessinfoform">

                <div className="row">
                  <Sidenav />
                  <fieldset disabled={!can_edit('HomelessnessDetails')} className="container-fluid col-md-9 col-lg-10">

                    <div className="card card-default">


                      <div className="card-header">
                        <p className="lead float-left"><b className="text-green">Homelessness Details</b></p>
                        <button type="submit" className="btn btn-primary float-right saveButtonProceed1" value="proceed" onClick={this.handleId}>
                          <img src="dist/img/save-proceed_icon.png" alt="" /> Save &amp; Proceed </button>
                        <button type="submit" value='save' className="btn btn-success float-right btnmargin saveButton1" onClick={this.handleId}> <i className="fas fa-save"></i> Save</button>
                        <input type="hidden" id="clicked" value="" readOnly />

                        <input type="hidden" id="client_id" name="client_id" defaultValue={sessionStorage.getItem('clientId')} />

                      </div>
                      <div className="card-body">

                        <div className="row">

                          <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                              <LabelField title={this.state.labelText.is_homeless} mandatory={this.state.required.is_homeless} name="is_homeless" tooltip={this.state.tooltips.is_homeless} id="is_homeless" />
                              <select className="custom-select" id="is_homeless" name="is_homeless" onChange={this.handleDataChange} required={this.state.required.is_homeless}>
                                {this.state.isHomelessOptions}
                              </select>
                            </div>
                          </div>


                          <div className="col-lg-4 col-md-6">

                            <div className="form-group">
                              <LabelField title={this.state.labelText.regularly_stay_shelter} mandatory={this.state.required.regularly_stay_shelter} name="regularly_stay_shelter" tooltip={this.state.tooltips.regularly_stay_shelter} id="regularly_stay_shelter" />
                              <select className="custom-select" id="regularly_stay_shelter" name="regularly_stay_shelter" onChange={this.handleDataChange} required={this.state.required.regularly_stay_shelter} >
                                {this.state.regularlyStayShelterOptions}
                              </select>
                            </div>

                          </div>
                          {this.state.homelessnessdata.regularly_stay_shelter === "Other" ? (
                            <div className="col-lg-4 col-md-6">
                              <div className="form-group">
                                <label >If other, specify</label>
                                <TextAreaAutosize
                                  name="other_regularly_stay_shelter"
                                  className="form-control"
                                  onChange={this.handleDataChange}
                                  defaultValue={this.state.homelessnessdata.other_regularly_stay_shelter}
                                />
                              </div>
                            </div>
                          ) : ""
                          }
                          <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                              <InputTextField labelText={this.state.labelText.known_worker_name} placeholder={this.state.labelText.known_worker_name} type="text" maxLength={this.state.maxLength.known_worker_name} className="form-control" id="known_worker_name" name="known_worker_name" defaultValue={this.state.homelessnessdata.known_worker_name} required={this.state.required.known_worker_name} tooltipText={this.state.tooltips.known_worker_name} tooltipVisibility={this.state.tooltipVisibility.known_worker_name} onChange={this.handleDataChange} />
                            </div>
                          </div>


                          <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                              <LabelField title={this.state.labelText.agency} mandatory={this.state.required.agency} name="agency" tooltip={this.state.tooltips.agency} id="agency" />
                              <select className="custom-select my_agency" id="agency" name="agency" required={this.state.required.agency} onChange={this.handleDataChange}>
                                {this.state.agencyOptions}
                              </select>
                            </div>
                          </div>
                          {this.state.homelessnessdata.agency === "Other (Please Specify Below)" ? (
                            <div className="col-lg-4 col-md-6">
                              <div className="form-group">
                                <label >If other, specify</label>
                                <TextAreaAutosize
                                  type="text"
                                  name="other_agency"
                                  className="form-control"
                                  onChange={this.handleDataChange}
                                  defaultValue={this.state.homelessnessdata.other_agency}

                                />
                              </div>
                            </div>
                          ) : ""
                          }

                          <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                              <InputTextField labelText={this.state.labelText.stay_on_street_outdoor} placeholder={this.state.labelText.stay_on_street_outdoor} type="text" maxLength={this.state.maxLength.stay_on_street_outdoor} className="form-control" id="stay_on_street_outdoor" name="stay_on_street_outdoor" defaultValue={this.state.homelessnessdata.stay_on_street_outdoor} required={this.state.required.stay_on_street_outdoor} tooltipText={this.state.tooltips.stay_on_street_outdoor} tooltipVisibility={this.state.tooltipVisibility.stay_on_street_outdoor} onChange={this.handleDataChange} />
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                              <InputTextField labelText={this.state.labelText.stay_anywhere_else} placeholder={this.state.labelText.stay_anywhere_else} type="text" maxLength={this.state.maxLength.stay_anywhere_else} className="form-control" id="stay_anywhere_else" name="stay_anywhere_else" defaultValue={this.state.homelessnessdata.stay_anywhere_else} required={this.state.required.stay_anywhere_else} tooltipText={this.state.tooltips.stay_anywhere_else} tooltipVisibility={this.state.tooltipVisibility.stay_anywhere_else} onChange={this.handleDataChange} />
                            </div>
                          </div>


                          <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                              <InputTextField labelText={this.state.labelText.where_buy_necessities} placeholder={this.state.labelText.where_buy_necessities} type="text" maxLength={this.state.maxLength.where_buy_necessities} className="form-control" id="where_buy_necessities" name="where_buy_necessities" defaultValue={this.state.homelessnessdata.where_buy_necessities} required={this.state.required.where_buy_necessities} tooltipText={this.state.tooltips.where_buy_necessities} tooltipVisibility={this.state.tooltipVisibility.where_buy_necessities} onChange={this.handleDataChange} />
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-6">
                            <div className="form-group">
                              <InputTextField labelText={this.state.labelText.which_library} placeholder={this.state.labelText.which_library} type="text" maxLength={this.state.maxLength.which_library} className="form-control" id="which_library" name="which_library" defaultValue={this.state.homelessnessdata.which_library} required={this.state.required.which_library} tooltipText={this.state.tooltips.which_library} tooltipVisibility={this.state.tooltipVisibility.which_library} onChange={this.handleDataChange} />
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-12">
                            <div className="">
                              <LabelField title={this.state.labelText.at_library_since} mandatory={this.state.required.at_library_since} name="at_library_since" tooltip={this.state.tooltips.at_library_since} id="at_library_since" /> (MM/DD/YYYY)
                              {/*<div className="input-group">
                                 <div className="input-group-prepend">
                                   <span className="input-group-text"><i className="far fa-clock"></i></span>
                                    </div>
                                   <div className="datetimepicker_custom">
                                      <DatetimeRangePicker
                                        autoUpdateInput={false}
                                        timePicker
                                        timePicker24Hour
                                        showDropdowns
                                        timePickerSeconds
                                        locale={locale}
                                        startDate={this.state.startDate}
                                        endDate={this.state.endDate}
                                        onEvent={this.handleEvent}
                                      >
                                        <div className="input-group"  >
                                        <input type="text" className="form-control" value={label} id="datetime_picker" required={this.state.required.at_library_since} maxLength={this.state.maxLength.at_library_since}/>
                                        </div>
                                     </DatetimeRangePicker>
                                    </div>
                                  </div>
                                   <input type="hidden"  id="at_library_since" name="at_library_since" value={moment(this.state.startDate).format("MM/DD/YYYY HH:mm:ss")} readOnly={true}/>
                                   <input type="hidden"  id="at_library_till" name="at_library_till" value={moment(this.state.endDate).format("MM/DD/YYYY HH:mm:ss")} readOnly={true}/>

                                 */}
                              <div className="row">
                                <div className="col-lg-6 col-md-6 form-group">

                                  <div className="input-group-append" style={{ height: '38px', float: 'left', marginRight: '-3px' }}>
                                    <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
                                  </div>
                                  <div className="input-group date timepicker" style={{ width: "calc(100% - 41px)" }}>

                                    <span >

                                      {/* <input type="text" className="form-control datetimepicker-input" data-target=".timepicker"/> */}
                                      {/*<DateTime />*/}
                                      <DateTime
                                        onChange={(date) => this.handleTimer(date, 'at_library_since')}
                                        onBlur={(date) => this.handleTimeronBlur(date, 'at_library_since')}
                                        inputProps={{ value: typeof context.state.homelessnessdata.at_library_since !== '' && context.state.homelessnessdata.at_library_since !== null && context.state.homelessnessdata.at_library_since !== undefined ? context.state.homelessnessdata.at_library_since : moment.utc(context.state.homelessnessdata.at_library_since).format('MM/DD/YYYY hh:mm A'), required: context.state.required.at_library_since, placeholder: 'Date' }}
                                        defaultValue={moment.utc(this.state.homelessnessdata.at_library_since).format('MM/DD/YYYY hh:mm A')}
                                        input={true}

                                      /*isValidDate={ this.validFromDate }*/

                                      />
                                    </span>

                                  </div>
                                  <input type="hidden" id="at_library_since" name="at_library_since" value={this.state.homelessnessdata.at_library_since !== '' && this.state.homelessnessdata.at_library_since !== null && this.state.homelessnessdata.at_library_since !== undefined ? moment(this.state.homelessnessdata.at_library_since).format("MM/DD/YYYY HH:mm:ss") : ''} readOnly={true} />




                                </div>

                                <div className="col-lg-6 col-md-6 form-group">


                                  <div className="input-group-append" style={{ height: '38px', float: 'left', marginRight: '-3px' }}>
                                    <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
                                  </div>
                                  <div className="input-group date timepicker" style={{ width: "calc(100% - 41px)" }}>

                                    <span >

                                      {/* <input type="text" className="form-control datetimepicker-input" data-target=".timepicker"/> */}
                                      {/* <DateTime /> */}
                                      <DateTime
                                        onChange={(date) => this.handleTimer(date, 'at_library_till')}
                                        onBlur={(date) => this.handleTimeronBlur(date, 'at_library_till')}
                                        viewMode='days'
                                        inputProps={{ value: typeof context.state.homelessnessdata.at_library_till !== '' && context.state.homelessnessdata.at_library_till !== null && context.state.homelessnessdata.at_library_till !== undefined ? context.state.homelessnessdata.at_library_till : moment.utc(context.state.homelessnessdata.at_library_till).format('MM/DD/YYYY hh:mm A'), required: context.state.required.at_library_till, placeholder: 'Date' }}
                                        defaultValue={moment.utc().format('MM/DD/YYYY hh:mm A')}
                                        isValidDate={this.validToDate}
                                        input={true}
                                      />
                                    </span>
                                  </div>
                                </div>
                                <input type="hidden" id="at_library_till" name="at_library_till" value={this.state.homelessnessdata.at_library_till !== '' && this.state.homelessnessdata.at_library_till !== null && this.state.homelessnessdata.at_library_till !== undefined ? moment(this.state.homelessnessdata.at_library_till).format("MM/DD/YYYY HH:mm:ss") : ''} readOnly={true} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer float-right" >
                        <div className="float-right">
                          <button type="submit" className="btn btn-primary float-right saveButtonProceed2" value="proceed" onClick={this.handleId}>
                            <img src="dist/img/save-proceed_icon.png" alt="" /> Save &amp; Proceed </button>
                          <button type="submit" id="saveButton" value='save' className="btn btn-success float-right btnmargin saveButton2" onClick={this.handleId}> <i className="fas fa-save"></i> Save</button>
                        </div>
                      </div>



                    </div>


                  </fieldset>
                </div>

              </form>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    )

  }
}
export default Homelessness
