import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import './FileUpload.css'
import axios from 'axios'
import { toast } from 'react-toastify'

const FileUpload = ({ files, setFiles, removeFile }) => {
  const dataListBody = {
        apikey: localStorage.getItem('__TOKEN__'),
        client_id: sessionStorage.getItem("clientId"),
    };
    const uploadHandler = async(event) => {
        let allFiles=[];
       try{
        const formData = new FormData();
        formData.append("dataListBody",JSON.stringify(dataListBody))
        for(let i=0;i<event.target.files.length;i++){
            allFiles.push(event.target.files[i].name)
            formData.append( "client_docs_"+i,event.target.files[i])
        }
        // upload file
       let res=await axios.post( global.restApiURL +'customrest/uploadClientDocs', formData);
       if(res.status==200 && res.data.success){
        let res_listing=await axios.post( global.restApiURL +'customrest/getFileDocs', JSON.stringify({dataListBody:dataListBody}));
        if(res_listing.data.success){
            toast.success('File uploaded successfully!', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                // theme: "light",
                });
            setFiles(res_listing.data.files)
        }
       }
       }catch{
        alert("Something going wrong Please try again...")
       }
       
       
        // axios.post( global.restApiURL +'customrest/uploadClientDocs', formData)
        //     .then((res) => {
        //         file.isUploading = false;
        //         setFiles([...files, file])
        //     })
        //     .catch((err) => {
        //         // inform the user
        //         console.error(err)
        //         removeFile(file.name)
        //     });
    }

    return (
        <>
            <div className="file-card">

                <div className="file-inputs">
                    <input type="file" onChange={uploadHandler} multiple/>
                    <button>
                        <i>
                            <FontAwesomeIcon icon={faPlus} />
                        </i>
                        Upload
                    </button>
                </div>

                {/* <p className="main">Supported files</p>
                <p className="info">PDF, JPG, PNG</p> */}

            </div>
        </>
    )
}

export default FileUpload
