import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Topnav from './Topnav';
import Footer from './Footer';
import Patientinfoheader from './Patientinfoheader';
import Sidenav from './Sidenav';
import { validationParser } from '../ReusableComponents/validationParser2';
import { validationParser1 } from '../ReusableComponents/validationParser';
import { LabelField } from '../ReusableComponents/LabelnInput';
import { formObjDataMapper, formObjDataMapper2, parseMultiSelectField } from '../ReusableComponents/formObjDataMapper';
import Select from 'react-select';
import { multiSelectOptionGenerator, createMultiselectDataToSend, selectOptionsGenerator, interviewersOptionsGenerator } from '../ReusableComponents/OptionsGenerator';
//Tooltip imports
import ReactTooltip from 'react-tooltip';
import Tooltip from '../ReusableComponents/Tooltip';
import DataTable, { defaultThemes } from 'react-data-table-component';
import Modal from './Modals/Modal/index';
import moment from 'moment';
import '../global.js';
import Loader from './Loader';
import { can_edit, can_add } from '../ReusableComponents/urlGenerator';
import { encryptStorage } from './LocalStorageInterceptor';
import { TextareaAutosize } from '@material-ui/core';
// import swal from 'sweetalert';
import { Alert } from './toastify.js';

var validationObj = {};
var context;

const axios = require('axios').default;

const customStyles = {
  table: {
    style: {
      color: defaultThemes.default.text.primary,
      backgroundColor: defaultThemes.default.background.default,
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "#F9F9F9"
    },
  },
  header: {
    style: {
      minHeight: '1px',
    },
  },
  headRow: {
    style: {
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: defaultThemes.default.divider.default,
      backgroundColor: "#F9F9F9",
      minHeight: "40px"
    },
  },
  headCells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
        fontWeight: "bold",
        fontSize: "0.91rem"
      },
    },
  },
  row: {
    style: {
      minHeight: '20px',
      borderRightStyle: 'solid',
      borderRightWidth: '10px',
      borderRightColor: defaultThemes.default.divider.default,
    }
  },
  cells: {
    style: {
      '&:not(:last-of-type)': {
        // borderStyle: 'solid',
        // borderWidth: '1px',
        // borderColor: defaultThemes.default.divider.default,
        borderLeftStyle: 'solid',
        borderLeftWidth: '1px',
        borderLeftColor: defaultThemes.default.divider.default,
        fontSize: '0.9rem'
      },
    },
  },
};

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? "green"
          : isFocused
            ? "#0074F0"
            : null,
      color: isDisabled
        ? '#ccc'
        : isFocused
          ? "white"
          : isSelected
            ? "white"
            : "black",

      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? "grey" : "red"),
      },
    };
  },

  multiValue: (styles, { data }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: "#007BFF",
    };
  },

  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "white",
    whiteSpace: "inherit"
  }),

  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "white",
    ':hover': {
      backgroundColor: "red",
      color: 'white',
    },
  }),
  
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      fontSize: '16px',
      color: '#495057'
    }
  }

};

class Routings extends Component {
  constructor() {
    super();
    context = this;
    this.state = {
      ...this.getModal("routingsData"),
      routingsData: this.getModal("routingsData"),
      mandatoryInfo: this.getModal("routingsData"),
      labelText: this.getModal("routingsData"),
      fullLabel: this.getModal("routingsData"),
      tooltips: this.getModal("routingsData"),
      tooltipsVisibility: this.getModal("routingsData"),
      fieldName: this.getModal("routingsData"),
      fieldType: this.getModal("routingsData"),
      agencySelectOptions: [],
      forms_to_be_printedMultiOptions: [],
      routingsHistoryData: [],
      interviewers: [],
      routingsHistoryColumns: this.getModal("routingsHistoryColumns"),
      //Modals
      showAddRoutingsModal: sessionStorage.getItem('modalOpen') ? true : false,
      showRoutingsDetailsModal: false,
      showNextActionModal: false,
      showLoader: false,
    };
  }

  getModal(type) {
    let dataModel = '';
    switch (type) {
      case "routingsData":
        dataModel = {
          agency: '',
          other_agency: '',
          forms_to_be_printed: [],
          notes: '',
          client_id: '',
          version: '',
          updated_on: '',
          updated_by: '',
        }
      break;

      case "resetData":
        dataModel = {
          agency: '',
          other_agency: '',
          forms_to_be_printed: [],
          notes: '',
          client_id: '',
          version: '',
          updated_on: '',
          updated_by: '',
        }
      break

      case "routingsHistoryColumns":
        dataModel = [

          {
            name: 'Routed On',
            selector: 'date',
            sortable: true,
            cell: (row) => {
              return moment.unix(row.date).format("MM/DD/YYYY hh:mm a");
            },

          },
          {
            name: 'Agency',
            selector: 'agency',
            sortable: true,

          },
          {
            name: 'Forms',
            selector: 'forms',
            wrap: true,
            sortable: true,

          },
          {
            name: 'Routed By',
            selector: 'routed_by',
            sortable: true,
          },
          {
            name: 'View',
            sortable: false,
            cell: (row) => (
              <div>
                <Link to="#"><i id={row.id} lang="none" className="fas fa-eye" aria-hidden="true" data-toggle="tooltip" title="view" onClick={this.handleRoutingsDetails}></i></Link>
              </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
          },
        ];
      break;
    }
    return dataModel;
  }

  componentWillMount() {
    // for fetching layout definition
    axios.get(global.restApiURL + 'webservice/rest/class/id/18?apikey=' + sessionStorage.getItem('__CL_TOKEN__'))
      .then(res => {
        var response = res.data;
        if (response.success && res.status == 200) {
          validationObj = validationParser(response.data.layoutDefinitions.childs[0].childs);
          let validationObj_old = validationParser1(response.data.layoutDefinitions.childs[0].childs);
          this.setState({
            labelText: validationObj.title,
            fullLabel: validationObj.full_title,
            mandatoryInfo: validationObj.mandatory,
            tooltips: validationObj.tooltip,
            tooltipsVisibility: validationObj.tooltipVisibility,
            fieldName: validationObj.name,
            fieldType: validationObj.fieldtype,
            ...validationObj_old,
          });
          this.createSelectOptions();
        }
        else if ("error_code" in response) {
          alert(response.msg);
          window.location.href = global.domain + global.traversArray['out'];
        }
        else {
          alert(response.msg);
        }
      })
      .catch(err => { // log request error and prevent access to undefined state
        this.setState({ loading: false, error: true });
        console.error(err);
      }
    );
  }

  async createSelectOptions() {
    if (validationObj !== "") {
      // Assuming selectOptionsGenerator and multiSelectOptionGenerator are async functions
      const agencySelectOptions = await selectOptionsGenerator(validationObj.options?.agency, context.state.routingsData?.agency);
      const formsToBePrintedMultiOptions = await multiSelectOptionGenerator(validationObj.options?.forms_to_be_printed);
      this.setState({
        agencySelectOptions: agencySelectOptions,
        forms_to_be_printedMultiOptions: formsToBePrintedMultiOptions,
      });
    }
  }

  downloadFormsData = async (saveRecord) => {
    if (
      sessionStorage.getItem('clientId') !== '' &&
      this.state.routingsData.forms_to_be_printed &&
      this.state.routingsData.forms_to_be_printed.length > 0
    ) {
      this.setState({ showLoader: true });
      let forms = this.state.routingsData.forms_to_be_printed.map((form) => ({
        class_name: form.value,
      }));
      let getFollowupListBody = {
        apikey: localStorage.getItem('__TOKEN__'),
        client_id: sessionStorage.getItem('clientId'),
        forms,
      };
      try {
        const res = await axios.post(
          `${global.restApiURL}customRest/downloadFormsToPdf`,
          JSON.stringify(getFollowupListBody)
        );
        if (res.data.success) {
          window.location = res.data.file;
          if (res.data.name !== '') {
            delete getFollowupListBody.forms;
            setTimeout(async () => {
              try {
                const deleteRes = await axios.post(
                  `${global.restApiURL}customRest/deleteTempFile`,
                  JSON.stringify(getFollowupListBody)
                );
                this.setState({ showLoader: false });
                if (deleteRes.data.success && saveRecord) {
                  this.saveRoutingsdetails();
                }
              } catch (deleteErr) {
                this.setState({ showLoader: false, error: true });
                console.error('Error deleting temp file:', deleteErr);
              }
            }, 2000);
          } else {
            this.setState({ showLoader: false });
          }
        } else {
          this.setState({ showLoader: false });
          sessionStorage.setItem('error_msg', 'Error occurred.');
          console.error('Error response:', res.data.msg);
          // You can redirect or handle the error as needed
        }
      } catch (err) {
        this.setState({ showLoader: false, error: true });
        console.error('Error downloading forms to PDF:', err.response.data.msg);
        Alert('error', err.response.data.msg)
      }
    }
  };

  componentDidMount() {
    if (context.state.interviewers.length === 0) {
      let getInterviewersListBody = {
        apikey: localStorage.getItem('__TOKEN__'),
      };
      axios.post(global.restApiURL + 'customRest/getAttendants', JSON.stringify(getInterviewersListBody))
        .then(res => {
          if (res.data.success && res.status == 200) {
            context.setState({
              interviewers: res.data.data,
            })
          }
          else if ("error_code" in res) {
            alert(res.msg);
            window.location.href = global.domain + global.traversArray['out'];
          }
          else {
            alert(res.msg);
          }
        })
        .catch(err => { // log request error and prevent access to undefined state
          this.setState({ loading: false, error: true });
          console.error(err);
        }
      )
    }
    this.getRoutingsList();
  }

  getRoutingsList = () => {
    if (sessionStorage.getItem('clientId') !== '') {
      let getRoutingListBody = {
        apikey: localStorage.getItem('__TOKEN__'),
        client_id: sessionStorage.getItem('clientId'),
      };
      axios.post(global.restApiURL + 'customRest/getRoutingsList', JSON.stringify(getRoutingListBody))
        .then(res => {
          if (res.data.success && res.status == 200) {
            setTimeout(function () {
              context.setState({
                routingsHistoryData: context.parseRoutingHistory(res.data.data)
              });
            }, 50)
          }
          else if ("error_code" in res) {
            alert(res.msg);
            window.location.href = global.domain + global.traversArray['out'];
          }
          else {
            alert(res.msg);
          }
        })
        .catch(err => { // log request error and prevent access to undefined state
          this.setState({ loading: false, error: true });
          console.error(err);
        }
      )
    }
  }

  parseRoutingHistory(historyDataArray) {
    let parsedData = [];
    parsedData = historyDataArray.length > 0 && historyDataArray.map((item, i) => {
      var op = {};
      item.map((val) => {
        if (val.name === "forms") {
          op[val.name] = val.value ? val.value.join(', ') : '';
        } else {
          op[val.name] = val.value;
        }
      })
      return op;
    }, this);
    return parsedData;
  }

  getAssignedInterviewerName(staffId) {
    let name = '';
    context.state.interviewers.map((val) => {
      if (Number(val.id) === Number(staffId)){
        name = val.name;
      }
    });
    return name;
  }

  handleMultiSelectChanges = (selectedOption, event) => {
    var updatedState = this.state.routingsData;
    updatedState[event.name] = selectedOption;
    this.setState(prevState => ({
      ...prevState,
      routingsData: updatedState
    }));
  };

  handleRoutingsDetails(event) {
    let historyID = event.target.id;
    context.setState({
      routingsData: context.getModal("routingsData")
    })
    axios.get(global.restApiURL + 'webservice/rest/object/id/' + historyID + '?apikey=' + localStorage.getItem('__TOKEN__'))
      .then(res => {
        const response = res.data.data.elements;
        let parsedRoutingsDetails = formObjDataMapper2(response, context.getModal("routingsData"));
        parsedRoutingsDetails.forms_to_be_printed = parseMultiSelectField(parsedRoutingsDetails.forms_to_be_printed, context.state.forms_to_be_printedMultiOptions);
        context.setState({
          routingsData: parsedRoutingsDetails,
          showRoutingsDetailsModal: true,
          showLoader: false
        })
        context.createSelectOptions();
      })
      .catch(err => { // log request error and prevent access to undefined state
        context.setState({ showLoader: false, error: true });
        console.error(err);
      }
    )
  }

  openAddRoutingModal() {
    context.setState({
      showAddRoutingsModal: true,
      routingsData: context.getModal('resetData')
    })
    setTimeout(() => context.createSelectOptions(), 100);
  }

  closeAddRoutingModal() {
    sessionStorage.removeItem('modalOpen');
    context.getRoutingsList();
    context.setState({
      showAddRoutingsModal: false,
      routingsData: context.getModal('resetData')
    })
    setTimeout(() => context.createSelectOptions(), 100);
  }

  closeRoutingsDetailsModal() {
    context.setState({
      showRoutingsDetailsModal: false,
      routingsData: context.getModal('resetData')
    });
    setTimeout(() => context.createSelectOptions(), 100);
  }

  showLoader = () => {
    context.setState({
      showLoader: true,
    })
  }

  closeLoader = () => {
    context.setState({
      showLoader: false,
    })
  }

  draftEmail = () => {

  }

  sendEmail = () => {

  }

  handleRoutingChange = (event) => {
    let routingsDataCopy = context.state.routingsData;
    routingsDataCopy[event.target.name] = event.target.value;
    context.setState({ routingsData: routingsDataCopy });
  }

  saveRoutingsdetails() {
    if (sessionStorage.getItem('clientId') !== "") {
      var jsonData = '';
      var jsonData_validation = [];
      if (!context.state.routingsHistoryData || context.state.routingsHistoryData.length === 0) {
        jsonData = { 'className': 'RoutingForm', 'key': (sessionStorage.getItem('clientId') + "-1"), 'published': true };
      } else {
        jsonData = { 'className': 'RoutingForm', 'key': (sessionStorage.getItem('clientId') + "-" + (context.state.routingsHistoryData.length + 1)), 'published': true };
      }
      jsonData.elements = [];
      jsonData.elements.push({ name: 'notes', value: context.state.routingsData.notes });
      jsonData.elements.push({ name: 'other_agency', value: context.state.routingsData.other_agency });
      jsonData.elements.push({ name: context.state.fieldName.forms_to_be_printed, value: createMultiselectDataToSend(context.state.routingsData.forms_to_be_printed) });
      jsonData.elements.push({ name: context.state.fieldName.agency, value: context.state.routingsData.agency });
      jsonData.elements.push({ name: 'client_id', value: sessionStorage.getItem('clientId') });
      jsonData.elements.push({ name: 'updated_by', value: sessionStorage.getItem('loggedinUserID') });
      Object.values(context.state.routingsData).map(v => {
        if (v.length > 0) {
          jsonData_validation.push('exists');
        }
      });
      if (jsonData_validation.length === 0) {
        sessionStorage.setItem('error_msg', 'Empty Information is not saved');
        window.location.href = "";
      } else {
        var json = JSON.stringify(jsonData);
        axios.post(global.save_url, json)
          .then(function (res) {
            var response = res.data;
            if (response.success) {
              context.setState({ showLoader: false });
              sessionStorage.setItem('success_msg', "Routing added successfully.");
              context.openNextActionModal();
            }
            else if ("error_code" in response) {
              sessionStorage.setItem('error_msg', 'Error occured.');
              window.location.href = global.domain + global.traversArray['out'];
            }
            else {
              context.setState({ showLoader: false });
              window.location.href = '';
            }
          })
          .catch(function (err) {
            context.setState({ showLoader: false });
            // sessionStorage.setItem('error_msg', 'Error occured.')
            window.location.href = '';
          }
        );
      }
    } else {
      console.log("Invalid Data ! ");
    }
  }

  route = (route) => {
    sessionStorage.setItem('modalOpen', true);
    switch (route) {
      case 'Follow-Up':
        window.location.href = global.domain + 'followup';
      break;

      case 'Referral':
        window.location.href = global.domain + 'referrals';
      break;
    }
  }

  openNextActionModal() {
    context.getRoutingsList();
    context.setState({
      showAddRoutingsModal: false,
      showRoutingsDetailsModal: false,
      showNextActionModal: true,
      routingsData: context.getModal('resetData')
    });
    setTimeout(() => context.createSelectOptions(), 100);
  }

  closeNextActionModal() {
    context.getRoutingsList();
    context.setState({
      showNextActionModal: false,
      routingsData: context.getModal('resetData')
    });
  }

  setToEmpty = (name) => {
    let routingsDataCopy = context.state.routingsData;
    routingsDataCopy[name] = '';
    context.setState({ routingsData: routingsDataCopy });
  }

  render() {
    sessionStorage.setItem('formname', 'Routings');
    let forms_to_be_printed_list = [];
    if (context.state.routingsData.forms_to_be_printed && context.state.routingsData.forms_to_be_printed.length > 0) {
      context.state.routingsData.forms_to_be_printed.map((form) => {
        forms_to_be_printed_list.push(<li>{form.label}</li>);
      })
    }

    return (

      <div className="hold-transition sidebar-mini text-sm">
        <ReactTooltip place="right" type="dark" effect="solid" />
        <div className="wrapper">
          <Topnav />
          <div className="content-wrapper">
            <Patientinfoheader clientId={sessionStorage.getItem('clientId')} dob={encryptStorage.getItem('dob')}
              email={encryptStorage.getItem('email')} phone={encryptStorage.getItem('phone')}
              peer_support_specialist={encryptStorage.getItem('peer_support_specialist')} 
              next_followup_date={encryptStorage.getItem('month_1_followup_date')} 
              intake_date={encryptStorage.getItem('intake_date')} 
              initiated_by={encryptStorage.getItem('last_updated_by')} 
              formname={sessionStorage.getItem('formname')} 
              client_name={encryptStorage.getItem('client_first_name') + " " + encryptStorage.getItem('client_middle_initial') + " " + encryptStorage.getItem('client_last_name')} 
            />
            <section className="content">
              <div className="row">
                <Sidenav />
                <div className="container-fluid col-md-9 col-lg-10">
                  <div className="card card-default">
                    <div className="card-header">
                      <p className="lead float-left"><b className="text-green">Routings</b></p>
                      <button type="submit" className="btn btn-primary float-right" value="proceed" onClick={() => window.location.href = global.traversArray['routing']}><img src="dist/img/save-proceed_icon.png" alt="" style={{ pointerEvents: "none" }} /> Save &amp; Proceed </button>
                      {can_add('RoutingForm') ? <button type="submit" className="btn btn-success float-sm-right btnmargin" value="all" onClick={this.openAddRoutingModal} lang="all" ><i className="fas fa-plus" aria-hidden="true"></i> Add Routing</button> : ''}
                      <input type="hidden" id="clicked" value="" readOnly />
                      <input type="text" id="client_id" name="client_id" style={{ display: "none" }} defaultValue={sessionStorage.getItem('clientId')} />
                    </div>
                    <div className="card-body">
                      <DataTable
                        columns={this.state.routingsHistoryColumns}
                        data={this.state.routingsHistoryData}
                        customStyles={global.customTableStyles}
                        striped={true}
                        pagination
                        paginationComponentOptions={global.paginationComponentOptions}
                        highlightOnHover
                        pointerOnHover
                        noDataComponent={<div className="no_record_found">{global.no_record_found_text}</div>}
                      />
                    </div>
                    <div class="card-footer">
                      <div class="float-right">
                        <button type="submit" className="btn btn-primary float-right" value="proceed" onClick={() => window.location.href = global.traversArray['routing']}><img src="dist/img/save-proceed_icon.png" alt="" style={{ pointerEvents: "none" }} /> Save &amp; Proceed </button>
                        {can_add('RoutingForm') ? <button type="submit" className="btn btn-success float-sm-right btnmargin" value="all" onClick={this.openAddRoutingModal} lang="all" ><i className="fas fa-plus" aria-hidden="true"></i> Add Routing</button> : ''}
                      </div>
                    </div>
                    <Modal
                      showOverlay={true}
                      show={this.state.showAddRoutingsModal}
                      onClose={this.closeAddRoutingModal}
                      disableOverlayClick={true}
                      showCloseButton={false}
                      size="sm"
                      className="modal-md modal-dialog"
                    >
                      <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                        <Modal.Title>
                          <div className="row" >
                            <div className="col-md-6">
                              <h4 className="modal-title float-left text-green">Add Routing</h4>
                            </div>
                            <div className="col-md-6"  >
                              <button type="submit" id="saveButton" className="btn btn-success float-right btnmargin" onClick={context.saveRoutingsdetails} > <i className="fas fa-save"></i> Save</button>
                            </div>
                          </div>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div>
                          <div className="row">
                            <div className="col-md-12">
                              <LabelField {...context.state.agency} />
                            </div>
                            <div className="col-md-12 form-group">
                              <select name={context.state.fieldName.agency} required={this.state.agency.mandatory} className="custom-select" aria-hidden="true" onChange={this.handleRoutingChange} >
                                {this.state.agencySelectOptions}
                              </select>
                            </div>
                            {this.state.routingsData.agency === 'Other (Please Specify Below)' ? (<>
                              <div className="col-md-12">
                                <LabelField {...context.state.other_agency} />
                              </div>
                              <div className="col-md-12 form-group">
                                <input type="text" className="form-control" placeholder="" id={context.state.fieldName.other_agency} name={context.state.fieldName.other_agency} defaultValue={context.state.routingsData.other_agency} onChange={this.handleRoutingChange} />
                              </div></>) : (() => this.setToEmpty('other_agency'))
                            }
                            <div className="col-md-12">
                              <LabelField {...context.state.forms_to_be_printed} />
                            </div>
                            <div className="col-md-12 form-group">
                              <Select
                                name={context.state.fieldName.forms_to_be_printed}
                                isMulti
                                closeMenuOnSelect={false}
                                value={this.state.routingsData.forms_to_be_printed}
                                onChange={this.handleMultiSelectChanges}
                                options={this.state.forms_to_be_printedMultiOptions}
                                styles={colourStyles}
                                required={this.state.forms_to_be_printed.mandatory}
                                placeholder="Select"
                              />
                            </div>
                            <div className="col-md-12">
                              <LabelField {...context.state.notes} />
                            </div>
                            <div className="col-md-12 form-group">
                              <TextareaAutosize className="form-control" placeholder="" id={context.state.fieldName.notes} name={context.state.fieldName.notes} defaultValue={context.state.routingsData.notes} rows="3" onChange={this.handleRoutingChange}></TextareaAutosize>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="justify-content-between" >
                              <button className="btn btn-default float-left" id="closeButton" onClick={this.closeAddRoutingModal} type="button">Close</button>
                            </div>
                          </div>
                          <div className="col-md-8" >
                            <button id="Open" type="submit" className="btn btn-success float-right btnmargin" onClick={context.saveRoutingsdetails}> <i className="fas fa-save"></i> Save</button>
                            <button class="btn btn-secondary float-right btnmargin" type="button" data-dismiss="modal" onClick={() => this.downloadFormsData(true)}><i class="fas fa-download"></i>&nbsp;Download</button>
                          </div>
                        </div>
                      </Modal.Footer>
                    </Modal>
                    <Modal
                      showOverlay={true}
                      show={this.state.showRoutingsDetailsModal}
                      onClose={this.closeRoutingsDetailsModal}
                      disableOverlayClick={true}
                      showCloseButton={false}
                      size="sm"
                      className="modal-md modal-dialog"
                    >
                      <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                        <Modal.Title>
                          <div className="row" >
                            <div className="col-md-6">
                              <h4 className="modal-title float-left text-green">Routing</h4>
                            </div>
                          </div>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div>
                          <div className="row">
                            <div className="col-md-12">
                              <LabelField {...context.state.agency} />
                            </div>
                            <div className="col-md-12 form-group">
                              <select name={context.state.fieldName.agency} required={this.state.agency.mandatory} className="custom-select" aria-hidden="true" onChange={this.handleRoutingChange} disabled={true}>
                                {this.state.agencySelectOptions}
                              </select>
                            </div>
                            {this.state.routingsData.agency === 'Other (Please Specify Below)' ? (<>
                              <div className="col-md-12">
                                <LabelField {...context.state.other_agency} />
                              </div>
                              <div className="col-md-12 form-group">
                                <input type="text" className="form-control" placeholder="" id={context.state.fieldName.other_agency} name={context.state.fieldName.other_agency} defaultValue={context.state.routingsData.other_agency} onChange={this.handleRoutingChange} readOnly={true} />
                              </div></>) : (() => this.setToEmpty('other_agency'))
                            }
                            <div className="col-md-12">
                              <LabelField {...context.state.forms_to_be_printed} />
                            </div>
                            <div className="col-md-12 form-group">
                              <Select
                                name={context.state.fieldName.forms_to_be_printed}
                                isMulti
                                closeMenuOnSelect={false}
                                value={this.state.routingsData.forms_to_be_printed}
                                onChange={this.handleMultiSelectChanges}
                                options={this.state.forms_to_be_printedMultiOptions}
                                styles={colourStyles}
                                required={this.state.forms_to_be_printed.mandatory}
                                isDisabled={true}
                              />
                            </div>
                            <div className="col-md-12">
                              <LabelField {...context.state.notes} />
                            </div>
                            <div className="col-md-12 form-group">
                              <TextareaAutosize className="form-control" placeholder="" id={context.state.fieldName.notes} name={context.state.fieldName.notes} defaultValue={context.state.routingsData.notes} rows="3" onChange={this.handleRoutingChange} disabled={true}></TextareaAutosize>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="justify-content-between" >
                              <button className="btn btn-default float-left" id="closeButton" onClick={this.closeRoutingsDetailsModal} type="button">Close</button>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="justify-content-between" >
                              <button class="btn btn-secondary float-right" type="button" data-dismiss="modal" onClick={() => this.downloadFormsData(false)}><i class="fas fa-download"></i>&nbsp;Download</button>
                            </div>
                          </div>
                        </div>
                      </Modal.Footer>
                    </Modal>
                    <Modal
                      showOverlay={true}
                      show={this.state.showNextActionModal}
                      onClose={this.closeNextActionModal}
                      disableOverlayClick={true}
                      showCloseButton={true}
                      size="sm"
                      className="modal-md modal-dialog"
                    >
                      <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                        <Modal.Title>
                          <div className="row" >
                            <div className="col-md-8">
                              <h4 className="modal-title float-left text-green">Next Actions you can take</h4>
                            </div>
                          </div>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="modal-body">
                          <div className="row">
                            <p style={{ paddingLeft: "10px" }}>Routing form is filled, you can choose to do any one of the following or click X above, to do nothing</p>
                          </div>
                          <div className="row" style={{ margin: "10px 0px 50px -10px", width: "100%" }}>
                            <div className="">
                              <div className="btn btn-app  btn-success" type="button" style={{ width: "133px" }} onClick={() => this.route('Referral')}><i className="fas fa-prescription"></i> Add Referral</div>
                            </div>
                            <div className="">
                              <div className="btn btn-app btn-success" type="button" style={{ width: "133px" }} onClick={() => this.route('Follow-Up')}><i className="fas fa-calendar-check"></i> Add Follow-Up</div>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <Footer />
        {this.state.showLoader ? (<Loader />) : ''}
      </div>
    );
  }
}

export default Routings;