import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { Component } from "react";
import Modal from "../Modals/Modal";
import TextAreaAutosize from 'react-textarea-autosize';

const axios = require("axios").default;

export default class MentalHealthDisorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [1],
      mental_health_orders: [
        {
          mental_health_disorder: "",
          icd_10_code: "",
          comments: "",
        },
      ],
    
      dataListBody: {
        apikey: localStorage.getItem('__TOKEN__'),
        client_id: sessionStorage.getItem("clientId"),
        form_type:props.form_type,
        uploaded_by:sessionStorage.getItem("username"),
      },
      deleteSubstance: false,
      change: false,
      isSaveAndComplete:false,
      isSaved:true,
      form_type:props.form_type,
    };
  }

  componentDidUpdate(prevProps) {
    if (!this.state.isSaveAndComplete) {
      if (prevProps.mentalHealthDisorderData !== this.props.mentalHealthDisorderData) {
        // Update state based on new props
        const updatedOrders = [...this.state.mental_health_orders];
        const updatedOrder = { ...updatedOrders[0] }; // Clone the object
  
        // Check if the mental_health_disorder is not blank in this.props.mentalHealthDisorderData
        if (this.props.mentalHealthDisorderData && this.props.mentalHealthDisorderData.mental_health_disorder) {
          updatedOrder.mental_health_disorder = this.props.mentalHealthDisorderData.mental_health_disorder;
  
          // Check if the index is 0, and update icd_10_code based on this.props.icd_10_code
          if (this.props.mentaldisorderOptions) {
            const selectedOption = this.props.mentaldisorderOptions.find(option => option.description === updatedOrder.mental_health_disorder);
            if (selectedOption) {
              updatedOrder.icd_10_code = selectedOption.code;
            }
          }
  
          // Update the comments field as well, if available in the props
          if (this.props.mentalHealthDisorderData.comments) {
            updatedOrder.comments = this.props.mentalHealthDisorderData.comments;
          }
        } else {
          // If mental_health_disorder is blank, set icd_10_code and comments to blank as well
          updatedOrder.mental_health_disorder = "";
          updatedOrder.icd_10_code = "";
          updatedOrder.comments = "";
        }
  
        updatedOrders[0] = updatedOrder;
  
        this.setState({
          mental_health_orders: updatedOrders,
        }, () => {
          // Call the childToParent function after state is updated
          this.props.childToParent(this.state.mental_health_orders);
        });
      }
    }
  }

handleChangeDropdown = (index, selectedValue) => {
  // Update the selected mental_health_disorder value in the state
  let mental_health_orders = [...this.state.mental_health_orders];
  mental_health_orders[index].mental_health_disorder = selectedValue;

  // Update the corresponding icd_10_code based on the selected value
  const selectedOption = this.props.mentaldisorderOptions.find(option => option.description === selectedValue);
  if (selectedOption) {
    mental_health_orders[index].icd_10_code = selectedOption.code;
  } else {
    // If selectedValue is blank or not found, set icd_10_code to blank as well
    mental_health_orders[index].icd_10_code = "";
  }

  this.setState({ mental_health_orders });
};

  addRows = (e) => {
    e.preventDefault();
    const data = {
      mental_health_disorder: "",
      comments: "",
    };
    this.setState({
      mental_health_orders: [...this.state.mental_health_orders, data],
    });
    this.setState({isSaved:false})
  };

  deleteRow = (e) => {
    this.setState({isSaved:false})
    let rows = this.state.mental_health_orders;
    rows.splice([parseInt(e.target.id)], 1);
    this.setState({ mental_health_orders: rows, deleteSubstance: false });
  };

  handleChange = (e) => {
    this.props.tabtabEdittedOrNot(true);
    this.props.saveAndExitSummary("", "", "", "", "", "", "", "", this.handleSubmit, "", "", "", "");
    this.setState({ change: true, isSaved: false });
  
    let { id, name, value } = e.target;
    let mental_health_orders = [...this.state.mental_health_orders];
  
    if (name === "mental_health_disorder") {
      mental_health_orders[id][name] = value;
  
      // Update the corresponding icd_10_code based on the selected value
      const selectedOption = this.props.mentaldisorderOptions.find(option => option.description === value);
      if (selectedOption) {
        mental_health_orders[id].icd_10_code = selectedOption.code;
      } else {
        mental_health_orders[id].icd_10_code = "";
      }
    } else {
      mental_health_orders[id][name] = value;
    }
  
    this.setState({ mental_health_orders });
  
    setTimeout(() => {
      this.props.childToParent(this.state.mental_health_orders);
    }, 200);
  };
  
  deletePopUp = (e) => {
    this.setState({ deleteSubstance: true });
  };
  // +++++++++++++++++++++--HANDLE--SUBMIT--START--+++++++++++++++++++++++++++

  handleSubmit = async (e, shouldToasterShow) => {
    e.preventDefault();
    this.props.tabtabEdittedOrNot(false);
    let final_data = {
      mental_health_orders: this.state.mental_health_orders,
      dataListBody: this.state.dataListBody,
    };
    let res = await axios.post(
      global.restApiURL + "customRest/saveMentalHealthOrder",
      JSON.stringify(final_data)
    );
    if(res.data.success == true && shouldToasterShow == undefined){
      toast.success('Data added successfully!', {toastId:('Data added successfully!')}, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      this.loadData();
    }else if(res.data.success == true && shouldToasterShow == 'saveNexit'){
      // msg should be show on parent componnt;
    }
  };
  // +++++++++++++++++++++--HANDLE--SUBMIT--END--+++++++++++++++++++++++++++
  // ==========================*******************============================
  // +++++++++++++++++++++--LOAD--DATA--START--+++++++++++++++++++++++++++
  loadData = async (e) => {
    this.setState({isSaved:true})
    if (!this.props.isSaveAndComplete) {
    let res = await axios.post(
      global.restApiURL + "customrest/getMentalHealthDisorderInfo",
      JSON.stringify(this.state.dataListBody)
    );
    if (res.status == 200 && res.data.success == true) {
      this.setState({
        mental_health_orders: res.data.data.mental_health_orders.map((val) => {
          return {
            mental_health_disorder: val?.mental_health_disorder,
            icd_10_code: val?.icd_10_code,
            comments: val.comments,
          };
        }),
      });
      setTimeout(()=>{
        this.props.childToParent(this.state.mental_health_orders)
     },500)
    }
  }else{
    let res = await axios.post(
      global.restApiURL + "customrest/getMentalHealthDisorderInfoTemp",
      JSON.stringify(this.state.dataListBody)
    );
    if (res.status == 200 && res.data.success == true) {
      this.setState({
        mental_health_orders: res.data.data.mental_health_orders.map((val) => {
          return {
            mental_health_disorder: val?.mental_health_disorder,
            icd_10_code: val?.icd_10_code,
            comments: val.comments,
          };
        }),
      });
      this.setState({isSaveAndComplete:true})
    }
  }
  };

  // +++++++++++++++++++++--LOAD--DATA--END--+++++++++++++++++++++++++++
  // ==============================****************======================
  // +++++++++++++++++++++--COMPONENT--DID--MOUNT--START--+++++++++++++++++++++++++++
  componentDidMount() {
    this.loadData();
  }

  // +++++++++++++++++++++--COMPONENT--DID--MOUNT--END--+++++++++++++++++++++++++++

  render() {
    const tableRows = this.state.mental_health_orders.map((row, index) => (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              {index != 0 && (
                <>
                  <div className="row">
                    <div className="col-md-12">
                      <i
                        className="fa fa-trash float-right"
                        id={index}
                        onClick={
                          this.state.change ? this.deletePopUp : this.deleteRow
                        }
                      ></i>
                    </div>
                  </div>
                  {this.state.deleteSubstance && (
                    <Modal
                      showCloseButton={false}
                      className="modal-sm"
                      showOverlay={true}
                    >
                      <Modal.Header>
                        <Modal.Title>
                          <h4 className="modal-title float-left text-green">
                            Delete Substance
                          </h4>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <p>Are you sure you want to delete this?</p>
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          className="btn btn-success float-left"
                          id={index}
                          onClick={this.deleteRow}
                        >
                          Yes
                        </button>
                        <button
                          className="btn btn-default"
                          onClick={() =>
                            this.setState({ deleteSubstance: false })
                          }
                        >
                          No
                        </button>
                      </Modal.Footer>
                    </Modal>
                  )}
                </>
              )}
              <div className="row">
                <div className="col-md-6">
                  <label>Disorder</label>
                  <select
                    className="form-control"
                    id={index}
                    name="mental_health_disorder"
                    onChange={this.handleChange}
                    value={row.mental_health_disorder}
                    disabled={this.state.isSaveAndComplete || index === 0}
                  >
                    <option value="">Select</option>
                    {this.props.mentaldisorderOptions.map((option) => (
                      <option key={option.code} value={option.description}>
                        {option.description}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-6">
                  <label>ICD 10 Code</label>
                  <input
                    type="text"
                    className="form-control"
                    name="icd_10_code"
                    id={index}
                    onChange={this.handleChange}
                    value={row.icd_10_code}
                    disabled={this.state.isSaveAndComplete || index === 0}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Comments</label>
                <TextAreaAutosize
                  type="text"
                  style={{overflow:'hidden'}}
                  minRows={(row.comments.length/68) + row.comments.split(/\r\n|\r|\n/).length}
                  value={row.comments}
                  className="form-control"
                  name="comments"
                  id={index}
                  onChange={this.handleChange}
                  disabled={this.state.isSaveAndComplete}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
    return (
      <div className="hold-transition sidebar-mini text-sm">
        <div className="wrapper">
          <div className="">
            <section className="content-body">
              <div className="row">
                <div className="col-md-12">
                  <form>
                    <div className="row marginBottom1 heightScroll">
                      <div className="col-md-12">
                        <button
                          className="btn bt-sm btn-secondary mt-2 mr-3 float-right"
                          onClick={this.addRows}
                          disabled={this.state.isSaveAndComplete}
                        >
                          <i className="fa fa-plus mr-1"></i>Add
                        </button>
                      </div>
                      <div className="col-md-12">
                        <div className="card-body">{tableRows}</div>
                      </div>
                      <div className="col-md-12">
                        {/* <button    onClick={this.handleSubmit} className='btn btn-success float-right mr-2'><i class="fas fa-save"></i> Save</button> */}
                        <button
                          id="saveButton"
                          type="submit"
                          onClick={this.handleSubmit}
                          className="btn btn-success float-right mr-3"
                          htmlType="submit"
                          disabled={this.state.isSaved}
                          // form="assessmentsForm"
                        >
                          {" "}
                          <i className="fas fa-save"></i> Save

                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
