import React, { useState, useEffect, useRef } from "react";
import Modal from '../../Modals/Modal/index';
import Topnav from "../../Topnav";
import SignatureCanvas from "react-signature-canvas";
import axios from "axios";

const SignInPage = () => {

    const sigPadRef = useRef(null);
    
    
    const fontStyles = [
        { name: 'Font1', style: { fontFamily: '"Roboto", sans-serif' } }, // Clean, modern sans-serif
        { name: 'Font2', style: { fontFamily: '"Open Sans", sans-serif' } }, // Versatile, professional sans-serif
        { name: 'Font3', style: { fontFamily: '"Lora", serif' } }, // Elegant serif font
        { name: 'Font4', style: { fontFamily: '"Montserrat", sans-serif' } }, // Geometric sans-serif
        { name: 'Font5', style: { fontFamily: '"Playfair Display", serif' } }, // Stylish and modern serif
        { name: 'Font6', style: { fontFamily: '"Merriweather", serif' } }, // Readable serif for body text
        { name: 'Font7', style: { fontFamily: '"Raleway", sans-serif' } }, // Thin and elegant sans-serif
        { name: 'Font8', style: { fontFamily: '"Pacifico", cursive' } }, // Casual, handwritten cursive
        { name: 'Font9', style: { fontFamily: '"Cinzel", serif' } }, // Classical, engraved style serif
        { name: 'Font10', style: { fontFamily: '"Indie Flower", cursive' } }, // Hand-drawn, playful handwriting
        { name: 'Font11', style: { fontFamily: '"Oswald", sans-serif' } }, // Tall, narrow sans-serif
        { name: 'Font12', style: { fontFamily: '"Dancing Script", cursive' } }, // Elegant and lively script
        { name: 'Font13', style: { fontFamily: '"Abril Fatface", serif' } }, // Bold, display serif font
        { name: 'Font14', style: { fontFamily: '"Courier Prime", monospace' } }, // Clean, monospace typewriter style
        { name: 'Font15', style: { fontFamily: '"Nunito", sans-serif' } }, // Rounded sans-serif for readability
        { name: 'Font16', style: { fontFamily: '"Poppins", sans-serif' } }, // Modern, geometric sans-serif
        { name: 'Font17', style: { fontFamily: '"PT Serif", serif' } }, // Neutral, classic serif
        { name: 'Font18', style: { fontFamily: '"Bangers", cursive' } }, // Fun, cartoonish display font
        { name: 'Font19', style: { fontFamily: '"Vollkorn", serif' } }, // Sturdy, classic serif for text
        { name: 'Font20', style: { fontFamily: '"Fira Code", monospace' } }, // Developer-friendly monospace
    ];

    const fieldss = {
        "_id": "676a727187baf7538830f0d2",
        "user": "155",
        "name": "Explicit Consent",
        "type": "ROI",
        "autoRenewal": "1",
        "description": "Info details Data",
        "lastUpdatedBy": "Hareesh",
        "status": "Active",
        "validity": "60 days",
        "consentContent": "<p>Order %%userEmail%% Data %%Preferred Contact Method%% Kuldeep Singhh. Alla %%event date%%   dfdvgydbkj  %%Agree to Terms%% knvlfdvorr. Alla %%event date%%   dfdvgydbkj  %%Agree to Terms%% knvlfdvorr   bikvdv 56165 %%acceptTerms%%Order %%userEmail%% Data %%Preferred Contact Method%% Kuldeep Singhh. Alla %%event date%%   dfdvgydbkj  %%Agree to Terms%% knvlfdvorr   bikvdv 56165 %%acceptTerms%%Order %%userEmail%% Data %%Preferred Contact Method%% Kuldeep Singhh. Alla %%event date%%   dfdvgydbkj  %%Agree to Terms%% knvlfdvorr   bikvdv 56165 %%acceptTerms%%Order %%userEmail%% Data %%Preferred Contact Method%% Kuldeep Singhh. Alla %%event date%%   dfdvgydbkj  %%Agree to Terms%% knvlfdvorr   bikvdv 56165 %%acceptTerms%%Order %%userEmail%% Data %%Preferred Contact Method%% Kuldeep Singhh. Alla %%event date%%   dfdvgydbkj  %%Agree to Terms%% knvlfdvorr   bikvdv 56165 %%acceptTerms%%Order %%userEmail%% Data %%Preferred Contact Method%% Kuldeep Singhh. Alla %%event date%%   dfdvgydbkj  %%Agree to Terms%% knvlfdvorr   bikvdv 56165 %%acceptTerms%%Order %%userEmail%% Data %%Preferred Contact Method%% Kuldeep Singhh. Alla %%event date%%   dfdvgydbkj  %%Agree to Terms%% knvlfdvorr   bikvdv 56165 %%acceptTerms%%Order %%userEmail%% Data %%Preferred Contact Method%% Kuldeep Singhh. Alla %%event date%%   dfdvgydbkj  %%Agree to Terms%% knvlfdvorr   bikvdv 56165 %%acceptTerms%% </p>",
        "consentContentDynamicFields": [
            {
                "fieldName": "userEmail",
                "type": "text",
                "value": "user@example.com"
            },
            {
                "fieldName": "Preferred Contact Method",
                "type": "select",
                "value": "Email",
                "options": ["Email", "Phone", "SMS"]
            },
            {
                "fieldName": "event date",
                "type": "datetime",
                "value": "2024-12-23 12:23"
            },
            {
                "fieldName": "Agree to Terms",
                "type": "checkbox",
                "value": ["Agree"],
                "options": ["Agree", "Disagree"]
            },
            {
                "fieldName": "acceptTerms",
                "type": "radio",
                "value": "true",
                "options": ["Agree", "Disagree", "full Agree"]
            }
        ],
    }

    const [fontStyle, setFontStyle] = useState('"Parisienne", cursive');
    const [selectedFont, setSelectedFont] = useState(fontStyles[0]);
    const [inputValue, setInputValue] = useState('');
    const [signatureType, setSignatureType] = useState();
    const [signature, setSignature] = useState('');
    const [signatureModal, setSignatureModal] = useState(false);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [thanksModal, setThanksModal] = useState(false);
    const [consentConetnt, setConsentConetnt] = useState("");
    const [getData, setGetData] = useState("");
    const [payLoadFormData, setPayLoadFormData] = useState("");

    useEffect(() => {
        getTemplateConsent();
        consentContentfunction();
    }, []
    );

    const getTemplateConsent = async () => {
        axios.get(`https://econsent.tactionclients.com/consenttemplates/getdetails?id=676a727187baf7538830f0d2`).then(
            (res) => {
                if (res.status == 200) {
                    setGetData(res.data);
                }
                console.log(res.response);
            }
        ).catch(
            (err) => {
                console.log(err.response);
            }
        );
    }

    const handleFontChange = (font, fontStyl) => {
        setFontStyle(fontStyl.fontFamily);
        setSelectedFont(font);
    };

    const consentContentfunction = (e) => {
        let newData = new DOMParser();
        let formWithContent = newData.parseFromString(fieldss.consentContent, "text/html");
        let breakeableContent = formWithContent.querySelector('p')?.textContent;
        const regex = /%%(.*?)%%/g;
        const parts = [];
        let lastIndex = 0;
        let match;
        while ((match = regex.exec(breakeableContent)) !== null) {
            parts.push(breakeableContent.slice(lastIndex, match.index));
            fieldss.consentContentDynamicFields.map((item) => {
                if (item.fieldName == match[1] && item.type == 'select') {
                    parts.push(<select name={item.fieldName} style={{ margin: "0 5px", padding: "5px", borderRadius: "4px", border: "1px solid #ccc", height: "33px", width: "180px" }} id={item.fieldName}>{item.options?.map((opt) => { return (<option value={opt.toLowerCase()}>{opt}</option>) })}</select>);
                }
                if (item.fieldName == match[1] && item.type == 'checkbox') {
                    parts.push(<>{item.options?.map((opt) => { return (<><input type="checkbox" className="btn btn-success ml-4" style={{ height: "23px", width: "25px" }} id={item.fieldName} name={item.fieldName} value={opt.toLowerCase()}></input><label className="ml-2" for={opt}>{opt}</label></>) })}</>);
                }
                if (item.fieldName == match[1] && item.type == 'datetime') {
                    parts.push(<input type="datetime-local" name={item.fieldName} style={{ margin: "0 5px", padding: "5px", borderRadius: "4px", border: "1px solid #ccc", height: "32px" }} id={item.fieldName}></input>);
                }
                if (item.fieldName == match[1] && item.type == 'text') {
                    parts.push(<input type="text" name={item.fieldName} style={{ margin: "0 5px", padding: "5px", borderRadius: "4px", border: "1px solid #ccc" }} id={item.fieldName}></input>);
                }
                if (item.fieldName == match[1] && item.type == 'radio') {
                    parts.push(<>{item.options?.map((opt) => { return (<><input type="radio" className="btn btn-success ml-4" style={{ height: "23px", width: "25px" }} id={item.fieldName} name={item.fieldName} value={opt.toLowerCase()}></input><label className="ml-2" for={opt}>{opt}</label></>) })}</>);
                }
            });
            lastIndex = regex.lastIndex;
        }
        parts.push(breakeableContent?.slice(lastIndex));
        // setConsentConetnt(parts);
        return parts;
    }

    const submitSignature = () => {
        const array = [];
        const formData = new FormData();
        formData.append("client_id", sessionStorage.getItem('clientId'));
        formData.append("consent_to_service_content", '<p>KKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKKK</p>');
        const blob = new Blob([new Uint8Array(array)], { type: "image/png" });
        if (signatureType === "draw") {
            if (sigPadRef.current && !sigPadRef.current.isEmpty()) {
                const dataURL = sigPadRef.current.toDataURL("image/png");
                const base64Data = dataURL.split(",")[1];
                const binary = atob(base64Data);
                const array = [];
                for (let i = 0; i < binary.length; i++) {
                    array.push(binary.charCodeAt(i));
                }
                const blob = new Blob([new Uint8Array(array)], { type: "image/png" });
                formData.append("signature", blob, "signature.png");
            } else {
                alert("Please provide a signature first!");
            }
        } else {
            if (document.getElementById('signText').value.length > 0) {
                const text = document.getElementById('signText').value;
                const canvas = document.getElementById('textCanvas');
                const context = canvas.getContext('2d');
                canvas.width = 800;
                canvas.height = 400;
                context.clearRect(0, 0, canvas.width, canvas.height);
                context.fillStyle = 'white';
                context.fillRect(0, 0, canvas.width, canvas.height);
                context.font = '48px Arial';
                context.fillStyle = '#89bc3b';
                context.textAlign = 'center';
                context.textBaseline = 'middle';
                context.fillText(text, canvas.width / 2, canvas.height / 2);
                const dataURL = canvas.toDataURL('image/png');
                const base64Data = dataURL.split(",")[1];
                const binary = atob(base64Data);
                const array = [];
                for (let i = 0; i < binary.length; i++) {
                    array.push(binary.charCodeAt(i));
                }
                const blob = new Blob([new Uint8Array(array)], { type: "image/png" });
                formData.append("signature", blob, "signature.png");
            } else {
                alert("Please provide a signature first!");
            }
        }
        formData.append("organization_id", '676e703e0d4d8ce657257d0e');
        formData.append("createdBy", 'Kuldeep Test');
        formData.append("signedBy", 'test Test');
        setPayLoadFormData(formData);
        setConfirmationModal(true);
        setSignatureModal(false);
    };

    const uploadSignature = () => {
        axios.post('https://econsent.tactionclients.com/api/client/createclientconsent', payLoadFormData).then(
            (res) => {
                if (res.status == 200 && res.data.success) {
                    setThanksModal(true);
                    alert("Sent successfully");
                    console.log(res.data.data);
                }
            }
        ).catch(
            (err) => {
                setThanksModal(true);
                alert(err.response.data.error);
                console.log(err.response.data.error);
            }
        );
    }

    return (
        <>
            <Topnav></Topnav>
            <div className="row p-3">
                <div className="col-9">
                    <div className="card">
                        <div className="row p-4 d-flex justify-content-center mt-3">
                            <div className="col-12">
                                <h3>Content for consent to services</h3>
                            </div>
                            <div className="col-12 mt-3">
                                {/* <TextAreaAutosize style={{ overflow: 'hidden' }} className="form-control" type="text" name="contentArea" id='contentArea' value={consentConetnt} placeholder="Type Something here" onChange={(e) => { consentContentfunction(e) }}></TextAreaAutosize> */}
                                <div id="myDiv">
                                    <div className="row d-flex justify-content-center"> {consentContentfunction()} </div>
                                    <div className="row d-flex justify-content-end">
                                        <input type="submit" className="btn btn-primary start-end" id="submitButton" name="submitButton" value="Sign" onClick={() => { setSignatureModal(true) }} ></input>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-12 mt-3">
                                <div className="card p-3">
                                    <div className="row d-flex justify-content-end">
                                        <input type="submit" className="btn btn-success start-end" id="submitButton" name="submitButton" value="Click me" onClick={(e) => { consentContentfunction(e) }} ></input>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="col-3">
                    <div className="card">
                        <div className="card-header">
                            <h5>Field Status</h5>
                        </div>
                        <div className="card-body pt-3 pl-4">
                            <div className="row">
                                <label>Label 1</label>
                            </div>
                            <div className="row">
                                <label>Label 2</label>
                            </div>
                            <div className="row">
                                <label>Label 3</label>
                            </div>
                            <div className="row">
                                <label>Label 4</label>
                            </div>
                            <div className="row">
                                <label>Label 5</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="row p-3">
                
            </div> */}
            {signatureModal ? <Modal
                // style={{height : "500px", width : "800px"}}
                showOverlay={true}
                show={true}
                onClose={() => { setSignatureModal(false) }}
                disableOverlayClick={true}
                showCloseButton={true}
            // size="sm"
            // className="modal-lg modal-dialog"
            >
                <Modal.Header>
                    <Modal.Title>
                        <div className="row">
                            <div className="col-md-3 mt-1">
                                <input type="radio" name="drawtype" id="drawtype" htmlFor="granular" value={'draw'} checked={signatureType === 'draw'} onChange={(e) => { setSignatureType(e.target.value) }} /><label className="ml-2">Draw</label>
                            </div>
                            <div className="col-md-3 mt-1">
                                <input type="radio" name="texttype" id="texttype" htmlFor="granular" value={'text'} checked={signatureType === 'text'} onChange={(e) => { setSignatureType(e.target.value) }} /><label className="ml-2">Text</label>
                                {/* <input type="radio" name="site_name" value={result.SITE_NAME}> {result.SITE_NAME} </input>  */}
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ width: "700px" }}>
                    <div className="modal-body">
                        {/* {signatureType === 'draw' ? <SignatureCanvas id="sign_id" penColor='#89bc3b' canvasProps={{ width: 690, height: 275, className: 'sigCanvas' }} /> : */}
                        {/* <div > */}
                        {signatureType === 'draw' ? <SignatureCanvas ref={sigPadRef} penColor='#89bc3b' id="sign_id" canvasProps={{ width: 690, height: 275, className: 'sigCanvas' }} /> :
                            <div className="font-options" style={{ overflow: 'visible' }}>
                                {/* <input type="text" style={{ fontFamily : `${fontStyle}` }} className="form-control" onChange={(e) => { setInputValue(e.target.value) }} value={typeof (selectedFont) == "string" ? selectedFont : inputValue} placeholder="Type your Sign here"></input> */}
                                <input type="text" style={{ fontFamily: `${fontStyle}` }} id="signText" className="form-control" onChange={(e) => { setInputValue(e.target.value) }} value={typeof (selectedFont) == "string" ? selectedFont : inputValue} placeholder="Type your Sign here"></input>
                                <canvas id="textCanvas" style={{ display: 'none' }}></canvas>
                                <div className="row">
                                    {fontStyles.map((font, index) => (
                                        <div className="col-6 p-3" style={{ border: "10px", color: '#89bc3b' }}>
                                            <div key={index} className={`font-sample ${selectedFont.name === font.name ? 'selected' : ''} form-control pointer`} onClick={() => handleFontChange(font, font.style)} style={font.style} >
                                                {inputValue || 'Type your name here'}
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                        }
                        {/* </div> */}
                        {/* } */}
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Modal.Title>
                        <div className="row float-right">
                            <input className='btn btn-success' type="submit" name="save" id="save" onClick={submitSignature}></input>
                        </div>
                    </Modal.Title>
                </Modal.Footer>

            </Modal> : ""}


            {confirmationModal ? <Modal
                style={{ width: "500px" }}
                showOverlay={true}
                show={true}
                onClose={() => { setConfirmationModal(false) }}
                disableOverlayClick={true}
                showCloseButton={true}
                size="sm"
            // className="modal-md modal-dialog"
            >
                <Modal.Header>
                    <Modal.Title>
                        {/* <h5>Are you Sure ?</h5> */}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row flex-column d-flex align-items-center mt-3 mb-4">
                        <i class='fas fa-exclamation-circle' style={{ fontSize: '100px', color: 'red' }}></i>
                        <h5>Are you sure ?</h5>
                        <span className="mt-2 bd-highlight">You want to save e-sign.</span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <input className='btn btn-primary mr-5 float-left' type="submit" name="noButton" id="noButton" value={"No"} onClick={() => setConfirmationModal(false)}></input>
                    <input className='btn btn-success ml-5 float-right' type="submit" name="yesButton" id="yesButton" value={"Yes"} onClick={() => uploadSignature()}></input>
                </Modal.Footer>

            </Modal> : ""}

            {thanksModal ? <Modal
                style={{ width: "600px" }}
                showOverlay={true}
                show={true}
                onClose={() => {
                    setConfirmationModal(false);
                    setThanksModal(false)
                }
                }
                disableOverlayClick={true}
                showCloseButton={true}
            // size="sm"
            // className="modal-md modal-dialog"
            >
                <Modal.Header>
                    <Modal.Title>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: "250px" }}>
                    <div className="row flex-column d-flex align-items-center mt-5">
                        <h1>THANK YOU !</h1>
                        <h1 className="fa fa-check" style={{ color: '#89bc3b' }}></h1>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Modal.Title>

                    </Modal.Title>
                </Modal.Footer>

            </Modal> : ""}
        </>
    )
};

export default SignInPage;
