import React, { Component } from 'react'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TextAreaAutosize from 'react-textarea-autosize';

export default class Disorder_problems extends Component {
    constructor(props) {
        super(props);
        this.state = {
           problems:{
            // code add by raj start
            other_frequency_of_use:props.other_frequency_of_use,
            other_route_of_administration:props.other_route_of_administration,
            // code add by raj end
            problem_type: props.problem_type,
            specific_substance: props.specific_substance,
            route_of_administration: props.route_of_administration,
            frequency_of_use: props.frequency_of_use,
            age_of_1st_use: props.age_of_1st_use,
            specific_substance_options: props.specific_substance_options,
            rank_order:props.rank_order,
            isSaved:true,
        },
            problem_type_options:[],
            // route_of_admin:"",
            // frequency_of_use: "",
            isSaveAndComplete:props.isSaveAndComplete,
        }
     
    }
    frequency_of_use_options= [
        { label: 'No use in the past month', value: 'No_use_in_the_past_month' },
        { label: '1 to 3 times in past month', value: '1_to_3_times_in_past_month' },
        { label: '1 to 2 times in the past week', value: '1_to_2_times_in_the_past_week' },
        { label: '3 to 6 times in the past week', value: '3_to_6_times_in_the_past_week' },
        { label: 'Daily', value: 'Daily' },
        { label: 'Other', value: 'Other' },
    ];
  
    dataListBody = {
        apikey: localStorage.getItem('__TOKEN__'),
        client_id: sessionStorage.getItem("clientId"),
        form_type:this.props.form_type,
        uploaded_by:sessionStorage.getItem("username"),
        flag:1,
        
      };
    handleChange = async(e)=>{
        this.setState({isSaved:false})
        let problems=this.state.problems;
        if(e.target.name=="problem_type"){
            problems.problem_type=e.target.value;
            this.setState({problems:problems})

            let final_data={dataListBody:this.dataListBody,problem_type:e.target.value}
            let res = await axios.post(
                global.restApiURL + "customRest/getSubstanceProblemType",
                JSON.stringify(final_data)
            );
            if (res.status == 200 && res.data.success == true){
                   let xyz=this.state.problems;
                    xyz.specific_substance_options=res.data.data.problem_type.substance;
                    this.setState({problems:xyz});
            }


        }else if(e.target.name=="other_route_of_administration"){
            problems.other_route_of_administration=e.target.value;
            this.setState({problems:problems})
        }else if(e.target.name=="other_frequency_of_use"){
            problems.other_frequency_of_use=e.target.value;
            this.setState({problems:problems})
        }else if(e.target.name=="specific_substance"){
            problems.specific_substance=e.target.value;
            this.setState({problems:problems})
        }else if(e.target.name=="route_of_administration"){
            problems.route_of_administration=e.target.value;
            this.setState({problems:problems})
        }else if(e.target.name=="frequency_of_use"){
            problems.frequency_of_use=e.target.value;
            this.setState({problems:problems})
        }else if(e.target.name=="age_of_1st_use"){
            problems.age_of_1st_use=e.target.value;
            this.setState({problems:problems})
           
        }
        setTimeout(()=>{
            this.props.childToParent(this.state.problems)
         },500)
    }
    handleOnSubmit = async(e) => {
       
        e.preventDefault();
        // let keyss=this.props.type+"_"+"SubstanceProblemTypeData";
        let final_data = {
            substances: this.state.problems,
            dataListBody: this.dataListBody,
            // problemType:this.props.type + "_"+"problem_type",
        };
        let res = await axios.post(
            global.restApiURL + "customrest/savesubstances",
            JSON.stringify(final_data)
        );
       
        if (res.data.success == true && res.status == 200) {
            this.setState({isSaved:true})
            toast.success('Data Saved successfully!', {toastId:('Data Saved successfully!')}, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.loadData();
        }
      };

      loadData=async()=>{
        // if (!this.props.isSaveAndComplete) {
        this.setState({isSaved:true})
        let final_data={dataListBody:this.dataListBody}
        let substanceProblemType_res = await axios.post(
            global.restApiURL + "customRest/getProblems",
            JSON.stringify(final_data)
        );
        if (substanceProblemType_res.status == 200 && substanceProblemType_res.data.success == true) {
            
            let options= substanceProblemType_res.data.data.problemType.length > 0 ?JSON.parse(substanceProblemType_res.data.data.problemType ):[]
                this.setState({problem_type_options:options})
        }
        // }
        setTimeout(()=>{
            this.props.childToParent(this.state.problems)
         },500)
       
    }  

    async componentDidMount() {
        // let final_data={dataListBody:this.dataListBody}
        // let specificSubstance_res = await axios.post(
        //     global.restApiURL + "customRest/getSubstances",
        //     JSON.stringify(final_data)
        // );
        //   if (specificSubstance_res.status == 200 && specificSubstance_res.data.success == true) {
        //     let options= specificSubstance_res.data.data.substanceType .length > 0 ?JSON.parse(specificSubstance_res.data.data.substanceType ):[]
        //      this.setState({defaultSpecificSubstanceOptions:options})
        //     // console.log("specificSubstance_res", specificSubstance_res.data.data)
        //  }
        this.loadData();
    }
    render() {
        return (
            <> 
            {/* <ToastContainer style={{zIndex:900}}/> */}
            <div className='card ml-4 mr-4 md-4 mt-4'>
                   <form onSubmit={this.handleOnSubmit}>
                <div className='card-body'>
                    <div className='row marginBottom1'>
                        <div className='col-lg-6 col-md-12'>
                            <div className='form-group'>
                                <label>{this.props?.type} Substance Problem Type </label>
                                <select name="problem_type" disabled={this.state.isSaveAndComplete} className='form-control' onChange={this.handleChange} value={this.state.problems.problem_type}>
                                    <option value="">Select</option>
                                    {this.state.problem_type_options.map((val)=>{
                                        return(<option value={val.value}>{val.label}</option>)
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12'>
                            <div className='form-group'>
                                <label>{this.props?.type} Substance Detail  </label>
                                <select name="specific_substance" className='form-control' disabled={this.state.isSaveAndComplete} value={this.state.problems.specific_substance} onChange={this.handleChange}>
                                    <option value="">Select</option>
                                    {this.state.problems.specific_substance_options.map((val)=>{
                                        return(<option value={val.value}>{val.label}</option>)
                                    })}
                                </select>
                            
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12'>
                            <div className='form-group'>
                                <label>{this.props?.type} Substance Route</label>
                                <select name="route_of_administration" className='form-control' disabled={this.state.isSaveAndComplete} value={this.state.problems.route_of_administration} onChange={this.handleChange}>
                                    <option value="">Select</option>
                                    <option value="oral">Oral</option>
                                    <option value="smoking">Smoking</option>
                                    <option value="inhalation">Inhalation/Nasal</option>
                                    <option value="injection">Injection/IV</option>
                                    <option value="injection_intramuscular">Injection/Intramuscular</option>
                                    <option value="rectal">Rectal</option>
                                    <option value="vaginal">Vaginal</option>
                                    <option value="other_specify">Other</option>
                                </select>
                            </div>
                        </div>
                        {this.state.problems.route_of_administration === "other_specify" ?
                            <div className='col-lg-6 col-md-12'>
                                <div className='form-group'>
                                    <label>If other, please specify</label>
                                    <TextAreaAutosize onChange={this.handleChange} minRows={(this.state.problems.other_route_of_administration?.length / 35) + this.state.problems.other_route_of_administration?.split(/\r\n|\r|\n/).length} name="other_route_of_administration" style={{ overflow: "hidden", textAlign: "justify" }} type="text" className="form-control" defaultValue={this.state.problems.other_route_of_administration} />
                                </div>
                            </div> : ""
                        }
                        <div className='col-lg-6 col-md-12'>
                            <div className='form-group'>
                                <label>{this.props?.type} Substance Frequency</label>
                                <select name="frequency_of_use" className='form-control' disabled={this.state.isSaveAndComplete} value={this.state.problems.frequency_of_use}  onChange={this.handleChange}>
                                    <option value="">Select</option>
                                    <option value="no_use_in_the_past_month ">No use in the past month      </option>
                                    <option value="1_to_3_times_in_past_month ">1 to 3 times in past month     </option>
                                    <option value="1_to_2_times_in_the_past_week">1 to 2 times in the past week</option>
                                    <option value="3_to_6_times_in_the_past_week">3 to 6 times in the past week</option>
                                    <option value="daily">Daily</option>
                                    <option value="other">Other</option>         
                                </select>
                            </div>
                        </div>
                        {this.state.problems.frequency_of_use === "other" ?
                            <div className='col-lg-6 col-md-12'>
                                <div className='form-group'>
                                    <label>If other, please specify</label>
                                    <TextAreaAutosize onChange={this.handleChange}  minRows={(this.state.problems.other_frequency_of_use?.length / 35) + this.state.problems.other_frequency_of_use?.split(/\r\n|\r|\n/).length}  style={{ overflow: "hidden", textAlign: "justify" }} name="other_frequency_of_use" type="text" className="form-control" defaultValue={this.state.problems.other_frequency_of_use} />
                                </div>
                            </div> : ""
                        }
                        <div className='col-lg-6 col-md-12'>
                            <div className='form-group'>
                                <label>{this.props?.type} Substance Age of 1st Use</label>
                                <input type="text" name="age_of_1st_use" disabled={this.state.isSaveAndComplete} className='form-control' value={this.state.problems.age_of_1st_use} onChange={this.handleChange} />
                           
                                {/* <input type="hidden" name="rank_order" className='form-control' value={this.state.problems.rank_order} onChange={this.handleChange} /> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-footer'>
                    {/* <button className='btn btn-success float-right'><i className="fas fa-save"></i> Save</button> */}
                    <button
                        type="submit"
                        data-save="save"
                        value="save"
                        className="btn btn-success float-right btnmargin"
                        disabled={this.state.isSaveAndComplete || this.state.isSaved}
                        >
                        {" "}
                        <i className="fas fa-save"></i> Save
                    </button>
                </div>
                </form>
            </div>
            </>)
    }
}
