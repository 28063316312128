import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { CSVLink } from "react-csv";

import CoverTableHeaderContainer from "./CoverTableHeader";
import Filter from "./CoverFilter";
import { addDays } from "date-fns";

import CoverTableContainer from "./CoverTableContainer";
import AdmhTableContainer from "./AdmhTableContainer";

import { sampleData } from "./ClientsStackBar";
import CalendarIcon from "../../assests/images/icon-calendar.png";
import ReactDatePicker from "react-datepicker";

import { DateRangePicker } from "rsuite";
import "../daterangecss/style.css";
import "rsuite/dist/rsuite.min.css";

import moment from "moment";

import axios from "axios";
import { createHashHistory } from "history";
import { API_URL } from "../../commonFunctions/ApiUrl";

import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  ListItemText,
  OutlinedInput,
  Checkbox,
  makeStyles,
} from "@material-ui/core";

const MainContainer = styled.section`
  box-sizing: border-box;
  width: 98%;
  margin: 50px 16px 0 16px;
`;

// header design

const DateContainer = styled.div`
  display: ${(props) => (props.openDateModal ? "block" : "none")};
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.span`
  flex: 0 0 15%;
  font-weight: 600;
  @media (max-width: 1024px) {
  }
`;

const FilterContainer = styled.div`
  display: flex;
  width: 100%;

  /* justify-content: space-between; */
  flex: 0 0 70%;
  @media (max-width: 1370px) {
  }
`;

const Button = styled.button`
  border: none;
  cursor: pointer;
  outline: none;
  background-color: #7bb731;
  color: #fff;
  border-radius: 4px;
  /* flex: 0 0 15%; */
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterDate = styled.div`
  width: 230px;

  border: 1px solid #cbcbcb;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  justify-content: center;
  overflow: hidden;
`;

const width = "220px";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      top: 0,
      bottom: 0,
    },
  },
};

const useStyles = makeStyles({
  root: {
    border: "none",
    height: "50px",
    fontSize: "0.875rem",
    "&::before": {
      border: "none",
    },
    "&:hover": {
      border: "none",
    },
  },
});

// header design ends

const AdmhSourceTable2 = ({ title, filter, filterOptions }) => {
  const [age, setage] = useState("2021");
  const classes = useStyles();
  const [year, setYear] = useState([]);
  const [month, setMonth] = useState([]);
  const [client, setClient] = useState([]);
  // console.log(client);
  const [getRrcData, setgetRrcData] = useState([]);

  const [getTreatmentData, setTreatmentData] = useState([]);
  const [getBarriers, setBarriersData] = useState([]);

  const [isRrc, setRrc] = useState(false);
  const [isTreatment, setTreatment] = useState(false);
  const [isBarriers, setBarriers] = useState(false);

  const [openDateModal, setOpenDateModal] = useState(false);
  const wrapperRef = React.useRef(null);
  // useOutsideAlerter(wrapperRef, openDateModal);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");

  const [isYearDisable, setIsYearDisable] = useState(false);
  const [isDateRangeDisable, setIsDateRangeDisable] = useState(false);
  // const OrgId = sessionStorage.getItem("organization_id");
  const OrgId = 5184;
  const styles = {
    width: 100,
    height: "100%",
    display: "block",
    marginBottom: 10,
    height: 50,
    borderRadius: "1px",
  };
  

  const arr = [];



  const options = ["2022", "2021", "2020", "2019", "2018", "2017"];

  // const optionData = sampleData[0].data.map((e) => {e.x, e.val});
  const optionData = sampleData[0].data;

  const yearhandleChange = (event) => {
    
    const {
      target: { value },
    } = event;
    setYear(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const monthhandleChange = (event) => {
    const {
      target: { value },
    } = event;
    setMonth(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const clienthandleChange = (event) => {
    const {
      target: { value },
    } = event;
    setClient(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // useEffect(() => {
  //   fetchsearchClients(year, month, client, state);
  // }, [year, month, client, state, startDate, endDate,isYearDisable]);
  useEffect(() => {
    fetchsearchClients(year, month, client, state);
  }, [year, month, client, state, startDate, endDate, isYearDisable]);
  const fetchsearchClients = (year, month, client, state) => {
    //  for year  //
    if (year == undefined || year == "") {
      year = "";
    } else {
      year = year.toString();
    }

    //  for Client  //
    if (client == undefined || client == "") {
      client = "all";
    } else {
      client = client.toString();
    }

    // for month //
    if (month == undefined || month == "") {
      month = 0;
    } else {
      if (month.length > 0) {
        month.forEach(function (item, i) {
          if (item == "January") {
            arr[i] = 1;
          }
          if (item == "Feburary") {
            arr[i] = 2;
          }
          if (item == "March") {
            arr[i] = 3;
          }
          if (item == "April") {
            arr[i] = 4;
          }
          if (item == "May") {
            arr[i] = 5;
          }
          if (item == "June") {
            arr[i] = 6;
          }
          if (item == "July") {
            arr[i] = 7;
          }
          if (item == "August") {
            arr[i] = 8;
          }
          if (item == "September") {
            arr[i] = 9;
          }
          if (item == "October") {
            arr[i] = 10;
          }
          if (item == "November") {
            arr[i] = 11;
          }
          if (item == "December") {
            arr[i] = 12;
          }
        });
      }

      //month = arr.toString();
      month = 0;
    }
    // (Object.values(year).length<=0?(setIsDateRangeDisable(false)):(setIsDateRangeDisable(true)));
    // (Object.values(year).length<=0?( alert("Length is less thyan 0")):( alert("")));
    (Object.values(year).length<=0?(setIsDateRangeDisable(false)):(setIsDateRangeDisable(true)));
    if (client.indexOf("all") != -1) 
    {
      if (year == "" && startDate == "" && endDate == "") {
        // for rrc
        axios
        .get(API_URL +`participation_by_rrc?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=Null&startDate=2022-01-01&endDate=2022-12-31`)
        .then((response) => {setgetRrcData(response.data.data);})
        .catch((error) => {console.log(error);});

         // for treatment  
          axios
          .get( API_URL +`participation_by_treatment?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=Null&startDate=2022-01-01&endDate=2022-12-31`)
          .then((response) => {setTreatmentData(response.data.data);})
          .catch((error) => {console.log(error);});

          // for barriers
          axios
          .get(API_URL +`participation_by_barriers?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=Null&startDate=2022-01-01&endDate=2022-12-31`)
          .then((response) => {setBarriersData(response.data.data);})
          .catch((error) => {console.log(error);});
        }else{
          if (year != "") {
             // for rrc
              axios
              .get(API_URL +`participation_by_rrc?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable == true ? "Null" : year}&startDate=Null&endDate=Null`)
              .then((response) => {setgetRrcData(response.data.data);})
              .catch((error) => {console.log(error);});

            // for treatment
              axios
              .get( API_URL +`participation_by_treatment?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable == true ? "Null" : year}&startDate=Null&endDate=Null`)
              .then((response) => {setTreatmentData(response.data.data);})
              .catch((error) => {console.log(error);});

              // for barriers
              axios
              .get(API_URL +`participation_by_barriers?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable == true ? "Null" : year}&startDate=Null&endDate=Null`)
              .then((response) => {setBarriersData(response.data.data);})
              .catch((error) => {console.log(error);});

          }else{
          //
            // for rrc
            axios
            // .get(API_URL +`participation_by_rrc?OrgId=${OrgId}&year=${isYearDisable == true ? "Null" : year}&startDate=${isDateRangeDisable == true ? "Null" : startDate}&endDate=$		{isDateRangeDisable==true ? "Null": endDate}`)
            .get(API_URL + `participation_by_rrc?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=${isDateRangeDisable==true ? "Null" : startDate}&endDate=${isDateRangeDisable==true ? "Null": endDate}`)
            .then((response) => {setgetRrcData(response.data.data);})
            .catch((error) => {console.log(error);});

            // .get(API_URL + `participation_by_rrc?OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=${isDateRangeDisable==true ? "Null" : startDate}&endDate=${isDateRangeDisable==true ? "Null": endDate}`)
          // for treatment
            axios
            .get( API_URL +`participation_by_treatment?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=${isDateRangeDisable==true ? "Null" : startDate}&endDate=${isDateRangeDisable==true ? "Null": endDate}`)
            .then((response) => {setTreatmentData(response.data.data);})
            .catch((error) => {console.log(error);});

            // for barriers
            axios
            .get(API_URL +`participation_by_barriers?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=${isDateRangeDisable==true ? "Null" : startDate}&endDate=${isDateRangeDisable==true ? "Null": endDate}`)
            .then((response) => {setBarriersData(response.data.data);})
            .catch((error) => {console.log(error);});
          }
        }
    }

  // ======================================RRC START========================================
    if (client.includes("RRC") == true) {
      if (year == "" && startDate == "" && endDate == "") {
        // 
        axios
        .get(API_URL +`participation_by_rrc?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=Null&startDate=2022-01-01&endDate=2022-12-31`)
        .then((response) => {setgetRrcData(response.data.data);})
        .catch((error) => {console.log(error);});
        
        }else{
          if (year != "") {
          // 
          axios
          .get(API_URL +`participation_by_rrc?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable == true ? "Null" : year}&startDate=Null&endDate=Null`)
          .then((response) => {setgetRrcData(response.data.data);})
          .catch((error) => {console.log(error);});
          }else{
          //
          axios
          .get(API_URL +`participation_by_rrc?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=${isDateRangeDisable==true ? "Null" : startDate}&endDate=${isDateRangeDisable==true ? "Null": endDate}`)
          .then((response) => {setgetRrcData(response.data.data);})
          .catch((error) => {console.log(error);});
        }
        }
    }

  // ======================================Treatment START========================================   
  if (client.includes("Treatment") == true) {
    if (year == "" && startDate == "" && endDate == "") {
       // for treatment
       axios
       .get( API_URL +`participation_by_treatment?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=Null&startDate=2022-01-01&endDate=2022-12-31`)
       .then((response) => {setTreatmentData(response.data.data);})
       .catch((error) => {console.log(error);});
      }else{
        if (year != "") {
         // for treatment
         axios
         .get( API_URL +`participation_by_treatment?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable == true ? "Null" : year}&startDate=Null&endDate=Null`)
         .then((response) => {setTreatmentData(response.data.data);})
         .catch((error) => {console.log(error);});
        }else{
         // for treatment
         axios
         .get( API_URL +`participation_by_treatment?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=${isDateRangeDisable==true ? "Null" : startDate}&endDate=${isDateRangeDisable==true ? "Null": endDate}`)
         .then((response) => {setTreatmentData(response.data.data);})
         .catch((error) => {console.log(error);});
        }
      }


  } 
// ======================================Barriers START========================================   
  if (client.includes("Barriers") == true) {
    if (year == "" && startDate == "" && endDate == "") {
      // 
       // for barriers
       axios
       .get(API_URL +`participation_by_barriers?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=Null&startDate=2022-01-01&endDate=2022-12-31`)
       .then((response) => {setBarriersData(response.data.data);})
       .catch((error) => {console.log(error);});
      
      }else{
        if (year != "") {
        // 
        axios
       .get(API_URL +`participation_by_barriers?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable == true ? "Null" : year}&startDate=Null&endDate=Null`)
       .then((response) => {setBarriersData(response.data.data);})
       .catch((error) => {console.log(error);});
        }else{
        //
        axios
       .get(API_URL +`participation_by_barriers?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable == true ? "Null" : year}&startDate=${isDateRangeDisable == true ? "Null" : startDate}&endDate=$		{isDateRangeDisable==true ? "Null": endDate}`)
       .then((response) => {setBarriersData(response.data.data);})
       .catch((error) => {console.log(error);});
      }
      }

  }
  


    
   

   

  };

  const headers = [
    { label: "Source", key: "Source" },
    { label: "Name", key: "Name" },
    { label: "21-22 YTD", key: "YTD" },
    { label: "January", key: "Jan" },
    { label: "Feburary", key: "Feb" },
    { label: "March", key: "March" },
    { label: "April", key: "April" },
    { label: "May", key: "May" },
    { label: "June", key: "June" },
    { label: "July", key: "July" },
    { label: "August", key: "Aug" },
    { label: "September", key: "Sep" },
    { label: "October", key: "Oct" },
    { label: "November", key: "Nov" },
    { label: "December", key: "Dec" },
  ];

  let combinedArray = [];

  combinedArray.push(...getRrcData, ...getTreatmentData, ...getBarriers);
 
  const downloadArr = [];

  if (combinedArray?.length > 0) {
    for (let index = 0; index < combinedArray.length; index++) {
      if (combinedArray[index]["data"].length > 0) {
        for (let i = 0; i < combinedArray[index]["data"].length; i++) {
          downloadArr.push({
            Source: combinedArray[index]["source"],
            Name: combinedArray[index]["data"][i].name,
            YTD: combinedArray[index]["data"][i].YTD,
            Jan: combinedArray[index].data[i].data[0].val || 0,
            Feb: combinedArray[index].data[i].data[1].val || 0,
            March: combinedArray[index].data[i].data[2].val || 0,
            April: combinedArray[index].data[i].data[3].val || 0,
            May: combinedArray[index].data[i].data[4].val || 0,
            June: combinedArray[index].data[i].data[5].val || 0,
            July: combinedArray[index].data[i].data[6].val || 0,
            Aug: combinedArray[index].data[i].data[7].val || 0,
            Sep: combinedArray[index].data[i].data[8].val || 0,
            Oct: combinedArray[index].data[i].data[9].val || 0,
            Nov: combinedArray[index].data[i].data[10].val || 0,
            Dec: combinedArray[index].data[i].data[11].val || 0,
            // Total: combinedArray[index]["data"][i].total,
          });
        }
      }
    }
  }

  return (
    <>
      <MainContainer>
        <HeaderContainer>
          <Title>{title}</Title>
          <FilterContainer>
            {/******************************************* for Multi year *********************  */}
            <div
              style={{ height: "50px" }}
              selected={age}
              value={age}
              placeholder="Show Data for Year 2021"
              width="220px"
            >
              <FormControl
                disabled={isYearDisable}
                sx={{
                  m: 1,
                  width: 130,
                }}
                style={{
                  width: width,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "50px",
                  border: "none",
                  margin: "0px 10px 0px 10px",
                }}
              >
                <InputLabel
                  id="demo-multiple-checkbox-label"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",

                    paddingTop: "18px",
                    transform: "none",
                    transition: "none",
                  }}
                >
                  {year.length !== 0 ? "" : "Show Data for Year 2021"}
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={year}
                  onChange={yearhandleChange}
                  input={<OutlinedInput label="" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  className={classes.root}
                  style={{ width: width }}
                >
                  {options.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox
                        // color="#7BB731"
                        style={{ color: "#7BB731" }}
                        checked={year.indexOf(name) > -1}
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {/* year multiselect end */}

            {/* <Filter
          selected={age}
          value={age}
          placeholder="Show Data for Year 2021"
          width="220px"
          
          options={[
            "2021",
            "2020",
            "2019",
            "2018",
            "2017",
          ]}
          
        />  */}
            {/******************************************* for Multi year end *********************  */}

            {/********************************************for Multi Month ************************** */}
            {/* <div
            style={{ height: "50px" }}
            selected={month}
            placeholder="All Months"
            width="150px"
          >
            <FormControl
              sx={{
                m: 1,
                width: 150,
              }}
              style={{
                width: width,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "50px",
                border: "none",
                margin: "0px 10px 0px 10px",
              }}
            >
              <InputLabel
                id="demo-multiple-checkbox-label"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",

                  paddingTop: "18px",
                  transform: "none",
                  transition: "none",
                }}
              >
                {month.length !== 0 ? "" : "All Months"}
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={month}
                onChange={monthhandleChange}
                input={<OutlinedInput label="" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                className={classes.root}
                style={{ width: width }}
              >
                {optionData.map((name) => (
                  <MenuItem key={name.x} value={name.x}>
                    <Checkbox
                      // color="#7BB731"

                      style={{ color: "#7BB731" }}
                      checked={month.indexOf(name.x) > -1}
                    />
                    <ListItemText primary={name.x} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div> */}

            {/********************************************for Multi Month ends ************************** */}

            {/******************************************* for Client *********************  */}

            <div style={{ height: "50px" }}>
              <FormControl
                sx={{
                  m: 1,
                  width: 130,
                }}
                style={{
                  width: width,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "50px",
                  border: "none",
                  margin: "0px 10px 0px 10px",
                }}
              >
                <InputLabel
                  id="demo-multiple-checkbox-label"
                  style={{
                    paddingLeft: "10px",
                    paddingRight: "10px",

                    paddingTop: "18px",
                    transform: "none",
                    transition: "none",
                  }}
                >
                  {client.length !== 0 ? "" : "All Clients "}
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={client}
                  onChange={clienthandleChange}
                  input={<OutlinedInput label="" />}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                  className={classes.root}
                  style={{ width: width }}
                >
                  {filterOptions.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox
                        // color="#7BB731"
                        style={{ color: "#7BB731" }}
                        checked={client.indexOf(name) > -1}
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {/******************************************* for Client ends  *********************  */}

            {/******************************************* for month  start *********************  */}

            {/* <Filter placeholder={filter} width="150px" options={options}  /> */}
            {/* <Filter
         selected={month}
          placeholder="All Months"
          width="150px"
          
          options={sampleData[0].data.map((e) => e.x)}
          
        /> */}

            {/* <FilterDate> */}

            {/* <Button data={genderData[0].data}>Download CSV</Button> */}
            {/* <FilterDate> */}
            {/* {" "}
            {state.map((date) => (
              <>
                <span> {date.startDate.toLocaleDateString("en-US")} </span>-{" "}
                <span style={{ marginLeft: "5px" }}>
                  {" "}
                  {date.endDate.toLocaleDateString("en-US")}{" "}
                </span>{" "}
              </>
            ))} */}
            {/* <DateContainer openDateModal={openDateModal} ref={wrapperRef}>
              <DateRangePicker
                onChange={(item) => {
                  setState([item.selection]);
                  setOpenDateModal(false);
                  // {state.map((date)=>{
                      // setstartDate(moment(date.startDate).format("YYYY-MM-DD"));
                      // setendDate(moment(date.endDate).format("YYYY-MM-DD"));

                      // console.log("inner Part ");
                    
                      //  console.log("Start date : " + date.startDate);
                      // console.log("End date : " +date.endDate);
                  // })}
                  console.log("inner Part  State");
                  console.log(state);
                  // setstartDate(moment(item.selection.endDate).format("YYYY-MM-DD"));
                  // setendDate(moment(item.selection.startDate).format("YYYY-MM-DD"));
                  // console.log(state[0].startDate.toLocaleDateString("en-US"));

                //   console.log("inner Part  Start Date And End date");
                //  console.log("Start date : " + moment(item.selection.startDate).format("YYYY-MM-DD"));
                //  console.log("End date : " + moment(item.selection.endDate).format("YYYY-MM-DD"));
                 
              
                //  setEndDate(moment(state[0].endDate).format("YYYY-MM-DD"))
                //  alert("Start Date " + startDate);
                //  alert("End date " + moment(state[0].startDate).format("YYYY-MM-DD"));
                  // alert(startDate + " to "+ endDate);
                  // console.log(startDate + " to "+ endDate);
                }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={state}
                direction="horizontal"
                className="monthlyReportdaterangepicker"
              />
            </DateContainer> */}
            {/* <img
              style={{paddingLeft: "5px"}}
              src={CalendarIcon}
              alt="calendaricon"
              onClick={() => setOpenDateModal(!openDateModal)}
            />{" "} */}
            {/* </FilterDate>{" "} */}
            {/* <FilterDate>  */}

            <DateRangePicker
             disabled={isDateRangeDisable}
              size="lg"
              placeholder="2021-01-01 ~ 2021-12-31"
              style={styles}
              onChange={(e) => {
                return e
                  ? (setstartDate(moment(e[0]).format("YYYY-MM-DD")),setendDate(moment(e[1]).format("YYYY-MM-DD")),
                    setIsYearDisable(true)): (setstartDate(""), setendDate(""), setIsYearDisable(false));
              }}
            />

            {/* </FilterDate>  */}
          </FilterContainer>
          <Button>
            {" "}
            <CSVLink
              filename="Admh_participation_report.csv"
              data={downloadArr}
              headers={headers}
              target="_blank"
              style={{
                color: "#ffffff",

                width: "120px",
                height: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Download CSV{" "}
            </CSVLink>
          </Button>
        </HeaderContainer>

        {client.includes("RRC") === true || client.length === 0 ? (
          <>
            {getRrcData.length > 0 ? (
              <>
                <AdmhTableContainer genderData={getRrcData} />
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}

      {client.includes("Treatment") === true || client.length === 0 ? (
          <>
            {getTreatmentData.length > 0 ? (
              <>
                <AdmhTableContainer genderData={getTreatmentData} />
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}

{client.includes("Barriers") === true || client.length === 0 ? (
          <>
            {getBarriers.length > 0 ? (
              <>
                <AdmhTableContainer genderData={getBarriers} />
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
  {/* RRC
  Treatment
  Barriers */}
   {/* setgetRrcData,setTreatmentData,setBarriersData
    getRrcData    getTreatmentData getBarriers */}
       
      </MainContainer>
    </>
  );
};

export default AdmhSourceTable2;
