import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { Component } from "react";
import axios from "axios";
import TextareaAutosize from 'react-textarea-autosize';
import { Hidden } from "@material-ui/core";

export default class Risk_ratings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acute_risk_rating: "",
      acute_risk_rating_information: "",
      biomedical_risk_rating: "",
      biomedical_risk_rating_information: "",
      emotional_risk_rating: "",
      emotional_risk_rating_information: "",
      readiness_risk_rating: "",
      readiness_risk_rating_information: "",
      relapse_risk_rating: "",
      relapse_risk_rating_information: "",
      recovery_risk_rating: "",
      recovery_risk_rating_information: "",
      // New state Added
      isCheckedAcute: false,
      isCheckedEmotional: false,
      isCheckedBiomedical: false,
      isCheckedReadiness: false,
      isCheckedRelapse: false,
      isCheckedRecovery: false,
      acute_risk_rating_summury:"",
      biomedical_comments:"",
      emotional_comments:"",
      readiness_comments:"",
      problem_comments2:"",
      enviornment_comments:"",
      dataListBody: {
        apikey: localStorage.getItem('__TOKEN__'),
        client_id: sessionStorage.getItem("clientId"),
        form_type: this.props.form_type,
        uploaded_by: sessionStorage.getItem("username"),
      },
      saved: true,
      isSaveAndComplete: false,
    };
  }

  // rating Analysis Variable Start

  // Acute Intoxication
  // acute_risk_rating0 = `Fully functioning, no signs of intoxication or withdrawal present.`;
  // acute_risk_rating1 = `Mild to moderate intoxication interferes with daily functioning, but does not pose a danger to self or others. Minimal risk of severe withdrawal.`;
  // acute_risk_rating2 = `Intoxication may be severe but responds to support; not posing a danger to self or others. Moderate risk of severe withdrawal.`;
  // acute_risk_rating3 = `Severe signs & symptoms of intoxication indicates an imminent danger to self or others. Risk of severe but manageable withdrawal; or withdrawal is worsening.`;
  // acute_risk_rating4 = `Incapacitated, with severe signs and symptoms. Severe withdrawal presents danger, as of seizures. Continued use poses an imminent threat to life(e.g., liver failure, GI bleed, or fetal death).`;

   acute_risk_rating0=`Fully functioning, no signs of intoxication or withdrawal present.`;
   acute_risk_rating1=`Mild to moderate intoxication interferes with daily functioning, but does not pose a danger to self or others. Minimal risk of severe withdrawal.`;
   acute_risk_rating2=`Intoxication may be severe but responds to support; not posing a danger to self or others. Moderate risk of severe withdrawal.`;
   acute_risk_rating3=`Severe signs & symptoms of intoxication indicates an imminent danger to self or others. Risk of severe but manageable withdrawal or withdrawal is worsening.`;
   acute_risk_rating4=`Incapacitated, with severe signs and symptoms. Severe withdrawal presents danger, as of seizures. Continued use poses an imminent threat to life (e.g., liver failure, GI bleed, or fetal death).`;
  
  // Biomedical condition
  // biomedical_risk_rating0 = `Fully functioning and able to cope with any physical discomfort or pain.`;
  // biomedical_risk_rating1 = `Adequate ability to cope with physical discomfort. Mild to moderate symptoms (such as mild to moderate pain) interfere with daily functioning.`;
  // biomedical_risk_rating2 = `Some difficulty tolerating physical problems. Acute, non-life threatening medical symptoms are present. Serious biomedical problems are neglected.`;
  // biomedical_risk_rating3 = `Serious medical problems are neglected during outpatient TX. Severe medical problems are present but stable. Poor ability to cope w/ physical problems`;
  // biomedical_risk_rating4 = `The patient is incapacitated, with severe medical problems.`;

  biomedical_risk_rating0=`Fully functioning and able to cope with any physical discomfort or pain.`
  biomedical_risk_rating1=`Adequate ability to cope with physical discomfort. Mild to moderate symptoms (such as mild to moderate pain) interfere with daily functioning.`
  biomedical_risk_rating2=`Some difficulty tolerating physical problems. Acute, non-life threatening medical symptoms are present. Serious biomedical problems are neglected.`
  biomedical_risk_rating3=`Serious medical problems are neglected during outpatient TX. Severe medical problems are present but stable. Poor ability to cope w/physical problems`
  biomedical_risk_rating4=`The patient is incapacitated, with severe medical problems.`
  // Emotional Behaviour
  // emotional_risk_rating0 = `Good impulse control & coping skills sub-domains (dangerousness/lethality, interference with recovery efforts, social functioning, self-care ability, course of illness).`;
  // emotional_risk_rating1 = `There is a suspected or diagnosed EBC condition that requires intervention, but does not significantly interfere with TX. Relationships are being impaired but not endangered by substance use.`;
  // emotional_risk_rating2 = ` Persistent EBC condition, with symptoms that distract from recovery efforts, but are not an immediate threat to safety and do not prevent independent functioning.`;
  // emotional_risk_rating3 = `Severe EBC symptomatology, but sufficient control that does not require involuntary confinement, impulses to harm self or others, but not dangerous in a 24-hr setting.`;
  // emotional_risk_rating4 = `Severe EBC symptomatology, requires involuntary confinement. Exhibits severe and acute life-threatening symptoms (e.g., dangerous or impulsive behavior or cognitive functioning) posing imminent danger to self and others.`;
   emotional_risk_rating0=`Good impulse control & coping skills sub-domains (dangerousness/lethality, interference with recovery efforts, social functioning, self-care ability, course of illness).`
   emotional_risk_rating1=`There is a suspected or diagnosed EBC condition that requires intervention, but does not significantly interfere with TX. Relationships are being impaired but not endangered by substance use.`
   emotional_risk_rating2=`Persistent EBC condition, with symptoms that distract from recovery efforts, but are not an immediate threat to safety and do not prevent independent functioning.`
   emotional_risk_rating3=`Severe EBC symptomatology, but sufficient control  that does not require involuntary confinement, impulses to harm self or others, but not dangerous in a 24-hr setting.`
   emotional_risk_rating4=`Severe EBC symptomatology, requires involuntary  confinement. Exhibits severe and acute life-threatening symptoms (e.g., dangerous or impulsive behavior or cognitive functioning) posing  imminent danger to self and others.`
  //  radinesss For Change
  // readiness_risk_rating0 = `Willing, engaged in treatment.`;
  // readiness_risk_rating1 = `Willing to enter treatment, but is ambivalent about the need to change. Or willing to change substance use, but believes it will not be difficult to do so.`;
  // readiness_risk_rating2 = `Reluctant to agree to treatment. Able to articulate negative consequences of usage but has low commitment to change use. Only passively involved in treatment.`;
  // readiness_risk_rating3 = `Unaware of need for change, minimal awareness of the need for treatment, and unwilling or only partially able to follow through with recommendations.`;
  // readiness_risk_rating4 = `Not willing to explore change, knows very little about addiction, and is in denial of the illness and its implications. Unable to follow-through with recommendations.`;
   readiness_risk_rating0 = `Willing, engaged in treatment.`
   readiness_risk_rating1 = `Willing to enter treatment, but is ambivalent about the need to change. Or willing to change substance use, but believes it will not be difficult to do so.`
   readiness_risk_rating2 = `Reluctant to agree to treatment. Able to articulate negative consequences of usage but has low commitment to change use. Only passively involved in treatment.`
   readiness_risk_rating3 = `Unaware of need for change, minimal awareness of the need for treatment, and unwilling or only partially able to follow through with recommendations.`
   readiness_risk_rating4 = `Not willing to explore change, knows very little about addiction, and is in denial of the illness and its implications. Unable to follow-through with recommendations.`
  //  Problem Potential
  // relapse_risk_rating0 = `Low or no potential for relapse, good coping skills.`;
  // relapse_risk_rating1 = `Minimal relapse potential, with some vulnerability, and has fair self management and relapse prevention skills.`;
  // relapse_risk_rating2 = `Impaired recognition and understanding of substance use relapse issues, but is able to self manage with prompting.`;
  // relapse_risk_rating3 = `Little recognition and understanding of substance use relapse issues, and poor skills to interrupt addiction problems, or to avoid or limit relapse.`;
  // relapse_risk_rating4 = `No skills to cope with addiction problems, or to prevent relapse. Continued addictive behavior places self and or others in imminent danger.`;
  relapse_risk_rating0 = `Low or no potential for relapse, good coping skills.`
  relapse_risk_rating1 = `Minimal relapse potential, with some vulnerability and has fair self management and relapse prevention skills.`
  relapse_risk_rating2 = `Impaired recognition and understanding of substance use relapse issues, but is able to self manage with prompting.`;
  relapse_risk_rating3 = `Little recognition and understanding of substance use relapse issues, and poor skills to interrupt addiction problems, or to avoid or limit relapse.`;
  relapse_risk_rating4 = `No skills to cope with addiction problems, or to prevent relapse. Continued addictive behavior places self and or others in imminent danger.`;
  // recovery
  // recovery_risk_rating0 = `Supportive environment and or able to cope in environment.`;
  // recovery_risk_rating1 = `Passive support or significant others are not interested in patients' addiction recovery, but is not too distracted by this and is able to cope.`;
  // recovery_risk_rating2 = `The environment is not supportive of addiction recovery but, with clinical structure, able to cope most of the time.`;
  // recovery_risk_rating3 = `The environment is not supportive of addiction recovery and the patient finds coping difficult, even with clinical structure.`;
  // recovery_risk_rating4 = `Environment is chronically hostile & toxic to recovery. Clt. unable to cope with the negative effects of this environment on recovery, and the environment may pose a threat to the clt's safety.`;
  recovery_risk_rating0 = `Supportive environment and or able to cope in environment.`
  recovery_risk_rating1 = `Passive support or significant others are not interested in patients' addiction recovery, but is not too distracted by this and is able to cope.`
  recovery_risk_rating2 = `The environment is not supportive of addiction recovery but, with clinical structure, able to cope most of the time.`;
  recovery_risk_rating3 = `The environment is not supportive of addiction recovery and the patient finds coping difficult, even with clinical structure.`;
  recovery_risk_rating4 = `Environment is chronically hostile & toxic to recovery. Clt. unable to cope with the negative effects of this environment on recovery, and the environment may pose a threat to the clt's safety.`;
  
  // Rating Analysis variable end

  handleCheckboxChange = (e) => {
    this.props.tabEditOrNot(true);
    this.props.saveAndExit("", "", "", "", "",  this.handleOnSubmit, "", "", "");
    const target = e.target;
    const name = target.name;
    const isChecked = target.checked;
    this.setState({[name]: isChecked, ["saved"]: false});
    setTimeout(() => {
      this.props.childToParent(this.state);
    }, 500);
  };

  handleOnChange = (e) => {
    this.props.tabEditOrNot(true);
    this.props.saveAndExit("", "", "", "", "",  this.handleOnSubmit, "", "", "");
    this.setState({ [e.target.name]: e.target.value, ["saved"]: false });
    // this.setState({ [e.target.name]: e.target.value });
    if (e.target.name == "acute_risk_rating" && e.target.value === "0") {
      this.setState({ acute_risk_rating_information: this.acute_risk_rating0 });
    } else if (e.target.name == "acute_risk_rating" && e.target.value === "1") {
      this.setState({ acute_risk_rating_information: this.acute_risk_rating1 });
    } else if (e.target.name == "acute_risk_rating" && e.target.value === "2") {
      this.setState({ acute_risk_rating_information: this.acute_risk_rating2 });
    } else if (e.target.name == "acute_risk_rating" && e.target.value === "3") {
      this.setState({ acute_risk_rating_information: this.acute_risk_rating3 });
    } else if (e.target.name == "acute_risk_rating" && e.target.value === "4") {
      this.setState({ acute_risk_rating_information: this.acute_risk_rating4 });
    }
    //   For biomedical_risk_rating
    else if (
      e.target.name == "biomedical_risk_rating" &&
      e.target.value === "0"
    ) {
      this.setState({
        biomedical_risk_rating_information: this.biomedical_risk_rating0,
      });
    } else if (
      e.target.name == "biomedical_risk_rating" &&
      e.target.value === "1"
    ) {
      this.setState({
        biomedical_risk_rating_information: this.biomedical_risk_rating1,
      });
    } else if (
      e.target.name == "biomedical_risk_rating" &&
      e.target.value === "2"
    ) {
      this.setState({
        biomedical_risk_rating_information: this.biomedical_risk_rating2,
      });
    } else if (
      e.target.name == "biomedical_risk_rating" &&
      e.target.value === "3"
    ) {
      this.setState({
        biomedical_risk_rating_information: this.biomedical_risk_rating3,
      });
    } else if (
      e.target.name == "biomedical_risk_rating" &&
      e.target.value === "4"
    ) {
      this.setState({
        biomedical_risk_rating_information: this.biomedical_risk_rating4,
      });
    }
    //   For emotional_risk_rating
    else if (
      e.target.name == "emotional_risk_rating" &&
      e.target.value === "0"
    ) {
      this.setState({
        emotional_risk_rating_information: this.emotional_risk_rating0,
      });
    } else if (
      e.target.name == "emotional_risk_rating" &&
      e.target.value === "1"
    ) {
      this.setState({
        emotional_risk_rating_information: this.emotional_risk_rating1,
      });
    } else if (
      e.target.name == "emotional_risk_rating" &&
      e.target.value === "2"
    ) {
      this.setState({
        emotional_risk_rating_information: this.emotional_risk_rating2,
      });
    } else if (
      e.target.name == "emotional_risk_rating" &&
      e.target.value === "3"
    ) {
      this.setState({
        emotional_risk_rating_information: this.emotional_risk_rating3,
      });
    } else if (
      e.target.name == "emotional_risk_rating" &&
      e.target.value === "4"
    ) {
      this.setState({
        emotional_risk_rating_information: this.emotional_risk_rating4,
      });
    }
    //   For readiness_risk_rating
    else if (
      e.target.name == "readiness_risk_rating" &&
      e.target.value === "0"
    ) {
      this.setState({
        readiness_risk_rating_information: this.readiness_risk_rating0,
      });
    } else if (
      e.target.name == "readiness_risk_rating" &&
      e.target.value === "1"
    ) {
      this.setState({
        readiness_risk_rating_information: this.readiness_risk_rating1,
      });
    } else if (
      e.target.name == "readiness_risk_rating" &&
      e.target.value === "2"
    ) {
      this.setState({
        readiness_risk_rating_information: this.readiness_risk_rating2,
      });
    } else if (
      e.target.name == "readiness_risk_rating" &&
      e.target.value === "3"
    ) {
      this.setState({
        readiness_risk_rating_information: this.readiness_risk_rating3,
      });
    } else if (
      e.target.name == "readiness_risk_rating" &&
      e.target.value === "4"
    ) {
      this.setState({
        readiness_risk_rating_information: this.readiness_risk_rating4,
      });
    }
    //   For relapse_risk_rating
    else if (e.target.name == "relapse_risk_rating" && e.target.value === "0") {
      this.setState({
        relapse_risk_rating_information: this.relapse_risk_rating0,
      });
    } else if (
      e.target.name == "relapse_risk_rating" &&
      e.target.value === "1"
    ) {
      this.setState({
        relapse_risk_rating_information: this.relapse_risk_rating1,
      });
    } else if (
      e.target.name == "relapse_risk_rating" &&
      e.target.value === "2"
    ) {
      this.setState({
        relapse_risk_rating_information: this.relapse_risk_rating2,
      });
    } else if (
      e.target.name == "relapse_risk_rating" &&
      e.target.value === "3"
    ) {
      this.setState({
        relapse_risk_rating_information: this.relapse_risk_rating3,
      });
    } else if (
      e.target.name == "relapse_risk_rating" &&
      e.target.value === "4"
    ) {
      this.setState({
        relapse_risk_rating_information: this.relapse_risk_rating4,
      });
    }
    //   For recovery_risk_rating
    else if (
      e.target.name == "recovery_risk_rating" &&
      e.target.value === "0"
    ) {
      this.setState({
        recovery_risk_rating_information: this.recovery_risk_rating0,
      });
    } else if (
      e.target.name == "recovery_risk_rating" &&
      e.target.value === "1"
    ) {
      this.setState({
        recovery_risk_rating_information: this.recovery_risk_rating1,
      });
    } else if (
      e.target.name == "recovery_risk_rating" &&
      e.target.value === "2"
    ) {
      this.setState({
        recovery_risk_rating_information: this.recovery_risk_rating2,
      });
    } else if (
      e.target.name == "recovery_risk_rating" &&
      e.target.value === "3"
    ) {
      this.setState({
        recovery_risk_rating_information: this.recovery_risk_rating3,
      });
    } else if (
      e.target.name == "recovery_risk_rating" &&
      e.target.value === "4"
    ) {
      this.setState({
        recovery_risk_rating_information: this.recovery_risk_rating4,
      });
    }
    // recovery_risk_rating,recovery_risk_rating_information
    // ,,recovery_risk_rating0

    setTimeout(() => {
      this.props.childToParent(this.state);
    }, 500);
  };

  handleOnSubmit = async (e, shouldToasterShow) => {
    e.preventDefault();
    let final_data = {
      //     RiskRatings: this.state,
      //   dataListBody: this.dataListBody,
    };
    let res = await axios.post(
      global.restApiURL + "customRest/updateRatings",
      JSON.stringify(this.state)
    );
    if (res.data.success == true && shouldToasterShow == undefined) {
      this.loadData();
      this.props.tabEditOrNot(false);
      this.setState({ saved : true });
      toast.success("Data added successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: 'success_riskRating',
      });
    }else if(res.data.success == true && shouldToasterShow == 'saveNexit'){
      // msg should be show on parent componnt;
    }
  };
  
  loadData = async (e) => {
    let res = await axios.post(
      global.restApiURL + "customrest/getRatings",
      JSON.stringify(this.state.dataListBody)
    );
    if (res.status == 200 && res.data.success == true) {
      this.setState({
        acute_risk_rating: res.data.data.acute_risk_rating,
        acute_risk_rating_information: res.data.data.acute_risk_rating_information,
        biomedical_risk_rating: res.data.data.biomedical_risk_rating,
        biomedical_risk_rating_information: res.data.data.biomedical_risk_rating_information,
        emotional_risk_rating: res.data.data.emotional_risk_rating,
        emotional_risk_rating_information: res.data.data.emotional_risk_rating_information,
        readiness_risk_rating: res.data.data.readiness_risk_rating,
        readiness_risk_rating_information:  res.data.data.readiness_risk_rating_information,
        relapse_risk_rating: res.data.data.relapse_risk_rating,
        relapse_risk_rating_information: res.data.data.relapse_risk_rating_information,
        recovery_risk_rating: res.data.data.recovery_risk_rating,
        recovery_risk_rating_information: res.data.data.recovery_risk_rating_information,
        isCheckedAcute: res.data.data.isCheckedAcute === "1",
        isCheckedBiomedical: res.data.data.isCheckedBiomedical === "1",
        isCheckedEmotional: res.data.data.isCheckedEmotional === "1",
        isCheckedReadiness: res.data.data.isCheckedReadiness === "1",
        isCheckedRelapse: res.data.data.isCheckedRelapse === "1",
        isCheckedRecovery: res.data.data.isCheckedRecovery === "1",
        // abcd:res.data.data,
        acute_risk_rating_summury:res.data.data.acute_risk_rating_summury,
        biomedical_comments:res.data.data.biomedical_comments,
        emotional_comments:res.data.data.emotional_comments,
        readiness_comments:res.data.data.readiness_comments,
        problem_comments2:res.data.data.problem_comments2,
        enviornment_comments:res.data.data.enviornment_comments,
        saved: true,
      });
      setTimeout(() => {
        this.props.childToParent(this.state);
      }, 500);
    }
  };

  componentDidMount() {
    this.loadData();
  }

  render() {
    return (
      <React.Fragment>
        {/* <ToastContainer style={{ zIndex: 900 }} /> */}
        <form onSubmit={this.handleOnSubmit}>
          <div className="row marginBottom1">
            <div className="col-md-12">
              <p
                className="lead float-left"
                style={{
                  fontSize: "1.05rem",
                  lineHeight: "20px",
                  marginBottom: "1rem",
                }}
              >
                <b className="text-green">Risk Ratings</b>
              </p>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label style={{ marginLeft: 2 , marginRight:20 }}>ACUTE INTOXICATION AND/OR WITHDRAWAL POTENTIAL</label>
                <input type="checkbox" style={{ marginLeft: 8 }} checked={this.state.isCheckedAcute} name="isCheckedAcute" onChange={this.handleCheckboxChange}  /> No Update
                <div className="row">
                  <div className="col-lg-2 col-md-3">
                    <select
                      placeholder="Risk Rating"
                      disabled={
                        this.state.isSaveAndComplete ||
                        this.state.isCheckedAcute
                      }
                      name="acute_risk_rating"
                      value={this.state.acute_risk_rating}
                      className="form-control"
                      onChange={this.handleOnChange}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                  </div>
                  <div className="col-lg-10 col-md-9">
                    <TextareaAutosize
                      disabled
                      className="form-control"
                      value={this.state.acute_risk_rating_information}
                      minRows={this.state.acute_risk_rating == 0 ? 1 : (this.state.acute_risk_rating == 1 || this.state.acute_risk_rating == 2 || this.state.acute_risk_rating == 3) ? 2 : this.state.acute_risk_rating == 4 ? 3 : 1}
                      style={{overflow:'Hidden'}}
                      name="acute_risk_rating_information"
                      onChange={this.handleOnChange}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-2 col-md-3">
                   
                  </div>
                  <div className="col-lg-10 col-md-9">
                    <TextareaAutosize
                      minRows={(this.state.acute_risk_rating_summury?.length/110) + this.state.acute_risk_rating_summury?.split(/\r\n|\r|\n/).length}
                      className="form-control"
                      style={{overflow:'hidden'}}
                      value={this.state.acute_risk_rating_summury}
                      name="acute_risk_rating_summury"
                      onChange={this.handleOnChange}
                      disabled={this.state.isSaveAndComplete || this.state.isCheckedAcute}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label style={{ marginLeft: 2 , marginRight:20}}>
                  BIOMEDICAL CONDITIONS AND COMPLICATIONS
                </label>
                <input type="checkbox" style={{ marginLeft: 8 }} checked={this.state.isCheckedBiomedical} name="isCheckedBiomedical" onChange={this.handleCheckboxChange}/>{" "} No Update
                <div className="row">
                  <div className="col-lg-2 col-md-3">
                    <select
                      className="form-control"
                      disabled={
                        this.state.isSaveAndComplete ||
                        this.state.isCheckedBiomedical
                      }
                      placeholder="Risk Rating"
                      value={this.state.biomedical_risk_rating}
                      name="biomedical_risk_rating"
                      onChange={this.handleOnChange}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                  </div>
                  <div className="col-lg-10 col-md-9">
                    <TextareaAutosize
                      className="form-control"
                      disabled
                      minRows={(this.state.biomedical_risk_rating == 0 || this.state.biomedical_risk_rating == 4 ) ? 1 : (this.state.biomedical_risk_rating == 1 || this.state.biomedical_risk_rating == 2  || this.state.biomedical_risk_rating == 3) ? 2 : 1}
                      value={this.state.biomedical_risk_rating_information}
                      name="biomedical_risk_rating_information"
                      onChange={this.handleOnChange}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-2 col-md-3">
                   
                  </div>
                  <div className="col-lg-10 col-md-9">
                    <TextareaAutosize
                      className="form-control"
                      minRows={(this.state.biomedical_comments?.length/110) + (this.state.biomedical_comments?.split(/\r\n|\r|\n/).length)}
                      style={{overflow:'Hidden'}}
                      value={this.state.biomedical_comments}
                      name="biomedical_comments"
                      onChange={this.handleOnChange}
                      disabled={this.state.isSaveAndComplete || this.state.isCheckedBiomedical}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label style={{ marginLeft: 2 , marginRight:20}}>EMOTIONAL, BEHAVIORAL, COGNITIVE CONDITIONS & COMPLICATIONS</label>
                <input type="checkbox" style={{ marginLeft: 8 }} checked={this.state.isCheckedEmotional} name="isCheckedEmotional" onChange={this.handleCheckboxChange} />{" "}No Update
                <div className="row">
                  <div className="col-lg-2 col-md-3">
                    <select
                      className="form-control"
                      style={{overflow:'hidden'}}
                      disabled={this.state.isSaveAndComplete ||this.state.isCheckedEmotional }
                      placeholder="Risk Rating"
                      name="emotional_risk_rating"
                      value={this.state.emotional_risk_rating}
                      onChange={this.handleOnChange}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                  </div>
                  <div className="col-lg-10 col-md-9">
                    <TextareaAutosize
                      className="form-control"
                      disabled
                      minRows={(this.state.emotional_risk_rating == 0 || this.state.emotional_risk_rating == 2 || this.state.emotional_risk_rating == 3 ) ? 2 : (this.state.emotional_risk_rating == 1 || this.state.emotional_risk_rating == 4) ? 3 : 1}
                      value={this.state.emotional_risk_rating_information}
                      name="emotional_risk_rating_information"
                      onChange={this.handleOnChange}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-2 col-md-3">
                   
                  </div>
                  <div className="col-lg-10 col-md-9">
                    <TextareaAutosize
                      className="form-control"
                      minRows={(this.state.emotional_comments?.length/110) + (this.state.emotional_comments?.split(/\r\n|\r|\n/).length)}
                      style={{overflow:'hidden'}}
                      value={this.state.emotional_comments}
                      name="emotional_comments"
                      onChange={this.handleOnChange}
                      disabled={
                        this.state.isSaveAndComplete ||
                        this.state.isCheckedEmotional
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label style={{ marginLeft: 2 , marginRight:20}}>READINESS TO CHANGE</label>
                <input  type="checkbox" style={{ marginLeft: 8 }}  checked={this.state.isCheckedReadiness} name="isCheckedReadiness" onChange={this.handleCheckboxChange} />{" "}No Update
                <div className="row">
                  <div className="col-lg-2 col-md-3">
                    <select
                      className="form-control"
                      disabled={
                        this.state.isSaveAndComplete ||
                        this.state.isCheckedReadiness
                      }
                      placeholder="Risk Rating"
                      value={this.state.readiness_risk_rating}
                      name="readiness_risk_rating"
                      onChange={this.handleOnChange}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                  </div>
                  <div className="col-lg-10 col-md-9">
                    <TextareaAutosize
                      className="form-control"
                      disabled
                      minRows={(this.state.readiness_risk_rating == 0 || this.state.readiness_risk_rating == "") ? 1 : 2}
                      value={this.state.readiness_risk_rating_information}
                      name="readiness_risk_rating_information"
                      onChange={this.handleOnChange}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-2 col-md-3">
                   
                  </div>
                  <div className="col-lg-10 col-md-9">
                    <TextareaAutosize
                      className="form-control"
                      minRows={(this.state.readiness_comments?.length/100) + (this.state.readiness_comments?.split(/\r\n|\r|\n/).length)}
                      style={{overflow:'hidden'}}
                      value={this.state.readiness_comments}
                      name="readiness_comments"
                      onChange={this.handleOnChange}
                      disabled={this.state.isSaveAndComplete || this.state.isCheckedReadiness
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label style={{ marginLeft: 2 , marginRight:20}}> RELAPSE, CONTINUED USE OR CONTINUED PROBLEMS </label>
                <input  type="checkbox"  style={{ marginLeft: 8 }}  checked={this.state.isCheckedRelapse} name="isCheckedRelapse" onChange={this.handleCheckboxChange} />{" "}No Update
                <div className="row">
                  <div className="col-lg-2 col-md-3">
                    <select
                      className="form-control"
                      disabled={
                        this.state.isSaveAndComplete ||
                        this.state.isCheckedRelapse
                      }
                      placeholder="Risk Rating"
                      name="relapse_risk_rating"
                      value={this.state.relapse_risk_rating}
                      onChange={this.handleOnChange}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                  </div>
                  <div className="col-lg-10 col-md-9">
                    <TextareaAutosize
                      className="form-control"
                      disabled
                      minRows={(this.state.relapse_risk_rating == 0 || this.state.relapse_risk_rating == "") ? 1 : 2}
                      value={this.state.relapse_risk_rating_information}
                      name="relapse_risk_rating_information"
                      onChange={this.handleOnChange}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-2 col-md-3">
                   
                  </div>
                  <div className="col-lg-10 col-md-9">
                    <TextareaAutosize
                      className="form-control"
                      minRows={(this.state.problem_comments2?.length/100) + (this.state.problem_comments2?.split(/\r\n|\r|\n/).length)}
                      style={{overflow:'hidden'}}
                      value={this.state.problem_comments2}
                      name="problem_comments2"
                      onChange={this.handleOnChange}
                      disabled={
                        this.state.isSaveAndComplete ||
                        this.state.isCheckedRelapse
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label style={{ marginLeft: 2 , marginRight:20}}>
                  RECOVERY LIVING ENVIRONMENT
                </label>
                <input type="checkbox" style={{ marginLeft: 8 }} checked={this.state.isCheckedRecovery} name="isCheckedRecovery"  onChange={this.handleCheckboxChange} />{" "} No Update
                <div className="row">
                  <div className="col-lg-2 col-md-3">
                    <select
                      className="form-control"
                      disabled={
                        this.state.isSaveAndComplete ||
                        this.state.isCheckedRecovery
                      }
                      placeholder="Risk Rating"
                      name="recovery_risk_rating"
                      value={this.state.recovery_risk_rating}
                      onChange={this.handleOnChange}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                  </div>
                  <div className="col-lg-10 col-md-9">
                    <TextareaAutosize
                      className="form-control"
                      style={{overflow:'hidden'}}
                      disabled
                      minRows={(this.state.recovery_risk_rating == 0 || this.state.recovery_risk_rating == "") ? 1 : (this.state.recovery_risk_rating == 1 || this.state.recovery_risk_rating == 3 || this.state.recovery_risk_rating == 4) ? 2 : 1}
                      value={this.state.recovery_risk_rating_information}
                      name="recovery_risk_rating_information"
                      onChange={this.handleOnChange}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-lg-2 col-md-3">
                   
                  </div>
                  <div className="col-lg-10 col-md-9">
                    <TextareaAutosize
                      className="form-control"
                      minRows={(this.state.enviornment_comments?.length/100) + (this.state.enviornment_comments?.split(/\r\n|\r|\n/).length)}
                      style={{overflow:'hidden'}}
                      value={this.state.enviornment_comments}
                      name="enviornment_comments"
                      onChange={this.handleOnChange}
                      disabled={this.state.isSaveAndComplete || this.state.isCheckedRecovery}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <button
                type="submit"
                data-save="save"
                value="save"
                disabled={this.state.saved}
                className="btn btn-success float-right btnmargin"
              >
                {" "}
                <i className="fas fa-save"></i> Save
              </button>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}
