
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { Component } from "react";
import Modal from "../Modals/Modal";
import TextareaAutosize from 'react-textarea-autosize';

const axios = require("axios").default;

export default class Psychological_enviornmental_problems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [1],
      psychological_problems: [
        {
          category:"",
          disorder_name: "",
          icd_10_code:"",
          comments: "",
          disorderOptions:[],
        },
      ],
      categoryOptions:[],
      // disorderOptions:[],
      dataListBody: {
        apikey: localStorage.getItem('__TOKEN__'),
        client_id: sessionStorage.getItem("clientId"),
        form_type: props.form_type,
        uploaded_by: sessionStorage.getItem("username"),

      },
      deleteSubstance: false,
      change: false,
      isSaved: true,
      isSaveAndComplete: false,
      // form_type:props.form_type,
    };
  }

  addRows = (e) => {
    e.preventDefault();
    const data = {
      category:"",
      disorder_name: "",
      icd_10_code:"",
      comments: "",
      disorderOptions:[],
    };
    this.setState({
      psychological_problems: [...this.state.psychological_problems, data],
    });

    this.setState({ isSaved: false })
  };
  deleteRow = (e) => {
    // this.setState({isSaved:false})
    let rows = this.state.psychological_problems;
    rows.splice([parseInt(e.target.id)], 1);
    this.setState({ psychological_problems: rows, deleteSubstance: false, isSaved: false });
  };

  // Test  file zilla uploading system;
  // Test  file zilla uploading system;
  // Test  file zilla uploading system;
  // Test  file zilla uploading system;
  
  handleChange = async(e) => {
    this.props.tabtabEdittedOrNot(true);
    this.props.saveAndExitSummary("", "", "", "", "", "", "", "", "", "", this.handleSubmit, "", "");
    let id = e.target.id
    this.setState({ isSaved: false });
    let psychological_problems = this.state.psychological_problems;
    psychological_problems[id][e.target.name] = e.target.value;
    this.setState({ psychological_problems: psychological_problems });
    if(e.target.name=="category")
    {
      let tempDisorder=await axios.post( global.restApiURL +"customrest/getCategoryDisorder",JSON.stringify({dataListBody: this.state.dataListBody,disorder_name:e.target.value}));
      if(tempDisorder.data.success)
      {
        // debugger
        this.setState({disorderOptions:tempDisorder.data.data.disorder_name})
        let psychological_problems = this.state.psychological_problems;
        psychological_problems[id]["disorderOptions"] = tempDisorder.data.data.disorder_name;
        psychological_problems[id]["icd_10_code"] = "";
        this.setState({ psychological_problems: psychological_problems });
      }
    }else if(e.target.name=="disorder_name")
    {
      let psychological_problems = this.state.psychological_problems;
      psychological_problems[id]["disorder_name"] = e.target.value;
      this.setState({ psychological_problems: psychological_problems });
     
      let tempICD_10Code_res=await axios.post( global.restApiURL +"customrest/getCategoryDisorderIcd",JSON.stringify({dataListBody: this.state.dataListBody,disorder_icd:e.target.value}));
      if(tempICD_10Code_res.data.success)
      {
        let psychological_problems = this.state.psychological_problems;
        psychological_problems[id]["icd_10_code"] = tempICD_10Code_res.data.data.icd_10_code;
        this.setState({ psychological_problems: psychological_problems });
      }
    }
    setTimeout(() => {
      this.props.childToParent(this.state.psychological_problems)
    }, 200)
  };
  deletePopUp = (e) => {
    this.setState({ deleteSubstance: true });
  };
  // +++++++++++++++++++++--HANDLE--SUBMIT--START--+++++++++++++++++++++++++++

  handleSubmit = async (e, shouldToasterShow) => {
    e.preventDefault();
    this.props.tabtabEdittedOrNot(false);
    let final_data = {
      psychological_problems: this.state.psychological_problems,
      dataListBody: this.state.dataListBody,
      // form_type:this.state.form_type,
    };
    let res = await axios.post(
      global.restApiURL + "customRest/savePsychologicalEnvironmentalProblems",
      JSON.stringify(final_data)
    );
    if (res.data.success == true && shouldToasterShow == undefined) {
      this.loadData();
      toast.success('Data added successfully!', {toastId:('Data added successfully!')}, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }else if(res.data.success == true && shouldToasterShow == 'saveNexit'){
      // msg should be show on parent componnt;
    }
  };

  // +++++++++++++++++++++--HANDLE--SUBMIT--END--+++++++++++++++++++++++++++
  // ==========================*******************============================
  // +++++++++++++++++++++--LOAD--DATA--START--+++++++++++++++++++++++++++
  
  loadData = async (e) => {
    this.setState({ isSaved: true });
    if (!this.props.isSaveAndComplete) {
      let res = await axios.post(
        global.restApiURL + "customrest/getPsychologicalEnvironmentalProblems",
        JSON.stringify(this.state.dataListBody)
      );
      if (res.status == 200 && res.data.success == true) {
        this.setState({
          psychological_problems: res.data.data.psychological_problems.map((val) => {
            return {
              category:val.category,
              disorder_name: val.disorder_name,
              icd_10_code:val.icd_10_code,
              comments: val.comments,
              disorderOptions:val.disorderOptions,
            };
          }),
        });

        setTimeout(() => {
          this.props.childToParent(this.state.psychological_problems)
        }, 200)
      }
    } else {
      let res = await axios.post(
        global.restApiURL + "customrest/getPsychologicalEnvironmentalProblemsTemp",
        JSON.stringify(this.state.dataListBody)
      );


      if (res.status == 200 && res.data.success == true) {
        this.setState({
          psychological_problems: res.data.data.psychological_problems.map((val) => {
            return {
              // disorder_name: val.disorder_name,
              // comments: val.comments,
              // disorderOptions:[],

              category:val.category,
              disorder_name: val.disorder_name,
              icd_10_code:val.icd_10_code,
              comments: val.comments,
              disorderOptions:val.disorderOptions,
            };
          }),
        });
        this.setState({ isSaveAndComplete: true, })
      }
    }

    let getCategories=await axios.post( global.restApiURL +"customrest/getCategories",JSON.stringify({dataListBody:this.state.dataListBody})); 
    if(getCategories.data.success && getCategories.status==200)
    {
      let data=getCategories.data.data;
      this.setState({categoryOptions:getCategories.data.data.categories})
    }
  };

  // +++++++++++++++++++++--LOAD--DATA--END--+++++++++++++++++++++++++++
  // ==============================****************======================
  // +++++++++++++++++++++--COMPONENT--DID--MOUNT--START--+++++++++++++++++++++++++++
  componentDidMount() {
    this.loadData();
  }

  // +++++++++++++++++++++--COMPONENT--DID--MOUNT--END--+++++++++++++++++++++++++++
  render() {
    const tableRows = this.state.psychological_problems.map((row, index) => (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              {index != 0 && (

                !this.state.isSaveAndComplete?<>
                <i
                  className="fa fa-trash float-right"
                  id={index}
                  onClick={
                    this.state.change ? this.deletePopUp : this.deleteRow
                  }
                  // isDisable={true}
                ></i>
                </>:<>
                <i
                  className="fa fa-trash float-right"
                  id={index}
                  // onClick={
                  //   this.state.change ? this.deletePopUp : this.deleteRow
                  // }
                  // isDisable={true}
                ></i>
                </>
                
              )}
            </div>

            <div className="col-md-12">
                <div className="form-group">
                  <label>Category</label>
                  <select  className="form-control" name="category"  id={index}  onChange={this.handleChange}
                    value={row.category} disabled={this.state.isSaveAndComplete}
                  >
                    <option value="">Select</option>
                    {this.state.categoryOptions.map((val)=>{
                      return (<option value={val.value}>{val.label}</option>)
                    })}
                  </select>
                </div>
              </div>

            <div className="col-md-6">
              <div className="form-group">
                <label>Disorder</label>
                <select
                  className="form-control"
                  name="disorder_name"
                  id={index}
                  onChange={this.handleChange}
                  value={row.disorder_name}
                  disabled={this.state.isSaveAndComplete}
                >
                  {row.disorderOptions.map((val)=>{
                      return (<option value={val.value}>{val.label}</option>)
                    })}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label>ICD-10 Code</label>
                <input
                  type="text"
                  className="form-control"
                  name="icd_10_code"
                  id={index}
                  onChange={this.handleChange}
                  value={row.icd_10_code}
                  disabled={this.state.isSaveAndComplete}
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label>Comments</label>
                <TextareaAutosize
                  type="text"
                  style={{overflow:'hidden'}}
                  minRows={(row.comments.length/69) + row.comments.split(/\r\n|\r|\n/).length}
                  className="form-control"
                  name="comments"
                  id={index}
                  onChange={this.handleChange}
                  value={row.comments}
                  disabled={this.state.isSaveAndComplete}
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.deleteSubstance && (
          <Modal
            showCloseButton={false}
            className="modal-sm"
            showOverlay={true}
          >
            <Modal.Header>
              <Modal.Title>
                <h4 className="modal-title float-left text-green">
                  Delete Substance
                </h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to delete this?</p>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-success float-left"
                id={index}
                onClick={this.deleteRow}
              >
                Yes
              </button>
              <button
                className="btn btn-default"
                onClick={() => this.setState({ deleteSubstance: false })}
              >
                No
              </button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    ));
    return (
      <div className="hold-transition sidebar-mini text-sm">
        <div className="wrapper">
          <div className="">
            <section className="content-body">
              <div className="row">
                <div className="col-md-12">
                  <form>
                    <div className="row marginBottom1 heightScroll">
                      <div className="col-md-12">
                        <button
                          className="btn bt-sm btn-secondary mt-2 mr-3 float-right"
                          onClick={this.addRows}
                          disabled={this.state.isSaveAndComplete}
                        >
                          <i className="fa fa-plus mr-1"></i>Add
                        </button>
                      </div>
                      <div className="col-md-12">
                        <div className="card-body">{tableRows}</div>
                      </div>
                      <div className="col-md-12">
                        {/* <button className='btn btn-success float-right mr-2'><i class="fas fa-save"></i> Save</button> */}
                        <button
                          id="saveButton"
                          type="submit"
                          onClick={this.handleSubmit}
                          className="btn btn-success float-right mr-3"
                          htmlType="submit"
                          disabled={this.state.isSaveAndComplete ? true : this.state.isSaved}
                        // form="assessmentsForm"
                        >
                          {" "}
                          <i className="fas fa-save"></i> Save
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
