import React from 'react' ;
import Tooltip from '../ReusableComponents/Tooltip' ;

export const InputTextField = ({labelText, name, id, type, className, defaultValue, required , tooltipText , tooltipVisibility, readOnly, placeholder, onChange, maxLength,onInput}) => (
   
    <div>
        {labelText  ? (<label>{labelText}</label>): ''}{labelText && required ? (<span className="required">*</span>) : ''}  {tooltipText !== "" ? <Tooltip name={name} text={tooltipText} visibiity={tooltipVisibility}/> : ""}
        <input
            type = {type}
            name = {name}
            id = {id}
            className = {className}
            defaultValue = {defaultValue}
            required = {required}
            readOnly = {readOnly}
            placeholder = {!labelText && required ? placeholder + " *" : placeholder}
            onChange = {onChange}
            maxlength = {maxLength}
            onInput={onInput}
        />
        
    </div>

);


export const LabelField = (prop) => {
    //console.log('prop',prop);
    return (
    
    <>
    <label className = {prop.className} id = {prop.id}  style = {prop.style}>{prop.title}</label> 
    {prop.mandatory === true ? (<span className="required">*</span>) : ''}  
    {prop.tooltip !== ''  ? (<Tooltip name={""+prop.name} text={""+prop.tooltip} visibiity={""}/>) : ""}
    </>
)};

export default InputTextField;
