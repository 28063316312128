import React, { Component } from 'react'
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import services from '../../services';
import Modal from '../Modals/Modal';
import { Alert } from '../toastify';
const axios = require('axios').default;

var allchecked = false;
export default class ClientListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editModal: false,
            checkbox: [],
            allChecked: false,
            columns: [
                {
                    name: 'Client Name',
                    selector: 'Full_name',
                    style: {
                    },
                },
                {
                    name: 'Client ID',
                    selector: 'Client_id',
                    style: {
                    },
                },
                {
                    name: 'Date of Birth',
                    selector: 'Dob',
                    style: {
                    },
                },
                {
                    name: 'Intake Date',
                    selector: 'Intake_date',
                    style: {
                    },
                },
                {
                    name: 'Phone',
                    selector: 'Primary_phone',
                    style: {
                    },
                },
                {
                    name: 'Email',
                    selector: 'Primary_email',
                },
                {
                    name: 'Last Updated By',
                    selector: 'Last_updated_by',
                },
                {
                    name: 'Peer Support Specialist',
                    selector: 'PeerSupportSpecialist',
                    style: {
                    },
                },
                {
                    name: 'Action',
                    cell: (row) => (
                        <div>
                            {/* <Link to="#"><i className="fas fa-edit" id={JSON.stringify(row)} aria-hidden="true" data-toggle="tooltip" title="Edit" onClick={(e, row) => this.handleButtonEdit(e, row, 'edit')}></i></Link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                            {row.published == false ?
                                <Link to="#"><i className="fas fa-user-times text-gray" id={row.Client_id} aria-hidden="true" data-toggle="tooltip" title="Publish" onClick={(e) => this.publishClient(e)}></i></Link> :
                                <Link to="#"><i className="fas fa-user-times" id={row.Client_id} aria-hidden="true" data-toggle="tooltip" title="Unpublish" onClick={(e) => this.unPublishClient(e)}></i></Link>}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                    ),
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                }
            ],
            user_id: '',
            first_name: '',
            last_name: '',
            email: '',
            user_name: '',
            role: '',
            reAssignUser: '',
            clearRows: false,
            clientLimit : 10
        }
        // this.handleButtonEdit = this.handleButtonEdit.bind(this);
        // this.closeModal = this.closeModal.bind(this);
    }

    onCheckboxCheck = (e) => {
        let checkbox = [];
        e.selectedRows.map(row => {
            checkbox.push(row.Client_id);
        })
        this.setState({ 'checkbox': checkbox });
    }

    publishClient = async (e) => {
        let requestData = {
            'apikey': localStorage.getItem('__TOKEN__'),
            "is_published": true,
            "client_id": e.target.id
        }
        let publishClientApi = await this.props.parseResponse(services.publishClients(requestData));
        if (this.props.selected_user) {
            this.props.getClientList(this.props.selected_user.firstname + " " + this.props.selected_user.lastname);
        } else {
            this.props.getClientList();
        }
        Alert('success', 'Client activated successfully ');
        this.paginationDataTable(1);
    }

    unPublishClient = async (e) => {
        let requestData = {
            'apikey': localStorage.getItem('__TOKEN__'),
            "is_published": false,
            "client_id": e.target.id
        }
        let unpublishClientApi = await this.props.parseResponse(services.publishClients(requestData));
        if (this.props.selected_user) {
            this.props.getClientList(this.props.selected_user.firstname + " " + this.props.selected_user.lastname);
        } else {
            this.props.getClientList();
        }
        Alert('success', 'Client deactivated successfully ');
        this.paginationDataTable(1);
    }

    reAssignClients = async () => {
        if(this.state.reAssignUser == "" || this.state.reAssignUser == undefined || this.state.reAssignUser == "Select"){
            Alert('warning', 'Please select a User for Re-assign To');
        }else if(!this.state.checkbox.length > 0 ){
            Alert('warning', 'Please select a client');
        }else if (this.state.checkbox.length > 0 && this.state.reAssignUser != "" && this.state.reAssignUser != undefined) {
            let requestData = {
                'apikey': localStorage.getItem('__TOKEN__'),
                'client_id': this.state.checkbox,
                'username': this.state.reAssignUser
            }
            let reAssignClient = axios.post(global.restApiURL + 'customRest/reAssignClient', JSON.stringify(requestData))//await this.props.parseResponse(services.reAssignClients(requestData));
            .then(
                res => {
                    this.setState({ clearRows: !this.state.clearRows, clientListModal : false, checkbox: [] });
                    this.props.changePagination(1);
                    Alert("success", "Re Assigned Successfully");
                    if (this.props.selected_user && this.props.selectedUserName) {
                        this.props.deActivateUser(this.props.selected_user.id)
                        this.props.getClientList(this.props.selectedUserName);
                    } else {
                        this.props.getClientList();
                        this.props.changePagination(1);
                        this.paginationDataTable(1);
                    }
                }
            )
            .catch(
                err => {
                    Alert('warning', 'Something went wrong');
                    console.log(err);
                }
            )
        }
        
        // else {
        //     Alert('warning', 'Please select a client');
        // }
    }

    selectReAssignUser = (e) => {
        this.setState({ reAssignUser: e.target.value });
    }

    handleClientOnPage = (e) => {
        this.setState({clientLimit : e.target.value});
        this.props.changePagination(1);
        if(this.props.searchValue()){
            this.props.searchclients(1);
        }else{
            this.props.getClientList(e.target.value);
        }
    }

    resetLimit = () => {
        this.setState({clientLimit : 10});
    }

    paginationDataTable = async (pageNum) => {
        this.props.changePagination(pageNum);
        var jsonData = {
            'apikey': localStorage.getItem('__TOKEN__'),
            "page": pageNum,
            "unpublished" : 1,
            limit : this.state.clientLimit,
            "filters": {
                "organization": sessionStorage.getItem('selected_organization')
            }
        };
        if (this.props.searchValue()) {
            this.props.searchclients(pageNum);
        }else{  
            let userData = await axios.post(global.restApiURL + 'customRest/getClientsListing', JSON.stringify(jsonData));
            this.props.stateSattlement( JSON.parse( this.CryptoJSAesDecrypt("tactionData", userData.data.data) ) , userData.data.pageInfo);
            this.props.dataSorting('Full_name', JSON.parse( this.CryptoJSAesDecrypt("tactionData", userData.data.data)));
            this.props.dataSorting('Primary_email', JSON.parse( this.CryptoJSAesDecrypt("tactionData", userData.data.data)));
        }
    }

    CryptoJSAesDecrypt(passphrase,encrypted_json_string){
        const CryptoJS = require("crypto-js");
        var obj_json = JSON.parse(encrypted_json_string);
        var encrypted = obj_json.ciphertext;
        var salt = CryptoJS.enc.Hex.parse(obj_json.salt);
        var iv = CryptoJS.enc.Hex.parse(obj_json.iv);   
        var key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64/8, iterations: 999});  
        var decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv});
        return decrypted.toString(CryptoJS.enc.Utf8);
    }

    generatePagination = () => {
        const paginationItems = [];
        paginationItems.push(
            <div className="card-body">
                <div className="sc-fzXfMB tnaXA">
                    <nav className="sc-fzXfNc reOZF rdt_Pagination row">
                        <span class="sc-fzXfNf sc-fzXfNg fsxVqc mt-1">{this.props.wholeState.pageInfo.pageString} of {this.props.wholeState.pageInfo.records}</span>
                        {/* <label>{this.props.wholeState.pages  + this.props.wholeState.pageString + this.props.wholeState.records + this.props.wholeState.userList}</label> */}
                        <div className="sc-fzXfNe cAfIxA ml-5">
                            <button onClick={() => { this.paginationDataTable(1) }} id="pagination-first-page" aria-label="First Page" aria-disabled="true" disabled="" className="sc-fzXfNd fZHyDG" style={{ backgroundColor: 'white', fill: '#007bff' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                    <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                                    <path fill="none" d="M24 24H0V0h24v24z"></path>
                                </svg>
                            </button>
                            <button onClick={() => { if (this.props.wholeState.listNext <= 1) { } else { this.paginationDataTable((this.props.wholeState.listNext - 1)) } }} id="pagination-previous-page" aria-label="Previous Page" aria-disabled="true" disabled="" className="sc-fzXfNd fZHyDG" style={{ backgroundColor: 'white', fill: '#007bff' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                </svg>
                            </button>
                            <button onClick={() => { if (this.props.wholeState.listNext >= this.props.wholeState.pageInfo.pages) { } else { this.paginationDataTable((this.props.wholeState.listNext + 1)) } }} id="pagination-next-page" aria-label="Next Page" aria-disabled="false" className="sc-fzXfNd fZHyDG" fdprocessedid="qnf8l" style={{ backgroundColor: 'white', fill: '#007bff' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                </svg>
                            </button>
                            <button onClick={() => { this.paginationDataTable(this.props.wholeState.pageInfo.pages) }} id="pagination-last-page" aria-label="Last Page" aria-disabled="false" className="sc-fzXfNd fZHyDG" fdprocessedid="bxu9i" style={{ backgroundColor: 'white', fill: '#007bff' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                    <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                                </svg>
                            </button>
                        </div>
                    </nav>
                </div>
            </div>
        );
        return paginationItems;
    };

    render() {
        console.log(typeof (this.props.userList));
        return (
            <div>
                <ReactTooltip place="right" type="dark" effect="solid" />
                <section className="content">
                    <div className={this.props?.selectedUser == undefined ? "card" : ""}>
                        {this.props?.selectedUser == undefined &&
                            <div className="card-header">
                                <p className="lead float-left"><b className="text-green">Client List</b></p>
                            </div>}
                        <div className={this.props?.selectedUser == undefined ? "card-body" : ""}>
                            <DataTable
                                columns={this.state.columns}
                                data={this.props.clientList}
                                customStyles={global.customTableStyles}
                                striped={true}
                                // pagination
                                paginationComponentOptions={global.paginationComponentOptions}
                                highlightOnHover
                                pointerOnHover
                                selectableRows
                                onSelectedRowsChange={this.onCheckboxCheck}
                                clearSelectedRows={this.state.clearRows}
                                noDataComponent={<div className="no_record_found">{global.no_record_found_text}</div>}
                            />
                            <br />
                            <div className='row'>
                                <div className='col-6'>
                                    <ul className="pagination paginationClient pagination-lg">
                                        {this.generatePagination()}
                                    </ul>
                                </div>
                                <div className='col-6'>
                                    <ul className="float-right mt-4">
                                        <span className="sc-fzXfNf sc-fzXfNg fsxVqc mt-1">Clients per Page :- </span>
                                        <select style={{ color: 'rgb(0, 123, 255)' }} onChange={(e) => this.handleClientOnPage(e)} name="clientOnPage" id='clientOnPage' value={this.state.clientOnPage}>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            <option value={30}>30</option>
                                            <option value={40}>40</option>
                                            <option value={50}>50</option>
                                            <option value={60}>60</option>
                                            <option value={70}>70</option>
                                            <option value={80}>80</option>
                                            <option value={90}>90</option>
                                            <option value={100}>100</option>
                                        </select>
                                    </ul>
                                </div>
                            </div>
                            {typeof (this.props.userList.length) != "object" && this.props.clientList.length > 0 && <>
                                <div className='form-group'>
                                    <div className='row'>
                                        <div className='col-md-2'>
                                            <label>Re-assign To:</label>
                                            <select className='form-control' onChange={this.selectReAssignUser}>
                                                <option>Select</option>
                                                {this.props?.selectedUser != undefined ?
                                                    this.props?.userList?.filter(user => user.name != this.props.selectedUser).map((Filtereduser) => (
                                                        <option value={Filtereduser.firstname + ' ' + Filtereduser.lastname}>{Filtereduser.full_name}</option>
                                                    )) :
                                                    this.props.userList.map((user) => (
                                                        <option value={user.firstname + ' ' + user.lastname}>{user.full_name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                        <div className='col-md-7'></div>
                                        <div className='col-md-3'>
                                            <button className='btn btn-success float-right' style={{ marginLeft: '6%', marginTop: '9%' }} onClick={this.reAssignClients}>Re-assign</button>
                                        </div>
                                    </div>
                                </div>
                            </>}
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
