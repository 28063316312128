import React , {Component} from 'react';
import Loader from './Loader';

const axios = require('axios').default;
var context;

class Otp extends Component{
    constructor(props){
        super(props);
        context = this;
        this.state={
            isInvalid: false,
            otpSent: true,
            isApiError: false,
            userID: null,
            email: null,
            OTPType: null,
            redirect: false,
            otpValue: '',
            showLoader: false,
            errors: {
                otpValue: '',
            }
        }
        this.onKeyDown = this.onKeyDown.bind(this);
    }
    handleChange = (e)=>{
        const name = e.target.name;
        this.setState({[name]: e.target.value});
    }

    onKeyDown(event) {
        console.log('enter')
        if (event.charCode === 13) {
          this.handleSubmit();
        }
    }

    handleSubmit = async ()=>{
        this.setState({otpSent: false});
        axios.post(global.restApiURL + 'customRest/validateOTP', JSON.stringify({
                'apikey': localStorage.getItem('__TOKEN__'),
                'tfa_otp': this.state.otpValue
              })).then(async data=>{
                console.log(data);
                if(data.data.success == true){
                  console.log('OTP Verified Succesfully');
                  // const location = {
                  //   pathname: '/Otp',
                  //   state: {__TOKEN__: response_data.data.apiKey}
                  // }
                  // history.push(location);
                  await(sessionStorage.setItem('verified', true));
                  window.location.href="/myclients";
                }else{
                    this.setState({isInvalid: true});
                }
              }).catch(err => {
                context.setState({isInvalid: true});
                  console.error(err); 
              });
    }
    handelResendOtp = () =>{
        this.setState({otpSent: true,isInvalid : false, showLoader: true});
        axios.post(global.restApiURL + 'customRest/sendOTP', JSON.stringify({
            'apikey': localStorage.getItem('__TOKEN__'),
            'username': sessionStorage.getItem('username'),
          })).then(data=>{
              context.setState({showLoader: false});
            console.log(data);
            if(data.data.success == true){
                alert(data.data.msg);
              console.log('OTP Sent Succesfully');
            }else{
                alert(data.data.msg);
            }
          }).catch(err => {
            context.setState({showLoader: false});
            console.error(err); 
            this.props.history.push('/');
          });
    }
    render(){
        return(
            <div>
                <div className="hold-transition login-page">
                    <div className="login-box" >
                        <div className="login-logo">
                            <a href="#">
                            <img src={window.location.origin + '/images/dcaris-logo-green.png'} alt="DCARIS" />
                            </a><br/> <br/>
                        </div>
                        <div className="card">
                            <div className="card-body login-card-body">
                            <strong><p className="login-box-msg" style={{color:'#89bc3b',fontWeight: 'bolder'}}>Verify OTP</p></strong>
                            {this.state.isInvalid  && <div id ="message" className="invalid-feedback" style={{display:'block','font-size': '18px'}}>Invalid OTP</div>}
                                {this.state.otpSent &&<div className="valid">OTP sent to your registered email</div> }
                                <form action="javascript:void(0);" method="post" id="loginform" onSubmit={this.handleSubmit}>
                                <div className="input-group mb-3">
                                    <input type="text" name="otpValue" className="form-control" id="username" placeholder="OTP" value={this.state.otpValue} onChange={this.handleChange}  />
                                    <div className="input-group-append">
                                    <div className="input-group-text">
                                    </div>
                                    </div>
                                </div>
                                <div className="row">
                                <div className="col-4">
                                        <input type="button" className="btn btn-primary btn-block" onClick={this.handelResendOtp} value="Resend OTP" />
                                    </div>
                                    <div className="col-4"></div>
                                   
                                    <div className="col-4">
                                        <input type="submit"  className="btn btn-primary btn-block" style={{color: '#fff',backgroundColor: '#89bc3b',borderColor: '#89bc3b'}} value="Verify" />
                                    </div>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showLoader && <Loader />}
            </div>

            // <div className="col-lg-12 col-lg-offset-3">
            //     <div className="otpbox">
            //         <div className="col-lg-6 col-md-6 col-sm-6 loginWelcm">
            //             <img src={window.location.origin + '/images/dcaris-logo-green.png'} alt="DCARIS" />
            //             <div className="loginTitle">
            //                 <h5>OTP Verification</h5>
            //             </div>
            //             <form  autoComplete="off">
            //                 {this.state.isInvalid && <div className="invalid">Invalid OTP!</div>}
            //                 {this.state.otpSent &&<div className="valid">OTP Sent to your Registered Email</div> }
            //                 {this.state.isApiError && <div className="invalid">Something went wrong! Please try again!</div>}                          
            //                 <div className="form-group">
            //                     <input type="number" className="form-control" value={this.state.otpValue} name="otpValue" maxLength="4" placeholder="Enter OTP" onChange={this.handleChange} required />
                                
            //                 </div>
            //                 <button type="button" onClick={this.handleSubmit} className="btn btn-primary" name="recover-submit">Verify</button>
            //                 <button className="btn btn-primary" style={{float: 'right'}} onClick={this.handelResendOtp}> Send OTP Again</button>
            //             </form>
            //         </div>
            //     </div>
            // </div>
        );
    }
}

export default Otp;