import moment from "moment";
import React, { Component } from 'react'
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios"
import InputMask from 'react-input-mask';
import LocationSearchInput from '../Components/AddressAutocomplete';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { TextareaAutosize } from "@material-ui/core";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TextAreaAutosize from 'react-textarea-autosize';

export default class ClientInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dobError: "",
      dobErrorAge: "",
      ssnValidation:"",
      errorMessage: "",
      toggleSSN: false,
      client_name: '',
      legal_guardian: '',
      dob: '',
      alias_1: '',
      primary_phone: '',
      address_lns: '',
      gender: '',
      ssn: '',
      relationship: '',
      medicaid: '',
      race: [],
      assesssed_level_of_care: [],
      medical_provider_review: [],
      priority_population: '',
      client_seeking_treatment: '',
      // add  code by Raj start
      other_referral_source: "",
      other_income_place: "",
      other_insurance_type: '',
      // add  code by Raj end
      primary_referral: '',
      princepal_source_of_income: '',
      insurance_type: '',
      payment_source: '',
      dependent_children: '',
      pregnant: '',
      military_status: '',
      current_prescribed_medications: '',
      participating_in_mat: '',
      client_seeking_explain: '',
      dependent_children_number: '',
      number_of_Pregnancies: "",
      prenatal_care: "",
      weeks: "",
      due_date: "",
      saved: true,
      visitType: '',
      validation: {
        number_of_Pregnancies_error: "",
        Pregnancies_week_error: "",
      },
      isSaveAndComplete: false,
    }
  }
  dataListBody = {
    apikey: localStorage.getItem('__TOKEN__'),
    client_id: sessionStorage.getItem("clientId"),
    form_type: this.props.form_type,
    uploaded_by: sessionStorage.getItem("username"),
  }
  sourceOfPayment_Option = [
    { value: "blue_cross", label: "Blue Cross/Blue Shield" },
    { value: "dmh", label: "DMH" },
    { value: "health_ins", label: "Health Ins (Not BCBS)" },
    { value: "medicaid", label: "Medicaid" },
    { value: "medicare", label: "Medicare" },
    { value: "no_charge", label: "No Charge(free...)" },
    { value: "personal_resourses", label: "Personal Resourses(Self/Family)" },
    { value: "service_contract", label: "Service Contract(EAP,HMO etc)" },
    { value: "workers_compensation", label: "Workers Compensation" },
    { value: "other_gov_payments", label: "Other Gov. Payments" },
  ];

  medicalProviderOptions = [
    { value: "agree_with_diagnostic", label: "Agree with diagnostic" },
    { value: "agree_with_level_of_care", label: "Agree with the level of care" },
    { value: "agree_with_recommended_admission", label: "Agree with recommended admission" },
    { value: "agree_with_the_preliminary", label: "Agree with the preliminary" },
    { value: "treatment_authorization", label: "Treatment authorization" },
    { value: "number_of_days_hours_approved", label: "Number of days/hours approved" },
    { value: "recommended_additional_services", label: "Recommended additional services" },
    { value: "need_additional_information", label: "Need additional information" },
  ]
  assesedLevelOptions = [
    { value: "early_intervention_services", label: "Early Intervention Services" },
    { value: "outpatient_treatment", label: "Outpatient Treatment" },
    { value: "awm_without_extended_monitoring", label: "AWM Without extended Monitoring" },
    { value: "opioid_treatment_services", label: "Opioid Treatment Services" },
    { value: "intensive_outpatient_treatment", label: "Intensive Outpatient Treatment" },
    { value: "partial_hospitalization", label: "Partial Hospitalization" },
    { value: "awm_extended_monitoring", label: "AWM extended Monitoring" },
    { value: "transitional_residential_treatment", label: "Transitional Residential Treatment" },
    { value: "clinically_managed_low_intensity", label: "Clinically Managed Low Intensity" },
    { value: "clinically_managed_high_intensity", label: "Clinically Managed High Intensity" },
    { value: "clinically_monitored_high_intensity", label: "Medically Monitored High Intensity" },
    { value: "medically_monitored_inpatient_withdrawal", label: "Medically Monitored Inpatient Withdrawal" },
  ];

  referralSource = [
    {
      value: "A_and_or_D_Treatment_Inpatient_Res",
      label: "A and/or D Treatment, Inpatient Res",
    },
    {
      value: "A_and_or_D_Treatment_Not_Inpatient",
      label: "A and/or D Treatment, Not Inpatient",
    },
    { value: "Clergy", label: "Clergy" },
    {
      value: "Court_Correctional_Agency",
      label: "Court/Correctional Agency",
    },
    { value: "dhr", label: "DHR" },
    {
      value: "Diversionary_Program_TASC",
      label: "Diversionary Program/TASC",
    },
    { value: "DUI_DWI", label: "DUI/DWI" },
    { value: "Educational_Agency", label: "Educational Agency" },
    { value: "Employer_EAP", label: "Employer/EAP" },
    { value: "Family", label: "Family" },
    {
      value: "Formal_Adjudication_Process",
      label: "Formal Adjudication Process",
    },
    { value: "Friend", label: "Friend" },
    {
      value: "General_Psychiatric_Hospital",
      label: "General/Psychiatric Hospital",
    },
    { value: "Guardian", label: "Guardian" },
    { value: "ID_310_Program", label: "ID 310 Program" },
    { value: "ID_arc", label: "ID ARC" },
    { value: "ID_Regional_Office", label: "ID Regional Office" },
    { value: "Multi_Service_MH_Agency", label: "Multi-Service MH Agency" },
    { value: "OP_Psych_Ser_Clinic", label: "OP Psych Ser/Clinic" },
    {
      value: "Nursing_Home_Extended_Care",
      label: "Nursing Home/Extended Care",
    },
    { value: "Parent", label: "Parent" },
    { value: "Partial_Day_Organization", label: "Partial Day Organization" },
    {
      value: "Personal_Care_Boarding_Home",
      label: "Personal Care/Boarding Home",
    },
    { value: "Physician", label: "Physician" },
    { value: "Police", label: "Police" },
    { value: "Prison", label: "Prison" },
    { value: "Private_Psychiatrist", label: "Private Psychiatrist" },
    { value: "Probation_Parole", label: "Probation/Parole" },
    { value: "Recognized_Legal_Entity", label: "Recognized Legal Entity" },
    { value: "School_System", label: "School System" },
    { value: "Self", label: "Self" },
    { value: "Shelter_for_the_Abused", label: "Shelter for the Abused" },
    { value: "Shelter_for_the_Homeless", label: "Shelter for the Homeless" },
    { value: "Spouse", label: "Spouse" },
    {
      value: "State_County_Psych_Hospital",
      label: "State/County Psych Hospital",
    },
    { value: "State_Federal_Court", label: "State/Federal Court" },
    { value: "Voc_Rehab_Services", label: "Voc. Rehab Services" },
    { value: "If_Other_Specify_below", label: "Other" },
  ];
  insurancetype = [
    { value: "AmBetter", label: "AmBetter" },
    { value: "BCBS", label: "BCBS" },
    { value: "Blue_Cross_Blue_Shield", label: "Blue Cross/Blue Shield" },
    { value: "Health_Maintenance_Organization_(HMO)", label: "Health Maintenance Organization (HMO)", },
    { value: "Humana", label: "Humana" },
    { value: "Medicaid", label: "Medicaid" },
    { value: "Medicare", label: "Medicare" },
    { value: "Private_Insurance", label: "Private Insurance" },
    { value: "Workers_Compensation", label: "Workers Compensation" },
    { value: "Unknown", label: "Unknown" },
    { value: "United", label: "United" },
    { value: "None", label: "None" },
    { value: "VIVA", label: "VIVA" },
    { value: "Other", label: "Other" },
  ];

  raceOptions = [
    { value: "Asian", label: "Asian" },
    { value: "Black", label: "Black" },
    { value: "White", label: "White" },
    { value: "American Indian or Alaska Native", label: "American Indian or Alaska Native" },
    { value: "Native Hawaiian or Pacific Islander", label: "Native Hawaiian or Pacific Islander" },
    { value: "Other Race", label: "Other Race" },
    // { value: "native_hawaiian_other_pac_island", label: "Native Hawaiian/Other Pac Island" },
    // { value: "other", label: "Other" },
  ];

  handleMaxLengthInput = (e) => {
    const input = e.target.value;
    if (input.length > 8) {
      e.preventDefault();
    }
  };

  dobHandleChange = (date) => {
    this.setState({ saved: false, toggleSSN: true });
    if (date) {
      let dob_date = moment(date);
      const formattedDueDate = dob_date.format('MM/DD/YYYY');
      const isValidDate = moment(formattedDueDate, "MM/DD/YYYY", true).isValid();
      if (isValidDate || this.props.dobError || this.props.dobErrorAge) {
        this.setState({
          dob: formattedDueDate,
          dobError: "",
          dobErrorAge: ""
        });
      }
    } else {
      this.setState({
        dob: "",
        dobError: "",
        dobErrorAge: ""
      });
    }
    setTimeout(() => {
      this.props.childToParent(this.state);
    }, 500)
  };

  handleBlur = (event) => {
    const value = event.target.value;
    const isValidDate = moment(value, "MM/DD/YYYY", true).isValid();
    if (!value || this.props.dobError || this.props.dobErrorAge ) { 
      this.setState({
        dob: value, 
        dobError: "",
        dobErrorAge: ""
      });
      alert("Date should not be blank") 
    }
    else if (!isValidDate || this.props.dobError || this.props.dobErrorAge) {
      this.setState({
        dob: "", 
        dobError: "",
        dobErrorAge: ""
      });
      alert("Invalid date") 
    }
    setTimeout(() => {
      this.props.childToParent(this.state);
    }, 500)
  };

  handleChange2 = (date) => {
    this.setState({ saved: false });
    if (!date) {
      this.setState({ weeks: "", due_date: null }, () => {
        this.props.childToParent(this.state);
      });
    } else {
      let current_date = moment();
      let due_date = moment(date);
      const formattedDueDate = due_date.format('MM-DD-YYYY');
      const pregnancyDate = due_date.subtract(252, 'days');
      let diff = moment.duration(current_date.diff(pregnancyDate));
      let weeks = Math.floor(diff.asWeeks());
      this.setState({ weeks: weeks, due_date: formattedDueDate }, () => {
        this.props.childToParent(this.state);
      });
    }
  }

  handleChange1 = async (e) => {
    this.props.tabEditOrNot(true);
    this.props.saveAndExit(this.handleOnSubmit, "", "", "", "", "", "", "", "");
    if (e.target.name == "weeks") {
      if (isNaN(e.target.value)) {
        let validation = this.state.validation;
        validation.Pregnancies_week_error = "* Only numbers are allowed";
        this.setState({ validation: validation })

      } else {
        if (e.target.value.length > 2) {
          let validation = this.state.validation;
          validation.Pregnancies_week_error = "* More than 2 numbers are not allowed";
          this.setState({ validation: validation });
        } else {
          this.setState({ [e.target.name]: e.target.value });
        }
      }
    }
    setTimeout(() => {
      this.props.childToParent(this.state);
    }, 500)
  }

  handleChange = async (e) => {
    this.props.tabEditOrNot(true);
    this.props.saveAndExit(this.handleOnSubmit, "", "", "", "", "", "", "", "");
    this.setState({ saved: false, toggleSSN: true });
    if (e.target.name == "number_of_Pregnancies") {
      if (isNaN(e.target.value)) {
        let validation = this.state.validation;
        validation.number_of_Pregnancies_error = "* Only numbers are allowed";
        this.setState({ validation: validation })

      } else {
        if (e.target.value.length > 2) {
          let validation = this.state.validation;
          validation.number_of_Pregnancies_error = "* More than 2 numbers are not allowed";
          this.setState({ validation: validation });
        } else {
          this.setState({ [e.target.name]: e.target.value });
        }
      }
    }
    else if (e.target.name == "weeks") {
      // if(isNaN(e.target.value))
      // {
      //   let validation=this.state.validation;
      //   validation.Pregnancies_week_error="* Only numbers are allowed";
      //   this.setState({validation:validation})

      // }else{
      //     if(e.target.value.length>2)
      //     {
      //       let validation=this.state.validation;
      //       validation.Pregnancies_week_error="* More than 2 numbers are not allowed";
      //       this.setState({validation:validation});
      //     }else{
      //       this.setState({ [e.target.name]: e.target.value });
      //     }
      // }
    }
    else if (e.target.name == "client_seeking_treatment" && e.target.value == "no") {
      this.setState({ ["client_seeking_explain"]: "", ["client_seeking_treatment"]: "no", saved: false });
    } else if (e.target.name == "dependent_children" && e.target.value == "No") {
      this.setState({ ["dependent_children"]: "No", ["dependent_children_number"]: "", saved: false });
    } else if (e.target.name == "pregnant") {
      this.setState({ [e.target.name]: e.target.value, saved: false });
      // ["number_of_Pregnancies"]: "" ,["prenatal_care"]: "" ,["weeks"]: "" ,["due_date"]: ""
    } else if (e.target.name == "ssn"  || this.props.ssnValidation) {
      if(this.state.ssn !=""){
        this.setState({ssnValidation:""})
      }
      this.setState({ [e.target.name]: e.target.value, saved: false });
    }else if(e.target.name == "alias_1"){
      this.setState({ [e.target.name]: e.target.value < 0 ? "" : e.target.value, saved: false });
    }else {
      this.setState({ [e.target.name]: e.target.value });
    }
    //  if (e.target.name == 'due_date') {
    //   let current_date = moment();
    //   let due_date = moment(e.target.value, 'Y-MM-DD');
    //   const pregnancyDate = moment(due_date).subtract(252, 'days');
    //   let diff = moment.duration(current_date.diff(pregnancyDate));
    //   let weeks = Math.floor(diff.asWeeks())
    //   this.setState({ 'weeks': weeks });
    // }
    setTimeout(() => {
      this.props.childToParent(this.state);
    }, 500)
  }

  handleMultiSelectChanges = (selectedoption, e) => {
    if (e.name != "") {
      this.setState({ [e.name]: selectedoption, saved: false })
    }
    setTimeout(() => {
      this.props.childToParent(this.state);
    }, 500)
  };

  handleOnSubmit = async (e, shouldToasterShow) => {
    e.preventDefault();
    const { primary_phone, ssn, dob } = this.state;
    if (!dob) {
      this.setState({ dobError: "Date of Birth is mandatory" });
      return;
    } else {
      this.setState({ dobError: "" });
    }

    //Find date 14 years ago
    const currentDate = new Date();
    const fourteenYearsAgo = new Date();
    fourteenYearsAgo.setFullYear(currentDate.getFullYear() - 14);
    
    const oneHundredTwentyYearsAgo = new Date();
    oneHundredTwentyYearsAgo.setFullYear(currentDate.getFullYear() - 120);

    const dobDate = new Date(dob);

    // Check if the age is between 14 and 120 years
    if (dobDate > fourteenYearsAgo) {
      this.setState({ dobErrorAge:  "Age should be >= 14 and <= 120 " });
      return;
    }else if (dobDate < oneHundredTwentyYearsAgo) {
      this.setState({ dobErrorAge: "Age should be >= 14 and <= 120 " });
      return;
    } else {
      this.setState({ dobErrorAge: "" });
    }
    let formattedPrimaryPhone = primary_phone;
    let formattedssn = ssn;
    // Remove all non-numeric characters from the phone number
    const numericPrimaryPhone = primary_phone.replace(/\D/g, '');
    const numericssn = ssn.replace(/\D/g, '');
    // Format the phone number as (999) 999-9999
    if (numericPrimaryPhone.length === 10) {
      formattedPrimaryPhone = `(${numericPrimaryPhone.substring(0, 3)}) ${numericPrimaryPhone.substring(3, 6)}-${numericPrimaryPhone.substring(6)}`;
    }
    if (numericssn.length === 9) {
      formattedssn = `${ssn.substring(0, 3)}-${ssn.substring(3, 5)}-${ssn.substring(5)}`;
    }
    // Check if the formatted phone number is less than 14 characters (formatted length)
    if ((formattedPrimaryPhone.length < 14 && formattedPrimaryPhone.length > 0)  || ( formattedssn.length < 11 && formattedssn.length > 0)) {
      if (formattedPrimaryPhone.length < 14 && formattedPrimaryPhone.length > 0) {
        e.preventDefault();
        this.setState({
          errorMessage: "Please enter at least 10 digits."
        });
      }
      if (formattedssn.length < 11 && formattedssn.length > 0) {
        e.preventDefault();
        this.setState({
          ssnValidation:'Please enter ssn at least 9 digits.'
        });
      }
      
    } else {
      e.preventDefault();
      let final_data = {
        ClientInformationData: this.state,
        dataListBody: this.dataListBody,
      };
      let res = await axios.post(
        global.restApiURL + "customrest/updateClientInoforamation",
        JSON.stringify(final_data)
      );
      if (res.data.success == true && shouldToasterShow == undefined) {
        this.setState({ saved: false });
        toast.success('Data added successfully!', { toastId: ('Data added successfully!') }, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: 'success_clientInformation',
        });
        this.loadData();
      } else if (res.data.success == true && shouldToasterShow == 'saveNexit') {
        // msg should be show on parent componnt;
      }
    }
  }

  loadData = async () => {
    let res = await axios.post(
      global.restApiURL + "customRest/getUpdateClientInoforamation",
      JSON.stringify(this.dataListBody)
    );
    this.setState({ saved: true });
    if (res.data.success == true) {
      this.setState({
        client_name: res.data.data.ClientInformationData.client_name,
        legal_guardian: res.data.data.ClientInformationData.legal_guardian,
        dob: res.data.data.ClientInformationData.dob,
        alias_1: res.data.data.ClientInformationData.alias_1,
        primary_phone: res.data.data.ClientInformationData.primary_phone,
        address_lns: res.data.data.ClientInformationData.address_lns,
        gender: res.data.data.ClientInformationData.gender,
        ssn: res.data.data.ClientInformationData.ssn,
        relationship: res.data.data.ClientInformationData.relationship,
        medicaid: res.data.data.ClientInformationData.medicaid,
        // race: res.data.data.ClientInformationData.race,
        race: res.data.data.ClientInformationData.race?.length > 0 ? JSON.parse(res.data.data.ClientInformationData.race) : [],

        assesssed_level_of_care: res.data.data.ClientInformationData.assesssed_level_of_care.length !== 0 ? JSON.parse(res.data.data.ClientInformationData.assesssed_level_of_care) : [],
        medical_provider_review: res.data.data.ClientInformationData.medical_provider_review.length !== 0 ? JSON.parse(res.data.data.ClientInformationData.medical_provider_review) : [],
        priority_population: res.data.data.ClientInformationData.priority_population,
        client_seeking_treatment: res.data.data.ClientInformationData.client_seeking_treatment,
        client_seeking_explain: res.data.data.ClientInformationData.client_seeking_explain,
        primary_referral: res.data.data.ClientInformationData.primary_referral,
        // code add by raj start
        other_referral_source: res.data.data.ClientInformationData.other_referral_source,
        other_income_place: res.data.data.ClientInformationData.other_income_place,
        other_insurance_type: res.data.data.ClientInformationData.other_insurance_type,
        // code add by raj end
        princepal_source_of_income: res.data.data.ClientInformationData.princepal_source_of_income,
        insurance_type: res.data.data.ClientInformationData.insurance_type,
        payment_source: res.data.data.ClientInformationData.payment_source,
        dependent_children: res.data.data.ClientInformationData.dependent_children,
        pregnant: res.data.data.ClientInformationData.pregnant,

        number_of_Pregnancies: res.data.data.ClientInformationData.number_of_Pregnancies,
        prenatal_care: res.data.data.ClientInformationData.prenatal_care,
        weeks: res.data.data.ClientInformationData.weeks,
        due_date: res.data.data.ClientInformationData.due_date,

        military_status: res.data.data.ClientInformationData.military_status,
        current_prescribed_medications: res.data.data.ClientInformationData.current_prescribed_medications,
        participating_in_mat: res.data.data.ClientInformationData.participating_in_mat,

        dependent_children_number: res.data.data.ClientInformationData.dependent_children_number,
        visitType: res.data.data.ClientInformationData.visitType,
        saved: true,
      })
      setTimeout(() => {
        this.props.childToParent(this.state);
      }, 500)
    }
  };

  toggleSSN = () => {
    this.setState({ toggleSSN: !this.state.toggleSSN });
  }

  beforeMaskedValueChangeSSN = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;
    if (value.endsWith('-') && userInput !== '-' && !this.state.ssn.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }
    return {
      value,
      selection
    };
  }

  componentDidMount() {
    this.loadData();
  }

  // Google API for address
  handleChange_gpi = address => {
    this.setState({ address_lns: address });
  };

  handleSelect = address => {
    this.handleChange_gpi(address)
    geocodeByAddress(address)
      .then((results) => {
        var stateCopy = Object.assign({}, this.state);
        // for (var i = 0; i < results[0].address_components.length; i++) {
        //   if (results[0].address_components[i].types[0] === "locality") {
        //     stateCopy.address_lns = results[0].address_components[0].long_name.replace(results[0].address_components[i].long_name + ', ', '');
        //   }

        // }
        stateCopy['saved'] = false;
        this.setState(stateCopy);
        setTimeout(() => {
          this.props.childToParent(this.state);
        }, 500)
      })
      .catch(error => console.error('Error', error));
  };

  handlePhoneChange = (event) => {
    this.setState({ saved: false, toggleSSN: true });
    if (event.target !== null) {
      let test = event.target.value;
      if (event.target.name === "primary_phone") {
        if (this.state.primary_phone !== "" || this.props.errorMessage) {
          this.setState({ errorMessage: '' });
        }
        this.setState({ primary_phone: test })
      }
    }
    setTimeout(() => {
      this.props.childToParent(this.state);
    }, 500)
  }

  beforeMaskedValueChange2 = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;
    if (value.endsWith('-') && userInput !== '-' && !this.state.primary_phone.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }
    return {
      value,
      selection
    };
  }

  render() {
    return (
      <React.Fragment>
        {/* <ToastContainer style={{zIndex:900}}/> */}
        <form onSubmit={this.handleOnSubmit}>
          <div className='row'>
            <>
              <div className='col-md-12'>
                <p className="lead float-left" style={{ fontSize: '1.05rem', lineHeight: '20px', marginBottom: '1rem' }} ><b className="text-green">Client Information</b></p>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='form-group'>
                  <label>Client Name</label>
                  <input type="text" disabled={true} name="client_name" value={this.state.client_name} onChange={this.handleChange} className='form-control' />
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='form-group'>
                  <label>Legal Guardian</label>
                  <input type="text" name="legal_guardian" disabled={this.state.isSaveAndComplete} value={this.state.legal_guardian} onChange={this.handleChange} className='form-control' />
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='form-group'>
                  <label>Date of Birth</label>
                   {/* <input type="date" name="dob" disabled={this.state.isSaveAndComplete} value={this.state.dob} onChange={this.handleChange} className='form-control' /> */}
                   <div>
                    <DatePicker
                    style={{ transition: "border-color .15s ease-in-out"}}
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={15}
                      selected={this.state.dob ? moment(this.state.dob).toDate() : null}
                      onChange={this.dobHandleChange}
                      disabled={this.state.isSaveAndComplete}
                      dateFormat="MM/dd/yyyy"
                      placeholder="MM/DD/YYYY"
                      name="dob"
                      showIcon  
                      onBlur={this.handleBlur}
                      customInput={
                        <input
                          type="text"
                          onInput={this.handleMaxLengthInput}
                          maxLength="10"
                          placeholder="MM/DD/YYYY"
                          className="due_Date_clientInformation"
                          style={{ transition: "border-color .15s ease-in-out"}}
                          value={this.state.dob} 
                        />
                      }
                    />

                  </div>
                  {this.props.dobError ? <span style={{ color: "red" }}>{this.props.dobError}</span>:<span style={{ color: "red" }}>{this.state.dobError}</span>}
                  {this.props.dobErrorAge ? <span style={{ color: "red", }}>{this.props.dobErrorAge}</span>:<span style={{ color: "red" }}>{this.state.dobErrorAge}</span>}
                </div>
              </div>
              <div className='col-lg-2 col-md-6'>
                <div className='form-group'>
                  <label>Days in Treatment</label>
                  <input type="number" name="alias_1" disabled={this.state.isSaveAndComplete} value={this.state.alias_1} onChange={this.handleChange} className='form-control' />
                </div>
              </div>
              <div className='col-lg-3 col-md-6'>
                <div className='form-group'>
                  <label>Phone</label>
                  {/* <input type="text" name="primary_phone" disabled={this.state.isSaveAndComplete} value={this.state.primary_phone} onChange={this.handleChange} className='form-control' /> */}
                  {/* <InputMask id="primary_phone" disabled={this.state.isSaveAndComplete} name="primary_phone" className="form-control" mask="(999) 999-9999" maskChar={null} value={this.state.primary_phone} beforeMaskedValueChange={this.beforeMaskedValueChange2} onChange={this.handlePhoneChange} /> */}
                  {this.props.errorMessage ? <>
                    <InputMask id="primary_phone" disabled={this.state.isSaveAndComplete} name="primary_phone" className="form-control" mask="(999) 999-9999" maskChar={null} value={this.state.primary_phone} beforeMaskedValueChange={this.beforeMaskedValueChange2} onChange={this.handlePhoneChange} />
                    {this.props.errorMessage.length > 0 && <span style={{ color: "red" }}>{this.state.primary_phone === "" ? "" : this.props.errorMessage}</span>}
                  </> : <>
                    <InputMask id="primary_phone" disabled={this.state.isSaveAndComplete} name="primary_phone" className="form-control" mask="(999) 999-9999" maskChar={null} value={this.state.primary_phone} beforeMaskedValueChange={this.beforeMaskedValueChange2} onChange={this.handlePhoneChange} />
                    {this.state.errorMessage.length > 0 && <span style={{ color: "red" }}>{this.state.primary_phone === "" ? "" : this.state.errorMessage}</span>}
                  </>}
                </div>
              </div>
              <div className='col-lg-7 col-md-6'>
                <div className='form-group'>
                  <label>Address</label>
                  {/* <input type="text" name="address_lns" value={this.state.address_lns} onChange={this.handleChange} className='form-control' /> */}
                  {!this.state.isSaveAndComplete && <>

                    <LocationSearchInput
                      value={this.state.address_lns}
                      onChange={this.handleChange_gpi}
                      onSelect={this.handleSelect}
                      id="address_lns"
                      name="address_lns"
                      defaultValue={this.state.address_lns}
                    />

                  </>}
                  {this.state.isSaveAndComplete && <>
                    <input type="text" name="address_lns" className="form-control" value={this.state.address_lns} disabled={true} />
                  </>}
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='form-group'>
                  <label>Sex</label>
                  <select className='form-control' disabled={this.state.isSaveAndComplete} name="gender" value={this.state.gender} onChange={this.handleChange}>
                    <option value="">Select</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Trans MTF"> Trans MTF</option>
                    <option value="Trans FTM">Trans FTM</option>
                    <option value="Doesn’t identify as M/F/T">Doesn’t identify as M/F/T</option>
                  </select>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='form-group'>
                  <label>SSN</label>
                  {(this.state.toggleSSN && this.state.ssn !== null && this.state.ssn !== undefined && this.state.ssn !== '' && this.state.ssn.replace(/-/g, "").length >= 9) ? (
                    <div>
                      <input type="text" id="ssn_view" disabled={this.state.isSaveAndComplete} placeholder="SSN" name="ssn_text" className="form-control" value={`***-**-` + this.state.ssn.substr(this.state.ssn.length - 4)} onFocus={this.toggleSSN} />
                      <input type="hidden" id="ssn" name="ssn" className="form-control" value={this.state.ssn} />
                    </div>) : (
                    <InputMask type='text' disabled={this.state.isSaveAndComplete} placeholder="SSN" id="ssn" name="ssn" className="form-control" mask="999-99-9999" maskChar={null} value={this.state.ssn} beforeMaskedValueChange={this.beforeMaskedValueChangeSSN} onChange={this.handleChange} minLength={11} maxLength={11} onBlur={this.toggleSSN} onMouseLeave={this.toggleSSN} />
                  )}
                  {this.props.ssnValidation ? <>
                   <span style={{ color: 'red' }}>{this.props.ssnValidation === "" ? "" : this.props.ssnValidation}</span>
                   </>: <span style={{ color: 'red' }}>{this.state.ssn=== "" ? "" : this.state.ssnValidation}</span>}
                </div>
              </div>

              <div className='col-lg-4 col-md-6'>
                <div className='form-group'>
                  <label>Visit Type</label>
                  <select className='form-control' disabled={this.state.isSaveAndComplete} name="visitType" value={this.state.visitType} onChange={this.handleChange}>
                    <option value="">Select</option>
                    <option value="In-Person">In-Person</option>
                    <option value="Telehealth">Telehealth</option>
                  </select>
                </div>
              </div>

              <div className='col-lg-4 col-md-6'>
                <div className='form-group'>
                  <label>Last School/Grade</label>
                  <input type="text" name="medicaid" disabled={this.state.isSaveAndComplete} value={this.state.medicaid} onChange={this.handleChange} className='form-control' />
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='form-group'>
                  <label>Race</label>
                  <Select
                    isMulti
                    isDisabled={this.state.isSaveAndComplete}
                    closeMenuOnSelect={false}
                    value={this.state.race}
                    name="race"
                    options={this.raceOptions}
                    // onChange={(e) => { this.setState({ race: e, saved: false }) }} 
                    onChange={(selectedoption, e) => { this.handleMultiSelectChanges(selectedoption, e) }}
                  />
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='form-group'>
                  <label>Current LOC</label>
                  <Select
                    isMulti
                    isDisabled={this.state.isSaveAndComplete}
                    closeMenuOnSelect={false}
                    value={this.state.assesssed_level_of_care}
                    name="assesssed_level_of_care"
                    options={this.assesedLevelOptions}
                    onChange={(selectedoption, e) => { this.handleMultiSelectChanges(selectedoption, e) }}
                  />
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='form-group'>
                  <label>Recommended LOC</label>
                  <Select
                    name="medical_provider_review"
                    isDisabled={this.state.isSaveAndComplete}
                    isMulti
                    closeMenuOnSelect={false}
                    options={this.medicalProviderOptions}
                    value={this.state.medical_provider_review}
                    // onChange={(e) => { this.setState({ medical_provider_review: e, saved: false }) }}
                    onChange={(selectedoption, e) => { this.handleMultiSelectChanges(selectedoption, e) }}
                  />
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='form-group'>
                  <label>Priority IV User</label>
                  <select className='form-control' disabled={this.state.isSaveAndComplete} name="priority_population" value={this.state.priority_population} onChange={this.handleChange}>
                    <option value="">Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='form-group'>
                  <label>Co-Dependent/Collateral</label>
                  {/* <input type="text" className='form-control' name="co_dependent" onChange={this.handleChange} /> */}
                  <select name="client_seeking_treatment" disabled={this.state.isSaveAndComplete} value={this.state.client_seeking_treatment} className='form-control' onChange={this.handleChange}>
                    <option value="">Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
              </div>

              {this.state.client_seeking_treatment == "yes" && <>

                <div className='col-lg-4 col-md-6'>
                  <label>Explain</label>
                  <TextareaAutosize
                    minRows={(this.state.client_seeking_explain?.length / 50) + this.state.client_seeking_explain?.split(/\r\n|\r|\n/).length}
                    type='text'
                    style={{ overflow: "hidden", textAlign: "justify" }}
                    disabled={this.state.isSaveAndComplete}
                    name="client_seeking_explain"
                    value={this.state.client_seeking_explain}
                    className='form-control'
                    onChange={this.handleChange}
                  />
                </div></>
              }
              <div className='col-lg-4 col-md-6'>
                <div className='form-group'>
                  <label>Principal Source of Referral</label>
                  {/* <input type="text" className='form-control' name="principal_source_of_referral" onChange={this.handleChange} /> */}
                  <select
                    className="form-control"
                    name="primary_referral"
                    onChange={this.handleChange}
                    value={this.state.primary_referral}
                    disabled={this.state.isSaveAndComplete}
                  >
                    <option value="">Select</option>
                    {this.referralSource.map((referal) => (
                      <option value={referal.value}>
                        {referal.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              { this.state.primary_referral === "If_Other_Specify_below" ?
                <div className='col-lg-4 col-md-6'>
                  <div className='form-group'>
                    <label>If other, please specify</label>
                    <TextareaAutosize onChange={this.handleChange} name="other_referral_source" type="text" className="form-control " value={this.state.other_referral_source} />
                  </div>
                </div> : ""
              }
              <div className='col-lg-4 col-md-6'>
                <div className='form-group'>
                  <label>Source of Financial Support</label>
                  <select className='form-control' disabled={this.state.isSaveAndComplete} name="princepal_source_of_income" value={this.state.princepal_source_of_income} onChange={this.handleChange}>
                    <option value="">Select</option>
                    <option value="wages_salary">Wages/Salary</option>
                    <option value="public_assistance"> Public Assistance</option>
                    <option value="retirement_pension">Retirement/Pension</option>
                    <option value="disablity">Disablity</option>
                    <option value="unknown">UnKnown</option>
                    <option value="none">None</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>
              {this.state.princepal_source_of_income === "other" ?
                <div className='col-lg-4 col-md-6'>
                  <div className='form-group'>
                    <label>If other, please specify</label>
                    <TextareaAutosize onChange={this.handleChange} name="other_income_place" type="text" className="form-control " value={this.state.other_income_place} />
                  </div>
                </div> : ""
              }
              <div className='col-lg-4 col-md-6'>
                <div className='form-group'>
                  {/* <label>Health Insurance</label> */}
                  <label>Insurance Type</label>
                  <select className='form-control' disabled={this.state.isSaveAndComplete} name="insurance_type" value={this.state.insurance_type} onChange={this.handleChange}>
                    <option value="">Select</option>
                    {this.insurancetype.map((insurance) => (
                      <option value={insurance.value}>
                        {insurance.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {this.state.insurance_type === "Other" ?
                <div className='col-lg-4 col-md-6'>
                  <div className='form-group'>
                    <label>If other, please specify</label>
                    <TextareaAutosize onChange={this.handleChange}  name="other_insurance_type" type="text" className="form-control " value={this.state.other_insurance_type} />
                  </div>
                </div> : ""
              }
              <div className='col-lg-4 col-md-6'>
                <div className='form-group'>
                  <label>Source of Payment</label>
                  <select className='form-control' disabled={this.state.isSaveAndComplete} name="payment_source" value={this.state.payment_source} onChange={this.handleChange}>
                    <option value="">Select</option>
                    {this.sourceOfPayment_Option.map(
                      (insurance) => (
                        <option value={insurance.value}>
                          {insurance.label}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='form-group'>
                  <label>Number of dependent children at admission</label>
                  {/* <input type="text" className='form-control' name="number_of_dependent_children" onChange={this.handleChange} /> */}
                  <select className="form-control" disabled={this.state.isSaveAndComplete} name="dependent_children" value={this.state.dependent_children} onChange={this.handleChange}>
                    <option value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              </div>

              {this.state.dependent_children == "Yes" && <>
                <div className="col-md-4">
                  <label>How many?</label>
                  <input type="number" disabled={this.state.isSaveAndComplete} name="dependent_children_number" value={this.state.dependent_children_number} className="form-control" onChange={this.handleChange} />
                </div>
              </>}

              <div className='col-lg-4 col-md-6'>
                {/* <div className='form-group'>
                                <label>Pregnant</label>
                                <input type="text" className='form-control' name="pregnant" value={this.state.pregnant} onChange={this.handleChange} />
                            </div> */}
                <div className="form-group">
                  <label>Pregnant?</label>
                  <select className="form-control" disabled={this.state.isSaveAndComplete} name="pregnant" onChange={this.handleChange} value={this.state.pregnant}>
                    <option value="">Select</option>
                    <option value="yes" selected={this.state.pregnant == "yes" ? true : false}>Yes</option>
                    <option value="no" selected={this.state.pregnant == "no" ? true : false}>No</option>
                    <option value="na" selected={this.state.pregnant == "na" ? true : false}>NA</option>
                    <option value="unknown" selected={this.state.pregnant == "unknown" ? true : false}>Unknown</option>
                  </select>
                </div>

              </div>

              {/* <div className="row marginBottom1">   */}
              {this.state.pregnant == "yes" && <>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label>Due date </label>
                    {/* <input type="date" disabled={this.state.isSaveAndComplete} name="due_date" value={this.state.due_date} className="form-control" onChange={this.handleChange} /> */}
                    <DatePicker
                      selected={this.state.due_date ? moment(this.state.due_date).toDate() : null}
                      onChange={this.handleChange2}
                      disabled={this.state.isSaveAndComplete}
                      dateFormat="MM/dd/yyyy"
                      placeholder="MM/DD/YYYY"
                      className="due_Date_clientInformation"
                      minDate={new Date()}
                      showIcon
                      customInput={
                        <input
                          type="text"
                          onInput={this.handleMaxLengthInput}
                          maxLength="10"
                          placeholder="MM/DD/YYYY"
                          className="due_Date_clientInformation"
                        />
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label>If yes, how many weeks?</label>
                    {/* <input type="text" name="weeks" value={this.state.weeks} className="form-control" onChange={this.handleChange} /> */}

                    <input type="text" disabled={this.state.isSaveAndComplete} name="weeks" value={this.state.weeks} className="form-control" onChange={this.handleChange1} maxlength={2} />
                    <span style={{ color: "red" }}>{this.state.validation.Pregnancies_week_error}</span>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label>If yes, are you receiving prenatal care? </label>
                    <select name="prenatal_care" disabled={this.state.isSaveAndComplete} className="form-control" onChange={this.handleChange}>
                      <option value="">Select</option>
                      <option value="yes" selected={this.state.prenatal_care == "yes" ? true : false}>Yes</option>
                      <option value="no" selected={this.state.prenatal_care == "no" ? true : false}>No</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <label>Total Number of Pregnancies</label>
                    <input type="text" name="number_of_Pregnancies" disabled={this.state.isSaveAndComplete} value={this.state.number_of_Pregnancies} maxLength={2} className="form-control" onChange={this.handleChange} />
                    <span style={{ color: "red" }}>{this.state.validation.number_of_Pregnancies_error}</span>
                    {/* <select name="number_of_Pregnancies" value={this.state.number_of_Pregnancies} className="form-control" onChange={this.handleChange}>
                                        <option value="">Select</option>
                                        <option value="1" >1</option>
                                        <option value="2" >2</option>
                                        <option value="3" >3</option>
                                        <option value="4" >4</option>
                                    </select> */}
                  </div>
                </div>
              </>}
              {/* </div> */}




              <div className='col-lg-4 col-md-6'>
                <div className='form-group'>
                  <label>Military Status</label>
                  <select className='form-control' disabled={this.state.isSaveAndComplete} name="military_status" value={this.state.military_status} onChange={this.handleChange}>

                    <option value="">Select</option>
                    <option value="na">NA</option>
                    <option value="active">Active</option>
                    <option value="non_active_honorable_dis">Non-Active Honorable Dis</option>
                    <option value="non_active_dishonorable_dis">Non-Active Dishonorable Dis</option>
                    <option value="military_dependent">Military Dependent</option>
                  </select>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='form-group'>
                  <label>Current prescribed medications</label>
                  <input type="text" className='form-control' disabled={this.state.isSaveAndComplete} name="current_prescribed_medications" value={this.state.current_prescribed_medications} onChange={this.handleChange} />
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='form-group'>
                  <label>Is the client participating in MAT for an OUD?</label>
                  <select className='form-control' disabled={this.state.isSaveAndComplete} name="participating_in_mat" value={this.state.participating_in_mat} onChange={this.handleChange}>
                    <option value="">Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
              </div>
            </>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              {/* <button className='btn btn-success float-right' ><i className="fas fa-save"></i> Save</button> */}
              <button type="submit" data-save="save" disabled={this.state.saved} value='save' className="btn btn-success float-right btnmargin"> <i className="fas fa-save"></i> Save</button>
            </div>
          </div>
        </form>
      </React.Fragment>
    )
  }
}
