import React, { Component } from 'react'
import AdultPlacementAssesment from './adultPlacementAssesment';
import BehavioralComplications from './behavioralComplications';
import BioMedicalCondition from './bioMedicalCondition';
import Modal from './Modals/Modal'
import ProblemPotential from './problemPotential';
import ReadinessChange from './readinessChange';
import Recovery from './recovery';
import Summary from './AssesmentSummary';
import SubstanceUseBackground from './SubstanceUseBackground';
import Level_of_care from './AssesmentSummary/level_of_care';
import { Alert } from './toastify';
import Signature from './signature';
import services from '../services';
import { newMultiSelectOptionGenerator } from '../ReusableComponents/OptionsGenerator';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './Loader';
import { encryptStorage } from './LocalStorageInterceptor.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert';

export default class Admh extends Component {
    constructor(props) {
        super(props);
        this.state = {
            route_of_admin: [],
            frequency_of_use: [],
            specificSubstanceOptions: [],
            classSubstanceOptions: [],
            problemTypeOptions: [],
            age: this.props?.age,
            showLeft: true,
            showRight: false,
            updateModal: props.updateModal,
            creationDate: "",
            adult_assement_creation_date: "",
            adolescent_assement_creation_date: "",
            saveComplete: "",
            savePreview:"",
            showLeaveModal: false,
            dataListBody: {
                form_type: props.form_type,
                apikey: localStorage.getItem('__TOKEN__'),
                client_id: sessionStorage.getItem("clientId"),
                uploaded_by: sessionStorage.getItem("username"),
            },
            form_type: props.form_type,
            isSaveAndComplete: props.saveComplete,
            // isSaveAndComplete:false,
            updateModal_adultAssesment: props.updateModal_adult,
            updateModal_adolecentAssesment: props.updateModal_adolecent,

            //Acute Intoxication
            adultAssesmentData: {},
            old_symptoms: [],

            // Substance Use
            substances: [],
            substance_comments: '',
            withdrawl_symptoms: '',
            old_symptoms: [],
            new_symptoms: '',
            new_symptoms_explain: '',
            Larger_amount_of_aod: '',
            Larger_amount_aod_explain: '',
            previous_effort_to_control_aod: '',
            previous_effort_to_cut_down_explain: '',

            //BioMedicalCondition
            BioMedicalCondition: {},

            //Behavioral Complications
            EmotionalBehaviour: {},
            mental_health_disorder:[],
            EmotionalMedications:[],
            mentaldisorderOptions:[],
            immediate: [],
            recent: [],
            remote: [],
            speech: [],
            orientation: [],
            dress: [],
            grooming: [],
            facial_exp: [],
            mood: [],
            judgement:[],
            insight:[],
            affect: [],
            process: [],
            content: [],

            //Readiness for Change
            otherData: {},
            questions: [{}],
            precontemplationScore: "",
            contemplationScore: "",
            actionScore: "",
            stage_of_change_designation: "",

            // ProblemPotential
            ProblemPotential: {},

            //Recovery
            Enviornment: {},

            //Level_of_care
            Assessed: {},
            disposition: [],
            medical_provider_review: [],
            assesssed_level_of_care: [],
            placed_level_of_care: [],

            Referred_to: [],
            refered_for_case: [],
            assesed_level: [],
            no_service_availaible1: [],
            interim_assessed_level_of_care: [],

            //Release of Information
            SignatureData: {},

            //substanceUseDisorder
            substanceUseDisorder: [],
            // substanceUseDisorder:{},
            // deletedBlock:[],

            //MentalHealthUseDisorder
            mental_health_orders: [],

            // Other Disorder
            otherDisorder: [],

            // psychological_problems
            psychological_problems: [],


            // Preloader
            isloading: false,

            //save and complete with risk rating
            RiskRatingComment: "",
            RiskRatingComment2: "",
            RiskRatingValue: "",
            RiskRatingValue2: "",

            //  Exit as save and exit or exit with out save or stay on page===
            popUpShow: "",
            saveTabsDataIndividually : {
                saveSubstanceUse : "",
                savAcuteIntoxication : "",
                saveBiomedicalCondition : "",
                saveEmotionalBehavioural : "",
                saveRedinessForChange : "",
                saveProblemPotentioal : "",
                saveInvironment : "",
                saveSubstanceUseDisorder : "",
                saveMentalHealthDishOrder : "",
                saveOtherDishOrder : "",
                savePsychologicalEnvironmetalProblems : "",
                saveLevelOfCare : "",
                saveReleaseOfInformation : ""
            },
            // justificationError
            justificationError: "",
        }
    }

    arrowChange = (e) => {
        this.setState({ [e.target.dataset.param]: true, [e.target.id]: false });
    }

    saveAndComplete = (option) => {
        if (option == "yes") {
            this.props.closeAdmh();
            this.props.closeSaveComplete();
            Alert("success", "Saved Successfully");
        } else if (option == "no") {
            this.props.closeSaveComplete()
        }
    }

    async componentDidMount() {
        this.setState({ creationDate: sessionStorage.getItem("assement_form_type_date") });
        this.setState({ adult_assement_creation_date: sessionStorage.getItem("adult_assement_form_type_date"), adolescent_assement_creation_date: sessionStorage.getItem("adolescent_assement_form_type_date") });
        this.loadData();
    }

    // componentDidUpdate(A) {
        
    // }

    loadData = async () => {
        let res = await axios.post(global.restApiURL + "customrest/checkIfAssessmentCompleted", JSON.stringify(this.state.dataListBody));
        if (res.data.success && res.status == 200) {
            if (res.data.data.is_assessment_completed == "1") {
                this.setState({ isSaveAndComplete: true })
            } else {
                this.setState({ isSaveAndComplete: false })
            }
        }
    }


    closeAdmh = () => {
        window.location.reload();
    }

    saveBeforeExit= (
                        saveFunctionSubstanceUse = "",
                        saveFunctionAccuteIntoxication = "",
                        saveFunctionBiomedicalCondition = "",
                        saveFunctionEmotionalBehavioral = "",
                        saveFunctionRedinessForchange = "",
                        saveFunctionProblemPotential = "",
                        saveFunctionInvironment = "",
                        saveFunctionSubstanceDishOrder = "",
                        saveMentalDishorder = "",
                        saveOtherDishOrder = "",
                        savePsychoProblem = "",
                        saveLevelOfCare = "",
                        saveReleaseInformation = ""
                    )   =>  { 
                            if(typeof(saveFunctionSubstanceUse) == 'function'){
                                this.state.saveTabsDataIndividually.saveSubstanceUse = saveFunctionSubstanceUse;
                            }
                            if(typeof(saveFunctionAccuteIntoxication) == 'function'){
                                this.state.saveTabsDataIndividually.savAcuteIntoxication = saveFunctionAccuteIntoxication;
                            }
                            if(typeof(saveFunctionBiomedicalCondition) == 'function'){
                                this.state.saveTabsDataIndividually.saveBiomedicalCondition = saveFunctionBiomedicalCondition;
                            }
                            if(typeof(saveFunctionEmotionalBehavioral) == 'function'){
                                this.state.saveTabsDataIndividually.saveEmotionalBehavioural = saveFunctionEmotionalBehavioral;
                            }
                            if(typeof(saveFunctionRedinessForchange) == 'function'){
                                this.state.saveTabsDataIndividually.saveRedinessForChange = saveFunctionRedinessForchange;
                            }
                            if(typeof(saveFunctionProblemPotential) == 'function'){
                                this.state.saveTabsDataIndividually.saveProblemPotentioal = saveFunctionProblemPotential;
                            }
                            if(typeof(saveFunctionInvironment) == 'function'){
                                this.state.saveTabsDataIndividually.saveInvironment = saveFunctionInvironment;
                            }
                            if(typeof(saveFunctionSubstanceDishOrder) == 'function'){
                                this.state.saveTabsDataIndividually.saveSubstanceUseDisorder = saveFunctionSubstanceDishOrder;
                            }
                            if(typeof(saveMentalDishorder) == 'function'){
                                this.state.saveTabsDataIndividually.saveMentalHealthDishOrder = saveMentalDishorder;
                            }
                            if(typeof(saveOtherDishOrder) == 'function'){
                                this.state.saveTabsDataIndividually.saveOtherDishOrder = saveOtherDishOrder;
                            }
                            if(typeof(savePsychoProblem) == 'function'){
                                this.state.saveTabsDataIndividually.savePsychologicalEnvironmetalProblems = savePsychoProblem;
                            }
                            if(typeof(saveLevelOfCare) == 'function'){
                                this.state.saveTabsDataIndividually.saveLevelOfCare = saveLevelOfCare;
                            }
                            if(typeof(saveReleaseInformation) == 'function'){
                                this.state.saveTabsDataIndividually.saveReleaseOfInformation = saveReleaseInformation;
                            }
    }

    saveAndExit = (e) => {
        let showMsg = false;
        this.setState({ showLeaveModal: false });
        if(typeof(this.state.saveTabsDataIndividually.saveSubstanceUse) == 'function'){
            this.state.saveTabsDataIndividually.saveSubstanceUse(e,"saveNexit");
            showMsg = true;
        }
        if(typeof(this.state.saveTabsDataIndividually.savAcuteIntoxication) == 'function'){
            this.state.saveTabsDataIndividually.savAcuteIntoxication(e,"saveNexit");
            showMsg = true;
        }
        if(typeof(this.state.saveTabsDataIndividually.saveBiomedicalCondition) == 'function'){
            this.state.saveTabsDataIndividually.saveBiomedicalCondition(e,"saveNexit");
            showMsg = true;
        }
        if(typeof(this.state.saveTabsDataIndividually.saveEmotionalBehavioural) == 'function'){
            this.state.saveTabsDataIndividually.saveEmotionalBehavioural(e,"saveNexit");
            showMsg = true;
        }
        if(typeof(this.state.saveTabsDataIndividually.saveRedinessForChange) == 'function'){
            this.state.saveTabsDataIndividually.saveRedinessForChange(e,"saveNexit");
            showMsg = true;
        }
        if(typeof(this.state.saveTabsDataIndividually.saveProblemPotentioal) == 'function'){
            this.state.saveTabsDataIndividually.saveProblemPotentioal(e,"saveNexit");
            showMsg = true;
        }
        if(typeof(this.state.saveTabsDataIndividually.saveInvironment) == 'function'){
            this.state.saveTabsDataIndividually.saveInvironment(e,"saveNexit");
            showMsg = true;
        }
        if(typeof(this.state.saveTabsDataIndividually.saveSubstanceUseDisorder) == 'function'){
            this.state.saveTabsDataIndividually.saveSubstanceUseDisorder(e,"saveNexit");
            showMsg = true;
        }
        if(typeof(this.state.saveTabsDataIndividually.saveMentalHealthDishOrder) == 'function'){
            this.state.saveTabsDataIndividually.saveMentalHealthDishOrder(e,"saveNexit");
            showMsg = true;
        }
        if(typeof(this.state.saveTabsDataIndividually.saveOtherDishOrder) == 'function'){
            this.state.saveTabsDataIndividually.saveOtherDishOrder(e,"saveNexit");
            showMsg = true;
        }
        if(typeof(this.state.saveTabsDataIndividually.savePsychologicalEnvironmetalProblems) == 'function'){
            this.state.saveTabsDataIndividually.savePsychologicalEnvironmetalProblems(e,"saveNexit");
            showMsg = true;
        }
        if(typeof(this.state.saveTabsDataIndividually.saveLevelOfCare) == 'function'){
            this.state.saveTabsDataIndividually.saveLevelOfCare(e,"saveNexit");
            showMsg = true;
        }
        if(typeof(this.state.saveTabsDataIndividually.saveReleaseOfInformation) == 'function'){
            this.state.saveTabsDataIndividually.saveReleaseOfInformation(e,"saveNexit");
            showMsg = true;
        }
        if(showMsg){
            toast.success('Data added successfully!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: 'success_clientInformation',
              }
            );
        }
        setTimeout(() => {
            window.location.href = '/state_assessment';
        }, 3000);
    }


    isSomethingEditOrNot = (yesOrNo) => {
        this.setState({ popUpShow: yesOrNo });
    }

    handleBackButtonClick = () => {
        if(this.state.popUpShow){
            this.setState({ showLeaveModal: this.state.popUpShow });
        }else{
            window.location.href = '/state_assessment';
        }
    };

    closeAdmh = () => {
        if (!this.state.saveComplete) {
            this.setState({ showLeaveModal: true });
        } else {
            // Code to navigate away
            // Example: window.location.href = "/previous-page";
        }
    }

    saveAndComplete = async (val) => {
        if (val == "yes") {
            this.setState({ isloading: true }) 
            let acute_res = await axios.post(global.restApiURL + "customrest/saveAcuteIntoxication", JSON.stringify({ adultAssesmentData: this.state.adultAssesmentData, old_symptoms: this.state.old_symptoms, dataListBody: this.state.dataListBody }));
            let substanceUse_res = await axios.post(global.restApiURL + "customrest/savesubstances", JSON.stringify({
                substances: this.state.substances,
                substance_comments: this.state.substance_comments,
                withdrawl_symptoms: this.state.withdrawl_symptoms,
                old_symptoms: this.state.old_symptoms,
                new_symptoms: this.state.new_symptoms,
                new_symptoms_explain: this.state.new_symptoms_explain,
                Larger_amount_of_aod: this.state.Larger_amount_of_aod,
                Larger_amount_aod_explain: this.state.Larger_amount_aod_explain,
                previous_effort_to_control_aod: this.state.previous_effort_to_control_aod,
                previous_effort_to_cut_down_explain: this.state.previous_effort_to_cut_down_explain,
                dataListBody: this.state.dataListBody
            }));
            let biomedical_res = await axios.post(global.restApiURL + "customrest/savebiomedicalcondition", JSON.stringify({ BioMedicalCondition: this.state.BioMedicalCondition, dataListBody: this.state.dataListBody }));
            let payload_for_EmotionalBehaviour = {
                dataListBody: this.state.dataListBody,
                EmotionalBehaviour: this.state.EmotionalBehaviour,
                mental_health_orders: [
                    this.state.mental_health_disorder
                ],
                EmotionalMedications:this.state.EmotionalMedications,
                immediate: this.state.immediate,
                recent: this.state.recent,
                remote: this.state.remote,
                speech: this.state.speech,
                orientation: this.state.orientation,
                dress: this.state.dress,
                grooming: this.state.grooming,
                facial_exp: this.state.facial_exp,
                mood: this.state.mood,
                judgement:this.state.judgement,
                insight:this.state.insight,
                affect: this.state.affect,
                process: this.state.process,
                content: this.state.content,
            };
            let emotional_res = await axios.post(global.restApiURL + "customrest/saveEmotionalBehaviour", JSON.stringify(payload_for_EmotionalBehaviour));

            let radinessChange_res = await axios.post(global.restApiURL + "customrest/saveReadinessForChange", JSON.stringify(
                {
                    otherData: this.state.otherData,
                    questions: this.state.questions,
                    precontemplationScore: this.state.precontemplationScore,
                    contemplationScore: this.state.contemplationScore,
                    actionScore: this.state.actionScore,
                    stage_of_change_designation: this.state.stage_of_change_designation,
                    dataListBody: this.state.dataListBody
                }));
            let problemPotential_res = await axios.post(global.restApiURL + "customrest/saveproblempotential", JSON.stringify({ ProblemPotential: this.state.ProblemPotential, dataListBody: this.state.dataListBody }));
            let environment_res = await axios.post(global.restApiURL + "customrest/saveEnviornment", JSON.stringify({ Enviornment: this.state.Enviornment, dataListBody: this.state.dataListBody }));
            let levelOfCare_res = await axios.post(global.restApiURL + "customrest/saveAssessed", JSON.stringify(
                {
                    Assessed: this.state.Assessed,
                    disposition: this.state.disposition,
                    medical_provider_review: this.state.medical_provider_review,
                    assesssed_level_of_care: this.state.assesssed_level_of_care,
                    placed_level_of_care: this.state.placed_level_of_care,
                    Referred_to: this.state.Referred_to,
                    refered_for_case: this.state.refered_for_case,
                    assesed_level: this.state.assesed_level,
                    no_service_availaible1: this.state.no_service_availaible1,
                    interim_assessed_level_of_care: this.state.interim_assessed_level_of_care,
                    dataListBody: this.state.dataListBody
                }
            ));
            // Signature Data save
            const formData = new FormData();
            formData.append("assesment_staff_signature", this.state.SignatureData.assesment_staff_signature);
            formData.append("client_signature", this.state.SignatureData.client_signature);
            formData.append("physician_signature", this.state.SignatureData.physician_signature);
            formData.append("staff_signature", this.state.SignatureData.staff_signature);
            formData.append("client_name", this.state.SignatureData.client_name);
            formData.append("assesment_staff", this.state.SignatureData.assesment_staff);
            formData.append("staff_name", this.state.SignatureData.staff_name);
            formData.append("justification", this.state.SignatureData.justification);
            formData.append("physician_name", this.state.SignatureData.physician_name);
            formData.append("client_sign_uploaded_time", this.state.SignatureData.client_sign_uploaded_time);
            formData.append("client_sign_uploaded_time_Assesment_Staff", this.state.SignatureData.client_sign_uploaded_time_Assesment_Staff);
            formData.append("client_sign_uploaded_time_Physician", this.state.SignatureData.client_sign_uploaded_time_Physician);
            formData.append("client_sign_uploaded_time_Staff", this.state.SignatureData.client_sign_uploaded_time_Staff);
            formData.append("uploaded_by", sessionStorage.getItem("username"));
            formData.append("datalistBody", JSON.stringify(this.state.dataListBody));
            let signature_res = await axios.post(global.restApiURL + "customrest/saveReleaseInfo", formData);
            let substanceUseDisorder_res = await axios.post(global.restApiURL + "customrest/savesubstances", JSON.stringify({
                substances: this.state.substanceUseDisorder, dataListBody: {
                    apikey: localStorage.getItem('__TOKEN__'),
                    client_id: sessionStorage.getItem("clientId"),
                    form_type: this.props.form_type,
                    uploaded_by: sessionStorage.getItem("username"),
                    data_flag: "2",
                }
            }));

            let mentalhealthDisOrder_res = await axios.post(global.restApiURL + "customrest/saveMentalHealthOrder", JSON.stringify({ mental_health_orders: this.state.mental_health_orders, dataListBody: this.state.dataListBody }));
            let otherDisorder_res = await axios.post(global.restApiURL + "customrest/saveOtherHealthDisorder", JSON.stringify({ otherDisorder: this.state.otherDisorder, dataListBody: this.state.dataListBody }));
            let psyEnvProb_res = await axios.post(global.restApiURL + "customrest/savePsychologicalEnvironmentalProblems", JSON.stringify({ psychological_problems: this.state.psychological_problems, dataListBody: this.state.dataListBody }));
            if (acute_res.data.success && substanceUse_res && biomedical_res && emotional_res && radinessChange_res && problemPotential_res &&
                environment_res && levelOfCare_res && signature_res && substanceUseDisorder_res && mentalhealthDisOrder_res && otherDisorder_res && psyEnvProb_res
            ) {
                let final_data = { dataListBody: this.state.dataListBody }
                let res = await axios.post(global.restApiURL + "customrest/saveCompleteAssessment", JSON.stringify(final_data));
                if (res.data.success && res.status == 200) {
                    this.setState({ isloading: false })
                    this.setState({ saveComplete: true })
                    setTimeout(() => {
                        window.location.reload();
                    }, 100)
                }
            } else {
                this.setState({ isloading: false });
                toast.warning('Something going wrong... Please try again!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            // New code  For Save and Complete Start
            //  =================+++++++++============
        } else {
            this.setState({ saveComplete: false })
        }
    }

    saveAndPreview = async (val) => {
        if((this.state.Enviornment.reliable_transportation === "Yes"||this.state.Enviornment.reliable_transportation==="No") && this.state.Enviornment.reliable_transportation_explain==="" ){
            alert("Please explain field is mandatory in Environment.");
            return
        } 
        if (
            
            (this.state.adultAssesmentData.acute_risk_rating >= 2 && this.state.adultAssesmentData.acute_risk_rating_summury.length < 1) ||
            (this.state.BioMedicalCondition.biomedical_risk_rating >= 2 && this.state.BioMedicalCondition.biomedical_comments < 1) ||
            (this.state.EmotionalBehaviour.emotional_risk_rating >= 2 && this.state.EmotionalBehaviour.emotional_comments < 1) ||
            (this.state.otherData.readiness_risk_rating >= 2 && this.state.otherData.readiness_comments < 1) ||
            (this.state.otherData.readiness_mental_risk_rating >= 2 && this.state.otherData.readiness_mental_comments < 1) ||
            (this.state.ProblemPotential.relapse_risk_rating >= 2 && this.state.ProblemPotential.problem_comments2 < 1) ||
            (this.state.ProblemPotential.relapse_mental_risk_rating >= 2 && this.state.ProblemPotential.relapse_mental_comments < 1) ||
            (this.state.Enviornment.recovery_risk_rating >= 2 && this.state.Enviornment.enviornment_comments < 1) ||
            (this.state.Enviornment.recovery_mental_risk_rating >= 2 && this.state.Enviornment.recovery_mental_comments < 1)
        ) {
            alert("Risk rating summary field is mandatory.");
        }
        
        else if (val == "preview") {
            this.setState({ isloading: true }) 
            let acute_res = await axios.post(global.restApiURL + "customrest/saveAcuteIntoxication" , JSON.stringify({ adultAssesmentData: this.state.adultAssesmentData, old_symptoms: this.state.old_symptoms, dataListBody: this.state.dataListBody }));
            let substanceUse_res = await axios.post(global.restApiURL + "customrest/savesubstances", JSON.stringify({
                substances: this.state.substances,
                substance_comments: this.state.substance_comments,
                withdrawl_symptoms: this.state.withdrawl_symptoms,
                old_symptoms: this.state.old_symptoms,
                new_symptoms: this.state.new_symptoms,
                new_symptoms_explain: this.state.new_symptoms_explain,
                Larger_amount_of_aod: this.state.Larger_amount_of_aod,
                Larger_amount_aod_explain: this.state.Larger_amount_aod_explain,
                previous_effort_to_control_aod: this.state.previous_effort_to_control_aod,
                previous_effort_to_cut_down_explain: this.state.previous_effort_to_cut_down_explain,
                dataListBody: this.state.dataListBody
            }));
            let biomedical_res = await axios.post(global.restApiURL + "customrest/savebiomedicalcondition", JSON.stringify({ BioMedicalCondition: this.state.BioMedicalCondition, dataListBody: this.state.dataListBody }));
            let payload_for_EmotionalBehaviour = {
                dataListBody: this.state.dataListBody,
                EmotionalBehaviour: this.state.EmotionalBehaviour,
                mental_health_orders: [
                    this.state.mental_health_disorder
                ],
                EmotionalMedications:this.state.EmotionalMedications,
                
                immediate: this.state.immediate,
                recent: this.state.recent,
                remote: this.state.remote,
                speech: this.state.speech,
                orientation: this.state.orientation,
                dress: this.state.dress,
                grooming: this.state.grooming,
                facial_exp: this.state.facial_exp,
                mood: this.state.mood,
                judgement:this.state.judgement,
                insight:this.state.insight,
                affect: this.state.affect,
                process: this.state.process,
                content: this.state.content,
            };
            let emotional_res = await axios.post(global.restApiURL + "customrest/saveEmotionalBehaviour", JSON.stringify(payload_for_EmotionalBehaviour));

            let radinessChange_res = await axios.post(global.restApiURL + "customrest/saveReadinessForChange", JSON.stringify(
                {
                    otherData: this.state.otherData,
                    questions: this.state.questions,
                    precontemplationScore: this.state.precontemplationScore,
                    contemplationScore: this.state.contemplationScore,
                    actionScore: this.state.actionScore,
                    stage_of_change_designation: this.state.stage_of_change_designation,
                    dataListBody: this.state.dataListBody
                }));
            let problemPotential_res = await axios.post(global.restApiURL + "customrest/saveproblempotential", JSON.stringify({ ProblemPotential: this.state.ProblemPotential, dataListBody: this.state.dataListBody }));
            let environment_res = await axios.post(global.restApiURL + "customrest/saveEnviornment", JSON.stringify({ Enviornment: this.state.Enviornment, dataListBody: this.state.dataListBody }));
            let levelOfCare_res = await axios.post(global.restApiURL + "customrest/saveAssessed", JSON.stringify(
                {
                    Assessed: this.state.Assessed,
                    disposition: this.state.disposition,
                    medical_provider_review: this.state.medical_provider_review,
                    assesssed_level_of_care: this.state.assesssed_level_of_care,
                    placed_level_of_care: this.state.placed_level_of_care,
                    Referred_to: this.state.Referred_to,
                    refered_for_case: this.state.refered_for_case,
                    assesed_level: this.state.assesed_level,
                    no_service_availaible1: this.state.no_service_availaible1,
                    interim_assessed_level_of_care: this.state.interim_assessed_level_of_care,
                    dataListBody: this.state.dataListBody
                }
            ));
            // Signature Data save
            const formData = new FormData();
            formData.append("assesment_staff_signature", this.state.SignatureData.assesment_staff_signature);
            formData.append("client_signature", this.state.SignatureData.client_signature);
            formData.append("physician_signature", this.state.SignatureData.physician_signature);
            formData.append("staff_signature", this.state.SignatureData.staff_signature);
            formData.append("client_name", this.state.SignatureData.client_name);
            formData.append("assesment_staff", this.state.SignatureData.assesment_staff);
            formData.append("staff_name", this.state.SignatureData.staff_name);
            formData.append("justification", this.state.SignatureData.justification);
            formData.append("physician_name", this.state.SignatureData.physician_name);
            formData.append("client_sign_uploaded_time", this.state.SignatureData.client_sign_uploaded_time);
            formData.append("client_sign_uploaded_time_Assesment_Staff", this.state.SignatureData.client_sign_uploaded_time_Assesment_Staff);
            formData.append("client_sign_uploaded_time_Physician", this.state.SignatureData.client_sign_uploaded_time_Physician);
            formData.append("client_sign_uploaded_time_Staff", this.state.SignatureData.client_sign_uploaded_time_Staff);
            formData.append("uploaded_by", sessionStorage.getItem("username"));
            formData.append("datalistBody", JSON.stringify(this.state.dataListBody));
            let signature_res = await axios.post(global.restApiURL + "customrest/saveReleaseInfo", formData);
            let substanceUseDisorder_res = await axios.post(global.restApiURL + "customrest/savesubstances", JSON.stringify({
                substances: this.state.substanceUseDisorder, dataListBody: {
                    apikey: localStorage.getItem('__TOKEN__'),
                    client_id: sessionStorage.getItem("clientId"),
                    form_type: this.props.form_type,
                    uploaded_by: sessionStorage.getItem("username"),
                    data_flag: "2",
                }
            }));

            let mentalhealthDisOrder_res = await axios.post(global.restApiURL + "customrest/saveMentalHealthOrder", JSON.stringify({ mental_health_orders: this.state.mental_health_orders, dataListBody: this.state.dataListBody }));
            let otherDisorder_res = await axios.post(global.restApiURL + "customrest/saveOtherHealthDisorder", JSON.stringify({ otherDisorder: this.state.otherDisorder, dataListBody: this.state.dataListBody }));
            let psyEnvProb_res = await axios.post(global.restApiURL + "customrest/savePsychologicalEnvironmentalProblems", JSON.stringify({ psychological_problems: this.state.psychological_problems, dataListBody: this.state.dataListBody }));
            if (acute_res.data.success && substanceUse_res && biomedical_res && emotional_res && radinessChange_res && problemPotential_res &&
                environment_res && levelOfCare_res && signature_res && substanceUseDisorder_res && mentalhealthDisOrder_res && otherDisorder_res && psyEnvProb_res
            ) {
                // let final_data = { dataListBody: this.state.dataListBody }
                let res = await axios.post(global.restApiURL + "customrest/saveCompleteAssessment", JSON.stringify({
                    dataListBody: {
                        form_type: this.props.form_type,
                        apikey: localStorage.getItem('__TOKEN__'),
                        client_id: sessionStorage.getItem("clientId"),
                        uploaded_by: sessionStorage.getItem("username"),
                        is_draft:"1"
                    }
                }));
                
                if (res.data.success && res.status == 200) {
                    this.setState({ isloading: false })
                    this.setState({ savePreview: true })
                    setTimeout(() => {
                        window.location.reload();
                    }, 100)
                }
            } else {
                this.setState({ isloading: false });
                toast.warning('Something going wrong... Please try again!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            // New code  For Save and Complete Start
            //  =================+++++++++============
        } else {
            this.setState({ saveComplete: false })
        }
    }

    AutoSave = async (val) => {
         if (val == "preview") {
            this.setState({ isloading: true }) 
            let acute_res = await axios.post(global.restApiURL + "customrest/saveAcuteIntoxication", JSON.stringify({ adultAssesmentData: this.state.adultAssesmentData, old_symptoms: this.state.old_symptoms, dataListBody: this.state.dataListBody }));
            let substanceUse_res = await axios.post(global.restApiURL + "customrest/savesubstances", JSON.stringify({
                substances: this.state.substances,
                substance_comments: this.state.substance_comments,
                withdrawl_symptoms: this.state.withdrawl_symptoms,
                old_symptoms: this.state.old_symptoms,
                new_symptoms: this.state.new_symptoms,
                new_symptoms_explain: this.state.new_symptoms_explain,
                Larger_amount_of_aod: this.state.Larger_amount_of_aod,
                Larger_amount_aod_explain: this.state.Larger_amount_aod_explain,
                previous_effort_to_control_aod: this.state.previous_effort_to_control_aod,
                previous_effort_to_cut_down_explain: this.state.previous_effort_to_cut_down_explain,
                dataListBody: this.state.dataListBody
            }));
            let biomedical_res = await axios.post(global.restApiURL + "customrest/savebiomedicalcondition", JSON.stringify({ BioMedicalCondition: this.state.BioMedicalCondition, dataListBody: this.state.dataListBody }));
            let payload_for_EmotionalBehaviour = {
                dataListBody: this.state.dataListBody,
                EmotionalBehaviour: this.state.EmotionalBehaviour,
                mental_health_orders: [
                    this.state.mental_health_disorder
                ],
                EmotionalMedications:this.state.EmotionalMedications,
                
                immediate: this.state.immediate,
                recent: this.state.recent,
                remote: this.state.remote,
                speech: this.state.speech,
                orientation: this.state.orientation,
                dress: this.state.dress,
                grooming: this.state.grooming,
                facial_exp: this.state.facial_exp,
                mood: this.state.mood,
                judgement:this.state.judgement,
                insight:this.state.insight,
                affect: this.state.affect,
                process: this.state.process,
                content: this.state.content,
            };
            let emotional_res = await axios.post(global.restApiURL + "customrest/saveEmotionalBehaviour", JSON.stringify(payload_for_EmotionalBehaviour));

            let radinessChange_res = await axios.post(global.restApiURL + "customrest/saveReadinessForChange", JSON.stringify(
                {
                    otherData: this.state.otherData,
                    questions: this.state.questions,
                    precontemplationScore: this.state.precontemplationScore,
                    contemplationScore: this.state.contemplationScore,
                    actionScore: this.state.actionScore,
                    stage_of_change_designation: this.state.stage_of_change_designation,
                    dataListBody: this.state.dataListBody
                }));
            let problemPotential_res = await axios.post(global.restApiURL + "customrest/saveproblempotential", JSON.stringify({ ProblemPotential: this.state.ProblemPotential, dataListBody: this.state.dataListBody }));
            let environment_res = await axios.post(global.restApiURL + "customrest/saveEnviornment", JSON.stringify({ Enviornment: this.state.Enviornment, dataListBody: this.state.dataListBody }));
            let levelOfCare_res = await axios.post(global.restApiURL + "customrest/saveAssessed", JSON.stringify(
                {
                    Assessed: this.state.Assessed,
                    disposition: this.state.disposition,
                    medical_provider_review: this.state.medical_provider_review,
                    assesssed_level_of_care: this.state.assesssed_level_of_care,
                    placed_level_of_care: this.state.placed_level_of_care,
                    Referred_to: this.state.Referred_to,
                    refered_for_case: this.state.refered_for_case,
                    assesed_level: this.state.assesed_level,
                    no_service_availaible1: this.state.no_service_availaible1,
                    interim_assessed_level_of_care: this.state.interim_assessed_level_of_care,
                    dataListBody: this.state.dataListBody
                }
            ));
            // Signature Data save
            const formData = new FormData();
            formData.append("assesment_staff_signature", this.state.SignatureData.assesment_staff_signature);
            formData.append("client_signature", this.state.SignatureData.client_signature);
            formData.append("physician_signature", this.state.SignatureData.physician_signature);
            formData.append("staff_signature", this.state.SignatureData.staff_signature);
            formData.append("client_name", this.state.SignatureData.client_name);
            formData.append("assesment_staff", this.state.SignatureData.assesment_staff);
            formData.append("staff_name", this.state.SignatureData.staff_name);
            formData.append("justification", this.state.SignatureData.justification);
            formData.append("physician_name", this.state.SignatureData.physician_name);
            formData.append("client_sign_uploaded_time", this.state.SignatureData.client_sign_uploaded_time);
            formData.append("client_sign_uploaded_time_Assesment_Staff", this.state.SignatureData.client_sign_uploaded_time_Assesment_Staff);
            formData.append("client_sign_uploaded_time_Physician", this.state.SignatureData.client_sign_uploaded_time_Physician);
            formData.append("client_sign_uploaded_time_Staff", this.state.SignatureData.client_sign_uploaded_time_Staff);
            formData.append("uploaded_by", sessionStorage.getItem("username"));
            formData.append("datalistBody", JSON.stringify(this.state.dataListBody));
            let signature_res = await axios.post(global.restApiURL + "customrest/saveReleaseInfo", formData);
            let substanceUseDisorder_res = await axios.post(global.restApiURL + "customrest/savesubstances", JSON.stringify({
                substances: this.state.substanceUseDisorder, dataListBody: {
                    apikey: localStorage.getItem('__TOKEN__'),
                    client_id: sessionStorage.getItem("clientId"),
                    form_type: this.props.form_type,
                    uploaded_by: sessionStorage.getItem("username"),
                    data_flag: "2",
                }
            }));

            let mentalhealthDisOrder_res = await axios.post(global.restApiURL + "customrest/saveMentalHealthOrder", JSON.stringify({ mental_health_orders: this.state.mental_health_orders, dataListBody: this.state.dataListBody }));
            let otherDisorder_res = await axios.post(global.restApiURL + "customrest/saveOtherHealthDisorder", JSON.stringify({ otherDisorder: this.state.otherDisorder, dataListBody: this.state.dataListBody }));
            let psyEnvProb_res = await axios.post(global.restApiURL + "customrest/savePsychologicalEnvironmentalProblems", JSON.stringify({ psychological_problems: this.state.psychological_problems, dataListBody: this.state.dataListBody }));
            if (acute_res.data.success && substanceUse_res && biomedical_res && emotional_res && radinessChange_res && problemPotential_res &&
                environment_res && levelOfCare_res && signature_res && substanceUseDisorder_res && mentalhealthDisOrder_res && otherDisorder_res && psyEnvProb_res
            ) {
                // let final_data = { dataListBody: this.state.dataListBody }
                let res = await axios.post(global.restApiURL + "customrest/saveCompleteAssessment", JSON.stringify({
                    dataListBody: {
                        form_type: this.props.form_type,
                        apikey: localStorage.getItem('__TOKEN__'),
                        client_id: sessionStorage.getItem("clientId"),
                        uploaded_by: sessionStorage.getItem("username"),
                        is_draft:"1"
                    }
                }));
                
                if (res.data.success && res.status == 200) {
                    this.setState({ isloading: false })
                    this.setState({ savePreview: true })
                    setTimeout(() => {
                        window.location.href = "/session_timeout";
                    }, 400);
                }
            } else {
                this.setState({ isloading: false });
                toast.warning('Something going wrong... Please try again!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            // New code  For Save and Complete Start
            //  =================+++++++++============
        } else {
            this.setState({ saveComplete: false })
        }
    }


    // Data carry from Child component start
    datafromAcuteChild = (adultAssesment = {}, old_symptoms = []) => {
        this.setState({ adultAssesmentData: adultAssesment, old_symptoms: old_symptoms });
    }

    datafromSubstanseUseChild = (
        substances = [], comments = '', withdrawl_symptoms = ''
        , old_symptoms = [], new_symptoms = '', new_symptoms_explain = ''
        , Larger_amount_of_aod = "", Larger_amount_aod_explain = '', previous_effort_to_control_aod = ''
        , previous_effort_to_cut_down_explain = ""
    ) => {
        this.setState({
            substances: substances,
            substance_comments: comments,
            withdrawl_symptoms: withdrawl_symptoms,
            old_symptoms: old_symptoms,
            new_symptoms: new_symptoms,
            new_symptoms_explain: new_symptoms_explain,
            Larger_amount_of_aod: Larger_amount_of_aod,
            Larger_amount_aod_explain: Larger_amount_aod_explain,
            previous_effort_to_control_aod: previous_effort_to_control_aod,
            previous_effort_to_cut_down_explain: previous_effort_to_cut_down_explain,
        });

    }

    datafromBiomedicalConditionChild = (BioMedicalConditiondata = {}) => {
        this.setState({ BioMedicalCondition: BioMedicalConditiondata });
    }

    datafromEmotionalChild = (Emotionaldata = {},mental_health_disorder={},EmotionalMedications={}, mentaldisorderOptions={}, immediate = [], recent = [], remote = [], speech = [], orientation = [], dress = [], grooming = [], facial_exp = [], mood = [],affect=[], judgement=[],insight = [], process = [], content = []) => {

        this.setState(
            {
                EmotionalBehaviour: Emotionaldata,
                mental_health_disorder:mental_health_disorder,
                EmotionalMedications:EmotionalMedications,
                mentaldisorderOptions:mentaldisorderOptions,
                immediate: immediate,
                recent: recent,
                remote: remote,
                speech: speech,
                orientation: orientation,
                dress: dress,
                grooming: grooming,
                facial_exp: facial_exp,
                mood: mood,
                judgement:judgement,
                insight:insight,
                affect: affect,
                process: process,
                content: content,
            }
        )
    }

    datafromReadinessChangeChild = (radinessData = {}, questions = [], precontemplationScore, contemplationScore, actionScore, stage_of_change_designation) => {
        this.setState({ otherData: radinessData, questions: questions, precontemplationScore: precontemplationScore, contemplationScore: contemplationScore, actionScore: actionScore, stage_of_change_designation: stage_of_change_designation });
    }

    datafromProblemPotentialChild = (ProblemPotentialData = {}) => {
        this.setState({ ProblemPotential: ProblemPotentialData });
    }

    datafromRecoveryChild = (EnviornmentData = {}) => {
        this.setState({ Enviornment: EnviornmentData });
    }

    datafromLevelOfCareChild = (AssessedData = {}, dispositionData = [], medical_provider_reviewData = [],
        assesssed_level_of_careData = [], placed_level_of_care = [], Referred_to = [], refered_for_case = [], assesed_level = [], no_service_availaible1 = [], interim_assessed_level_of_care = []
    ) => {

        this.setState({
            Assessed: AssessedData,
            disposition: dispositionData,
            medical_provider_review: medical_provider_reviewData,
            assesssed_level_of_care: assesssed_level_of_careData,
            placed_level_of_care: placed_level_of_care,
            Referred_to: Referred_to,
            refered_for_case: refered_for_case,
            assesed_level: assesed_level,
            no_service_availaible1: no_service_availaible1,
            interim_assessed_level_of_care: interim_assessed_level_of_care,
        });
    }

    dataSignatureChild = (SignatureData = {}) => {
        this.setState({ SignatureData: SignatureData });
    }

    dataFromSubstanceUseDisorderGrandChild = (substanceUseDisorderData = [], index = "0", name = "", value = "", isNewBlockAdd = false) => {
        if (name && value && index) {
            substanceUseDisorderData[index].name = value
            this.setState({ substanceUseDisorder: substanceUseDisorderData })
        } else {
            this.setState({ substanceUseDisorder: substanceUseDisorderData })
        }
    }

    dataFromMentalHealthDisorderGrandChild = (mental_health_ordersData = []) => {
        this.setState({ mental_health_orders: mental_health_ordersData });
    }

    dataFromOtherDisorderGrandChild = (otherDisorderData = []) => {
        this.setState({ otherDisorder: otherDisorderData });
    }

    dataFromPsychologicalEnviornmentalProblemsrGrandChild = (psychological_problemsData = []) => {
        this.setState({ psychological_problems: psychological_problemsData });
    }

    onTabClick = () => {
        this.decisionWithValue("");
        this.decisionWithValue2("");
        this.decisionWithComment("");
        this.decisionWithComment2("");
    }

    decisionWithValue =  (riskValue) => {
        this.setState({ RiskRatingValue: riskValue });
    }

    decisionWithValue2 = (riskValue2) => {
        this.setState({ RiskRatingValue2: riskValue2 });
    }

    decisionWithComment = (riskComment) => {
        this.setState({ RiskRatingComment: riskComment });
    }

    decisionWithComment2 = (riskComment2) => {
        this.setState({ RiskRatingComment2: riskComment2 });
    }

    handleOnclickOnSaveAndCompleteButton = () => {
        if((this.state.Enviornment.reliable_transportation === "Yes"||this.state.Enviornment.reliable_transportation==="No") && this.state.Enviornment.reliable_transportation_explain==="" ){
            alert("Please explain field is mandatory in Environment.");
        } 
        else if (
            (this.state.adultAssesmentData.acute_risk_rating >= 2 && this.state.adultAssesmentData.acute_risk_rating_summury.length < 1) ||
            (this.state.BioMedicalCondition.biomedical_risk_rating >= 2 && this.state.BioMedicalCondition.biomedical_comments < 1) ||
            (this.state.EmotionalBehaviour.emotional_risk_rating >= 2 && this.state.EmotionalBehaviour.emotional_comments < 1) ||
            (this.state.otherData.readiness_risk_rating >= 2 && this.state.otherData.readiness_comments < 1) ||
            (this.state.otherData.readiness_mental_risk_rating >= 2 && this.state.otherData.readiness_mental_comments < 1) ||
            (this.state.ProblemPotential.relapse_risk_rating >= 2 && this.state.ProblemPotential.problem_comments2 < 1) ||
            (this.state.ProblemPotential.relapse_mental_risk_rating >= 2 && this.state.ProblemPotential.relapse_mental_comments < 1) ||
            (this.state.Enviornment.recovery_risk_rating >= 2 && this.state.Enviornment.enviornment_comments < 1) ||
            (this.state.Enviornment.recovery_mental_risk_rating >= 2 && this.state.Enviornment.recovery_mental_comments < 1)
        ) {
            alert("Risk rating summary field is mandatory.");
        }
    
        else {
            if (
                ((this.state.form_type == "Adult_Assessment" && sessionStorage.getItem("uncopeScore") < 2) ||
                    (this.state.form_type == "Adolescent_Assessment" && sessionStorage.getItem("craftScore") < 2)
                ) && !this.state.SignatureData.justification
            ) {
                this.setState({ saveComplete: false }, () => {
                    alert("The client's questionnaire score was below 2 out of 6. Please provide a justification for performing the state Assessment.", this.state.SignatureData.justification)
                    this.setState({ justificationError: "The client's questionnaire score was below 2 out of 6. Please provide a justification for performing the state Assessment." })
                })
            } else {
                this.setState({ saveComplete: true })
            }
        }
    }

    AutoSaveHandler = (action) => {
        this.AutoSave(action);
    }


    componentDidMount() {
        window.Admh = {
          AutoSave: this.AutoSaveHandler
        };
    }

    handleGoToSupport = () => {
        // Navigate to the route '/requestsupport'
        window.location.href="/RequestSupport"
    }

      

    render() {
        return (
            <div>
                <section className='content'>
                    <div className='container-fluid'>
                        <div className="row border-bottom mb-3" >
                            <div className="col-12 mt-2">
                                <h5>
                                    <i class="fa fa-arrow-left" onClick={this.handleBackButtonClick}></i>
                                    {this.props.form_type == "Adult_Assessment" ? "State Assessment (Adult)" : "State Assessment (Adolescent)"}

                                </h5>
                                <div className="row invoice-info">
                                    <div className="col-sm-2 invoice-col">
                                        <address>
                                            Client Name: {encryptStorage.getItem('client_first_name') + " " + encryptStorage.getItem('client_middle_initial') + " " + encryptStorage.getItem('client_last_name')}
                                        </address>
                                    </div>
                                    <div className="col-sm-2 invoice-col">
                                        <address>
                                            Client ID: {sessionStorage.getItem('clientId')}
                                        </address>
                                    </div>
                                    <div className="col-sm-2 invoice-col">
                                        <address>
                                            Interviewer: {encryptStorage.getItem('last_updated_by')}
                                        </address>
                                    </div>
                                    <div className="col-sm-2 invoice-col">
                                        <address>
                                            Date: {encryptStorage.getItem('intake_date')}<br />
                                        </address>
                                    </div>
                                    <div className='col-sm-12' style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                        <button className='btn btn-primary mr-1' disabled={this.props.saveComplete || this.state.isloading} onClick={() => this.saveAndPreview('preview')}>Save and Preview</button>
                                        <button className='btn btn-primary mr-1' disabled={this.props.saveComplete || this.state.isloading} onClick={this.handleOnclickOnSaveAndCompleteButton}>Save and Complete</button>
                                        <a className='btn btn-primary mr-1' href="/programTypeDetail.pdf" target="_blank" rel="noopener noreferrer" data-title="Program Type Detail">Program Type Detail</a>
                                        <button className='btn btn-primary mr-1'  onClick={this.handleGoToSupport}>Get Support</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="row marginBottom1">
                    <div className='col-md-7'>
                        <h5 class="head-text mb-3">Step 1: Assessment</h5>
                        <div className="card card-primary card-tabs">
                            <div classNameName="card-header p-0 pt-3 w-100 card-wrapper">
                                <div className='wrapper'>
                                    <ul className="nav nav-tabs list" id="custom-tabs-one-tab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" id="custom-tabs-one-profile-tab" data-toggle="pill" onClick={this.onTabClick} href="#custom-tabs-one-substance-used" role="tab" aria-controls="custom-tabs-one-substance-used" aria-selected="false">Substance use</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link " id="custom-tabs-one-home-tab" data-toggle="pill" onClick={this.onTabClick} href="#custom-tabs-one-home" role="tab" aria-controls="custom-tabs-one-home" aria-selected="true">Acute intoxication</a>
                                        </li>

                                        <li className="nav-item">
                                            <a className="nav-link" id="custom-tabs-one-profile-tab" data-toggle="pill" onClick={this.onTabClick} href="#custom-tabs-one-profile" role="tab" aria-controls="custom-tabs-one-profile" aria-selected="false">Biomedical condition</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="custom-tabs-one-messages-tab" data-toggle="pill" onClick={this.onTabClick} href="#custom-tabs-one-messages" role="tab" aria-controls="custom-tabs-one-messages" aria-selected="false">Emotional-behavioral </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="custom-tabs-one-settings-tab" data-toggle="pill" onClick={this.onTabClick} href="#custom-tabs-one-settings" role="tab" aria-controls="custom-tabs-one-settings" aria-selected="false">Readiness for change</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="custom-tabs-one-settings-tab" data-toggle="pill" onClick={this.onTabClick} href="#custom-tabs-one-problem-potential" role="tab" aria-controls="custom-tabs-one-problem-potential" aria-selected="false">Problem potential</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="custom-tabs-one-settings-tab" data-toggle="pill" onClick={this.onTabClick} href="#custom-tabs-one-recovery-enviornment" role="tab" aria-controls="custom-tabs-one-recovery-enviornment" aria-selected="false">Environment</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-body" id="downlodId">

                                <div className="tab-content" id="custom-tabs-one-tabContent">
                                    <div className="tab-pane fade show active" id="custom-tabs-one-substance-used" role="tabpanel" aria-labelledby="custom-tabs-one-substance-used-tab">
                                        <SubstanceUseBackground
                                            childToParent={this.datafromSubstanseUseChild}
                                            tabEditOrNot = {this.isSomethingEditOrNot}
                                            saveAndExit = {this.saveBeforeExit}
                                            DataManageforSubDisrder={this.dataFromSubstanceUseDisorderGrandChild}
                                            isSaveAndComplete={this.state.isSaveAndComplete}
                                            route_of_admin=""
                                            form_type={this.props.form_type}
                                            frequency_of_use=""
                                            specificSubstanceOptions=""
                                            classSubstanceOptions=""
                                            problemTypeOptions=""
                                        />
                                    </div>
                                    <div className="tab-pane fade  " id="custom-tabs-one-home" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
                                        <AdultPlacementAssesment
                                            childToParent={this.datafromAcuteChild}
                                            riskRatingDecisionWithValue={this.decisionWithValue}
                                            tabEditOrNot = {this.isSomethingEditOrNot}
                                            saveAndExit = {this.saveBeforeExit}
                                            riskRatingDecisionWithComment={this.decisionWithComment}
                                            isSaveAndComplete={this.state.isSaveAndComplete}
                                            route_of_admin="" form_type={this.props.form_type}
                                        />
                                    </div>


                                    <div className="tab-pane fade" id="custom-tabs-one-profile" role="tabpanel" aria-labelledby="custom-tabs-one-profile-tab">
                                        <BioMedicalCondition
                                            childToParent={this.datafromBiomedicalConditionChild}
                                            riskRatingDecisionWithValue={this.decisionWithValue}
                                            tabEditOrNot = {this.isSomethingEditOrNot}
                                            saveAndExit = {this.saveBeforeExit}
                                            riskRatingDecisionWithComment={this.decisionWithComment}
                                            isSaveAndComplete={this.state.isSaveAndComplete}
                                            form_type={this.props.form_type}
                                        />
                                    </div>
                                    <div className="tab-pane fade" id="custom-tabs-one-messages" role="tabpanel" aria-labelledby="custom-tabs-one-messages-tab">
                                        <BehavioralComplications
                                            childToParent={this.datafromEmotionalChild}
                                            datafrommentaldisorder={this.state.mental_health_orders}
                                            riskRatingDecisionWithValue={this.decisionWithValue}
                                            riskRatingDecisionWithComment={this.decisionWithComment}
                                            tabEditOrNot = {this.isSomethingEditOrNot}
                                            saveAndExit = {this.saveBeforeExit}
                                            isSaveAndComplete={this.state.isSaveAndComplete}
                                            form_type={this.props.form_type}
                                            age={this.state.age}
                                        />
                                    </div>
                                    <div className="tab-pane fade" id="custom-tabs-one-settings" role="tabpanel" aria-labelledby="custom-tabs-one-settings-tab">
                                        <ReadinessChange
                                            childToParent={this.datafromReadinessChangeChild}
                                            riskRatingDecisionWithValue={this.decisionWithValue}
                                            riskRatingDecisionWithComment={this.decisionWithComment}
                                            riskRatingDecisionWithValue2={this.decisionWithValue2}
                                            riskRatingDecisionWithComment2={this.decisionWithComment2}
                                            tabEditOrNot = {this.isSomethingEditOrNot}
                                            saveAndExit = {this.saveBeforeExit}
                                            isSaveAndComplete={this.state.isSaveAndComplete}
                                            form_type={this.props.form_type}
                                        />
                                    </div>
                                    <div className="tab-pane fade" id="custom-tabs-one-problem-potential" role="tabpanel" aria-labelledby="custom-tabs-one-problem-potential-tab">
                                        <ProblemPotential
                                            childToParent={this.datafromProblemPotentialChild}
                                            riskRatingDecisionWithValue={this.decisionWithValue}
                                            riskRatingDecisionWithValue2={this.decisionWithValue2}
                                            riskRatingDecisionWithComment={this.decisionWithComment}
                                            riskRatingDecisionWithComment2={this.decisionWithComment2}
                                            tabEditOrNot = {this.isSomethingEditOrNot}
                                            saveAndExit = {this.saveBeforeExit}
                                            isSaveAndComplete={this.state.isSaveAndComplete}
                                            form_type={this.props.form_type}
                                        />
                                    </div>
                                    <div className="tab-pane fade" id="custom-tabs-one-recovery-enviornment" role="tabpanel" aria-labelledby="custom-tabs-one-recovery-enviornment-tab">
                                        <Recovery
                                            childToParent={this.datafromRecoveryChild}
                                            riskRatingDecisionWithValue={this.decisionWithValue}
                                            riskRatingDecisionWithValue2={this.decisionWithValue2}
                                            riskRatingDecisionWithComment={this.decisionWithComment}
                                            riskRatingDecisionWithComment2={this.decisionWithComment2}
                                            tabEditOrNot = {this.isSomethingEditOrNot}
                                            saveAndExit = {this.saveBeforeExit}
                                            isSaveAndComplete={this.state.isSaveAndComplete}
                                            age={this.state.age}
                                            form_type={this.props.form_type}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-5'>
                        <h5 class="head-text mb-3">Step 2: Diagnostic Summary</h5>
                        <Summary
                            mentalHealthDisorderData={this.state.mental_health_disorder}
                            mentaldisorderOptions={this.state.mentaldisorderOptions}
                            isSaveAndComplete={this.state.isSaveAndComplete}
                            route_of_admin={this.state.route_of_admin}
                            frequency_of_use={this.state.frequency_of_use}
                            form_type={this.props.form_type}
                            childToParent={[this.dataFromSubstanceUseDisorderGrandChild, this.dataFromMentalHealthDisorderGrandChild,
                            this.dataFromOtherDisorderGrandChild, this.dataFromPsychologicalEnviornmentalProblemsrGrandChild,
                            ]}
                            tabEditOrNot = {this.isSomethingEditOrNot}
                            saveAndExit = {this.saveBeforeExit}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <h5 class="head-text mb-3">Step 3: Assessed Level of Care</h5>
                        <Level_of_care
                            childToParent={this.datafromLevelOfCareChild}
                            tabEditOrNot = {this.isSomethingEditOrNot}
                            saveAndExit = {this.saveBeforeExit}
                            isSaveAndComplete={this.state.isSaveAndComplete}
                            form_type={this.props.form_type}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <h5 class="head-text mb-3">Step 4: Release of Information</h5>
                        <Signature
                            justificationError={this.state.justificationError}
                            childToParent={this.dataSignatureChild}
                            isSaveAndComplete={this.state.isSaveAndComplete}
                            tabEditOrNot = {this.isSomethingEditOrNot}
                            saveAndExit = {this.saveBeforeExit}
                            form_type={this.props.form_type}
                        />
                    </div>
                </div>

                {this.state.isloading ? (<Loader />) : ''}

                {this.state.updateModal_adultAssesment && this.props.form_type == "Adult_Assessment" && !sessionStorage.getItem("is_one_year_completed") &&
                    <Modal showCloseButton={false} className="modal-sm" showOverlay={true}>
                        <Modal.Header>
                            <Modal.Title>
                                {/* <h4 className="modal-title float-left text-green">{this.props.age >= 18 ? 'State Assessment' : 'State Assessment (Adolescent)'}</h4> */}
                                <h4 className="modal-title float-left text-green">{this.props.form_type >= "Adult_Assessment" ? 'State Assessment (Adult)' : 'State Assessment (Adolescent)'}</h4>
                                {/* <h4 className="modal-title float-left text-green">{this.props }</h4> */}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>This form was last completed on <b style={{ padding: "0px 2px 0px 2px", color: "#89BC3B" }}>{this.state.adult_assement_creation_date}.</b> It is due annually. Do you want to update an existing one?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            {/* <button className='btn btn-success' onClick={() => this.setState({ updateModal: false })}>Create New</button> */}
                            <button className='btn btn-default' onClick={() => this.setState({ updateModal_adultAssesment: false })}>Update existing</button>
                        </Modal.Footer>
                    </Modal>}

                {this.state.updateModal_adolecentAssesment && this.props.form_type == "Adolescent_Assessment" && !sessionStorage.getItem("is_one_year_completed") &&
                    <Modal showCloseButton={false} className="modal-sm" showOverlay={true}>
                        <Modal.Header>
                            <Modal.Title>
                                {/* <h4 className="modal-title float-left text-green">{this.props.age >= 18 ? 'State Assessment' : 'State Assessment (Adolescent)'}</h4> */}
                                <h4 className="modal-title float-left text-green">{this.props.form_type >= "Adult_Assessment" ? 'State Assessment (Adult)' : 'State Assessment (Adolescent)'}</h4>
                                {/* <h4 className="modal-title float-left text-green">{this.props }</h4> */}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>This form was last completed on <b style={{ padding: "0px 2px 0px 2px", color: "#89BC3B" }}>{this.state.adolescent_assement_creation_date}.</b> It is due annually. Do you want to update an existing one?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            {/* <button className='btn btn-success' onClick={() => this.setState({ updateModal: false })}>Create New</button> */}
                            <button className='btn btn-default' onClick={() => this.setState({ updateModal_adolecentAssesment: false })}>Update existing</button>
                        </Modal.Footer>
                    </Modal>}


                {this.state.saveComplete &&
                    <Modal showCloseButton={false} className="modal-sm" showOverlay={true}>
                        <Modal.Header>
                            <Modal.Title>
                                <h4 className="modal-title float-left text-green">Save & Complete</h4>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>By clicking Save & Complete, this form will be submitted and you will not be able to make any changes. Are you sure you want to proceed?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className='btn btn-success float-left' disabled={this.state.isloading} onClick={() => this.saveAndComplete('yes')}>{this.state.isloading ? "Saving..." : "Yes"}</button>
                            <button className='btn btn-default' disabled={this.state.isloading} onClick={() => this.saveAndComplete('no')}>No</button>
                        </Modal.Footer>
                    </Modal>
                }


                {this.state.showLeaveModal && !this.state.saveComplete && (
                    <Modal showCloseButton={false} className="modal-sm" showOverlay={true}>
                        <Modal.Header>
                            <Modal.Title>
                                <h4 className="modal-title float-left text-green">Are you sure you want to leave?</h4>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>    
                            <p>Are you sure you want to leave without saving?</p>
                        </Modal.Body>
                        <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <button
                                className='btn btn-primary btn-equal-width'
                                onClick={this.saveAndExit}
                                disabled={this.props.saveComplete || this.state.isloading}
                            >
                                <i className="fas fa-save mr-1"></i>Save and Exit
                            </button>
                            <button className='btn btn-danger btn-equal-width' onClick={() => { window.location.href = '/state_assessment' }}>
                                <FontAwesomeIcon icon={faSignOutAlt} className="mr-1" />Leave without Save
                            </button>
                            <button className='btn btn-primary btn-equal-width' onClick={() => this.setState({ showLeaveModal: false })}>
                                <FontAwesomeIcon icon={faArrowDown} className="mr-1" /> Stay on Page
                            </button>
                        </Modal.Footer>
                    </Modal>
                )}

            </div>
        )
    }
}


