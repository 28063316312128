import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { CSVLink } from "react-csv";

import CoverTableHeaderContainer from "./CoverTableHeader";
import Filter from "./CoverFilter";
import { addDays } from "date-fns";

import CoverTableContainer_new from "./CoverTableContainer";
import { sampleData } from "./ClientsStackBar";
import CalendarIcon from "../../assests/images/icon-calendar.png";
import ReactDatePicker from "react-datepicker";
// import { DateRangePicker } from "react-date-range";

import { DateRangePicker } from "rsuite";
import "../daterangecss/style.css";
import "rsuite/dist/rsuite.min.css";

import moment from "moment";

import axios from "axios";
import { createHashHistory } from "history";
import { API_URL } from "../../commonFunctions/ApiUrl";

import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  ListItemText,
  OutlinedInput,
  Checkbox,
  makeStyles,
} from "@material-ui/core";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      top: 0,
      bottom: 0,
    },
  },
};

const styles = {
  width: 260,
  display: "block",
  marginBottom: 10,
  height: 50,
  borderRadius: "2px",
};

const useStyles = makeStyles({
  root: {
    border: "none",
    height: "50px",
    "&::before": {
      border: "none",
    },
    "&:hover": {
      border: "none",
    },
  },
});

const MainContainer = styled.section`
  width: 98%;
  margin: 50px 16px 0 16px;
`;

// header design

const DateContainer = styled.div`
  display: ${(props) => (props.openDateModal ? "block" : "none")};
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.span`
  flex: 0 0 15%;
  font-weight: 600;
  @media (max-width: 1370px) {
  }
`;

const FilterContainer = styled.div`
  display: flex;
  flex: 0 0 70%;
  justify-content: flex-start;
  @media (max-width: 1370px) {
    /* flex: 0 0 70%; */
  }
`;

const Button = styled.button`
  border: none;
  cursor: pointer;
  outline: none;
  background-color: #7bb731;
  color: #fff;
  border-radius: 4px;
  width: 120px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterDate = styled.div`
  width: 165px;
  border: 1px solid #cbcbcb;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  justify-content: center;
`;

const width = "220px";

// header design ends

function CoverSourcesTable1({ title, filter, filterOptions }) {
  const [date, setDate] = useState("");
  const [age, setage] = useState();
  const classes = useStyles();
  const [year, setYear] = useState([]);
  const [month, setMonth] = useState([]);
  const [client, setClient] = useState([]);

  const [getPeer_specialist, setPeer_specialist] = useState([]);
  const [getIntakeCountry, setIntakeCountry] = useState([]);
  const [get123RaceData, set123RaceData] = useState([]);

  const [isGender, setGender] = useState(false);
  const [isRace, setRace] = useState(false);

  const [openDateModal, setOpenDateModal] = useState(false);
  const wrapperRef = React.useRef(null);

  const OrgId = sessionStorage.getItem("organization_id");
  // const OrgId = 5184;
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");

  const [isYearDisable, setIsYearDisable] = useState(false);
  const [isDateRangeDisable, setIsDateRangeDisable] = useState(false);

  const arr = [];

  const options = ["2022", "2021", "2020", "2019", "2018", "2017"];

  // const optionData = sampleData[0].data.map((e) => {e.x, e.val});
  const optionData = sampleData[0].data;

  const yearhandleChange = (event) => {
    const {
      target: { value },
    } = event;
    setYear(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const monthhandleChange = (event) => {
    const {
      target: { value },
    } = event;
    setMonth(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const clienthandleChange = (event) => {
    const {
      target: { value },
    } = event;
    setClient(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(() => {
    fetchsearchClients(year, month, client, startDate, endDate);
  }, [year, month, client, startDate, endDate, isYearDisable]);

  const fetchsearchClients = (year, month, client, startDate, endDate) => {
    //  for year  //
    if (year == undefined || year == "") {
      year = "";
    } else {
      year = year.toString();
    }
    //  for Client  //
    if (client == undefined || client == "") {
      client = "all";
    } else {
      client = client.toString();
      //console.log(client);
    }

    // Peer Specialist,Intake Per County

    // for month //
    if (month == undefined || month == "") {
      month = 0;
    } else {
      if (month.length > 0) {
        month.forEach(function (item, i) {
          if (item == "January") {
            arr[i] = 1;
          }
          if (item == "Feburary") {
            arr[i] = 2;
          }
          if (item == "March") {
            arr[i] = 3;
          }
          if (item == "April") {
            arr[i] = 4;
          }
          if (item == "May") {
            arr[i] = 5;
          }
          if (item == "June") {
            arr[i] = 6;
          }
          if (item == "July") {
            arr[i] = 7;
          }
          if (item == "August") {
            arr[i] = 8;
          }
          if (item == "September") {
            arr[i] = 9;
          }
          if (item == "October") {
            arr[i] = 10;
          }
          if (item == "November") {
            arr[i] = 11;
          }
          if (item == "December") {
            arr[i] = 12;
          }
        });
      }

      month = arr.toString();
    }

    Object.values(year).length <= 0
      ? setIsDateRangeDisable(false)
      : setIsDateRangeDisable(true);

    //  ========================All========= START=============

    if (client.indexOf("all") != -1) {
      if (year == "" && startDate == "" && endDate == "") {
        console.log("Year is :" + year + "typeof year is " + typeof year);
        axios
          .get(
            API_URL +
              `client_by_peer_specialist?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=Null&startDate=2022-01-01&endDate=2022-12-31`
          )

          .then((response) => {
            setPeer_specialist(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
        // for client_by_intake_country
        axios
          .get(
            API_URL +
              `client_by_intake_country?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=Null&startDate=2022-01-01&endDate=2022-12-31`
          )

          .then((response) => {
            setIntakeCountry(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        if (year != "") {
          axios

            .get(
              API_URL +
                `client_by_peer_specialist?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${
                  isYearDisable == true ? "Null" : year
                }&startDate=Null&endDate=Null`
            )

            .then((response) => {
              setPeer_specialist(response.data.data);
            })
            .catch((error) => {
              console.log(error);
            });
          // for client_by_intake_country
          axios
            .get(
              API_URL +
                `client_by_intake_country?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${
                  isYearDisable == true ? "Null" : year
                }&startDate=Null&endDate=Null`
            )

            .then((response) => {
              setIntakeCountry(response.data.data);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          axios
            .get(
              API_URL +
                `client_by_peer_specialist?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${
                  isYearDisable == true ? "Null" : year
                }&startDate=${
                  isDateRangeDisable == true ? "Null" : startDate
                }&endDate=${isDateRangeDisable == true ? "Null" : endDate}`
            )

            .then((response) => {
              setPeer_specialist(response.data.data);
            })
            .catch((error) => {
              console.log(error);
            });
          // for client_by_intake_country
          axios
            .get(
              API_URL +
                `client_by_intake_country?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${
                  isYearDisable == true ? "Null" : year
                }&startDate=${
                  isDateRangeDisable == true ? "Null" : startDate
                }&endDate=${isDateRangeDisable == true ? "Null" : endDate}`
            )

            .then((response) => {
              setIntakeCountry(response.data.data);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    }
    //  ========================All========= END=============
    // ******************************************************
    // =====================Peer Specialist START=================
    if (client.includes("Peer Specialist") == true) {
      axios.get(
        API_URL +
          `client_by_peer_specialist?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`
      );
      // .get(API_URL + `client_by_peer_specialist?year=${year}&startDate=${startDate}&endDate=${endDate}`)
      // .then((response) => {
      //   setGender(true);
      //   setRace(false);
      //   setPeer_specialist(response.data.data);
      // })
      // .catch((error) => {
      //   console.log(error);
      // });

      if (year == "" && startDate == "" && endDate == "") {
        axios
          // .get(API_URL + `clients_by_gender?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
          .get(
            API_URL +
              `client_by_peer_specialist?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=Null&startDate=2022-01-01&endDate=2022-12-31`
          )

          .then((response) => {
            setGender(true);
            setRace(false);
            setPeer_specialist(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        if (year != "") {
          axios
            // .get(API_URL + `clients_by_gender?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
            .get(
              API_URL +
                `client_by_peer_specialist?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${
                  isYearDisable == true ? "Null" : year
                }&startDate=Null&endDate=Null`
            )

            .then((response) => {
              setGender(true);
              setRace(false);
              setPeer_specialist(response.data.data);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          axios
            // .get(API_URL + `clients_by_gender?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
            .get(
              API_URL +
                `client_by_peer_specialist?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${
                  isYearDisable == true ? "Null" : year
                }&startDate=${
                  isDateRangeDisable == true ? "Null" : startDate
                }&endDate=${isDateRangeDisable == true ? "Null" : endDate}`
            )

            .then((response) => {
              setGender(true);
              setRace(false);
              setPeer_specialist(response.data.data);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    }
    // =====================Peer Specialist END=================
    // ******************************************************
    // =====================Intake Per County START=================
    if (client.includes("Intake Per County") == true) {
      
      if (year == "" && startDate == "" && endDate == "") {
        axios
          // .get(API_URL + `clients_by_gender?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
          .get(
            API_URL +
              `client_by_intake_country?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=Null&startDate=2022-01-01&endDate=2022-12-31`
          )

          .then((response) => {
            setRace(true);
            setGender(false);

            setIntakeCountry(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        if (year != "") {
          axios
            // .get(API_URL + `clients_by_gender?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
            .get(
              API_URL +
                `client_by_intake_country?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${
                  isYearDisable == true ? "Null" : year
                }&startDate=Null&endDate=Null`
            )

            .then((response) => {
              setRace(true);
              setGender(false);

              setIntakeCountry(response.data.data);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          axios
            // .get(API_URL + `clients_by_gender?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
            .get(
              API_URL +
                `client_by_intake_country?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${
                  isYearDisable == true ? "Null" : year
                }&startDate=${
                  isDateRangeDisable == true ? "Null" : startDate
                }&endDate=${isDateRangeDisable == true ? "Null" : endDate}`
            )

            .then((response) => {
              setRace(true);
              setGender(false);

              setIntakeCountry(response.data.data);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    }
    // =====================Intake Per County END=================
    // ******************************************************
  };

  const headers = [
    { label: "Source", key: "Source" },
    { label: "Name", key: "Name" },
    { label: "January", key: "Jan" },
    { label: "Feburary", key: "Feb" },
    { label: "March", key: "March" },
    { label: "April", key: "April" },
    { label: "May", key: "May" },
    { label: "June", key: "June" },
    { label: "July", key: "July" },
    { label: "August", key: "Aug" },
    { label: "September", key: "Sep" },
    { label: "October", key: "Oct" },
    { label: "November", key: "Nov" },
    { label: "December", key: "Dec" },
    { label: "Total", key: "Total" },
  ];

  let combinedArray = [];

  combinedArray.push(getPeer_specialist, getIntakeCountry);
  const downloadArr = [];
  if (combinedArray?.length > 0) {
    for (let index = 0; index < combinedArray?.length; index++) {
      if (combinedArray[index]?.length > 0) {
        for (let i = 0; i < combinedArray[index][0].data.length; i++) {
          downloadArr.push({
            Source: combinedArray[index][0].source,
            Name: combinedArray[index][0].data[i].name,
           
            Jan:combinedArray[index][0].data[i].data[0].val == null?0:combinedArray[index][0].data[i].data[0].val,
            Feb:combinedArray[index][0].data[i].data[1].val == null?0:combinedArray[index][0].data[i].data[1].val,
            March:combinedArray[index][0].data[i].data[2].val == null?0:combinedArray[index][0].data[i].data[2].val,

            April:combinedArray[index][0].data[i].data[3].val == null?0:combinedArray[index][0].data[i].data[3].val,
            May:combinedArray[index][0].data[i].data[4].val == null?0:combinedArray[index][0].data[i].data[4].val,
            June:combinedArray[index][0].data[i].data[5].val == null?0:combinedArray[index][0].data[i].data[5].val,
            July:combinedArray[index][0].data[i].data[6].val == null?0:combinedArray[index][0].data[i].data[6].val,
            Aug:combinedArray[index][0].data[i].data[7].val == null?0:combinedArray[index][0].data[i].data[7].val,
            Sep:combinedArray[index][0].data[i].data[8].val == null?0:combinedArray[index][0].data[i].data[8].val,
            Oct:combinedArray[index][0].data[i].data[9].val == null?0:combinedArray[index][0].data[i].data[9].val,
            Nov:combinedArray[index][0].data[i].data[10].val == null?0:combinedArray[index][0].data[i].data[10].val,
            Dec:combinedArray[index][0].data[i].data[11].val == null?0:combinedArray[index][0].data[i].data[11].val,
           
          
            Total: parseInt(combinedArray[index][0].data[i].total),
          
          });
          
        }
      }
    }
  }

  return (
    <MainContainer>
      <HeaderContainer>
        <Title>{title}</Title>
        <FilterContainer>
          {/******************************************* for Multi year *********************  */}
          <div
            style={{ height: "50px" }}
            selected={age}
            value={age}
            placeholder="Show Data for Year 2022"
            width="220px"
          >
            <FormControl
              disabled={isYearDisable}
              sx={{
                m: 1,
                width: 150,
              }}
              style={{
                width: width,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "50px",
                border: "none",
                margin: "0px 10px 0px 10px",
              }}
            >
              <InputLabel
                id="demo-multiple-checkbox-label"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",

                  paddingTop: "18px",
                  transform: "none",
                  transition: "none",
                }}
              >
                {year.length !== 0 ? "" : "Show Data for Year 2022"}
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={year}
                onChange={yearhandleChange}
                input={<OutlinedInput label="" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                className={classes.root}
                style={{ width: width }}
              >
                {options.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox
                      // color="#7BB731"
                      style={{ color: "#7BB731" }}
                      checked={year.indexOf(name) > -1}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {/* year multiselect end */}

          {/* <Filter
          selected={age}
          value={age}
          placeholder="Show Data for Year 2021"
          width="220px"
          
          options={[
            "2021",
            "2020",
            "2019",
            "2018",
            "2017",
          ]}
          
        />  */}
          {/******************************************* for Multi year end *********************  */}

          {/********************************************for Multi Month ************************** */}
          {/* <div
            style={{ height: "50px" }}
            selected={month}
            placeholder="All Months"
            width="150px"
          >
            <FormControl
              sx={{
                m: 1,
                width: 150,
              }}
              style={{
                width: width,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "50px",
                border: "none",
                margin: "0px 10px 0px 10px",
              }}
            >
              <InputLabel
                id="demo-multiple-checkbox-label"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",

                  paddingTop: "18px",
                  transform: "none",
                  transition: "none",
                }}
              >
                {month.length !== 0 ? "" : "All Months"}
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={month}
                onChange={monthhandleChange}
                input={<OutlinedInput label="" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                className={classes.root}
                style={{ width: width }}
              >
                {optionData.map((name) => (
                  <MenuItem key={name.x} value={name.x}>
                    <Checkbox
                      // color="#7BB731"

                      style={{ color: "#7BB731" }}
                      checked={month.indexOf(name.x) > -1}
                    />
                    <ListItemText primary={name.x} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div> */}

          {/********************************************for Multi Month ends ************************** */}

          {/******************************************* for Client *********************  */}

          <div style={{ height: "50px" }}>
            <FormControl
              sx={{
                m: 1,
                width: 150,
              }}
              style={{
                width: width,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "50px",
                border: "none",
                margin: "0px 10px 0px 10px",
              }}
            >
              <InputLabel
                id="demo-multiple-checkbox-label"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",

                  paddingTop: "18px",
                  transform: "none",
                  transition: "none",
                }}
              >
                {client.length !== 0 ? "" : "All Clients"}
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={client}
                onChange={clienthandleChange}
                input={<OutlinedInput label="" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                className={classes.root}
                style={{ width: width }}
              >
                {filterOptions.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox
                      // color="#7BB731"
                      style={{ color: "#7BB731" }}
                      checked={client.indexOf(name) > -1}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {/******************************************* for Client ends  *********************  */}

          {/******************************************* for month  start *********************  */}

          {/* <Filter placeholder={filter} width="150px" options={options}  /> */}
          {/* <Filter
         selected={month}
          placeholder="All Months"
          width="150px"
          
          options={sampleData[0].data.map((e) => e.x)}
          
        /> */}

          {/* <FilterDate>
            <ReactDatePicker
              selected={date}
              placeholderText="Filter Date"
              onChange={(date) => setDate(date)}
              className="filterByDate"
            />

            <img src={CalendarIcon} alt="calendaricon" />
          </FilterDate> */}

          {/* <FilterDate>
            {" "}
            {state.map((date) => (
              <>
                <span> {date.startDate.toLocaleDateString("en-US")} </span>-{" "}
                <span style={{ marginLeft: "5px" }}>
                  {" "}
                  {date.endDate.toLocaleDateString("en-US")}{" "}
                </span>{" "}
              </>
            ))}
            <DateContainer openDateModal={openDateModal} ref={wrapperRef}>
              <DateRangePicker
                onChange={(item) => {
                  setState([item.selection]);
                  setOpenDateModal(false);
                }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={state}
                direction="horizontal"
                className="monthlyReportdaterangepicker"
              />
            </DateContainer>
            <img
              style={{paddingLeft: "5px"}}
              src={CalendarIcon}
              alt="calendaricon"
              onClick={() => setOpenDateModal(!openDateModal)}
            />{" "}
          </FilterDate>{" "} */}

          <DateRangePicker
            disabled={isDateRangeDisable}
            size="lg"
            placeholder="2022-01-01 ~ 2022-12-31"
            style={styles}
            onChange={(e) => {
              return e
                ? (setstartDate(moment(e[0]).format("YYYY-MM-DD")),
                  setendDate(moment(e[1]).format("YYYY-MM-DD")),
                  setIsYearDisable(true))
                : (setstartDate(""), setendDate(""), setIsYearDisable(false));
            }}
          />

          {/* <Button data={genderData[0].data}>Download CSV</Button> */}
        </FilterContainer>
        <Button>
          {" "}
          <CSVLink
            filename="Peer_Support_Specialist_report.csv"
            data={downloadArr}
            headers={headers}
            target="_blank"
            style={{
              color: "#ffffff",
              width: "120px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Download CSV{" "}
          </CSVLink>
        </Button>
      </HeaderContainer>

      {/* <CoverTableHeaderContainer
        title={title}
        filter={filter}
        options={filterOptions}
        
      />  */}
      {/* {isGender === true ? (
        <>
          <CoverTableContainer genderData={getPeer_specialist} />
        </>
      ) : (
        ""
      )} */}

      {/* {isRace === true ? (
        <>
          <CoverTableContainer genderData={getIntakeCountry} />
        </>
      ) : (
        ""
      )} */}

      {client.includes("Peer Specialist") === true || client.length === 0 ? (
        <>
          <CoverTableContainer_new genderData={getPeer_specialist} />
        </>
      ) : (
        ""
      )}

      {client.includes("Intake Per County") === true || client.length === 0 ? (
        <>
          <CoverTableContainer_new genderData={getIntakeCountry} />
        </>
      ) : (
        ""
      )}
    </MainContainer>
  );
}

export default CoverSourcesTable1;
