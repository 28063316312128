import React, { Component } from 'react'
import AdultPlacementAssesment from './adultPlacementAssesment';
import BehavioralComplications from './behavioralComplications';
import BioMedicalCondition from './bioMedicalCondition';
import Modal from './Modals/Modal'
import ProblemPotential from './problemPotential';
import ReadinessChange from './readinessChange';
import Recovery from './recovery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import Summary from './AssesmentSummary';
import SubstanceUseBackground from './SubstanceUseBackground';
import Level_of_care from './AssesmentSummary/level_of_care';
import $ from 'jquery';
import ClientInformation from './clientInformation';
import Client_characteristics from './Client_characteristics';
import Co_occuring from './Co_occuring';
import Criteria_questions from './criteria_questions';
import Dsm_diagnosis from './Dsm_diagnosis';
import Risk_ratings from './Risk_ratings';
import { Alert } from './toastify';
import services from '../services';
import { newMultiSelectOptionGenerator } from '../ReusableComponents/OptionsGenerator';
import Signature from './signature';
import axios from 'axios';
import Loader from './Loader';
import { encryptStorage } from './LocalStorageInterceptor';
import { ToastContainer, toast } from 'react-toastify';

export default class UpdateAdmh extends Component {
    constructor(props) {
        super(props);
        this.state = {
            route_of_admin: [],
            frequency_of_use: [],
            specificSubstanceOptions: [],
            classSubstanceOptions: [],
            age: this.props?.age,
            showLeft: true,
            showRight: false,
            updateModal: true,
            form_type: props.form_type,
            saveComplete: false,
            // saveComplete:props.saveComplete,

            // This is for level of care


            //ClientInformation
            ClientInformationData: {},
            primary_phone: "",
            errorMessage: "",
            ssnValidation:'',
            ssn:"",
            dobError: "",
            dobErrorAge: "",
            ClientCharacteristicsData: {},
            // CoOccurringData 
            CoOccurringData: {},

            // Substance Use
            substances: [],
            substance_comments: '',
            withdrawl_symptoms: "",
            old_symptoms: "",
            new_symptoms: "",
            new_symptoms_explain : "",//new_symptoms_explain, 
            Larger_amount_of_aod : "",//Larger_amount_of_aod, 
            Larger_amount_aod_explain :  "",//Larger_amount_aod_explain, 
            previous_effort_to_control_aod : "",// previous_effort_to_control_aod, 
            previous_effort_to_cut_down_explain : "",// previous_effort_to_cut_down_explain
            // Criteria Question
            adultAssesmentData: '',

            // Risk_ratings
            Risk_ratings: {},

            //Level_of_care
            Assessed: {},
            disposition: [],
            medical_provider_review: [],
            assesssed_level_of_care: [],
            placed_level_of_care: [],

            //Release of Information
            SignatureData: {},

            //substanceUseDisorder
            substanceUseDisorder: {},
            deletedBlock: [],

            //substanceUseDisorder
            substanceUseDisorder: {},
            deletedBlock: [],

            // DisOrder Problems
            problems: [],
            dataListBody: {
                form_type: props.form_type,
                apikey: localStorage.getItem('__TOKEN__'),
                client_id: sessionStorage.getItem("clientId"),
                uploaded_by: sessionStorage.getItem("username"),
            },
            dataListBodywithFlag: {
                form_type: props.form_type,
                apikey: localStorage.getItem('__TOKEN__'),
                client_id: sessionStorage.getItem("clientId"),
                uploaded_by: sessionStorage.getItem("username"),
                data_flag: props.form_type == "Adolescent_updateAdmh" || props.form_type == "Adult_updateAdmh" ? "1" : "2"
            },

            saveTabsDataIndividuallyUpdate: {
                clientInformation: "",
                clientCharacteristics: "",
                co_occuring: "",
                subStanceUse: "",
                criteriaQuestions: "",
                riskRating: "",
                diognosis: "",
                assessedLavelofCare: "",
                releaseOfInformation: ""
            },

            isLoaderShow: false,
            popUpShow: false
        }
    }

    async componentDidMount() {
        // let substanceUseBackground = await services.getOptionsfromClass(66);
        // let specificSubstanceOptions = [];
        // substanceUseBackground.options.specific_substance.map((option) => {
        //     specificSubstanceOptions.push({ specific_substance: option.value });
        // })
        // let classSubstanceOptions = newMultiSelectOptionGenerator(substanceUseBackground?.options?.class_of_substance);
        // let routeAdministrationOptions = newMultiSelectOptionGenerator(substanceUseBackground?.options?.route_of_administration);
        // let frequencyUseOptions = newMultiSelectOptionGenerator(substanceUseBackground?.options?.frequency_of_use);
        // this.setState({ route_of_admin: routeAdministrationOptions, frequency_of_use: frequencyUseOptions, specificSubstanceOptions: specificSubstanceOptions, classSubstanceOptions: classSubstanceOptions });
        // console.log("Props sare from update admh are : ", this.props)
    }

    arrowChange = (e) => {
        this.setState({ [e.target.dataset.param]: true, [e.target.id]: false });
    }

    isSomethingEditOrNot = (yesOrNo) => {
        this.setState({ popUpShow: yesOrNo });
    }

    // saveAndComplete = (option) => {
    //     if (option == "yes") {
    //         this.props.closeAdmh();
    //         this.props.closeSaveComplete();
    //         Alert("success", "Saved Successfully");
    //     } else if (option == "no") {
    //         this.props.closeSaveComplete()
    //     }
    // }

    closeAdmh = () => {
        // this.setState({ Adth: false, updateAdmh: false });
        window.location.reload();
    }

    // handleBackButtonClick = () => {
    //     if (this.state.popUpShow) {
    //         this.setState({ showLeaveModal: this.state.popUpShow });
    //     } else {
    //         window.location.href = '/state_assessment';
    //     }
    // };
    handleBackButtonClick = () => {
        const { primary_phone, ssn,dob } = this.state;
        if (!dob ) {
            alert("Date of Birth is mandatory in client information tab")
            this.setState({ dobError: "Date of Birth is mandatory" });
            return;
          } else {
            this.setState({ dobError: "" });
          }
      
          //Find date 14 years ago
          const currentDate = new Date();
          const fourteenYearsAgo = new Date();
          fourteenYearsAgo.setFullYear(currentDate.getFullYear() - 14);
      
          const oneHundredTwentyYearsAgo = new Date();
          oneHundredTwentyYearsAgo.setFullYear(currentDate.getFullYear() - 120);

          const dobDate = new Date(dob);
      
         // Check if the age is between 14 and 120 years
          if (dobDate > fourteenYearsAgo) {
            alert("Age should be >= 14 and <= 120 in client information tab")
            this.setState({ dobErrorAge: " Age must be at least 14 years." });
            return;
          } else if (dobDate < oneHundredTwentyYearsAgo) {
            this.setState({ dobErrorAge: "Age should be >= 14 and <= 120 " });
            return;
          } else {
            this.setState({ dobErrorAge: "" });
        }
        let formattedPrimaryPhone = primary_phone;
        let formattedssn = ssn;
        // Remove all non-numeric characters from the phone number
        const numericPrimaryPhone = primary_phone.replace(/\D/g, '');
        const numericssn = ssn.replace(/\D/g, '');
        // Format the phone number as (999) 999-9999
        if (numericPrimaryPhone.length === 10) {
            formattedPrimaryPhone = `(${numericPrimaryPhone.substring(0, 3)}) ${numericPrimaryPhone.substring(3, 6)}-${numericPrimaryPhone.substring(6)}`;
        }
        if (numericssn.length === 9) {
            formattedssn = `${ssn.substring(0, 3)}-${ssn.substring(3, 5)}-${ssn.substring(5)}`;
        }
        if ((formattedPrimaryPhone.length < 14 && formattedPrimaryPhone.length > 0) || (formattedssn.length < 11 && formattedssn.length > 0)) {
            if (formattedPrimaryPhone.length < 14 && formattedPrimaryPhone.length > 0) {
                this.setState({
                    errorMessage: "Please enter at least 10 digits."
                });
                alert("Please enter at least 10 digits.")
                return false
            }
            if (formattedssn.length < 11 && formattedssn.length > 0) {
                this.setState({
                    ssnValidation: 'Please enter ssn at least 9 digits.'
                });
                alert('Please enter ssn at least 9 digits.')
                return false
            }
        } else {
            if (this.state.popUpShow) {
                this.setState({ showLeaveModal: this.state.popUpShow });
            } else {
                window.location.href = '/state_assessment';
            }
        }
    };

    saveAndComplete = async (val) => {
        // if (this.state.primary_phone.length < 14 && this.state.primary_phone.length > 0 && val == "yes") {
        //     this.setState({ saveComplete: false }, () => {
        //         // alert("Please enter at least 10 digits.")
        //         this.setState({ errorMessage: 'Please enter at least 10 digits.' });
        //         return false;
        //     })

        // } 
        const { primary_phone, ssn,dob } = this.state;
        if (!dob && val == "yes") {
            alert("Date of Birth is mandatory in client information tab")
            this.setState({ dobError: "Date of Birth is mandatory" });
            return;
          } else {
            this.setState({ dobError: "" });
          }
      
          //Find date 14 years ago
          const currentDate = new Date();
          const fourteenYearsAgo = new Date();
          fourteenYearsAgo.setFullYear(currentDate.getFullYear() - 14);
      
          const oneHundredTwentyYearsAgo = new Date();
          oneHundredTwentyYearsAgo.setFullYear(currentDate.getFullYear() - 120);

          const dobDate = new Date(dob);
      
         // Check if the age is between 14 and 120 years
          if (dobDate > fourteenYearsAgo) {
            alert("Age should be >= 14 and <= 120 in client information tab")
            this.setState({ dobErrorAge: " Age must be at least 14 years." });
            return;
          } else if (dobDate < oneHundredTwentyYearsAgo) {
            this.setState({ dobErrorAge: "Age should be >= 14 and <= 120 " });
            return;
          } else {
            this.setState({ dobErrorAge: "" });
        }
        let formattedPrimaryPhone = primary_phone;
        let formattedssn = ssn;
        // Remove all non-numeric characters from the phone number
        const numericPrimaryPhone = primary_phone.replace(/\D/g, '');
        const numericssn = ssn.replace(/\D/g, '');
        // Format the phone number as (999) 999-9999
        if (numericPrimaryPhone.length === 10) {
            formattedPrimaryPhone = `(${numericPrimaryPhone.substring(0, 3)}) ${numericPrimaryPhone.substring(3, 6)}-${numericPrimaryPhone.substring(6)}`;
        }
        if (numericssn.length === 9) {
            formattedssn = `${ssn.substring(0, 3)}-${ssn.substring(3, 5)}-${ssn.substring(5)}`;
        }
        if ((formattedPrimaryPhone.length < 14 && formattedPrimaryPhone.length > 0)  && val == "yes" || ( formattedssn.length < 11 && formattedssn.length > 0) && val == "yes") {
            if (formattedPrimaryPhone.length < 14 && formattedPrimaryPhone.length > 0) {
              this.setState({
                errorMessage: "Please enter at least 10 digits."
              });
              alert("Please enter at least 10 digits.")
              return false
            }
            if (formattedssn.length < 11 && formattedssn.length > 0) {
              this.setState({
                ssnValidation:'Please enter ssn at least 9 digits.'
              });
              alert("Please enter ssn at least 9 digits.")
              return false
            }  
        }else {
            try {
                if (val == "yes") {
                    this.setState({ isLoaderShow: true })
                    let clientInformation_res = await axios.post(global.restApiURL + "customrest/updateClientInoforamation", JSON.stringify({ ClientInformationData: this.state.ClientInformationData, dataListBody: this.state.dataListBody }));
                    let clientCharacteristic_res = await axios.post(global.restApiURL + "customrest/updateClientCharacterstics", JSON.stringify({ ClientCharacteristicsData: this.state.ClientCharacteristicsData, dataListBody: this.state.dataListBody }));
                    let coOccouring_res = await axios.post(global.restApiURL + "customrest/updateCooccuring", JSON.stringify({ CoOccurringData: this.state.CoOccurringData, dataListBody: this.state.dataListBody }));
                    let substanceUse_res = await axios.post(global.restApiURL + "customrest/savesubstances", JSON.stringify({
                        substances: this.state.substances,
                        substance_comments: this.state.substance_comments,
                        withdrawl_symptoms: this.state.withdrawl_symptoms,
                        old_symptoms: this.state.old_symptoms,
                        new_symptoms: this.state.new_symptoms,
                        dataListBody: this.state.dataListBody
                    }));
                    let criteriaQuestion_res = await axios.post(global.restApiURL + "customrest/saveAcuteIntoxication", JSON.stringify({ adultAssesmentData: this.state.adultAssesmentData, dataListBody: this.state.dataListBody }));
                    let payload_for_riskRating = { ...this.state.Risk_ratings, ...this.state.dataListBody }
                    let riskrating_res = await axios.post(global.restApiURL + "customrest/updateRatings", JSON.stringify(payload_for_riskRating));
                    let levelOfCare_res = await axios.post(global.restApiURL + "customrest/saveAssessed", JSON.stringify(
                        {
                            Assessed: this.state.Assessed,
                            disposition: this.state.disposition,
                            medical_provider_review: this.state.medical_provider_review,
                            assesssed_level_of_care: this.state.assesssed_level_of_care,
                            placed_level_of_care: this.state.placed_level_of_care,
                            dataListBody: this.state.dataListBody
                        }
                    ));

                    let substanceUseDisorder_res = await axios.post(global.restApiURL + "customrest/savesubstances", JSON.stringify({ substances: this.state.substanceUseDisorder, deleted_items: this.state.deletedBlock, dataListBody: this.state.dataListBodywithFlag }));
                    // let disorderProblem_res = await axios.post(global.restApiURL + "customrest/savesubstances", JSON.stringify({substances:this.state.substances,substance_comments:this.state.substance_comments,dataListBody:this.state.dataListBody}));

                    //    console.log(clientInformation_res.success ,clientCharacteristic_res.success,
                    //     coOccouring_res.data.success ,substanceUse_res.data.success,
                    //     criteriaQuestion_res.data.success , riskrating_res.data.success , levelOfCare_res.data.success , substanceUseDisorder_res.data.success
                    //     )

                    // Signature Data save
                    const formData = new FormData();
                    formData.append("assesment_staff_signature", this.state.SignatureData.assesment_staff_signature);
                    formData.append("client_signature", this.state.SignatureData.client_signature);
                    formData.append("physician_signature", this.state.SignatureData.physician_signature);
                    formData.append("staff_signature", this.state.SignatureData.staff_signature);
                    formData.append("client_name", this.state.SignatureData.client_name);
                    formData.append("assesment_staff", this.state.SignatureData.assesment_staff);
                    formData.append("staff_name", this.state.SignatureData.staff_name);
                    formData.append("physician_name", this.state.SignatureData.physician_name);
                    formData.append("client_sign_uploaded_time", this.state.SignatureData.client_sign_uploaded_time);
                    formData.append("client_sign_uploaded_time_Assesment_Staff", this.state.SignatureData.client_sign_uploaded_time_Assesment_Staff);
                    formData.append("client_sign_uploaded_time_Physician", this.state.SignatureData.client_sign_uploaded_time_Physician);
                    formData.append("client_sign_uploaded_time_Staff", this.state.SignatureData.client_sign_uploaded_time_Staff);
                    formData.append("uploaded_by", sessionStorage.getItem("username"));
                    formData.append("datalistBody", JSON.stringify(this.state.dataListBody));
                    let signature_res = await axios.post(global.restApiURL + "customrest/saveReleaseInfo", formData);

                    if (clientInformation_res.data.success && clientCharacteristic_res.data.success && coOccouring_res.data.success && substanceUse_res.data.success &&
                        signature_res.data.success && criteriaQuestion_res.data.success && riskrating_res.data.success && levelOfCare_res.data.success && substanceUseDisorder_res.data.success
                    ) {
                        let final_data = { dataListBody: this.state.dataListBody }
                        let res = await axios.post(global.restApiURL + "customrest/saveAssessmentUpdateTemp", JSON.stringify(final_data));
                        if (res.data.success && res.status == 200) {
                            this.setState({ isLoaderShow: true, saveComplete: false })
                            // this.setState({ saveComplete: true })
                            setTimeout(() => {
                                window.location.reload();
                            }, 100)
                        }
                    } else {
                        // console.log("I am from else  part")
                    }

                } else {

                    // alert("Something going wrong Please try later...")
                    this.setState({ isLoaderShow: false, saveComplete: false })

                }
            } catch {
                alert("Something going wrong Please try later...")
                this.setState({ isLoaderShow: false, saveComplete: false })
            }
        }

    }

    saveAndPreview = async (val) => {
        // if (this.state.primary_phone.length < 14 && this.state.primary_phone.length > 0 && val == "preview") {
        //     this.setState({ saveComplete: false }, () => {
        //         // alert("Please enter at least 10 digits.")
        //         this.setState({ errorMessage: 'Please enter at least 10 digits.' });
        //         return false;
        //     })

        // } 
        const { primary_phone, ssn,dob } = this.state;
        if (!dob ) {
            alert("Date of Birth is mandatory in client information tab")
            this.setState({ dobError: "Date of Birth is mandatory" });
            return;
          } else {
            this.setState({ dobError: "" });
          }
      
          //Find date 14 years ago
          const currentDate = new Date();
          const fourteenYearsAgo = new Date();
          fourteenYearsAgo.setFullYear(currentDate.getFullYear() - 14);
        
          const oneHundredTwentyYearsAgo = new Date();
          oneHundredTwentyYearsAgo.setFullYear(currentDate.getFullYear() - 120);

          const dobDate = new Date(dob);
      
         // Check if the age is between 14 and 120 years
          if (dobDate > fourteenYearsAgo) {
            alert("Age should be >= 14 and <= 120 in client information tab")
            this.setState({ dobErrorAge: " Age must be at least 14 years." });
            return;
          } else if (dobDate < oneHundredTwentyYearsAgo) {
            this.setState({ dobErrorAge: "Age should be >= 14 and <= 120 " });
            return;
          } else {
            this.setState({ dobErrorAge: "" });
        }
        let formattedPrimaryPhone = primary_phone;
        let formattedssn = ssn;
        // Remove all non-numeric characters from the phone number
        const numericPrimaryPhone = primary_phone.replace(/\D/g, '');
        const numericssn = ssn.replace(/\D/g, '');
        // Format the phone number as (999) 999-9999
        if (numericPrimaryPhone.length === 10) {
            formattedPrimaryPhone = `(${numericPrimaryPhone.substring(0, 3)}) ${numericPrimaryPhone.substring(3, 6)}-${numericPrimaryPhone.substring(6)}`;
        }
        if (numericssn.length === 9) {
            formattedssn = `${ssn.substring(0, 3)}-${ssn.substring(3, 5)}-${ssn.substring(5)}`;
        }
        if ((formattedPrimaryPhone.length < 14 && formattedPrimaryPhone.length > 0)  || ( formattedssn.length < 11 && formattedssn.length > 0)) {
            if (formattedPrimaryPhone.length < 14 && formattedPrimaryPhone.length > 0) {
              this.setState({
                errorMessage: "Please enter at least 10 digits."
              });
              alert("Please enter at least 10 digits.")
              return false
            }
            if (formattedssn.length < 11 && formattedssn.length > 0) {
              this.setState({
                ssnValidation:'Please enter ssn at least 9 digits.'
              });
              alert("Please enter ssn at least 9 digits.")
              return false
            } 
        }else {
            try {
                if (val == "preview") {
                    this.setState({ isLoaderShow: true })
                    let clientCharacteristic_res = await axios.post(global.restApiURL + "customrest/updateClientCharacterstics", JSON.stringify({ ClientCharacteristicsData: this.state.ClientCharacteristicsData, dataListBody: this.state.dataListBody }));
                    let coOccouring_res = await axios.post(global.restApiURL + "customrest/updateCooccuring", JSON.stringify({ CoOccurringData: this.state.CoOccurringData, dataListBody: this.state.dataListBody }));
                    let substanceUse_res = await axios.post(global.restApiURL + "customrest/savesubstances", JSON.stringify({ substances: this.state.substances, substance_comments: this.state.substance_comments, dataListBody: this.state.dataListBody }));
                    let criteriaQuestion_res = await axios.post(global.restApiURL + "customrest/saveAcuteIntoxication", JSON.stringify({ adultAssesmentData: this.state.adultAssesmentData, dataListBody: this.state.dataListBody }));
                    let payload_for_riskRating = { ...this.state.Risk_ratings, ...this.state.dataListBody }
                    let riskrating_res = await axios.post(global.restApiURL + "customrest/updateRatings", JSON.stringify(payload_for_riskRating));
                    let levelOfCare_res = await axios.post(global.restApiURL + "customrest/saveAssessed", JSON.stringify(
                        {
                            Assessed: this.state.Assessed,
                            disposition: this.state.disposition,
                            medical_provider_review: this.state.medical_provider_review,
                            assesssed_level_of_care: this.state.assesssed_level_of_care,
                            dataListBody: this.state.dataListBody
                        }
                    ));
                    let clientInformation_res = await axios.post(global.restApiURL + "customrest/updateClientInoforamation", JSON.stringify({ ClientInformationData: this.state.ClientInformationData, dataListBody: this.state.dataListBody }));
                    let substanceUseDisorder_res = await axios.post(global.restApiURL + "customrest/savesubstances", JSON.stringify({ substances: this.state.substanceUseDisorder, deleted_items: this.state.deletedBlock, dataListBody: this.state.dataListBodywithFlag }));
                    // let disorderProblem_res = await axios.post(global.restApiURL + "customrest/savesubstances", JSON.stringify({substances:this.state.substances,substance_comments:this.state.substance_comments,dataListBody:this.state.dataListBody}));

                    //    console.log(clientInformation_res.success ,clientCharacteristic_res.success,
                    //     coOccouring_res.data.success ,substanceUse_res.data.success,
                    //     criteriaQuestion_res.data.success , riskrating_res.data.success , levelOfCare_res.data.success , substanceUseDisorder_res.data.success
                    //     )

                    // Signature Data save
                    const formData = new FormData();
                    formData.append("assesment_staff_signature", this.state.SignatureData.assesment_staff_signature);
                    formData.append("client_signature", this.state.SignatureData.client_signature);
                    formData.append("physician_signature", this.state.SignatureData.physician_signature);
                    formData.append("staff_signature", this.state.SignatureData.staff_signature);
                    formData.append("client_name", this.state.SignatureData.client_name);
                    formData.append("assesment_staff", this.state.SignatureData.assesment_staff);
                    formData.append("staff_name", this.state.SignatureData.staff_name);
                    formData.append("physician_name", this.state.SignatureData.physician_name);
                    formData.append("client_sign_uploaded_time", this.state.SignatureData.client_sign_uploaded_time);
                    formData.append("client_sign_uploaded_time_Assesment_Staff", this.state.SignatureData.client_sign_uploaded_time_Assesment_Staff);
                    formData.append("client_sign_uploaded_time_Physician", this.state.SignatureData.client_sign_uploaded_time_Physician);
                    formData.append("client_sign_uploaded_time_Staff", this.state.SignatureData.client_sign_uploaded_time_Staff);
                    formData.append("uploaded_by", sessionStorage.getItem("username"));
                    formData.append("datalistBody", JSON.stringify(this.state.dataListBody));
                    let signature_res = await axios.post(global.restApiURL + "customrest/saveReleaseInfo", formData);

                    if (clientInformation_res.data.success && clientCharacteristic_res.data.success && coOccouring_res.data.success && substanceUse_res.data.success &&
                        signature_res.data.success && criteriaQuestion_res.data.success && riskrating_res.data.success && levelOfCare_res.data.success && substanceUseDisorder_res.data.success
                    ) {
                        let final_data = { dataListBody: this.state.dataListBody }
                        let res = await axios.post(global.restApiURL + "customrest/saveAssessmentUpdateTemp", JSON.stringify({
                            dataListBody: {
                                form_type: this.props.form_type,
                                apikey: localStorage.getItem('__TOKEN__'),
                                client_id: sessionStorage.getItem("clientId"),
                                uploaded_by: sessionStorage.getItem("username"),
                                is_draft: "1"
                            }
                        }));
                        if (res.data.success && res.status == 200) {
                            this.setState({ isLoaderShow: true, saveComplete: false })
                            // this.setState({ saveComplete: true })
                            setTimeout(() => {
                                window.location.reload();
                            }, 100)
                        }
                    } else {
                        // console.log("I am from else  part")
                    }

                } else {

                    // alert("Something going wrong Please try later...")
                    this.setState({ isLoaderShow: false, saveComplete: false })

                }
            } catch {
                alert("Something going wrong Please try later...")
                this.setState({ isLoaderShow: false, saveComplete: false })
            }
        }
    }



    AutoSave = async (val) => {
        try {
            if (val == "preview") {
                this.setState({ isLoaderShow: true })
                let clientInformation_res = await axios.post(global.restApiURL + "customrest/updateClientInoforamation", JSON.stringify({ ClientInformationData: this.state.ClientInformationData, dataListBody: this.state.dataListBody }));
                let clientCharacteristic_res = await axios.post(global.restApiURL + "customrest/updateClientCharacterstics", JSON.stringify({ ClientCharacteristicsData: this.state.ClientCharacteristicsData, dataListBody: this.state.dataListBody }));
                let coOccouring_res = await axios.post(global.restApiURL + "customrest/updateCooccuring", JSON.stringify({ CoOccurringData: this.state.CoOccurringData, dataListBody: this.state.dataListBody }));
                let substanceUse_res = await axios.post(global.restApiURL + "customrest/savesubstances", JSON.stringify({ substances: this.state.substances, substance_comments: this.state.substance_comments, dataListBody: this.state.dataListBody }));
                let criteriaQuestion_res = await axios.post(global.restApiURL + "customrest/saveAcuteIntoxication", JSON.stringify({ adultAssesmentData: this.state.adultAssesmentData, dataListBody: this.state.dataListBody }));
                let payload_for_riskRating = { ...this.state.Risk_ratings, ...this.state.dataListBody }
                let riskrating_res = await axios.post(global.restApiURL + "customrest/updateRatings", JSON.stringify(payload_for_riskRating));
                let levelOfCare_res = await axios.post(global.restApiURL + "customrest/saveAssessed", JSON.stringify(
                    {
                        Assessed: this.state.Assessed,
                        disposition: this.state.disposition,
                        medical_provider_review: this.state.medical_provider_review,
                        assesssed_level_of_care: this.state.assesssed_level_of_care,
                        dataListBody: this.state.dataListBody
                    }
                ));

                let substanceUseDisorder_res = await axios.post(global.restApiURL + "customrest/savesubstances", JSON.stringify({ substances: this.state.substanceUseDisorder, deleted_items: this.state.deletedBlock, dataListBody: this.state.dataListBodywithFlag }));
                // let disorderProblem_res = await axios.post(global.restApiURL + "customrest/savesubstances", JSON.stringify({substances:this.state.substances,substance_comments:this.state.substance_comments,dataListBody:this.state.dataListBody}));

                //    console.log(clientInformation_res.success ,clientCharacteristic_res.success,
                //     coOccouring_res.data.success ,substanceUse_res.data.success,
                //     criteriaQuestion_res.data.success , riskrating_res.data.success , levelOfCare_res.data.success , substanceUseDisorder_res.data.success
                //     )

                // Signature Data save
                const formData = new FormData();
                formData.append("assesment_staff_signature", this.state.SignatureData.assesment_staff_signature);
                formData.append("client_signature", this.state.SignatureData.client_signature);
                formData.append("physician_signature", this.state.SignatureData.physician_signature);
                formData.append("staff_signature", this.state.SignatureData.staff_signature);
                formData.append("client_name", this.state.SignatureData.client_name);
                formData.append("assesment_staff", this.state.SignatureData.assesment_staff);
                formData.append("staff_name", this.state.SignatureData.staff_name);
                formData.append("physician_name", this.state.SignatureData.physician_name);
                formData.append("client_sign_uploaded_time", this.state.SignatureData.client_sign_uploaded_time);
                formData.append("client_sign_uploaded_time_Assesment_Staff", this.state.SignatureData.client_sign_uploaded_time_Assesment_Staff);
                formData.append("client_sign_uploaded_time_Physician", this.state.SignatureData.client_sign_uploaded_time_Physician);
                formData.append("client_sign_uploaded_time_Staff", this.state.SignatureData.client_sign_uploaded_time_Staff);
                formData.append("uploaded_by", sessionStorage.getItem("username"));
                formData.append("datalistBody", JSON.stringify(this.state.dataListBody));
                let signature_res = await axios.post(global.restApiURL + "customrest/saveReleaseInfo", formData);

                if (clientInformation_res.data.success && clientCharacteristic_res.data.success && coOccouring_res.data.success && substanceUse_res.data.success &&
                    signature_res.data.success && criteriaQuestion_res.data.success && riskrating_res.data.success && levelOfCare_res.data.success && substanceUseDisorder_res.data.success
                ) {
                    let final_data = { dataListBody: this.state.dataListBody }
                    let res = await axios.post(global.restApiURL + "customrest/saveAssessmentUpdateTemp", JSON.stringify({
                        dataListBody: {
                            form_type: this.props.form_type,
                            apikey: localStorage.getItem('__TOKEN__'),
                            client_id: sessionStorage.getItem("clientId"),
                            uploaded_by: sessionStorage.getItem("username"),
                            is_draft: "1"
                        }
                    }));
                    if (res.data.success && res.status == 200) {
                        this.setState({ isLoaderShow: true, saveComplete: false })
                        setTimeout(() => {
                            window.location.href = "/session_timeout";
                        }, 400);
                    }
                } else {
                    // console.log("I am from else  part")
                }

            } else {

                // alert("Something going wrong Please try later...")
                this.setState({ isLoaderShow: false, saveComplete: false })

            }
        } catch {
            alert("Something going wrong Please try later...")
            this.setState({ isLoaderShow: false, saveComplete: false })
        }

    }

    datafromClientInformationChild = (ClientInformation = {}) => {
        if (this.state.primary_phone !== "") {
            this.setState({ errorMessage: '' });
        }
        if(this.state.ssn !==""){
            this.setState({
                ssnValidation: ''
            });
        }
        if (this.state.dob !== "") {
            this.setState({
                dobError: '',
                dobErrorAge:'',
            });
        }
        let Assessed = this.state.Assessed;
        Assessed.client_seeking_treatment = ClientInformation.client_seeking_treatment;
        this.setState({ ClientInformationData: ClientInformation, medical_provider_review: ClientInformation.medical_provider_review, assesssed_level_of_care: ClientInformation.assesssed_level_of_care, Assessed: Assessed, primary_phone: ClientInformation.primary_phone ,ssn:ClientInformation.ssn,dob:ClientInformation.dob });
    }

    datafromClientCheracteristicChild = (ClientCharacteristics = {}) => {
        this.setState({ ClientCharacteristicsData: ClientCharacteristics });
    }

    datafromCoOccouringChild = (CoOccurring = {}) => {
        let Assessed = this.state.Assessed;
        Assessed.co_occuring_disorder_screen = CoOccurring.co_occuring_disorder_screen;
        Assessed.co_occuring_disorder_assesment = CoOccurring.co_occuring_disorder_assesment;
        this.setState({ CoOccurringData: CoOccurring, Assessed: Assessed });
    }

    datafromSubstanseUseChild = (substances = [], comments = '', withdrawl_symptoms = "", old_symptoms = "", new_symptoms = "", new_symptoms_explain = "", Larger_amount_of_aod = "", Larger_amount_aod_explain = "", previous_effort_to_control_aod = "", previous_effort_to_cut_down_explain = "") => {
        this.setState({ 
            substances: substances, 
            substance_comments: comments, 
            withdrawl_symptoms: withdrawl_symptoms, 
            old_symptoms: old_symptoms, 
            new_symptoms: new_symptoms, 
            new_symptoms_explain : new_symptoms_explain, 
            Larger_amount_of_aod : Larger_amount_of_aod, 
            Larger_amount_aod_explain : Larger_amount_aod_explain, 
            previous_effort_to_control_aod : previous_effort_to_control_aod, 
            previous_effort_to_cut_down_explain : previous_effort_to_cut_down_explain
        });
    }

    dataCriteriaQuestionsChild = (adultAssesmentData = {}) => {
        this.setState({ adultAssesmentData: adultAssesmentData });
    }

    dataRiskRatingsChild = (Risk_ratings = {}) => {
        this.setState({ Risk_ratings: Risk_ratings });
    }

    datafromLevelOfCareChild = (AssessedData = {}, dispositionData = [], medical_provider_reviewData = [], assesssed_level_of_careData = [], placed_level_of_care = []) => {
        this.setState({ Assessed: AssessedData, disposition: dispositionData, medical_provider_review: medical_provider_reviewData, assesssed_level_of_care: assesssed_level_of_careData, placed_level_of_care: placed_level_of_care, });
    }

    dataSignatureChild = (SignatureData = {}) => {
        this.setState({ SignatureData: SignatureData });
    }

    dataFromSubstanceUseDisorderGrandChild = (substanceUseDisorderData = [], index = "0", name = "", value = "", isNewBlockAdd = false) => {
        if (name && value && index) {
            substanceUseDisorderData[index].name = value
            this.setState({ substanceUseDisorder: substanceUseDisorderData }, () => {
                console.log(this.state.substanceUseDisorder)
            })
        } else {
            this.setState({ substanceUseDisorder: substanceUseDisorderData })
        }
    }

    dataDisorderProblemGrandChild = (problemsData = []) => {
        // this.setState({ problems: problemsData });
        // console.log("Problem data is : ",this.state.problems);
        // this.state.problems.push(problemsData);
        // console.log("Rank Order is : ",problemsData.rank_order)
        // setTimeout(()=>{
        //     console.log("Problem data is : ",this.state.problems);
        // },1000)
    }

    saveBeforeExit = (
        clientInformation = "",
        clientCharacteristics = "",
        co_occuring = "",
        subStanceUse = "",
        criteriaQuestions = "",
        riskRating = "",
        diognosis = "",
        assessedLavelofCare = "",
        releaseOfInformation = ""
    ) => {
        if (typeof (clientInformation) == 'function') {
            this.state.saveTabsDataIndividuallyUpdate.clientInformation = clientInformation;
        }
        if (typeof (clientCharacteristics) == 'function') {
            this.state.saveTabsDataIndividuallyUpdate.clientCharacteristics = clientCharacteristics;
        }
        if (typeof (co_occuring) == 'function') {
            this.state.saveTabsDataIndividuallyUpdate.co_occuring = co_occuring;
        }
        if (typeof (subStanceUse) == 'function') {
            this.state.saveTabsDataIndividuallyUpdate.subStanceUse = subStanceUse;
        }
        if (typeof (criteriaQuestions) == 'function') {
            this.state.saveTabsDataIndividuallyUpdate.criteriaQuestions = criteriaQuestions;
        }
        if (typeof (riskRating) == 'function') {
            this.state.saveTabsDataIndividuallyUpdate.riskRating = riskRating;
        }
        if (typeof (diognosis) == 'function') {
            this.state.saveTabsDataIndividuallyUpdate.diognosis = diognosis;
        }
        if (typeof (assessedLavelofCare) == 'function') {
            this.state.saveTabsDataIndividuallyUpdate.assessedLavelofCare = assessedLavelofCare;
        }
        if (typeof (releaseOfInformation) == 'function') {
            this.state.saveTabsDataIndividuallyUpdate.releaseOfInformation = releaseOfInformation;
        }
    }
    handleGoToSupport = () => {
        // Navigate to the route '/requestsupport'
        window.location.href = "/RequestSupport"
    }

    saveAndExit = (e) => {
        let showMsg = false;
        this.setState({ showLeaveModal: false });
        if (typeof (this.state.saveTabsDataIndividuallyUpdate.clientInformation) == 'function') {
            this.state.saveTabsDataIndividuallyUpdate.clientInformation(e, "saveNexit");
            showMsg = true;
        }
        if (typeof (this.state.saveTabsDataIndividuallyUpdate.clientCharacteristics) == 'function') {
            this.state.saveTabsDataIndividuallyUpdate.clientCharacteristics(e, "saveNexit");
            showMsg = true;
        }
        if (typeof (this.state.saveTabsDataIndividuallyUpdate.co_occuring) == 'function') {
            this.state.saveTabsDataIndividuallyUpdate.co_occuring(e, "saveNexit");
            showMsg = true;
        }
        if (typeof (this.state.saveTabsDataIndividuallyUpdate.subStanceUse) == 'function') {
            this.state.saveTabsDataIndividuallyUpdate.subStanceUse(e, "saveNexit");
            showMsg = true;
        }
        if (typeof (this.state.saveTabsDataIndividuallyUpdate.criteriaQuestions) == 'function') {
            this.state.saveTabsDataIndividuallyUpdate.criteriaQuestions(e, "saveNexit");
            showMsg = true;
        }
        if (typeof (this.state.saveTabsDataIndividuallyUpdate.riskRating) == 'function') {
            this.state.saveTabsDataIndividuallyUpdate.riskRating(e, "saveNexit");
            showMsg = true;
        }
        if (typeof (this.state.saveTabsDataIndividuallyUpdate.diognosis) == 'function') {
            this.state.saveTabsDataIndividuallyUpdate.diognosis(e, "saveNexit");
            showMsg = true;
        }
        if (typeof (this.state.saveTabsDataIndividuallyUpdate.assessedLavelofCare) == 'function') {
            this.state.saveTabsDataIndividuallyUpdate.assessedLavelofCare(e, "saveNexit");
            showMsg = true;
        }
        if (typeof (this.state.saveTabsDataIndividuallyUpdate.releaseOfInformation) == 'function') {
            this.state.saveTabsDataIndividuallyUpdate.releaseOfInformation(e, "saveNexit");
            showMsg = true;
        }
        if (showMsg) {
            toast.success('Data added successfully!', { toastId: "Data added successfully!" }, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: 'success_clientInformation',
            }
            );
        }
        setTimeout(() => {
            window.location.href = '/state_assessment';
        }, 3000);
    }




    AutoSaveHandler = (action) => {
        this.AutoSave(action);
    }


    componentDidMount() {
        window.updateAdmh = {
            AutoSave: this.AutoSaveHandler
        };
    }

    render() {
        return (
            <div>
                <section className='content'>
                    <div className='container-fluid'>
                        <div className="row border-bottom mb-3" >
                            <div className="col-12 mt-2">
                                <h5>
                                    <i class="fa fa-arrow-left" onClick={this.handleBackButtonClick}></i>
                                    {/* {this.props.form_type =="Adult_Assessment"||this.props.form_type =="Adolescent_Assessment" ? "State Assessment (Adolescent)" : "State Assessment (Adult)" } */}
                                    {/* {this.props.form_type =="Adult_Assessment" ? "State Assessment (Adult)":this.props.form_type=="Adolescent_Assessment"?"State Assessment (Adolescent)":"" } */}
                                    {this.props.form_type == "Adult_updateAdmh" ? "Assessment Update (Adult)" : "Assessment Update (Adolescent)"}

                                </h5>
                                <div className="row invoice-info">
                                    <div className="col-sm-2 invoice-col">
                                        <address>
                                            Client Name: {encryptStorage.getItem('client_first_name') + " " + encryptStorage.getItem('client_middle_initial') + " " + encryptStorage.getItem('client_last_name')}
                                        </address>
                                    </div>
                                    <div className="col-sm-2 invoice-col">
                                        <address>
                                            Client ID: {sessionStorage.getItem('clientId')}
                                        </address>
                                    </div>
                                    <div className="col-sm-2 invoice-col">
                                        <address>
                                            Interviewer: {encryptStorage.getItem('last_updated_by')}
                                        </address>
                                    </div>
                                    <div className="col-sm-2 invoice-col">
                                        <address>
                                            Date: {encryptStorage.getItem('intake_date')}<br />
                                        </address>
                                    </div>
                                    <div className='col-sm-12' style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                        <button className='btn btn-primary mr-1' onClick={() => this.saveAndPreview('preview')}>Save and Preview</button>
                                        <button className='btn btn-primary mr-1' onClick={() => this.setState({ saveComplete: true })}>Save and Complete</button>
                                        <a className='btn btn-primary mr-1' href="/programTypeDetail.pdf" target="_blank" data-title="Program Type Detail">Program Type Detail</a>
                                        <button className='btn btn-primary mr-1' onClick={this.handleGoToSupport}>Get Support</button>
                                    </div>

                                    {/* <div className='col-sm-4'>
                                        <button type="submit" className="btn btn-primary dropdown-toggle float-sm-right btnmargin" data-toggle="dropdown" aria-expanded="false" value="all" lang="all" ><i className="fas fa-print" aria-hidden="true"></i> Print</button>
                                        <div className="dropdown-menu " x-placement="bottom-start" >
                                            <a className="dropdown-item" data-toggle="modal" data-target="#cssr" href="#cssr">ADMH</a>
                                            <a className="dropdown-item" data-toggle="modal" data-target="#gadscreeningtool">ASAIS Data</a>
                                            <a className="dropdown-item" data-toggle="modal" data-target="#phq9">Profile -- Discharge</a>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div class="row marginBottom1">
                    <div className='col-md-7'>
                        <h5 class="head-text mb-3">Step 1: Assessment</h5>
                        <div className="card card-primary card-tabs">
                            <div classNameName="card-header p-0 pt-3 w-100 card-wrapper">
                                {/* <div class="scroller scroller-left float-left mt-2" ><i class="fa fa-chevron-left" data-param="showLeft" id="showRight" onClick={this.arrowChange}></i></div>
                                <div class="scroller scroller-right float-right mt-2" ><i class="fa fa-chevron-right" data-param="showRight" id="showLeft" onClick={this.arrowChange}></i></div> */}
                                <div className='wrapper'>
                                    <ul className="nav nav-tabs list" id="custom-tabs-one-tab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" id="custom-tabs-one-home-tab" data-toggle="pill" href="#custom-tabs-one-home" role="tab" aria-controls="custom-tabs-one-home" aria-selected="true">Client Information</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="custom-tabs-one-profile-tab" data-toggle="pill" href="#custom-tabs-one-substance-used" role="tab" aria-controls="custom-tabs-one-substance-used" aria-selected="false">Client Characteristics</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="custom-tabs-one-profile-tab" data-toggle="pill" href="#custom-tabs-one-profile" role="tab" aria-controls="custom-tabs-one-profile" aria-selected="false">Co-Occurring</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="custom-tabs-one-messages-tab" data-toggle="pill" href="#custom-tabs-one-messages" role="tab" aria-controls="custom-tabs-one-messages" aria-selected="false">Substance Use </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="custom-tabs-one-settings-tab" data-toggle="pill" href="#custom-tabs-one-settings" role="tab" aria-controls="custom-tabs-one-settings" aria-selected="false">Criteria Questions</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="custom-tabs-one-settings-tab" data-toggle="pill" href="#custom-tabs-one-problem-potential" role="tab" aria-controls="custom-tabs-one-problem-potential" aria-selected="false">Risk Ratings</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="tab-content" id="custom-tabs-one-tabContent">
                                    <div className="tab-pane fade show active" id="custom-tabs-one-home" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
                                        <ClientInformation childToParent={this.datafromClientInformationChild} errorMessage={this.state.errorMessage} ssnValidation ={this.state.ssnValidation}  dobErrorAge={this.state.dobErrorAge} dobError={this.state.dobError}  isSaveAndComplete={false} form_type={this.props.form_type} tabEditOrNot={this.isSomethingEditOrNot} saveAndExit={this.saveBeforeExit} />
                                    </div>
                                    <div className="tab-pane fade" id="custom-tabs-one-substance-used" role="tabpanel" aria-labelledby="custom-tabs-one-substance-used-tab">
                                        <Client_characteristics childToParent={this.datafromClientCheracteristicChild} isSaveAndComplete={false} form_type={this.props.form_type} age={this.state.age} tabEditOrNot={this.isSomethingEditOrNot} saveAndExit={this.saveBeforeExit} />
                                    </div>
                                    <div className="tab-pane fade" id="custom-tabs-one-profile" role="tabpanel" aria-labelledby="custom-tabs-one-profile-tab">
                                        <Co_occuring childToParent={this.datafromCoOccouringChild} isSaveAndComplete={false} form_type={this.props.form_type} tabEditOrNot={this.isSomethingEditOrNot} saveAndExit={this.saveBeforeExit} />
                                    </div>
                                    <div className="tab-pane fade" id="custom-tabs-one-messages" role="tabpanel" aria-labelledby="custom-tabs-one-messages-tab">
                                        <SubstanceUseBackground childToParent={this.datafromSubstanseUseChild} DataManageforSubDisrder={this.dataFromSubstanceUseDisorderGrandChild} isSaveAndComplete={false}
                                            form_type={this.props.form_type} route_of_admin={this.state.route_of_admin} frequency_of_use={this.state.frequency_of_use} specificSubstanceOptions={this.state.specificSubstanceOptions} classSubstanceOptions={this.state.classSubstanceOptions}
                                            tabEditOrNot={this.isSomethingEditOrNot} saveAndExit={this.saveBeforeExit}
                                        />
                                    </div>
                                    <div className="tab-pane fade" id="custom-tabs-one-settings" role="tabpanel" aria-labelledby="custom-tabs-one-settings-tab">
                                        <Criteria_questions childToParent={this.dataCriteriaQuestionsChild} isSaveAndComplete={false} form_type={this.props.form_type} tabEditOrNot={this.isSomethingEditOrNot} saveAndExit={this.saveBeforeExit} />
                                    </div>
                                    <div className="tab-pane fade" id="custom-tabs-one-problem-potential" role="tabpanel" aria-labelledby="custom-tabs-one-problem-potential-tab">
                                        <Risk_ratings childToParent={this.dataRiskRatingsChild} isSaveAndComplete={false} form_type={this.props.form_type} tabEditOrNot={this.isSomethingEditOrNot} saveAndExit={this.saveBeforeExit} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-5'>
                        <h5 class="head-text mb-3">Step 2: Diagnostic Summary</h5>
                        <Dsm_diagnosis
                            tabEditOrNot={this.isSomethingEditOrNot}
                            saveAndExit={this.saveBeforeExit}
                            isSaveAndComplete={false}
                            route_of_admin={this.state.route_of_admin}
                            frequency_of_use={this.state.frequency_of_use}
                            form_type={this.props.form_type}
                            childToParent={[this.dataFromSubstanceUseDisorderGrandChild, this.dataDisorderProblemGrandChild]}
                        />
                        {/* <Summary route_of_admin={this.state.route_of_admin} frequency_of_use={this.state.frequency_of_use} /> */}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <h5 class="head-text mb-3">Step 3: Assessed Level of Care</h5>
                        <Level_of_care childToParent={this.datafromLevelOfCareChild} isSaveAndComplete={false} updateForm={true} form_type={this.props.form_type} tabEditOrNot={this.isSomethingEditOrNot} saveAndExit={this.saveBeforeExit} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <h5 class="head-text mb-3">Step 4: Release of Information</h5>
                        <Signature childToParent={this.dataSignatureChild} isSaveAndComplete={false} form_type={this.props.form_type} tabEditOrNot={this.isSomethingEditOrNot} saveAndExit={this.saveBeforeExit} />
                    </div>
                </div>
                {this.state.isLoaderShow ? (<Loader />) : ''}

                {this.state.saveComplete &&
                    <Modal showCloseButton={false} className="modal-sm" showOverlay={true}>
                        <Modal.Header>
                            <Modal.Title>
                                <h4 className="modal-title float-left text-green">Save & Complete</h4>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>By clicking Save & Complete, this form will be submitted and you will not be able to make any changes. Are you sure you want to proceed?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className='btn btn-success float-left' onClick={() => this.saveAndComplete('yes')}>Yes</button>
                            <button className='btn btn-default' onClick={() => this.saveAndComplete('no')}>No</button>
                        </Modal.Footer>
                    </Modal>
                }

                {this.state.showLeaveModal && !this.state.saveComplete && (
                    <Modal showCloseButton={false} className="modal-sm" showOverlay={true}>
                        <Modal.Header>
                            <Modal.Title>
                                <h4 className="modal-title float-left text-green">Are you sure you want to leave?</h4>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure you want to leave without saving?</p>
                        </Modal.Body>
                        <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <button
                                className='btn btn-primary btn-equal-width'
                                onClick={this.saveAndExit}
                            // disabled={this.props.saveComplete || this.state.isloading}
                            >
                                <i className="fas fa-save mr-1"></i>Save and Exit
                            </button>
                            <button className='btn btn-danger btn-equal-width' onClick={() => { window.location.href = '/state_assessment' }}>
                                <FontAwesomeIcon icon={faSignOutAlt} className="mr-1" />Leave without Save
                            </button>
                            <button className='btn btn-primary btn-equal-width' onClick={() => this.setState({ showLeaveModal: false })}>
                                <FontAwesomeIcon icon={faArrowDown} className="mr-1" /> Stay on Page
                            </button>
                        </Modal.Footer>
                    </Modal>
                )}

            </div>
        )
    }
}
