import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { Component } from "react";
import Modal from "../Modals/Modal";
import { type } from 'jquery';
const axios = require("axios").default;
export default class SubstanceUseDisorder extends Component {
  constructor(props) {
    super(props);
    // console.log("Props from SubstanceUseDisorder",props)
    this.state = {
      rows: [1],

      substanceUseDisorder: [
        {
          disorder: "",
          icd_10_code: "",
          specific_substance: "",
          route_of_administration: "",
          age_of_1st_use: "",
          frequency_of_use: "",
          specifier: "",
          // code add by raj start
          other_frequency_of_use: "",
          other_route_of_administration: "",
          // code add by raj end
          rank_order: "",
          uid: "",
        },
      ],
      disorderOptions: [],
      deleteSubstance: false,
      change: false,
      saved: true,
      isSaveAndComplete: false,
      deletedBlock: [],
      specific_substance_options: [],
      dataListBody: {
        apikey: localStorage.getItem('__TOKEN__'),
        client_id: sessionStorage.getItem("clientId"),
        form_type: props.form_type,
        uploaded_by: sessionStorage.getItem("username"),
        // data_flag:"1",
        data_flag: props.form_type == "Adolescent_updateAdmh" || props.form_type == "Adult_updateAdmh" ? "1" : "2"
      }
    };
    // this.dataListBody={}
  }
  addRows = (e) => {
    e.preventDefault();
    let unique_id = Math.floor(Math.random() * 10000);
    const data = {
      disorder: "",
      icd_10_code: "",
      specific_substance: "",
      route_of_administration: "",
      age_of_1st_use: "",
      frequency_of_use: "",
      specifier: "",
      rank_order: "",
      // code add by raj start
      other_frequency_of_use: '',
      other_route_of_administration: "",
      // code add by raj end
      uid: unique_id,
    };
    this.setState({
      substanceUseDisorder: [...this.state.substanceUseDisorder, data],
    });
  };
  deleteRow = (e, uid) => {
    this.state.deletedBlock.push(uid);
    let rows = this.state.substanceUseDisorder;
    rows.splice([parseInt(e.target.id)], 1);
    this.setState({ substanceUseDisorder: rows, deleteSubstance: false });

  };

  handleChange = async (e) => {
    var id = e.target.id;
    this.setState({ saved: false });
    this.props.tabEditOrNot(true);
    if (this.props.form_type == "Adult_Assessment") {
      this.props.saveAndExit("", "", "", "", "", "", "", this.handleSubmit, "", "", "", "", "");
    }
    if (this.props.form_type == "Adult_updateAdmh") {
      this.props.saveAndExit("", "", "", "", "", "", this.handleSubmit, "", "");
    }
    if (e.target.name == "disorder") {
      let substanceUseDisorder = this.state.substanceUseDisorder;
      substanceUseDisorder[id]["disorder"] = e.target.value;
      let final_data = { dataListBody: this.state.dataListBody, disorder: e.target.value }
      let get_ICD_10_code_res = await axios.post(global.restApiURL + "customrest/getDisorderIcd", JSON.stringify(final_data));
      if (get_ICD_10_code_res.status == "200" && get_ICD_10_code_res.data.success) {
        substanceUseDisorder[id]["icd_10_code"] = get_ICD_10_code_res.data.data.icd_code;
      }
      this.setState({ substanceUseDisorder: substanceUseDisorder });

    } else {
      let substanceUseDisorder = this.state.substanceUseDisorder;
      substanceUseDisorder[e.target.id][e.target.name] = e.target.value;
      this.setState({ substanceUseDisorder: substanceUseDisorder });
    }
    setTimeout(() => {
      this.props.childToParent(this.state.substanceUseDisorder, this.state.deletedBlock)
    }, 500)
  };

  deletePopUp = (e) => {
    this.setState({ deleteSubstance: true });
  };

  loadData = async (e) => {
    if (!this.props.isSaveAndComplete) {
      let final_data = { dataListBody: this.state.dataListBody }
      let res = await axios.post(
        global.restApiURL + "customrest/getSubstanceinfo",
        JSON.stringify(final_data)
      );
      if (res.status == 200 && res.data.success == true) {
        this.setState({
          substanceUseDisorder: res.data.data.substances.map((val) => {
            return {
              disorder: val.disorder,
              icd_10_code: val.icd_10_code,
              specific_substance: val.specific_substance,
              route_of_administration: val.route_of_administration,
              // code add by raj start
              other_frequency_of_use: val.other_frequency_of_use,
              other_route_of_administration: val.other_route_of_administration,
              // code add by raj end
              age_of_1st_use: val.age_of_1st_use,
              frequency_of_use: val.frequency_of_use,
              specifier: val.specifier,
              rank_order: val.rank_order,
              uid: val.uid
            }
          })
        }, () => { this.props.childToParent(this.state.substanceUseDisorder, this.state.deletedBlock) })
        this.setState({ saved: true })
        //   setTimeout(()=>{
        //     this.props.childToParent(this.state.substanceUseDisorder,this.state.deletedBlock)
        //  },500)
      }
    } else {
      if (this.props.form_type == "Adolescent_updateAdmh" || this.props.form_type == "Adult_updateAdmh") {
        let final_data = { dataListBody: this.state.dataListBody, }
        let res = await axios.post(
          global.restApiURL + "customrest/getSubstanceinfo",
          JSON.stringify(final_data)
        );
        if (res.status == 200 && res.data.success == true) {
          this.setState({
            substanceUseDisorder: res.data.data.substances.map((val) => {
              return {
                disorder: val?.disorder,
                icd_10_code: val.icd_10_code,
                specific_substance: val.specific_substance,
                route_of_administration: val.route_of_administration,
                age_of_1st_use: val.age_of_1st_use,
                frequency_of_use: val.frequency_of_use,
                specifier: val.specifier,
                rank_order: val.rank_order,
                uid: val.uid
              }
            })
          })

          this.setState({ isSaveAndComplete: true, saved: true })
        }
      } else {
        let final_data = { dataListBody: this.state.dataListBody, }
        let res = await axios.post(
          global.restApiURL + "customrest/getSubstanceInfoTemp",
          JSON.stringify(this.state.dataListBody)
        );
        if (res.status == 200 && res.data.success == true) {
          this.setState({
            substanceUseDisorder: res.data.data.substances.map((val) => {
              return {
                disorder: val?.disorder,
                icd_10_code: val.icd_10_code,
                specific_substance: val.specific_substance,
                route_of_administration: val.route_of_administration,
                age_of_1st_use: val.age_of_1st_use,
                frequency_of_use: val.frequency_of_use,
                specifier: val.specifier,
                rank_order: val.rank_order,
                uid: val.uid
              }
            })
          })

          this.setState({ isSaveAndComplete: true, saved: true })
        }
      }

    }

    // Load Disorder  Options
    let final_data = { dataListBody: this.state.dataListBody, }
    let disorder_res = await axios.post(global.restApiURL + "customrest/getSubstanceuseDisorderData", JSON.stringify(final_data))
    if (disorder_res.status == "200" && disorder_res.data.success) {
      if (disorder_res.data.data.substanceUseDisordersDrop.length > 0) {
        let tempVal = JSON.parse(disorder_res.data.data.substanceUseDisordersDrop)
        this.setState({ disorderOptions: tempVal })
      }
    }



    //  
    // Specific substance Options
    let res_specific_options = await axios.post(global.restApiURL + "customrest/getSubstanceProblemType", JSON.stringify({
      dataListBody: {
        apikey: localStorage.getItem('__TOKEN__'),
        client_id: sessionStorage.getItem("clientId"),
      },
      "problem_type": "",
    }));
    if (res_specific_options.status == 200 && res_specific_options.data.success == true) {
      this.setState({ specific_substance_options: res_specific_options.data.data.problem_type.substance });
    }
  }


  handleSubmit = async (e, shouldToasterShow) => {
    e.preventDefault();
    this.props.tabEditOrNot(false);
    let final_data = {
      substances: this.state.substanceUseDisorder,
      dataListBody: this.state.dataListBody,
      deleted_items: { uid: this.state.deletedBlock },
    };
    let res = await axios.post(global.restApiURL + "customRest/savesubstances", JSON.stringify(final_data));
    if (res.data.success == true && shouldToasterShow == undefined) {
      this.loadData();
      this.setState({ deletedBlock: [], saved: true });
      toast.success('Data added successfully!', { toastId: ('Data added successfully!') }, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (res.data.success == true && shouldToasterShow == 'saveNexit') {
      // msg should be show on parent componnt;
    }
  };

  // +++++++++++++++++++++--COMPONENT--DID--MOUNT--START--+++++++++++++++++++++++++
  componentDidMount() {
    this.loadData();
  }

  // +++++++++++++++++++++--COMPONENT--DID--MOUNT--END--+++++++++++++++++++++++++++

  render() {
    const tableRows = this.state.substanceUseDisorder.map((row, index) => (


      <div className="card ml-4 mr-4 md-4">

        <div className="card-body">
          <form>
            {index != 0 && (
              <>
                <div className="row">
                  <div className="col-md-12">
                    {/* <i
                      className="fa fa-trash float-right"
                      id={index}
                      onClick={
                        (e) => this.state.change ? this.deletePopUp : this.deleteRow(e, row.uid)
                      }
                      disabled={this.state.isSaveAndComplete}
                    ></i> */}
                  </div>
                </div>
                {this.state.deleteSubstance && (
                  <Modal
                    showCloseButton={false}
                    className="modal-sm"
                    showOverlay={true}
                  >
                    <Modal.Header>
                      <Modal.Title>
                        <h4 className="modal-title float-left text-green">
                          Delete Substance
                        </h4>
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>Are you sure you want to delete this?</p>
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        className="btn btn-success float-left"
                        id={index}
                        onClick={this.deleteRow}
                        disabled={this.state.isSaveAndComplete}
                      >
                        Yes
                      </button>
                      <button
                        className="btn btn-default"
                        onClick={() =>
                          this.setState({ deleteSubstance: false })
                        }
                      >
                        No
                      </button>
                    </Modal.Footer>
                  </Modal>
                )}
              </>
            )}
            <div className="row marginBottom1">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Disorder</label>
                  <select className="form-control" id={index} name="disorder" onChange={this.handleChange} value={row.disorder} disabled={this.state.isSaveAndComplete} >
                    <option value="">Select</option>
                    {this.state.disorderOptions.map((val) => {
                      return <option value={val.value}>{val.label}</option>
                    })}
                  </select>

                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>ICD 10 Code</label>
                  <input
                    type="text"
                    className="form-control"
                    name="icd_10_code"
                    id={index}
                    onChange={this.handleChange}
                    value={row.icd_10_code}
                    disabled={this.state.isSaveAndComplete}
                  // disabled={true}

                  />
                </div>
              </div>
              {this.props.updateForm != true && (
                <>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Specific Substance</label>
                      {/* <input
                        type="text"
                        className="form-control"
                        name="specific_substance"
                        id={index}
                        onChange={this.handleChange}
                        value={row.specific_substance}
                        disabled={this.state.isSaveAndComplete}
                      /> */}

                      <select className="form-control" id={index} name="specific_substance" onChange={this.handleChange} value={row.specific_substance} disabled >
                        <option value="">Select</option>
                        {this.state.specific_substance_options.map((val) => {
                          return <option value={val.value}>{val.label}</option>
                        })}
                      </select>

                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Route of Administration</label>
                      <select
                        name="route_of_administration"
                        className="form-control"
                        id={index}
                        onChange={this.handleChange}
                        value={row.route_of_administration}
                        disabled={this.state.isSaveAndComplete}
                      >
                        <option value="">Select</option>
                        <option value="oral">Oral</option>
                        <option value="smoking">Smoking</option>
                        <option value="inhalation">Inhalation/Nasal</option>
                        <option value="injection">Injection/IV</option>
                        <option value="injection_intramuscular">Injection/Intramuscular</option>
                        <option value="rectal">Rectal</option>
                        <option value="vaginal">Vaginal</option>
                        <option value="other_specify">Other (Specify)</option>
                      </select>
                    </div>
                  </div>
                  {
                    row.route_of_administration == "other_specify" && <>
                      <div className='col-lg-6 col-md-6'>
                        <div className='form-group'>
                          <label>Please Specify</label>
                          <input type='text' disabled={this.state.isSaveAndComplete} name="other_route_of_administration" value={row.other_route_of_administration} id={index} onChange={this.handleChange} className='form-control' />
                        </div>
                      </div>
                    </>
                  }
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Age of 1st Use</label>
                      <input
                        type="text"
                        className="form-control"
                        name="age_of_1st_use"
                        id={index}
                        onChange={this.handleChange}
                        value={row.age_of_1st_use}
                        disabled={this.state.isSaveAndComplete}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Frequency</label>
                      <select
                        name="frequency_of_use"
                        className="form-control"
                        id={index}
                        onChange={this.handleChange}
                        value={row.frequency_of_use}
                        disabled={this.state.isSaveAndComplete}
                      >
                        <option value="">Select</option>
                        {/* {this.props.frequency_of_use.map((frequency) => (
                          <option value={frequency.label}>
                            {frequency.value}
                          </option>
                        ))} */}
                        <option value="no_use_in_the_past_month ">
                          No use in the past month{" "}
                        </option>
                        <option value="1_to_3_times_in_past_month ">
                          1 to 3 times in past month{" "}
                        </option>
                        <option value="1_to_2_times_in_the_past_week">
                          1 to 2 times in the past week
                        </option>
                        <option value="3_to_6_times_in_the_past_week">
                          3 to 6 times in the past week
                        </option>
                        <option value="daily">Daily</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                  </div>
                  {
                    row.frequency_of_use == "other" && <>
                      <div className='col-lg-6 col-md-6'>
                        <div className='form-group'>
                          <label>Please Specify</label>
                          <input type='text' disabled={this.state.isSaveAndComplete} name="other_frequency_of_use" value={row.other_frequency_of_use} id={index} onChange={this.handleChange} className='form-control' />
                        </div>
                      </div>
                    </>
                  }
                </>
              )}

              <div className="col-md-6">
                <div className="form-group">
                  <label>Specifier</label>
                  <select name="specifier" className="form-control" id={index} onChange={this.handleChange} value={row.specifier} disabled={this.state.isSaveAndComplete} >
                    <option value="">Select</option>
                    {this.props.specifier.map((specifier) => (
                      <option value={specifier.value}>{specifier.label}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    ));
    return (
      <>
        {/* <ToastContainer style={{ zIndex: 900 }} /> */}
        <div className="hold-transition sidebar-mini text-sm">
          <div className="wrapper">
            <div className="">
              <section className="content-body">
                <div className="row">
                  <div className="col-md-12">
                    <form>
                      <div className="row marginBottom1 heightScroll">
                        <div className="col-md-12 marginBottom1">
                          <div className="float-right">
                            {/* <button
                            className="btn bt-sm btn-secondary mt-2 mr-3"
                            onClick={this.addRows}
                            disabled={this.state.isSaveAndComplete}
                          >
                            <i class="fa fa-plus mr-1"></i>Add
                          </button> */}
                          </div>
                        </div>
                        {tableRows}
                        <div className="col-md-12">
                          {/* <button className='btn btn-success float-right mr-3'><i className='fas fa-save'></i> Save</button> */}
                          <button
                            id="saveButton"
                            type="submit"
                            onClick={this.handleSubmit}
                            className="btn btn-success float-right mr-3"
                            htmlType="submit"
                            // form="assessmentsForm"
                            disabled={this.state.saved}
                          >
                            {" "}
                            <i className="fas fa-save"></i> Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </>);
  }
}
