export const countInitialState = {
    count: 0,
    apiKey : ''
  };
  
  export const countActions = {
    increment: state => ({ count: state.count + 1 }),
    decrement: state => ({ count: state.count - 1 }),
    setKey : state => ({ apiKey: localStorage.getItem('__TOKEN__') }),
    eraseKey : state => ({ apiKey: '' }),
  };