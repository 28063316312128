/* global CKEDITOR */
import React, { useState, useEffect, useRef } from "react";
import $ from 'jquery';
import Topnav from "../../Topnav";
import Modal from '../../Modals/Modal/index';
import DateTime from 'react-datetime';
import moment from 'moment-timezone';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const EConsentEditor = () => {
  const location = useLocation();
  const formData = location.state?.formData;
  const [showNewButton, setShowNewButton] = useState(true);
  const [showCloseButton, setshowCloseButton] = useState(false);
  const [usedVars, setUsedVars] = useState([]);
  const editorRef = useRef(null);
  const [showfieldsAll, setshowfieldsAll] = useState(false);
  const [pageContent, setPageContent] = useState();
  const [fieldTypes, setFieldTypes] = useState({});
  const [selectImage, setSelectImage] = useState(false);
  const [show, setShow] = useState(false);
  const [currentClick, setCurrentClick] = useState("");
  const [checkboxCount, setCheckboxCount] = useState({}); // Default to 2 options
  const [checkboxValues, setCheckboxValues] = useState({}); // Stores text values for each checkbox
  const [modalValues, setModalValues] = useState({});
  const [currentDateClick, setCurrentDateClick] = useState("");
  const [formData1, setFormData1] = useState({});
  const [consentContent, setConsentContent] = useState({});
  const [selectedRadio, setSelectedRadio] = useState({}); // To track the selected radio button for each keyword
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({}); // To track the checked checkboxes for each keyword
  const [keyValuePairs, setKeyValuePairs] = useState({}); // Default key-value pair
  const [dropdownOptions, setDropdownOptions] = useState({});
  const [selectedDateTime, setSelectedDateTime] = useState({});
  const [formats, setFormats] = useState({});
  const [tempFormats, setTempFormats] = useState({});





  const handleChange1 = (e, keyword, index) => {
    const { type, checked, value } = e.target;

    let updatedField = {
      fieldName: keyword, // Use the keyword as the field name
      type: type === "select-one" ? "select" : type,
      value: value,
      option: checkboxCount[keyword],
    };

    if (type === "radio") {
      // For radio buttons, only one option can be selected at a time
      setSelectedRadio((prevState) => ({ ...prevState, [keyword]: value }));
      // Update the updatedField for radio
      updatedField.option = [value]; // For radio, the option array will contain the selected option

    } else if (type === "checkbox") {
      // For checkboxes, toggle the checked state
      setSelectedCheckboxes((prevState) => {
        const updatedCheckboxes = { ...prevState };
        if (checked) {
          // If checkbox is checked, add the value to the selected options
          if (!updatedCheckboxes[keyword]) updatedCheckboxes[keyword] = [];
          updatedCheckboxes[keyword].push(value);
        } else {
          // If checkbox is unchecked, remove the value from the selected options
          updatedCheckboxes[keyword] = updatedCheckboxes[keyword].filter((val) => val !== value);
        }
        return updatedCheckboxes;
      });
      // Update the updatedField for checkbox
      updatedField.option = selectedCheckboxes[keyword] || []; // Set the option array with selected checkboxes

    } else if (type === "text") {
      // Handle text input change
      setModalValues((prevValues) => ({ ...prevValues, [keyword]: { ...prevValues[keyword], [index]: value } }));

      // Update the updatedField for text input
      updatedField.option = [value]; // Set the updated text input as the option
    }
    setFormData1((prevFields) => ({ ...prevFields, [keyword]: updatedField }));
    // At this point, updatedField will contain the updated values, including the selected options.
    console.log(updatedField, "updatedField"); // This will show the updated field with its options
  };


  const generateGroups = (keyword) => {
    const groups = [];
    const dropdownValue = fieldTypes[keyword];
    const dropradiocheck = dropdownValue;
    const loopRecurs = checkboxCount[keyword] || 2;

    for (let i = 1; i <= loopRecurs; i++) {
      groups.push(
        <div className="row mb-4" key={`${keyword}-${i}`}>
          <div className="col-1">
            {dropradiocheck === "radio" ? (
              <input type="radio" id={`radio-${keyword}-${i}`} name={`radio-group-${keyword}`} value={`Option ${i}`} checked={selectedRadio[keyword] === `Option ${i}`} onChange={(e) => handleChange1(e, keyword, i)} />
            ) : (
              <input type="checkbox" id={`checkbox-${keyword}-${i}`} value={`Option ${i}`} checked={selectedCheckboxes[keyword]?.includes(`Option ${i}`)} onChange={(e) => handleChange1(e, keyword, i)} />
            )}
          </div>
          <div className="col-5">
            <label htmlFor={`${dropradiocheck}-${keyword}-${i}`} className="ml-2">
              {checkboxValues[keyword] && checkboxValues[keyword][i] ? checkboxValues[keyword][i] : `Option ${i}`}
            </label>
          </div>
        </div>
      );
    }
    return groups;
  };



  const handleModalInputChangeSelect = (e, index, keyword) => {
    const { name, value } = e.target;
    // Update keyValuePairs dynamically
    setKeyValuePairs((prevState) => {
      const updatedPairs = { ...prevState };
      // Initialize if keyword doesn't exist
      if (!updatedPairs[keyword]) {
        updatedPairs[keyword] = [{ key: "", value: "" }];
      }
      // Update specific pair at the given index
      updatedPairs[keyword][index] = { ...updatedPairs[keyword][index], [name]: value };
      
      return updatedPairs;
    });
  };
  

  // Add a key-value pair for a specific keyword
  const handleAddKeyValuePair = (keyword) => {
    setKeyValuePairs((prevState) => {
      const updatedPairs = { ...prevState };
      if (!updatedPairs[keyword]) {
        updatedPairs[keyword] = [];
      }
      updatedPairs[keyword].push({ key: "", value: "" });
      return updatedPairs;
    });
  };

  // Remove the last key-value pair for a specific keyword
  const handleRemoveLastKeyValuePair = (keyword) => {
    setKeyValuePairs((prevState) => {
      const updatedPairs = { ...prevState };
      if (updatedPairs[keyword]?.length > 1) {
        updatedPairs[keyword] = updatedPairs[keyword].slice(0, -1);
      }
      return updatedPairs;
    });
  };

  const handleDateTimeChange = (keyword, newDate) => {
    setSelectedDateTime((prev) => ({ ...prev, [keyword]: newDate, }));
  };

  const handleTempDateFormatChange = (format, keyword) => {
    setTempFormats((prev) => ({...prev,[keyword]: { ...prev[keyword], dateFormat: format }}));
  };
  
  const handleTempTimeFormatChange = (format, keyword) => {
    setTempFormats((prev) => ({...prev,[keyword]: { ...prev[keyword], timeFormat: format } }));
  };


  const generateGroups1 = (keyword) => {
    const groups = [];
    const dropdownValue = fieldTypes[keyword];
    const dropradiocheck = dropdownValue;
    const loopRecurs = checkboxCount[keyword] || 2;

    if (fieldTypes[keyword] === "select") {
      return (<>
        <div className="row">
          <div className="col-10">
            {(keyValuePairs[keyword] || [{ key: "", value: "" }]).map(
              (pair, index) => (
                <div key={index} className="d-flex mb-2">
                  <div className="form-group w-50 mr-2">
                    <label>Key</label>
                    <input type="text" name="key" className="form-control" placeholder="Enter key" value={pair.key} onChange={(e) => handleModalInputChangeSelect(e, index, keyword)} />
                  </div>
                  <div className="form-group w-50 mr-2">
                    <label>Value</label>
                    <input type="text" name="value" className="form-control" placeholder="Enter value" value={pair.value} onChange={(e) => handleModalInputChangeSelect(e, index, keyword)} />
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </>
      )
    }
    if (fieldTypes[keyword] === "date") {
      return (
        <div>
          <div className="row">
            <div className="col-6">
              <h5>Date Format</h5>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-1">
                <input type="radio" value="MM/DD/YYYY"checked={tempFormats[keyword]?.dateFormat === "MM/DD/YYYY"} onChange={() => handleTempDateFormatChange("MM/DD/YYYY", keyword)} />
                </div>
                <div className="col-11">
                  <label>
                    MM/DD/YYYY
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-1">
                <input type="radio" value="YYYY-MM-DD"checked={tempFormats[keyword]?.dateFormat === "YYYY-MM-DD"} onChange={() => handleTempDateFormatChange("YYYY-MM-DD", keyword)} />
                </div>
                <div className="col-11">
                  <label>
                    YYYY-MM-DD
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-1">
                <input type="radio"   value="DD/MM/YYYY" checked={tempFormats[keyword]?.dateFormat === "DD/MM/YYYY"}  onChange={() => handleTempDateFormatChange("DD/MM/YYYY", keyword)} />
                </div>
                <div className="col-11">
                  <label>
                    DD/MM/YYYY
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-6">
              <h5>Time Format</h5>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-1">
                <input type="radio"    value="h:mm A" checked={tempFormats[keyword]?.timeFormat === "h:mm A"} onChange={() => handleTempTimeFormatChange("h:mm A", keyword)} />
                </div>
                <div className="col-11">
                  <label>
                    h:mm a (12-hour format )
                  </label>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-1">
                <input type="radio" value="h:mm A" checked={formats[keyword]?.timeFormat === "h:mm A"} onChange={() => handleTimeFormatChange("h:mm A", keyword)} />
                </div>
                <div className="col-11">
                  <label>
                    h:mm A (12-hour format with uppercase AM/PM)
                  </label>
                </div>
              </div> */}
              <div className="row">
                <div className="col-1">
                <input  type="radio"  value="HH:mm"  checked={tempFormats[keyword]?.timeFormat === "HH:mm"} onChange={() => handleTempTimeFormatChange("HH:mm", keyword)} />
                </div>
                <div className="col-11">
                  <label>
                    HH:mm (24-hour format)
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      // Render default options for other types
      for (let i = 1; i <= loopRecurs; i++) {
        groups.push(
          <div className="row mb-4" key={`${keyword}-${i}`}>
            <div className="col-1">
              {dropradiocheck === "radio" ? (
                <input type="radio" id={`radio-${keyword}-${i}`} name={`radio-group-${keyword}`} value={`Option ${i}`} checked={selectedRadio[keyword] === `Option ${i}`} onChange={(e) => handleChange1(e, keyword, i)} />
              ) : (
                <input type="checkbox" id={`checkbox-${keyword}-${i}`} value={`Option ${i}`} checked={selectedCheckboxes[keyword]?.includes(`Option ${i}`)} onChange={(e) => handleChange1(e, keyword, i)} />
              )}
            </div>
            <div className="col-5">
              <input type="text" className="form-control" placeholder={`Edit Option ${i}`} value={modalValues[keyword] && modalValues[keyword][i]} onChange={(e) => handleModalInputChange(i, e.target.value, keyword)} />
            </div>
          </div>
        );
      }
    }

    return groups;
  };

  const handleAddClick = (keyword) => {
    setCheckboxCount((prevCount) => ({ ...prevCount, [keyword]: (prevCount[keyword] || 2) + 1 }));
  };

  // Handle removing a checkbox
  const handleRemoveClick = (keyword) => {
    setCheckboxCount((prevCount) => ({ ...prevCount, [keyword]: (prevCount[keyword] || 2) - 1 }));
  };


  const handleButtonClick = () => {
    setShowNewButton(false);
    setshowCloseButton(true);
    setshowfieldsAll(true);
  };

  const handleNextButtonClick = () => {
    setShowNewButton(true);
    setshowCloseButton(false);
    setshowfieldsAll(false);
  };

  const handleSelectChange = (e, variable) => {
    setDropdownOptions((prevTypes) => ({ ...prevTypes, [variable]: "" }));
    setFormData1((prevTypes) => ({ ...prevTypes, [variable]: "" }));
    setKeyValuePairs((prevTypes) => ({ ...prevTypes, [variable]: "" }))
    const value = e.target.value;
    setFieldTypes((prevTypes) => ({ ...prevTypes, [variable]: value, }));
    // Update `selectImage` for the specific dropdown
    setSelectImage((prevSelectImage) => ({ ...prevSelectImage, [variable]: !(value === "textarea" || value === "text") }));
    setCheckboxCount((prevCount) => ({ ...prevCount, [variable]: 2 }));
  };


  const addRowElement = (keyword) => {
    const dropdownValue = fieldTypes[keyword];
    const dropradiocheck = dropdownValue;
    if (dropradiocheck === "radio" || dropradiocheck === "checkbox") {
      setCurrentDateClick(true)
    } else {
      setCurrentDateClick(false)
    }
    setShow(true);
    setCurrentClick(keyword);
  }

  const closeModal1 = () => {
    setShow(false);
  }

  const addRadioButton = (keyword) => {
    //  dropdown add start
    setDropdownOptions((prevOptions) => {
      const updatedOptions = (keyValuePairs[keyword] || []).filter((pair) => pair.key && pair.value).map((pair) => ({key: pair.key,value: pair.value }));
      return { ...prevOptions, [keyword]: updatedOptions };
    });
    setDropdownOptions((prevOptions) => {
      const currentOptions = prevOptions[keyword] || []; // Get current dropdown options for this keyword
      const updatedField = {
        fieldName: keyword,
        type: "select",
        option: currentOptions.map((item) => ({ ...item })), // Map dropdown options
        value: "", // Set default value
      };
      setFormData1((prevFields) => ({...prevFields,[keyword]: updatedField }));
      return prevOptions;
    });
    // dropdown end
    setCheckboxValues(modalValues);
    // Date format change start
    setFormats((prev) => ({...prev,[keyword]: { ...tempFormats[keyword]}}));
    // Date format change end

    setShow(false);
  }

  const handleModalInputChange = (index, value, keyword) => {
    setModalValues((prevValues) => ({ ...prevValues, [keyword]: { ...prevValues[keyword], [index]: value } }));
  };



  useEffect(() => {
    if (editorRef.current) {
      const editorInstance = CKEDITOR.replace(editorRef.current);
      // Handle change in editor content
      editorInstance.on("change", () => {
        const editorData = editorInstance.getData();
        setPageContent(editorData); // Update pageContent with editor content
        // Now extract variables inside %% %% format
        if (editorData && editorData.length > 4) {
          const regex = /%%([a-zA-Z0-9\-\.]*)%%/g; // Regex for matching text inside %%
          const matches = [...editorData.matchAll(regex)];
          const vars = {};
          matches.forEach((match) => {
            if (match[1]) {
              vars[match[1]] = true; // Store variable inside `%%` as a key
            }
          });
          setUsedVars(Object.keys(vars)); // Update state with the found variables
        }
        const insRegex = /<ins[^>]*>(.*?)<\/ins>/g; // Regex to match text inside <ins> tags
        const insMatches = [...editorData.matchAll(insRegex)];
        const insText = insMatches.map((match) => match[1]).join(" "); // Extract the text content from <ins> tags
        console.log("Text inside <ins> tag: ", insText);
        setConsentContent({ "consentContent": insText })
      });
      return () => {
        if (CKEDITOR.instances[editorRef.current.id]) {
          CKEDITOR.instances[editorRef.current.id].destroy(true);
        }
      };
    }
  }, []);

  const handleSubmit = async (e) => {

    const consentContentDynamicFields = { consentContentDynamicFields: Object.keys(formData1).map((key) => ({ fieldName: key, ...formData1[key], })) };
    const concatenatedData = { ...formData, ...consentContentDynamicFields, ...modalValues, ...consentContent };

    console.log(consentContentDynamicFields, "consentContentDynamicFields");
    console.log(concatenatedData, "setCampleteFormData - Concatenated Data");

    e.preventDefault();
    try {
      const response = await axios.post('https://econsent.tactionclients.com/consenttemplates/create', JSON.stringify(concatenatedData),
        {
          headers: { 'Content-Type': 'application/json', },
        }
      );
      console.log('Form submitted successfully:', response.data);
      alert('Data sent successfully!');
    } catch (error) {
      console.error('Error submitting form:', error.response || error.message);
      alert('Failed to send data.');
    }
  };


  const handleChange = (e) => {
    const { name, value, type } = e.target;
    const updatedField = {
      fieldName: name,
      type: type === "select-one" ? "select" : type,
      value: value,
      option: Array.isArray(dropdownOptions[name]) ? dropdownOptions[name].map((item) => ({ ...item })) : [],
    };
    setFormData1((prevState) => ({ ...prevState, [name]: updatedField }));
  };


  return (

    <>
      <div className="hold-transition sidebar-mini text-sm  ">
        <div className="wrapper ">
          <Topnav />
          <form onSubmit={handleSubmit} id="EConsentEditor" className="content-wrapper p-5">
            <div className="mt-5 " style={{ border: "1px solid black", borderRadius: "5px", background: "rgb(255 255 255 / 90%)" }}>
              <div className="text-center mt-5">
                {/* <label style={{ width: "30vw", marginLeft: "29vw" }} className="form-control"  ><b>{formData.name}</b></label> */}
              </div>
              <div className="mt-2 p-3 " style={{ border: "2px solid black mt" }}>
                <textarea name="richTextContent" ref={editorRef} />
                <div className="row ml-3 mb-2">
                  <div>
                    {showNewButton && (
                      <button id="nextButton" onClick={handleButtonClick} name="nextButton" className="btn btn-success mt-3 float-left btnmargin" value="Next">
                        Next
                      </button>
                    )}
                  </div>
                  <div className=" ">
                    {showCloseButton && (
                      <button id="nextButton" onClick={handleNextButtonClick} name="nextButton" className="btn btn-success mt-3 float-left btnmargin" value="Next">
                        Back
                      </button>
                    )}
                  </div>
                </div>
                {usedVars.length > 0 && showfieldsAll && (
                  <div >
                    <div className="box">
                      <table className="datatable" >
                        <thead>
                        </thead>
                        <tbody >
                          {usedVars.length > 0 && showfieldsAll && (
                            <div >
                              <div className="box">
                                <table className="datatable" >
                                  <tbody>
                                    {usedVars.reduce((resultArray, item, index) => {
                                      const chunkIndex = Math.floor(index / 6);
                                      if (!resultArray[chunkIndex]) {
                                        resultArray[chunkIndex] = []; // Start a new row
                                      }
                                      resultArray[chunkIndex].push(item);
                                      return resultArray;
                                    }, [])
                                      .map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                          <div className="row " style={{ width: "72vw" }} >
                                            {row.map((keyword, colIndex) => (
                                              <div className="col-lg-6 col-md-6 col-sm-12 mb-4 " key={colIndex}>
                                                <div className="mb-2">
                                                  <label className="form-label ml-4">{keyword}</label>
                                                </div>
                                                <div className="row  ml-4">
                                                  <div className="col-lg-6 col-md-6 col-sm-12" >
                                                    {(!fieldTypes[keyword] || fieldTypes[keyword] === "text") && (
                                                      <input type="text" className="form-control mb-4" name={keyword} onChange={handleChange} value={formData1[keyword]?.value || ""} id="textInput" placeholder="Enter text" />
                                                    )}

                                                    {fieldTypes[keyword] === "select" && (
                                                      <div className="form-group">
                                                        <select className="form-control mb-4" name={keyword} onChange={handleChange} value={formData1[keyword]?.value || ""}  >
                                                          <option value="">Select Option</option>
                                                          {(dropdownOptions[keyword] || []).map((option, index) => (
                                                            <option key={index} value={option.key}>
                                                              {option.value}
                                                            </option>
                                                          ))}
                                                        </select>
                                                      </div>
                                                    )}
                                                    {fieldTypes[keyword] === "date" && (
                                                      <>
                                                        <div className="row">
                                                          <div className="col-12">
                                                            <div className="form-group" >
                                                              <div className="input-group-append" style={{ height: '38px', float: 'left', marginRight: '-3px' }}>
                                                                <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
                                                              </div>
                                                              <div className="input-group date timepicker" style={{ width: "calc(100% - 41px)" }}>
                                                                <span style={{ width: '100%' }}>
                                                                  <DateTime
                                                                    value={selectedDateTime[keyword] || null}
                                                                    onChange={(newDate) => handleDateTimeChange(keyword, newDate)}
                                                                    dateFormat={formats[keyword]?.dateFormat || "MM/DD/YYYY"}
                                                                    timeFormat={formats[keyword]?.timeFormat || "h:mm A"}
                                                                    input={true} // Allow manual entry
                                                                  />
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </>
                                                    )}
                                                    {fieldTypes[keyword] === "textarea" && (
                                                      <input className="form-control mb-4" name={keyword} onChange={handleChange} value={formData1[keyword]?.value || ""} id="textInput" placeholder="Enter text" />
                                                    )}
                                                    {fieldTypes[keyword] === "checkbox" && (
                                                      <div className="row mb-4">{generateGroups(keyword)}</div>
                                                    )}

                                                    {fieldTypes[keyword] === "radio" && (
                                                      <div className="row mb-4">{generateGroups(keyword)}</div>
                                                    )}
                                                  </div>
                                                  <div className="col-lg-5 col-md-6 col-sm-12">
                                                    <select className="form-control mb-4" value={fieldTypes[keyword] || ""} onChange={(e) => handleSelectChange(e, keyword)} >
                                                      <option value="">Select Type</option>
                                                      <option value="text">Text Input</option>
                                                      <option value="select">Select Dropdown</option>
                                                      <option value="date">Date Input</option>
                                                      <option value="textarea">Text Area</option>
                                                      <option value="radio">Radio Buttons</option>
                                                      <option value="checkbox">Checkboxes</option>
                                                    </select>
                                                  </div>
                                                  {fieldTypes[keyword] && selectImage[keyword] ? (
                                                    <div className="col-lg-1 col-md-6 col-sm-12 d-flex ">
                                                      <i onClick={() => addRowElement(keyword)} className="fa fa-cog  mt-2" aria-hidden="false"></i>
                                                    </div>
                                                  ) : (""
                                                  )}
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {show === true ?
                      <form>
                        <Modal
                          showOverlay={show === true ? true : false}
                          // show={this.state.editModal}
                          disableOverlayClick={true}
                          showCloseButton={false}
                          size="lg " // Increased size
                          className="modal-lg-dialog modal-md-dialog"
                          style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px", minWidth: "600px", minheight: '1000px' }}
                        >
                          <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                            <Modal.Title>
                              <div className="row">
                                <div className="col-sm-12">
                                  <p className="lead float-left" style={{ margin: 0 }}>
                                    {usedVars.map((keyword, index) => (
                                      <div key={index}>
                                        {currentClick == keyword && fieldTypes[keyword] === "date" && (
                                          <div>
                                            <b className="text-green">Field Date and Time</b>
                                          </div>
                                        )}
                                        {currentClick == keyword && fieldTypes[keyword] === "radio" && (
                                          <div>
                                            <b className="text-green">Field Radio Button</b>
                                          </div>
                                        )}
                                        {currentClick == keyword && fieldTypes[keyword] === "checkbox" && (
                                          <div>
                                            <b className="text-green">Field Check Box</b>
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                  </p>
                                  <div className="float-right">
                                    <button style={{ background: "none" }} onClick={closeModal1} >
                                      <i className="material-icons" style={{ fontSize: "36px", color: "#89bc3b", background: "none" }}>
                                        cancel
                                      </i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <div className="row">
                              {usedVars.map((keyword, index) => (
                                currentClick == keyword ? (<div className="col-md-12 form-group" key={index}>
                                  <div className="row mb-4">
                                    <div className="col-10">
                                      {generateGroups1(keyword)}
                                    </div>
                                    {
                                      currentDateClick === true ?
                                        <>
                                          <div className="col-1">
                                            <i className="material-icons" style={{ fontSize: "36px", color: "#89bc3b", background: "none", border: "3px solid #ccc", borderRadius: "100%", cursor: "pointer", }} onClick={() => handleAddClick(keyword)}>
                                              add
                                            </i>
                                          </div>
                                          <div className="col-1">
                                            <i className="material-icons" style={{ fontSize: "36px", color: "#89bc3b", background: "none", border: "3px solid #ccc", borderRadius: "100%", cursor: "pointer", }} onClick={() => handleRemoveClick(keyword)}>
                                              remove
                                            </i>
                                          </div>
                                        </>
                                        : <>
                                          {fieldTypes[keyword] !== "date" ? <> <div className="col-1">
                                            <i className="material-icons" style={{ fontSize: "36px", color: "#89bc3b", background: "none", border: "3px solid #ccc", borderRadius: "100%", cursor: "pointer", marginTop: "27px" }} onClick={() => handleAddKeyValuePair(keyword)}>add</i>
                                          </div>
                                            <div className="col-1 ">
                                              <i className="material-icons" style={{ fontSize: "36px", color: "#89bc3b", background: "none", border: "3px solid #ccc", borderRadius: "100%", cursor: "pointer", marginTop: "27px", marginLeft: "10px" }} onClick={() => handleRemoveLastKeyValuePair(keyword)}>remove</i>
                                            </div></> : ""
                                          }
                                        </>
                                    }
                                  </div>
                                </div>
                                ) : ("")
                              ))}
                            </div>
                          </Modal.Body>
                          <Modal.Footer style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                            {usedVars.map((keyword) => (
                             currentClick == keyword ?  <div>
                                <div className='row'>
                                  <div className='col-md-12 '>
                                    <button type=" " id="nextButton" name='nextButton' onClick={() => addRadioButton(keyword)} className="btn btn-success float-right btnmargin" value="Next >"> OK</button>
                                  </div>
                                </div>
                              </div>:""
                            ))}
                          </Modal.Footer>
                        </Modal>
                      </form> : ""
                    }
                  </div>
                )}
                {showfieldsAll === true ?
                  <div className='row'>
                    <div className='col-md-12 '>
                      <button type="submit" id="SaveButton" name='SaveButton' style={{ height: "40px", width: "50px" }} className="btn btn-success float-right btnmargin" > Save</button>
                    </div>
                  </div> : ""
                }
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default EConsentEditor;