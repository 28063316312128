import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { CSVLink } from "react-csv";

import CoverTableHeaderContainer from "./CoverTableHeader";
import Filter from "./CoverFilter";

import CoverTableContainer from "./CoverTableContainer";
import { sampleData } from "./ClientsStackBar";
import CalendarIcon from "../../assests/images/icon-calendar.png";
import ReactDatePicker from "react-datepicker";

// import { DateRangePicker } from "react-date-range";

import { DateRangePicker } from "rsuite";
import "../daterangecss/style.css";
import "rsuite/dist/rsuite.min.css";
import moment from "moment";

import { addDays } from "date-fns";

import axios from "axios";
import { createHashHistory } from "history";
import { API_URL } from "../../commonFunctions/ApiUrl";

import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  ListItemText,
  OutlinedInput,
  Checkbox,
  makeStyles,
} from "@material-ui/core";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      top: 0,
      bottom: 0,
    },
  },
};

const useStyles = makeStyles({
  root: {
    border: "none",
    height: "50px",
    fontSize: "0.875rem",
    "&::before": {
      border: "none",
    },
    "&:hover": {
      border: "none",
    },
  },
});

const DateContainer = styled.div`
  display: ${(props) => (props.openDateModal ? "block" : "none")};
`;

const MainContainer = styled.section`
  width: 98%;
  margin: 50px 16px 0 16px;
`;

// header design  start

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* border: 1px solid black; */
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.span`
  flex: 0 0 10%;
  font-weight: 600;
  @media (max-width: 1370px) {
  }
`;

const FilterContainer = styled.div`
  display: flex;

  flex: 0 0 70%;
  justify-content: flex-start;
  @media (max-width: 1370px) {
    /* flex: 0 0 70%; */
  }
`;

const Button = styled.button`
  border: none;
  cursor: pointer;
  outline: none;
  background-color: #7bb731;
  color: #fff;
  border-radius: 4px;
  width: 120px;
  /* border: 1px solid black; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* align-self: flex-end; */
`;

const FilterDate = styled.div`
  width: 170px;
  border: 1px solid #cbcbcb;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  justify-content: center;
`;

const width = "220px";

// header design ends

function ReferralSourcesTable({ title, filter, filterOptions }) {
  const [date, setDate] = useState("");
  const [age, setage] = useState("2022");
  const classes = useStyles();
  const [year, setYear] = useState([]);
  const [month, setMonth] = useState([]);
  const [client, setClient] = useState([]);
  const [getSampleData1, setgetSampleData1] = useState([]);

  const [getEthnicity, setgetEthnicity] = useState([]);
  const [getRaceData, setRaceData] = useState([]);

  const [isGender, setGender] = useState(false);
  const [isRace, setRace] = useState(false);
  const [isEthnicity, setEthnicity] = useState(false);

  const [openDateModal, setOpenDateModal] = useState(false);
  const wrapperRef = React.useRef(null);

  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [isYearDisable,setIsYearDisable]=useState(false);
  const [isDateRangeDisable,setIsDateRangeDisable]=useState(false);

  const arr = [];

  const options = ["2022","2021", "2020", "2019", "2018", "2017"];


  const styles = { width: 260, display: 'block', marginBottom: 10,height:50,borderRadius:"2px" };
  // const optionData = sampleData[0].data.map((e) => {e.x, e.val});
  const optionData = sampleData[0].data;

  const yearhandleChange = (event) => {
    const {
      target: { value },
    } = event;
    setYear(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const monthhandleChange = (event) => {
    const {
      target: { value },
    } = event;
    setMonth(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const clienthandleChange = (event) => {
    const {
      target: { value },
    } = event;
    setClient(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const OrgId = sessionStorage.getItem("organization_id");

// const OrgId=5030;
  useEffect(() => {
    fetchsearchClients(year, month, client);
  }, [year, month, client, startDate, endDate,isYearDisable]);

  const fetchsearchClients = (year, month, client) => {
    //  for year  //
    if (year == undefined || year == "") {
      year = "";
    } else {
      year = year.toString();
    }
    //  for Client  //
    if (client == undefined || client == "") {
      client = "all";
    } else {
      client = client.toString();
    }

    // for month //
    if (month == undefined || month == "") {
      month = 0;
    } else {
      if (month.length > 0) {
        month.forEach(function (item, i) {
          if (item == "January") {
            arr[i] = 1;
          }
          if (item == "Feburary") {
            arr[i] = 2;
          }
          if (item == "March") {
            arr[i] = 3;
          }
          if (item == "April") {
            arr[i] = 4;
          }
          if (item == "May") {
            arr[i] = 5;
          }
          if (item == "June") {
            arr[i] = 6;
          }
          if (item == "July") {
            arr[i] = 7;
          }
          if (item == "August") {
            arr[i] = 8;
          }
          if (item == "September") {
            arr[i] = 9;
          }
          if (item == "October") {
            arr[i] = 10;
          }
          if (item == "November") {
            arr[i] = 11;
          }
          if (item == "December") {
            arr[i] = 12;
          }
        });
      }

      month = arr.toString();
    }

    (Object.values(year).length<=0?(setIsDateRangeDisable(false)):(setIsDateRangeDisable(true)));

    if (client.indexOf("all") != -1) {
 


        
        // ======================
        if(year=="" && startDate=="" && endDate=="")
        {
          axios
          .get(API_URL + `referal_by_hospitals?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=Null&startDate=2022-01-01&endDate=2022-12-31`)
        
          .then((response) => {
            setgetSampleData1(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });

          // for referal_by_community_organisation
          axios
          .get(API_URL + `referal_by_community_organisation?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=Null&startDate=2022-01-01&endDate=2022-12-31`)
          .then((response) => {
            setRaceData(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
  
        // for ethnicity
  
        axios
          // .get(API_URL + `clients_by_ethnicity?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
          .get(API_URL + `referal_by_other?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=Null&startDate=2022-01-01&endDate=2022-12-31`)
  
          // .get(API_URL + `clients_by_ethnicity?year=${year}&startDate=${startDate}&endDate=${endDate}`)
          .then((response) => {
            setgetEthnicity(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
        }else{
          if(year != "")
          {
            axios
            // .get(API_URL + `clients_by_gender?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
            .get(API_URL + `referal_by_hospitals?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=Null&endDate=Null`)
          
            .then((response) => {
              setgetSampleData1(response.data.data);
            })
            .catch((error) => {
              console.log(error);
            });
          // for referal_by_community_organisation
          axios
            // .get(API_URL + `clients_by_race?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
            .get(API_URL + `referal_by_community_organisation?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=Null&endDate=Null`)
    
            // .get(API_URL + `clients_by_race?year=${year}&startDate=${startDate}&endDate=${endDate}`)
            .then((response) => {
              setRaceData(response.data.data);
            })
            .catch((error) => {
              console.log(error);
            });
    
          // for ethnicity
    
          axios
            // .get(API_URL + `clients_by_ethnicity?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
            .get(API_URL + `referal_by_other?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=Null&endDate=Null`)
    
          
            .then((response) => {
              setgetEthnicity(response.data.data);
            })
            .catch((error) => {
              console.log(error);
            });
          }else{
            axios
            // .get(API_URL + `clients_by_gender?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
            .get(API_URL + `referal_by_hospitals?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=${isDateRangeDisable==true ? "Null" : startDate}&endDate=${isDateRangeDisable==true ? "Null": endDate}`)
           
            .then((response) => {
              setgetSampleData1(response.data.data);
            })
            .catch((error) => {
              console.log(error);
            });
          // for race
          axios
            // .get(API_URL + `clients_by_race?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
            .get(API_URL + `referal_by_community_organisation?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=${isDateRangeDisable==true ? "Null" : startDate}&endDate=${isDateRangeDisable==true ? "Null": endDate}`)
    
            // .get(API_URL + `clients_by_race?year=${year}&startDate=${startDate}&endDate=${endDate}`)
            .then((response) => {
              setRaceData(response.data.data);
            })
            .catch((error) => {
              console.log(error);
            });
    
          // for ethnicity
    
          axios
            // .get(API_URL + `clients_by_ethnicity?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
            .get(API_URL + `referal_by_other?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=${isDateRangeDisable==true ? "Null" : startDate}&endDate=${isDateRangeDisable==true ? "Null": endDate}`)
    
            .then((response) => {
              setgetEthnicity(response.data.data);
            })
            .catch((error) => {
              console.log(error);
            });
          }
        }


         // ======================
  
    }

    if  (client.includes('Hospitals') == true) {
      

        if(year=="" && startDate=="" && endDate=="")
        {
          axios
          // .get(API_URL + `clients_by_gender?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)    
          .get(API_URL + `referal_by_hospitals?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=Null&startDate=2022-01-01&endDate=2022-12-31`)
        
          .then((response) => {
    
            setgetSampleData1(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });

        }else{
          if(year != "")
          {
            axios
            // .get(API_URL + `clients_by_gender?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
            .get(API_URL + `referal_by_hospitals?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=Null&endDate=Null`)
          
            .then((response) => {
    
              setgetSampleData1(response.data.data);
            })
            .catch((error) => {
              console.log(error);
            });
    
          }else{
            axios
            // .get(API_URL + `clients_by_gender?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
            .get(API_URL + `referal_by_hospitals?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=${isDateRangeDisable==true ? "Null" : startDate}&endDate=${isDateRangeDisable==true ? "Null": endDate}`)
           
            .then((response) => {
    
              setgetSampleData1(response.data.data);
            })
            .catch((error) => {
              console.log(error);
            });
         
         
          }
        }


    }



    if  (client.includes('Community Organization') == true)  {
     
        // =========
        if(year=="" && startDate=="" && endDate=="")
        {
          axios
          // .get(API_URL + `clients_by_gender?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)    
          .get(API_URL + `referal_by_community_organisation?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=Null&startDate=2022-01-01&endDate=2022-12-31`)
        
          .then((response) => {
      
            setRaceData(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });

        }else{
          if(year != "")
          {
            axios
            // .get(API_URL + `clients_by_gender?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
            .get(API_URL + `referal_by_community_organisation?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=Null&endDate=Null`)
          
            .then((response) => {
      
              setRaceData(response.data.data);
            })
            .catch((error) => {
              console.log(error);
            });
    
          }else{
            axios
            // .get(API_URL + `clients_by_gender?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
            .get(API_URL + `referal_by_community_organisation?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=${isDateRangeDisable==true ? "Null" : startDate}&endDate=${isDateRangeDisable==true ? "Null": endDate}`)
           
            .then((response) => {
      
              setRaceData(response.data.data);
            })
            .catch((error) => {
              console.log(error);
            });
         
         
          }
        }
    }



    if(client.includes('Other') == true){
     



        if(year=="" && startDate=="" && endDate=="")
        {
          axios
          // .get(API_URL + `clients_by_gender?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)    
          .get(API_URL + `referal_by_other?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=Null&startDate=2022-01-01&endDate=2022-12-31`)
        
          .then((response) => {
       
            setgetEthnicity(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
  

        }else{
          if(year != "")
          {
            axios
            // .get(API_URL + `clients_by_gender?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
            .get(API_URL + `referal_by_other?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=Null&endDate=Null`)
          
            .then((response) => {
       
              setgetEthnicity(response.data.data);
            })
            .catch((error) => {
              console.log(error);
            });
    
    
          }else{
            axios
            // .get(API_URL + `clients_by_gender?OrgId=${OrgId}&year=${year}&startDate=${startDate}&endDate=${endDate}`)
            .get(API_URL + `referal_by_other?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${isYearDisable==true ? "Null" : year}&startDate=${isDateRangeDisable==true ? "Null" : startDate}&endDate=${isDateRangeDisable==true ? "Null": endDate}`)
            .then((response) => {
              setgetEthnicity(response.data.data);
            })
            .catch((error) => {
              console.log(error);
            });
    
         
         
          }
        }

    }


  };

  const headers = [
    { label: "Source", key: "Source" },
    { label: "Name", key: "Name" },
    { label: "January", key: "Jan" },
    { label: "Feburary", key: "Feb" },
    { label: "March", key: "March" },
    { label: "April", key: "April" },
    { label: "May", key: "May" },
    { label: "June", key: "June" },
    { label: "July", key: "July" },
    { label: "August", key: "Aug" },
    { label: "September", key: "Sep" },
    { label: "October", key: "Oct" },
    { label: "November", key: "Nov" },
    { label: "December", key: "Dec" },
    { label: "Total", key: "Total" },
  ];

  let combinedArray = [];

  combinedArray.push(...getSampleData1, ...getRaceData, ...getEthnicity);
  
// if(client.includes('Hospitals')==false && client.includes('Community Organization')==false && client.includes('Other')==false){
//   combinedArray.push(...getSampleData1, ...getRaceData, ...getEthnicity);
// }else if(client.includes('Hospitals')==true && client.includes('Community Organization')==true && client.includes('Other')==true){
//   combinedArray.push(...getSampleData1, ...getRaceData, ...getEthnicity);
// }else if(client.includes('Hospitals')==true && client.includes('Community Organization')==true && client.includes('Other')==false){
//   combinedArray.push(...getSampleData1, ...getRaceData);
// }else if(client.includes('Hospitals')==true && client.includes('Community Organization')==false && client.includes('Other')==true){
//   combinedArray.push(...getSampleData1, ...getRaceData, ...getEthnicity);
// }else if(client.includes('Hospitals')==false && client.includes('Community Organization')==true && client.includes('Other')==true){
//   combinedArray.push(...getSampleData1, ...getRaceData, ...getEthnicity);
// }else if(client.includes('Hospitals')==true && client.includes('Community Organization')==false && client.includes('Other')==false){
//   combinedArray.push(...getSampleData1, ...getRaceData, ...getEthnicity);
// }else if(client.includes('Hospitals')==false && client.includes('Community Organization')==true && client.includes('Other')==false){
//   combinedArray.push(...getSampleData1, ...getRaceData, ...getEthnicity);
// }else if(client.includes('Hospitals')==false && client.includes('Community Organization')==false && client.includes('Other')==true){
//   combinedArray.push(...getSampleData1, ...getRaceData, ...getEthnicity);
// }
  

  const downloadArr = [];
  var k = 0;

  for (let i = 0; i < combinedArray.length; i++) {
   
    for (let j = 0; j < combinedArray[i].data.length; j++) {
      downloadArr.push({
        Source: combinedArray[i].source,
        Name: combinedArray[i].data[j].name,
        // Jan: combinedArray[i].data[j].data[ 0].val,
        Jan:combinedArray[i].data[j].data[ 0].val==null?0:combinedArray[i].data[j].data[ 0].val,
        Feb: combinedArray[i].data[j].data[ 1].val==null?0:combinedArray[i].data[j].data[1].val,
        March: combinedArray[i].data[j].data[ 2].val==null?0:combinedArray[i].data[j].data[2].val,
        April: combinedArray[i].data[j].data[ 3].val==null?0:combinedArray[i].data[j].data[3].val,
        May: combinedArray[i].data[j].data[ 4].val==null?0:combinedArray[i].data[j].data[4].val,
        June:combinedArray[i].data[j].data[ 5].val==null?0: combinedArray[i].data[j].data[5].val,
        July: combinedArray[i].data[j].data[ 6].val==null?0:combinedArray[i].data[j].data[6].val,
        Aug: combinedArray[i].data[j].data[ 7].val==null?0:combinedArray[i].data[j].data[7].val,
        Sep: combinedArray[i].data[j].data[ 8].val==null?0:combinedArray[i].data[j].data[8].val,
        Oct: combinedArray[i].data[j].data[ 9].val==null?0:combinedArray[i].data[j].data[9].val,
        Nov: combinedArray[i].data[j].data[ 10].val==null?0:combinedArray[i].data[j].data[10].val,
        Dec: combinedArray[i].data[j].data[ 11].val==null?0:combinedArray[i].data[j].data[11].val,
        Total: combinedArray[i].data[j].total==null?0:combinedArray[i].data[j].total,
      });
    }
  }

  // console.log(downloadArr);

  // console.log(getEthnicity);

  return (
    <MainContainer>
      <HeaderContainer>
        <Title>{title}</Title>
        <FilterContainer>
          {/******************************************* for Multi year *********************  */}
          <div
            style={{ height: "50px" }}
            selected={age}
            value={age}
            placeholder="Show Data for Year 2022"
            width="220px"
          >
            <FormControl
              disabled={isYearDisable}
              sx={{
                m: 1,
                width: 150,
              }}
              style={{
                width: width,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "50px",
                border: "none",
                margin: "0px 10px 0px 10px",
              }}
            >
              <InputLabel
                id="demo-multiple-checkbox-label"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",

                  paddingTop: "18px",
                  transform: "none",
                  transition: "none",
                }}
              >
                {year.length !== 0 ? "" : "Show Data for Year 2022"}
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={year}
                onChange={yearhandleChange}
                input={<OutlinedInput label="" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                className={classes.root}
                style={{ width: width }}
              >
                {options.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox
                      // color="#7BB731"
                      style={{ color: "#7BB731" }}
                      checked={year.indexOf(name) > -1}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {/* year multiselect end */}

          {/* <Filter
          selected={age}
          value={age}
          placeholder="Show Data for Year 2021"
          width="220px"
          
          options={[
            "2021",
            "2020",
            "2019",
            "2018",
            "2017",
          ]}
          
        />  */}
          {/******************************************* for Multi year end *********************  */}

         

          {/******************************************* for Client *********************  */}

          <div style={{ height: "50px" }}>
            <FormControl
              sx={{
                m: 1,
                width: 150,
              }}
              style={{
                width: width,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "50px",
                border: "none",
                margin: "0px 10px 0px 10px",
              }}
            >
              <InputLabel
                id="demo-multiple-checkbox-label"
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",

                  paddingTop: "18px",
                  transform: "none",
                  transition: "none",
                }}
              >
                {client.length !== 0 ? "" : "All Clients"}
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={client}
                onChange={clienthandleChange}
                input={<OutlinedInput label="" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
                className={classes.root}
                style={{ width: width }}
              >
                {filterOptions.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox
                      // color="#7BB731"
                      style={{ color: "#7BB731" }}
                      checked={client.indexOf(name) > -1}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {/******************************************* for Client ends  *********************  */}

          {/******************************************* for month  start *********************  */}

          {/* <Filter placeholder={filter} width="150px" options={options}  /> */}
          {/* <Filter
         selected={month}
          placeholder="All Months"
          width="150px"
          
          options={sampleData[0].data.map((e) => e.x)}
          
        /> */}

          {/* <FilterDate>
            {" "}
            {state.map((date) => (
              <>
                <span> {date.startDate.toLocaleDateString("en-US")} </span>-{" "}
                <span style={{ marginLeft: "5px" }}>
                  {" "}
                  {date.endDate.toLocaleDateString("en-US")}{" "}
                </span>{" "}
              </>
            ))}
            <DateContainer openDateModal={openDateModal} ref={wrapperRef}>
              <DateRangePicker
                onChange={(item) => {
                  setState([item.selection]);
                  setOpenDateModal(false);
                }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={state}
                direction="horizontal"
                className="monthlyReportdaterangepicker"
              />
            </DateContainer>
            <img
              style={{paddingLeft: "5px"}}
              src={CalendarIcon}
              alt="calendaricon"
              onClick={() => setOpenDateModal(!openDateModal)}
            />{" "}
          </FilterDate>{" "} */}

          <DateRangePicker
          disabled={isDateRangeDisable}
           size="lg" placeholder="2022-01-01 ~ 2022-12-31" style={styles}
           onChange={(e) => {
            return ((e) ? (setstartDate(moment(e[0]).format("YYYY-MM-DD")), setendDate(moment(e[1]).format("YYYY-MM-DD")),setIsYearDisable(true)) : (setstartDate(''), setendDate(''),setIsYearDisable(false)));           
           }}
          />

          {/* <Button data={genderData[0].data}>Download CSV</Button> */}
        </FilterContainer>
        <Button style={{ margin: "0px 10px 0px 10px" }}>
          {" "}
          <CSVLink
            filename="referral_resource_report.csv"
            data={downloadArr}
            headers={headers}
            target="_blank"
            style={{
              color: "#ffffff",
              width: "120px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Download CSV{" "}
          </CSVLink>
        </Button>
      </HeaderContainer>

      {/* <CoverTableHeaderContainer
        title={title}
        filter={filter}
        options={filterOptions}
        
      />  */}
     {client.includes('Hospitals') === true || client.length === 0 ? (
        <>
           <CoverTableContainer genderData={getSampleData1} />
        </>
      ) : (
        ""
      )}

      {/* {isRace === true ? (
        <>
          <CoverTableContainer genderData={getRaceData} />
        </>
      ) : (
        ""
      )} */}

      {client.includes('Community Organization') === true || client.length === 0 ? (
        <>
            <CoverTableContainer genderData={getRaceData} />
        </>
      ) : (
        ""
      )}

      {/* {isEthnicity === true ? (
        <>
          <CoverTableContainer genderData={getEthnicity} />
        </>
      ) : (
        ""
      )} */}

    {client.includes('Other') === true || client.length === 0 ? (
        <>
           <CoverTableContainer genderData={getEthnicity} />
        </>
      ) : (
        ""
      )}
    </MainContainer>
  );
}

export default ReferralSourcesTable;
