import React, { Component } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom'
import Topnav from './Topnav';
import Footer from './Footer';
import Patientinfoheader from './Patientinfoheader';
import Sidenav from './Sidenav';
import DatePicker from "react-datepicker";
import { validationParser } from '../ReusableComponents/validationParser2';
import { validationParser1 } from '../ReusableComponents/validationParser';
import { getFormattedDate } from '../ReusableComponents/dataFormater';

import { LabelField } from '../ReusableComponents/LabelnInput';
import { formObjDataMapper, formObjDataMapper2 } from '../ReusableComponents/formObjDataMapper';
import Select from 'react-select';
import { multiSelectOptionGenerator, createMultiselectDataToSend, selectOptionsGenerator, interviewersOptionsGenerator } from '../ReusableComponents/OptionsGenerator';
//Tooltip imports
import { Alert } from './toastify';
import ReactTooltip from "react-tooltip";
import Tooltip from '../ReusableComponents/Tooltip';
import DataTable, { defaultThemes } from 'react-data-table-component';
import Modal from './Modals/Modal/index';
import DateTime from 'react-datetime';
import { Button } from './Modals/Buttons/index';
import moment from 'moment-timezone';
import '../../node_modules/react-datetime/css/react-datetime.css';
import '../global.js';
import { can_edit, can_add } from '../ReusableComponents/urlGenerator';
import UncopeScrrening from './uncopeScrrening';
import Admh from './Admh';
import UpdateAdmh from './updateAdmh';

import ReactToPrint from 'react-to-print';
import PrintAssesment from './Print/printAssesment';
import PrintScreening from './Print/printScreening';
import Printasias from './Print/Printasias';
import Printdischarge from './Print/Printdischarge';
import PrintUpdateAssesment from './Print/printUpdateAssesment';
// import TimePicker from 'rc-time-picker';
// import 'rc-time-picker/assets/index.css';

// Module Upload File
import FileItem from './UploadAssesmentModule/FileItem/FileItem';
import FileList from './UploadAssesmentModule/FileList/FileList';
import FileUpload from './UploadAssesmentModule/FileUpload/FileUpload';
import Loader from './Loader';
import { toast } from 'react-toastify';
import { encryptStorage } from './LocalStorageInterceptor';

var validationObj = {};
var context;

const axios = require('axios').default;

const customStyles = {
  table: {
    style: {
      color: defaultThemes.default.text.primary,
      backgroundColor: defaultThemes.default.background.default,
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "#F9F9F9"
    },
  },
  header: {
    style: {
      minHeight: '1px',
    },
  },
  headRow: {
    style: {
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: defaultThemes.default.divider.default,
      backgroundColor: "#F9F9F9",
      minHeight: "40px"
    },
  },
  headCells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
        fontWeight: "bold",
        fontSize: "0.91rem"
      },
    },
  },
  row: {
    style: {
      minHeight: '20px',
      borderRightStyle: 'solid',
      borderRightWidth: '10px',
      borderRightColor: defaultThemes.default.divider.default,
    }
  },
  cells: {
    style: {
      '&:not(:last-of-type)': {
        // borderStyle: 'solid',
        // borderWidth: '1px',
        // borderColor: defaultThemes.default.divider.default,
        borderLeftStyle: 'solid',
        borderLeftWidth: '1px',
        borderLeftColor: defaultThemes.default.divider.default,
        fontSize: '0.9rem'
      },
    },
  },
};

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? "green"
          : isFocused
            ? "#0074F0"
            : null,
      color: isDisabled
        ? '#ccc'
        : isFocused
          ? "white"
          : isSelected
            ? "white"
            : "black",

      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? "grey" : "red"),
      },
    };
  },
  multiValue: (styles, { data }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: "#007BFF",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "white",
    whiteSpace: "inherit"
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "white",
    ':hover': {
      backgroundColor: "red",
      color: 'white',
    },
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      fontSize: '16px',
      color: '#495057'
    }
  }
};

class Assessments extends Component {


  constructor(props) {
    super(props);
    context = this;
    this.Print_assesmentRef = React.createRef();
    this.state = {
      ...this.getModal("assessmentsData"),
      assessmentsData: this.getModal("assessmentsData"),
      mandatoryInfo: this.getModal("assessmentsData"),
      labelText: this.getModal("assessmentsData"),
      tooltips: this.getModal("assessmentsData"),
      tooltipsVisibility: this.getModal("assessmentsData"),
      fieldName: this.getModal("assessmentsData"),
      fieldType: this.getModal("assessmentsData"),

      xyz: [],

      //SelectOptions
      referralTypeSelectOptions: [],
      wasAssmntCompletedSelectOptions: [],
      wasClientDeemForMatSelectOptions: [],
      whichMatProgramSelectOptions: [],
      wereFamilyReferToServiceSelectOptions: [],
      whichAssessmentServiceSelectOptions: [],

      //MultiSelectOption
      clientRefferedAgencyMultiOptions: [],
      changeParticipantionsHistoryData: [],
      // screeningHistoryData: [],

      stateAssesmentHistoryData: [],
      stateAssesmentUpdateHistoryData: [],
      completedAssessmentsHistoryColumns: this.getModal("completedAssessmentsHistoryColumns"),
      // screeningHistoryColumns: this.getModal("screeningHistoryColumns"),
      stateAssessmentsHistoryColumns: this.getModal("stateAssessmentsHistoryColumns"),
      stateAssessmentUpdatesHistoryColumns: this.getModal("stateAssessmentUpdatesHistoryColumns"),

      //Modals
      showAddAssessmentsModal: sessionStorage.getItem('modalOpen') ? true : false,
      showNextActionModal: false,
      showAssessmentDetailsModal: false,
      // uncopeScreening: false,
      Adth: false,
      updateAdmh: false,
      openPrintModal: false,
      age: 19,
      saveComplete: false,


      issaveAndComplete_uncope: false,
      issaveAndComplete_craft: false,
      issaveAndComplete_adult_assesment: false,
      issaveAndComplete_adolecent_assesment: false,
      issaveAndComplete_adult_update_assesment: false,
      issaveAndComplete_adolecent_update_assesment: false,
      is_draft_adolescent_update:false,
      is_draft_adolescent:false,
      is_draft_adult:false,
      is_draft_adolescent:false,
      is_draft_uncope:false,
      is_draft_craft:false,
      is_draft_adult_update:false,


      buttonDisabled:false,
      // formType:"",

      // Assesment
      is_filled: "",

      // update_form_type: undefined,
      // assement_form_type: undefined,
      // form_type: undefined,

      uncope_form_type: undefined,
      craft_form_type: undefined,

      adult_assement_form_type: undefined,
      adolescent_assement_form_type: undefined,

      adult_assement_form_type: undefined,
      adolescent_assement_form_type: undefined,

      // adult_assement_form_type,adolescent_assement_form_type,adult_assement_form_type_date,adolescent_assement_form_type_date
      // printModule: [{ update_assesment: false }, { assesments: false }, { asais: false }, { profile_discharge: false }, { screening: false }],
      printModule: [{ name: "update_assesment", val: false }, { name: "assesments", val: false }, { name: "asais", val: false }, { name: "profile_discharge", val: false }, { name: "screening", val: false }],
      printCount: 0,
      IsPrintAssesmentModalOpen: false,
      // PDFName: "State_Assessment"  +  encryptStorage.getItem("client_first_name")  +  encryptStorage.getItem("client_last_name")  + "_" +  new Date().toLocaleDateString(),
      PDFName: "State Assessment" + " " + encryptStorage.getItem("client_first_name") + " " + encryptStorage.getItem("client_last_name") + " " + new Date().toLocaleDateString(),

      uploadModule: false,
      files: [],
      showLoader: false,
      disable : {
        // uploadCompleateAssessmentsDisable : "",
        uncpScrning : "",
        crftScrning : "",
        asesment_1 : "",
        asesment_2 : "",
        update_1 : "",
        update_2 : ""
      },
    };
    this.handleAssessmentSelectChange = this.handleAssessmentSelectChange.bind(this);
  }

  getModal(type) {
    let dataModel = '';
    switch (type) {
      case "assessmentsData":
        dataModel = {
          selectedFile: null,
          assessment_date: '',
          modification_date: '',
          assessed_level: '',
          other_assessed_level: '',
          placed_level: '',
          other_placed_level: '',
          referral_target: '',
          other_referral_target: '',
          reason_for_difference: '',
          assets: '',
          notes: '',
          // Code Added By ashraf New 
          visitType: '',

        }
        break;

      case "resetData":
        dataModel = {
          selectedFile: null,
          assessment_date: '',
          modification_date: '',
          assessed_level: '',
          other_assessed_level: '',
          placed_level: '',
          other_placed_level: '',
          referral_target: '',
          other_referral_target: '',
          reason_for_difference: '',
          assets: '',
          notes: '',
          // Code Added By ashraf New 
          visitType: '',

        }
        break;

      case "completedAssessmentsHistoryColumns":
        dataModel = [
          {
            name: 'Date Completed',
            selector: 'assessment_date',
            sortable: true,
            cell: (row) => {
              return moment.unix(row.assessment_date).tz("America/New_York").format("MM/DD/YYYY");
            },
          },
          {
            name: 'Visit Type',
            selector: 'visitType',
            sortable: true,
          },
          {
            name: 'Created by',
            selector: 'created_by',
            sortable: true,
          },
          {
            name: 'Assessed Level of Care',
            selector: 'assessed_level',
            sortable: true,
          },
          {
            name: 'Attachments',
            selector: 'attachments',
            sortable: true,
          },
          {
            name: 'Modification Date',
            selector: "modification_date",
            sortable: true,
            cell: (row) => {
              return moment.unix(row.modification_date).tz("America/New_York").format("MM/DD/YYYY");
            },
          },
          {
            name: 'Action',
            sortable: false,
            cell: (row) => (
              <div>
                <Link to="#"><i id={row.id} lang="none" className="fas fa-eye" aria-hidden="true" data-toggle="tooltip" title="view" onClick={() => this.handleAssessmentDetails(row.id)}></i></Link>
              </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
          },
        ];
        break;

      // case "screeningHistoryColumns":
      //   dataModel = [
      //     {
      //       name: 'Date Completed',
      //       selector: 'created_date',
      //       sortable: true,
      //     },
      //     {
      //       name: 'Created By',
      //       selector: 'created_by',
      //       sortable: true,
      //     },
      //     {
      //       name: 'Screening Type',
      //       selector: "form_type",
      //       sortable: true,
      //     },
      //     {
      //       name: 'Modification Date',
      //       selector: "modification_date",
      //       sortable: true,
      //     },
      //     {
      //       name: 'Status',
      //       selector: "status",
      //       sortable: true,
      //     },
      //   ];
      //   break;

      case "stateAssessmentsHistoryColumns":
        dataModel = [
          {
            name: 'Date Completed',
            selector: 'created_date',
            sortable: true,
          },
          {
            name: 'Created By',
            selector: 'created_by',
            sortable: true,
          },
          {
            name: 'Assesment Type',
            selector: "form_type",
            sortable: true,
          },
          {
            name: 'Modification Date',
            selector: "modification_date",
            sortable: true,
          },
          {
            name: 'Status',
            selector: "status",
            sortable: true,
          },
        ];
        break;

      case "stateAssessmentUpdatesHistoryColumns":
        dataModel = [
          {
            name: 'Date Completed',
            selector: 'created_date',
            sortable: true,
          },
          {
            name: 'Created By',
            selector: 'created_by',
            sortable: true,
          },
          {
            name: 'Assesment Type',
            selector: "form_type",
            sortable: true,
          },
          {
            name: 'Modification Date',
            selector: "modification_date",
            sortable: true,
          },
          {
            name: 'Status',
            selector: "status",
            sortable: true,
          },
        ];
        break;
    }
    return dataModel;
  }

  // closeModal = () => {
  //   this.setState({ uncopeScreening: false, Adth: false })
  // }

  componentWillMount() {
    axios.get(global.restApiURL + 'webservice/rest/class/id/44?apikey=' + sessionStorage.getItem('__CL_TOKEN__'))
    .then(res => {
      var response = res.data;
      if (response.success) {
        validationObj = validationParser(response.data.layoutDefinitions.childs[0].childs);
        let validationObj_old = validationParser1(response.data.layoutDefinitions.childs[0].childs);
        this.setState({
          labelText: validationObj.title,
          mandatoryInfo: validationObj.mandatory,
          tooltips: validationObj.tooltip,
          tooltipsVisibility: validationObj.tooltipVisibility,
          fieldName: validationObj.name,
          fieldType: validationObj.fieldtype,
          ...validationObj_old
        });
        this.createSelectOptions();
      }
      else if ("error_code" in response) {
        alert(response.msg);
        window.location.href = global.domain + global.traversArray['out'];
      }
      else {
        alert(response.msg);
      }
    })
    .catch(err => { // log request error and prevent access to undefined state
      this.setState({ loading: false, error: true });
      console.error(err);
    });


    let dataJson = {
      dataListBody: {
        apikey : localStorage.getItem('__TOKEN__'),
        organization : sessionStorage.getItem('selected_organization'),
      }
    }
    axios.post(global.restApiURL+'customRest/getOrgnizationFormsStatus', JSON.stringify(dataJson))
    .then(
      res => {
        this.state.disable.uncpScrning = res.data.data[0].uncope;
        this.state.disable.crftScrning = res.data.data[0].craft;
        this.state.disable.asesment_1 = res.data.data[0].assessment_1;
        this.state.disable.asesment_2 = res.data.data[0].assessment_2;
        this.state.disable.update_1 = res.data.data[0].update_assessment_1;
        this.state.disable.update_2 = res.data.data[0].update_assessment_2;
      }
    )
    .catch(
      err => { 
        console.log(err.data.data);
      }
    );
  }

  componentDidMount() {
    this.getScreeningDetails();
    this.getAssessmentsList();
    this.getScreeningList();
    this.getStateassesmentList();
    this.getStateassesmentUpdateList();
    this.saveAndComplete();
    this.getScreeningScrore();
    this.getAllUploadedFiles();
    if (sessionStorage.getItem('viewDetail') > 0) {
      context.handleAssessmentDetails(sessionStorage.getItem('viewDetail'))
    }
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }
  // +++++++++++++++++++++++++++++++++++---getAssessmentsList----Start--++++++++++++++++++++++++++++
  getAssessmentsList = () => {
    if (sessionStorage.getItem('clientId') !== '') {
      let getAssessmentsListBody = {
        apikey: localStorage.getItem('__TOKEN__'),
        client_id: sessionStorage.getItem('clientId'),
      };
      axios.post(global.restApiURL + 'customRest/getAssessmentsList', JSON.stringify(getAssessmentsListBody))
        .then(res => {
          if (res.data.success && res.status == 200) {
            setTimeout(function () {
              context.setState({ changeParticipantionsHistoryData: context.parseAssessmentHistory(res.data.data) });
              sessionStorage.setItem('AssessmentListData_length', res.data.data.length)
            }, 50)
          }
          else if ("error_code" in res) {
            alert(res.msg);
            window.location.href = global.domain + global.traversArray['out'];
          }
          else {
            alert(res.msg);
          }
        })
        .catch(err => { // log request error and prevent access to undefined state
          this.setState({ loading: false, error: true });
          console.error(err);
        })
    }
  }
  // +++++++++++++++++++++++++++++++++++---getAssessmentsList----End--++++++++++++++++++++++++++++
  // =====================================################===========================================
  // +++++++++++++++++++++++++++++++++++---getScreeningList----Start--++++++++++++++++++++++++++++
  getScreeningList = () => {
    if (sessionStorage.getItem('clientId') !== '') {
      let getScreeningListBody = {
        apikey: localStorage.getItem('__TOKEN__'),
        client_id: sessionStorage.getItem('clientId'),
      };
      // API call for Uncope and Craft List start
      axios.post(global.restApiURL + 'customRest/getUncofeCraftList', JSON.stringify(getScreeningListBody))
        .then(res => {
          if (res.data.success && res.status == 200) {
            Object.keys(sessionStorage).forEach(key => {
              if (key.startsWith("UNCOPE Screening_form_type_status") || key.startsWith("CRAFFT Screening_form_type_status")) {
                sessionStorage.setItem(key, "");
              }
            });
            context.parseAssessmentHistory(res.data.data).map((myData) => {
              if (myData.form_type == 'UNCOPE Screening') {
                sessionStorage.setItem("UNCOPE Screening_form_type_status", myData.status)
              }
              if (myData.form_type == 'CRAFFT Screening') {
                sessionStorage.setItem("CRAFFT Screening_form_type_status", myData.status)
              }
            })
            setTimeout(function () {
              // context.setState({
              //   screeningHistoryData: context.parseAssessmentHistory(res.data.data)
              // });
              context.setState({ xyz: res.data.data })
            }, 50)
          }
          else if ("error_code" in res) {
            alert(res.msg);
            window.location.href = global.domain + global.traversArray['out'];
          }
          else {
            alert(res.msg);
          }
        })
        .catch(err => { // log request error and prevent access to undefined state
          this.setState({ loading: false, error: true });
          console.error(err);
        })
    }
  }

  getStateassesmentList = () => {
    if (sessionStorage.getItem('clientId') !== '') {
      let getScreeningListBody = {
        apikey: localStorage.getItem('__TOKEN__'),
        client_id: sessionStorage.getItem('clientId'),
      };
      axios.post(global.restApiURL + 'customRest/getAssessmentListing', JSON.stringify({ dataListBody: getScreeningListBody }))
        .then(res => {
          if (res.data.success && res.status == 200) {
            Object.keys(sessionStorage).forEach(key => {
              if (key.startsWith("adolescent_assement_form_type_status") || key.startsWith("adult_assement_form_type_status")) {
                sessionStorage.setItem(key, "");
              }
            });
            context.parseAssessmentHistory(res.data.data).map((myData) => {
              if (myData.form_type == 'Adolescent Assessment') {
                sessionStorage.setItem("adolescent_assement_form_type_status", myData.status)
              }
              if (myData.form_type == 'Adult Assessment') {
                sessionStorage.setItem("adult_assement_form_type_status", myData.status)
              }
            })
            setTimeout(function () {
              context.setState({
                stateAssesmentHistoryData: context.parseAssessmentHistory(res.data.data)
              });
              // context.setState({ xy: res.data.data })
            }, 50)
          }
          else if ("error_code" in res) {
            alert(res.msg);
            window.location.href = global.domain + global.traversArray['out'];
          }
          else {
            alert(res.msg);
          }
        })
        .catch(err => { // log request error and prevent access to undefined state
          this.setState({ loading: false, error: true });
          console.error(err);
        })
    }
  }

  // This function in for Update Assesment listing
  getStateassesmentUpdateList = () => {
    if (sessionStorage.getItem('clientId') !== '') {
      let getScreeningListBody = {
        apikey: localStorage.getItem('__TOKEN__'),
        client_id: sessionStorage.getItem('clientId'),
      };
      axios.post(global.restApiURL + 'customRest/getAssessmentListingUpdate', JSON.stringify({ dataListBody: getScreeningListBody }))
        .then(res => {
          if (res.data.success && res.status == 200) {
            Object.keys(sessionStorage).forEach(key => {
              if (key.startsWith("update_adolescent_assement_form_type_status")|| key.startsWith("update_adult_assement_form_type_status"))  {
                sessionStorage.setItem(key, "");
              }
            });
            context.parseAssessmentHistory(res.data.data).map((myData) => {
              if (myData.form_type == 'Adolescent Assessment Update') {
                sessionStorage.setItem("update_adolescent_assement_form_type_status", myData.status)
              }
              if (myData.form_type == 'Adult Assessment Update') {
                sessionStorage.setItem("update_adult_assement_form_type_status", myData.status)
              }
            })
            setTimeout(function () {
              context.setState({
                stateAssesmentUpdateHistoryData: context.parseAssessmentHistory(res.data.data)
              });
              // context.setState({ xy: res.data.data })
            }, 50)
          }
          else if ("error_code" in res) {
            alert(res.msg);
            window.location.href = global.domain + global.traversArray['out'];
          }
          else {
            alert(res.msg);
          }
        })
        .catch(err => { // log request error and prevent access to undefined state
          this.setState({ loading: false, error: true });
          console.error(err);
        })
    }
  }

  saveAndComplete = async () => {
    let final_data = {
      //  form_type: this.props.form_type,
      client_id: sessionStorage.getItem('clientId'),
      apikey: localStorage.getItem('__TOKEN__'),
    }
    let res = await axios.post(global.restApiURL + "customrest/checkIfAssessmentCompleted", JSON.stringify(final_data));
    if (res.data.success && res.status == 200) {
      if (res.data.data.is_completed_adult_assessment == "1") {
        this.setState({ issaveAndComplete_adult_assesment: true })
      } if (res.data.data.is_completed_adolescent_assessment == "1") {
        this.setState({ issaveAndComplete_adolecent_assesment: true })
      } if (res.data.data.is_completed_adult_update == "1") {
        this.setState({ issaveAndComplete_adult_update_assesment: true })
      } if (res.data.data.is_completed_adolescent_update == "1") {
        this.setState({ issaveAndComplete_adolecent_update_assesment: true })
      } if (res.data.data.is_completed_craft == "1") {
        this.setState({ issaveAndComplete_craft: true })
      } if (res.data.data.is_completed_uncope == "1") {
        this.setState({ issaveAndComplete_uncope: true })
      } if(res.data.data.is_draft_adolescent=="1"){
        this.setState({is_draft_adolescent:true})
      }if(res.data.data.is_draft_adolescent_update=="1"){
        this.setState({is_draft_adolescent_update:true})
      }if(res.data.data.is_draft_adult=="1"){
        this.setState({is_draft_adult:true})
      }if(res.data.data.is_draft_adolescent=="1"){
        this.setState({is_draft_adolescent:true})
      }if(res.data.data.is_draft_uncope=="1"){
        this.setState({is_draft_uncope:true})
      }if(res.data.data.is_draft_craft=="1"){
        this.setState({is_draft_craft:true})
      }if(res.data.data.is_draft_adult_update=="1"){
        this.setState({is_draft_adult_update:true})
      }
    }
  }
  // +++++++++++++++++++++++++++++++++++---getScreeningList----End--++++++++++++++++++++++++++++
  // =====================================################===========================================
  // +++++++++++++++++++++++++++++++++++---getScreeningDetails----Start--++++++++++++++++++++++++++++
  getScreeningDetails = () => {
    var dataListBody = {
      apikey: localStorage.getItem('__TOKEN__'),
      client_id: sessionStorage.getItem("clientId"),
    }
    axios.post(global.restApiURL + 'customRest/getClientAssessmentFrominfo', JSON.stringify(dataListBody))
      .then(res => {
        if (res.data.success && res.status == 200) {
          sessionStorage.setItem("uncope_form_type", res.data.data.uncope_form_type);
          sessionStorage.setItem("uncope_form_type_date", res.data.data.uncope_form_type_date);
          sessionStorage.setItem("craft_form_type", res.data.data.craft_form_type);
          sessionStorage.setItem("craft_form_type_date", res.data.data.craft_form_type_date);
          sessionStorage.setItem("adult_assement_form_type", res.data.data.adult_assement_form_type);
          sessionStorage.setItem("adolescent_assement_form_type", res.data.data.adolescent_assement_form_type);
          sessionStorage.setItem("adult_assement_form_type_date", res.data.data.adult_assement_form_type_date);
          sessionStorage.setItem("adolescent_assement_form_type_date", res.data.data.adolescent_assement_form_type_date);
          sessionStorage.setItem("is_one_year_completed", res.data.data.is_one_year_completed);
          this.setState({ adult_assement_form_type: res.data.data.adult_assement_form_type })
          this.setState({ adolescent_assement_form_type: res.data.data.adolescent_assement_form_type });
          this.setState({ uncope_form_type: res.data.data.uncope_form_type });
          this.setState({ craft_form_type: res.data.data.craft_form_type });
          console.log("craft_form_type is:", this.state.craft_form_type, "form_typcbfde", typeof (res.data.data.uncope_form_type), "assement_form_type", res.data.data.assement_form_type, "update_form_type", res.data.data.update_form_type)
        }
      }).catch(() => {
        alert("Failed");
      })
  }
  // +++++++++++++++++++++++++++++++++++---getScreeningDetails----End--++++++++++++++++++++++++++++
  // ===============================================================================================
  // ====================== Justification API call for Fiiling the Assesment--START========================
  getScreeningScrore = async () => {
    let payload = {
      apikey: localStorage.getItem('__TOKEN__'),
      client_id: sessionStorage.getItem("clientId"),
    }
    let res = await axios.post(global.restApiURL + 'customRest/getScreenScore', JSON.stringify(payload))
    if (res.data.success) {
      sessionStorage.setItem("uncopeScore", res.data.data.uncope_score)
      sessionStorage.setItem("craftScore", res.data.data.craft_score)
    }
  }

  // ====================== Justification API call for Fiiling the Assesment--END========================
  parseAssessmentHistory(historyDataArray) {
    let parsedData = [];
    parsedData = historyDataArray.length > 0 && historyDataArray.map((item, i) => {
      var op = {};
      item.map((val) => {
        op[val.name] = val.value;
      })
      return op;
    }, this);
    return parsedData;
  }

  createSelectOptions() {
    if (validationObj !== "") {
      this.setState({
        assessed_levelSelectOptions: selectOptionsGenerator(validationObj.options.assessed_level, context.state.assessmentsData.assessed_level),
        placed_levelSelectOptions: selectOptionsGenerator(validationObj.options.placed_level, context.state.assessmentsData.placed_level),
        visitTypeOptions: selectOptionsGenerator(validationObj.options.visitType, this.state.assessmentsData.visitType),
        referral_targetMultiOptions: multiSelectOptionGenerator(validationObj.options.referral_target),
      });
    }
  }

  handleAssessmentDetails(objId) {
    let historyID = objId;
    context.setState({
      assessmentsData: context.getModal("assessmentsData")
    })
    axios.get(global.restApiURL + 'webservice/rest/object/id/' + historyID + '?apikey=' + localStorage.getItem('__TOKEN__'))
      .then(res => {
        const response = res.data.data.elements;
        let parsedAssessmentDetails = formObjDataMapper2(response, context.getModal("assessmentsData"));
        context.setState({
          assessmentsData: parsedAssessmentDetails,
          showAssessmentDetailsModal: true
        })
        context.createSelectOptions();
      })
      .catch(err => { // log request error and prevent access to undefined state
        context.setState({ loading: false, error: true });
        console.error(err);
      })
    if (sessionStorage.getItem('viewDetail') > 0) {
      sessionStorage.removeItem('viewDetail')
    }
  }

  openAddAssessmentModal() {
    context.setState({
      showAddAssessmentsModal: true,
      showAssessmentDetailsModal: false,
      showNextActionModal: false
    })
  }

  closeAddAssessmentModal = () => {
    context.getAssessmentsList();
    context.setState({
      assessmentsData: context.getModal('resetData'),
      showAddAssessmentsModal: false
    })
    setTimeout(() => context.createSelectOptions(), 100);
    sessionStorage.removeItem('modalOpen');
  }

  handleAssessmentSelectChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    this.setState((prevState) => ({
      ...prevState,
      assessmentsData: {
        ...prevState.assessmentsData,
        [name]: value
      }
    }));
  }

  handleMultiSelectChanges = (selectedOption, event) => {
    var updatedState = this.state.assessmentsData;
    updatedState[event.name] = selectedOption;
    this.setState(prevState => ({
      ...prevState,
      assessmentsData: updatedState
    }));
  };

  saveAssessmentsdetails1 = (event) => {
    if (sessionStorage.getItem('clientId') !== '') {
      var jsonData = '';
      if (sessionStorage.getItem('AssessmentListData_length') === 0) {
        jsonData = { 'className': 'FinalAssessments', 'key': (sessionStorage.getItem('clientId') + "-1"), 'published': true };
      } else {
        jsonData = { 'className': 'FinalAssessments', 'key': (sessionStorage.getItem('clientId') + "-" + (parseInt(sessionStorage.getItem('AssessmentListData_length')) + 1)), 'published': true };
      }
      jsonData.elements = [];
      let jsonData_validation = [];
      let keys = Object.keys(context.getModal("assessmentsData"));
      keys.map((key) => {
        if (key !== 'referral_target') {
          jsonData.elements.push({ name: context.state.fieldName[key], value: context.state.assessmentsData[key] });
          if (context.state.assessmentsData[key] !== '') {
            jsonData_validation.push(key);
          }
        }
      })
      jsonData.elements.push({ name: context.state.fieldName.referral_target, value: createMultiselectDataToSend(context.state.assessmentsData.referral_target) });
      if (context.state.assessmentsData.referral_target.length > 0) {
        jsonData_validation.push('referral_target');
      }
      var client_id = sessionStorage.getItem('clientId');
      jsonData.elements.push({ "name": 'client_id', 'value': client_id });
      if (jsonData_validation.length === 0) {
        sessionStorage.setItem('error_msg', 'Empty Information is not saved');
        window.location.href = "";
      } else {
        var json = JSON.stringify(jsonData);
        axios.post(global.save_url, json)
          .then(function (res) {
            var response = res.data;
            if (response.success && res.status == 200) {
              sessionStorage.setItem('success_msg', "Assessment added successfully.");
              sessionStorage.setItem('AssessmentListData_length', (parseInt(sessionStorage.getItem('AssessmentListData_length')) + 1));
              context.closeAddAssessmentModal();
            }
            else if ("error_code" in response) {
              alert(response.msg);
              window.location.href = global.domain + global.traversArray['out'];
            }
            else {
              alert(response.msg);
              context.closeAddAssessmentModal();
            }
          })
          .catch(function (err) {
            console.log(err.message);
          }
        );
      }
    }
    sessionStorage.removeItem('modalOpen');
  }

  closeNextActionModal() {
    context.getAssessmentsList();
    context.setState({
      showNextActionModal: false,
      openPrintModal: false
    });
  }

  openNextActionModal() {
    context.getAssessmentsList();
    context.setState({
      showAddAssessmentsModal: false,
      showAssessmentDetailsModal: false,
      showNextActionModal: true
    });
  }

  closeAssessmentDetailsModal() {
    context.setState({
      assessmentsData: context.getModal('resetData'),
      showAssessmentDetailsModal: false
    });
    setTimeout(() => context.createSelectOptions(), 100);
  }

  openAssessmentDetailsModal() {
    context.setState({
      showAddAssessmentsModal: false,
      showAssessmentDetailsModal: true,
      showNextActionModal: false
    });
  }

  onChangeHandler = event => {
    if (event.target.files.length > 0) {

    } else {
      return false;
    }
    let file_size = event.target.files[0].size;
    let file_name = event.target.files[0].name;
    let file_type = event.target.files[0].type;
    let selectedFile = [];
    for (let i = 0; i < event.target.files.length; i++) {
      selectedFile.push(event.target.files[i]);
    }
    this.setState({
      selectedFile,
      loaded: 0,
    })
  }

  saveAssessmentsdetails = async () => {
    this.setState({ buttonDisabled: true });
    this.setState({ showLoader: true })
    let buttonType = 'save';
    let today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const data = new FormData();
    Object.entries(context.state.assessmentsData).map(([key, value]) => {
      if (key === 'referral_target') {
        for (let i = 0; i < this.state.assessmentsData.referral_target.length; i++) {
          data.append(`referral_target[${i}]`, this.state.assessmentsData.referral_target[i].label)
        }
      } else if (key === 'assessment_date') {
        data.append('assessment_date', value !== '' && value !== null && value !== undefined ? moment(value).format('YYYY-MM-DD') : '');
      } else {
        data.append(key, value);
      }
    })
    let emptyFormData = true;
    for (var val of data.values()) {
      if (val !== '' && val !== 'null' && val !== null) {
        emptyFormData = false;
      } else {
        // No Code;
      }
    }
    if (this.state.selectedFile === null && emptyFormData) {
      sessionStorage.setItem('error_msg', 'Empty Information is not saved')
      context.closeAddAssessmentModal();
      window.location.href = "";
      return false;
    }
    if (sessionStorage.getItem('AssessmentListData_length') === 0) {
      data.append('key', sessionStorage.getItem('clientId') + "-1");
    } else {
      data.append('key', sessionStorage.getItem('clientId') + "-" + (parseInt(sessionStorage.getItem('AssessmentListData_length')) + 1))
    }
    if (this.state.selectedFile !== null) {
      for (let i = 0; i < this.state.selectedFile.length; i++) {
        if (this.vlidateFileSelection(this.state.selectedFile[i])) {
          await data.append(`attachments[${i}]`, this.state.selectedFile[i]);
        } else {
          return false;
        }
      }
      await this.setState({ selectedFile: null });
    }
    data.append('client_id', sessionStorage.getItem('clientId'))
    data.append('apikey', localStorage.getItem('__TOKEN__'))
    data.append('created_by', sessionStorage.getItem('username'))
    data.append('version', "1")
    try {
      axios.post(global.restApiURL + "customRest/uploadFinalAssessmentAttachments", data)
      .then(res => {
        this.setState({ buttonDisabled: false });
        this.setState({ showLoader: false })
        let response = res.data;
        if (response.success && res.status == 200) {
          sessionStorage.setItem('success_msg', "Assessment added successfully.");
          sessionStorage.setItem('AssessmentListData_length', (parseInt(sessionStorage.getItem('AssessmentListData_length')) + 1));
          context.closeAddAssessmentModal();
          Alert('success', "Assessment saved successfully.");
        }
        else if ("error_code" in response) {
          alert(response.msg);
          window.location.href = global.domain + global.traversArray['out'];
        }
        else {
          alert(response.msg);
          context.closeAddAssessmentModal();
        }
      }).catch(err => {
        console.error(err);
        this.setState({ buttonDisabled: false });
        this.setState({ showLoader: false })
      }
    );
      
    } catch (error) {
      console.error(error);
      this.setState({ buttonDisabled: false });
      this.setState({ showLoader: false })
      
    }   
  }

  vlidateFileSelection(file) {
    if ((file.size / 1024 / 1024) > 25) {
      alert(file.name + " : File size too large !");
      return false;
    }
    else {
      return true;
    }
  }

  uploadImage = (e) => {
    let file = e.target.files[0];
    if (file && !file.name) {
      window.alert("Please select a file");
      return false;
    }
    if (file.size > 25e6) {
      window.alert("Please upload a file smaller than 25 MB");
      return false;
    }
  }

  Filevalidation = () => {
    const fi = document.getElementById('file');
    if (fi.files.length > 0) {
      for (var i = 0; i <= fi.files.length - 1; i++) {
        const fsize = fi.files.item(i).size;
        const file = Math.round((fsize / 1024));
        if (file >= 4096) {
          alert(
            "File too Big, please select a file less than 4mb");
        } else if (file < 2048) {
          alert(
            "File too small, please select a file greater than 2mb");
        } else {
          document.getElementById('size').innerHTML = '<b>'
            + file + '</b> KB';
        }
      }
    }
  }

  handleDateChange = name => date => {
    var stateCopy = Object.assign({}, this.state);
    stateCopy.assessmentsData[name] = date;
    this.setState(stateCopy);
  };

  route = (route) => {
    sessionStorage.setItem('modalOpen', true);
    switch (route) {
      case 'Follow-Up':
        window.location.href = global.domain + 'followup';
        break;
      case 'Routing':
        window.location.href = global.domain + 'routing';
        break;
      case 'Referral':
        window.location.href = global.domain + 'referrals';
        break;
    }
  }

  handleTimer(date, field) {
    let assessmentsData_temp = this.state.assessmentsData;
    assessmentsData_temp[field] = date._d !== undefined ? moment(date).format('MM/DD/YYYY') : date;
    context.setState({ assessmentsData: assessmentsData_temp });
  };

  handleTimeronBlur(date, field) {
    date = date ? date : context.state.assessmentsData[field];
    let assessmentsData_temp = this.state.assessmentsData;
    assessmentsData_temp[field] = typeof date === 'string' && typeof date !== 'object' && date !== "" ? moment().format('MM/DD/YYYY') : (typeof date == 'object' && date !== null ? moment(date._d).format('MM/DD/YYYY') : "");
    context.setState({ assessmentsData: assessmentsData_temp, unSaved: true });
  };

  validDate(current, selectedDate) {
    return current.isBefore(moment());
  };

  showFormModal = (e, form) => {
    // if (form.name == 'uncopeScreening') {
    //   this.setState({ age: 19, uncopeScreening: true, formType: "uncopeScreening" });
    // } else if (form.name == 'crafftScreening') {
    //   this.setState({ age: 17, uncopeScreening: true, formType: "craftScreening" })
    // }else 
    if (form.name == 'Adult_Assessment') {
      this.setState({ age: 19, Adth: true, formType: "Adult_Assessment" });
    } else if (form.name == "Adolescent_Assessment") {
      this.setState({ age: 17, Adth: true, formType: "Adolescent_Assessment" });
    } else if (form.name == "Adult_updateAdmh") {
      this.setState({ age: 19, updateAdmh: true, formType: "Adult_updateAdmh" });
    } else if (form.name == "Adolescent_updateAdmh") {
      this.setState({ age: 17, updateAdmh: true, formType: "Adolescent_updateAdmh" });
    }
    else {
      this.setState({ [form.name]: true, age: form.age });
    }
  }

  closeAdmh = () => {
    this.setState({ Adth: false, updateAdmh: false });
    window.location.reload();
  }

  handleCheckbox = (e) => {
    for (let i = 0; i < this.state.printModule.length; i++) {
      if (this.state.printModule[i].name == e.target.name) {
        this.state.printModule[i].val = e.target.checked;
        break;
      }
    }
    // console.log("printModule is : ", this.state.printModule)
    let count = 0;
    for (let i = 0; i < this.state.printModule.length; i++)
      this.state.printModule[i].val && ++count;

    this.setState({ printCount: count }, () => {
      // console.log("Total count is : ", this.state.printCount)
    })
  }

  canAccessToView = (component_name) => {
    if (global.is_admin || global.is_clinical_specialist) {
      return true
    } else {
      return false
    }
  }

  removeFile = async (filename) => {
    if (window.confirm("Are you sure ? You are deteting Uploaded File..")) {
      let dataListBody = {
        apikey: localStorage.getItem('__TOKEN__'),
        client_id: sessionStorage.getItem("clientId"),
        file_name: filename
      }
      let del_res = await axios.post(global.restApiURL + "customrest/deleteClientDocs", JSON.stringify({ dataListBody: dataListBody }))
      if (del_res.data.success && del_res.status == 200) {
        this.getAllUploadedFiles();
      }
    }
  }

  setFiles = (filename) => {
    this.setState({ files: filename })
  }

  handleDownloadModules = async () => {

    this.setState({ showLoader: true })
    let dataListBody = {
      apikey: localStorage.getItem('__TOKEN__'),
      client_id: sessionStorage.getItem("clientId"),
    }

    try{
      let del_res = await axios.post(global.restApiURL + "customrest/downloadClientDocZip", JSON.stringify({ dataListBody: dataListBody }))
      if (del_res.data.success && del_res.status == 200) {
        this.setState({ showLoader: false })
        window.location = del_res.data.file;
      } 
    }catch{
      alert("Something going wrong Please try again...")
        this.setState({ showLoader: false })
    }

    
    // window.location = del_res.data.file;
  }
  getAllUploadedFiles = async () => {
    let dataListBody = {
      apikey: localStorage.getItem('__TOKEN__'),
      client_id: sessionStorage.getItem("clientId"),
    }

    let res_listing = await axios.post(global.restApiURL + 'customrest/getFileDocs', JSON.stringify({ dataListBody: dataListBody }));
    if (res_listing.data.success) {
      this.setState({ files: res_listing.data.files })
    }
  }

  handleAttachmentClick(url,fileName) {
    const path = new URL(url).pathname;
    console.log("path",path)
    const payload = {
      path: path,
      apikey: localStorage.getItem('__TOKEN__')
    };
    axios.post(global.restApiURL + "customRest/FileDownloadAuthenticated", payload, {
      responseType: 'blob'
    })
    .then((res) => {
      const blob = new Blob([res.data], { type: res.data.type });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); // specify the file name and extension
      document.body.appendChild(link);
      link.click();

      // Clean up
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error('Error downloading the file:', error);
    });
  }

  render() {
    $(document).ready(function () {
      $('#assessmentsForm').validate({
        submitHandler: function () {
          context.saveAssessmentsdetails();
        },
        errorElement: 'span',
        errorPlacement: function (error, element) {
          error.addClass('invalid-feedback');
          element.closest('.formfield').append(error);
        },
        highlight: function (element, errorClass, validClass) {
          $(element).addClass('is-invalid');
        },
        unhighlight: function (element, errorClass, validClass) {
          $(element).removeClass('is-invalid');
        }
      });
    });
    sessionStorage.setItem('formname', 'State Assessment Forms');
    let referral_target = [];
    let attachments = [];
    if (context.state.assessmentsData.referral_target && context.state.assessmentsData.referral_target.length > 0) {
      context.state.assessmentsData.referral_target.map((agency) => {
        if (agency.label !== 'Other (Please Specify Below)') {
          referral_target.push(<li>{agency.label}</li>);
        }
      })
    }
    if (context.state.assessmentsData.other_referral_target !== '') {
      referral_target.push(<li>Other - {context.state.assessmentsData.other_referral_target}</li>)
    }
    if (context.state.assessmentsData.assets !== '') {
      let assets = JSON.parse(context.state.assessmentsData.assets);
      if (assets.length > 0) {
        assets.map((attachment) => {
          attachments.push(<li key={attachment.url}>
            <a href={attachment.url} target="_blank" onClick={(e) => {
              e.preventDefault();
              context.handleAttachmentClick(attachment.url,attachment.name);
            }}>
              {attachment.name}
            </a>
          </li>)
        })
      }
    }


    return (
      <div className="hold-transition sidebar-mini text-sm">
        <div className="wrapper">
          {!this.state.Adth && !this.state.updateAdmh && <Topnav />}
          <div className="content-wrapper">
            {!this.state.Adth && !this.state.updateAdmh ?
              <Patientinfoheader
                clientId={sessionStorage.getItem('clientId')}
                dob={encryptStorage.getItem('dob')}
                email={encryptStorage.getItem('email')}
                phone={encryptStorage.getItem('phone')}
                peer_support_specialist={encryptStorage.getItem('peer_support_specialist')}
                next_followup_date={encryptStorage.getItem('month_1_followup_date')}
                intake_date={encryptStorage.getItem('intake_date')}
                initiated_by={encryptStorage.getItem('last_updated_by')}
                formname={sessionStorage.getItem('formname')}
                client_name={encryptStorage.getItem('client_first_name') + " " + encryptStorage.getItem('client_middle_initial') + " " + encryptStorage.getItem('client_last_name')}
              /> : ""
            }
            {!this.state.Adth && !this.state.updateAdmh ?
              <section className="content">
                <div className="row">
                  <Sidenav />
                  <div className="container-fluid col-md-9 col-lg-10">
                    <div className="card card-default">
                      <div className="card-header">
                        <p className="lead float-left"><b className="text-green">State Assessment Forms</b></p>
                        <button type="submit" className="btn btn-primary float-right" value="proceed" onClick={() => window.location.href = global.traversArray['state_assessment']}><img src="dist/img/save-proceed_icon.png" alt="" style={{ pointerEvents: "none" }} /> Save &amp; Proceed </button>
                        {global.is_admin || global.is_clinical_specialist || true ? <>
                          <button className="btn btn-primary float-sm-right btnmargin" onClick={() => this.setState({ openPrintModal: true })} value="all" lang="all" >Print</button>
                          <button type="submit" className="btn btn-success dropdown-toggle float-sm-right btnmargin" data-toggle="dropdown" aria-expanded="false" value="all" lang="all" ><i className="fas fa-plus" aria-hidden="true"></i> Add</button> </> : ""
                        }
                        <div className="dropdown-menu " x-placement="bottom-start" >
                          <a className="dropdown-item" data-toggle="modal" data-target="#cssr" href="#cssr" onClick={this.openAddAssessmentModal}>Upload Completed Assessment</a>
                          {/* {
                            (this.state.disable.uncpScrning == 0) ? "" : <a className="dropdown-item" data-toggle="modal" data-target="#gadscreeningtool" style={{ cursor: 'pointer' }} onClick={(event) => this.showFormModal(event, { name: 'uncopeScreening', age: 19 })}>UNCOPE Screening  {sessionStorage.getItem("UNCOPE Screening_form_type_status")  ? <b style={{ color: "#89BC3B" }}> ({sessionStorage.getItem("UNCOPE Screening_form_type_status")})</b> : ""}</a>
                          }
                          {
                            (this.state.disable.crftScrning == 0) ? "" : <a className="dropdown-item" data-toggle="modal" data-target="#gadscreeningtool" style={{ cursor: 'pointer' }} onClick={(event) => this.showFormModal(event, { name: 'crafftScreening', age: 17 })}>CRAFFT Screening  {sessionStorage.getItem("CRAFFT Screening_form_type_status") ? <b style={{ color: "#89BC3B" }}> ({sessionStorage.getItem("CRAFFT Screening_form_type_status")})</b> : ""}</a>
                          } */}
                          {
                            (this.state.disable.asesment_1 == 0) ? "" : <a className="dropdown-item" data-toggle="modal" data-target="#phq9" style={{ cursor: 'pointer' }} onClick={(event) => this.showFormModal(event, { name: 'Adult_Assessment', form_name: "Adult_Assessment", age: 19 })}>State Assessment (Adult) {sessionStorage.getItem("adult_assement_form_type_status") ? <b style={{ color: "#89BC3B" }}> ({sessionStorage.getItem("adult_assement_form_type_status")})</b> : ""} </a>
                          }
                          {
                            (this.state.disable.asesment_2 == 0) ? "" : <a className="dropdown-item" data-toggle="modal" data-target="#phq9" style={{ cursor: 'pointer' }} onClick={(event) => this.showFormModal(event, { name: 'Adolescent_Assessment', age: 17, form_name: "Adolescent_Assessment", })}>State Assessment (Adolescent) {sessionStorage.getItem("adolescent_assement_form_type_status") ? <b style={{ color: "#89BC3B" }}> ({sessionStorage.getItem("adolescent_assement_form_type_status")})</b> : ""}</a>
                          }
                          {
                            (this.state.disable.update_1 == 0) ? "" : <a className="dropdown-item" data-toggle="modal" data-target="#phq9" style={{ cursor: 'pointer' }} onClick={(event) => this.showFormModal(event, { name: 'Adult_updateAdmh', form_name: "Adult_updateAdmh", age: 19 })}>Assessment Update (Adult){sessionStorage.getItem("update_adult_assement_form_type_status") ? <b style={{ color: "#89BC3B" }}> ({sessionStorage.getItem("update_adult_assement_form_type_status")})</b> : ""}</a>
                          }
                          {
                            (this.state.disable.update_2 == 0) ? "" : <a className="dropdown-item" data-toggle="modal" data-target="#phq9" style={{ cursor: 'pointer' }} onClick={(event) => this.showFormModal(event, { name: 'Adolescent_updateAdmh', form_name: "Adolescent_updateAdmh", age: 17 })}>Assessment Update (Adolescent){sessionStorage.getItem("update_adolescent_assement_form_type_status") ? <b style={{ color: "#89BC3B" }}> ({sessionStorage.getItem("update_adolescent_assement_form_type_status")})</b> : ""} </a>
                          }
                        </div>
                        <input type="hidden" id="clicked" value="" readOnly />
                      </div>
                      <div className="card-body">
                        <DataTable
                          columns={this.state.stateAssessmentsHistoryColumns}
                          data={this.state.stateAssesmentHistoryData}
                          customStyles={global.customTableStyles}
                          striped={true}
                          pagination
                          paginationComponentOptions={global.paginationComponentOptions}
                          highlightOnHover
                          pointerOnHover
                          noDataComponent={<div className="no_record_found">{global.no_record_found_text}</div>}
                          onRowClicked={(event) => {
                            const assessmentType = event.form_type; // Extracting Assessment Type from event
                            let form = {};
                    
                            if (assessmentType === 'Adult Assessment') {
                                form = { name: 'Adult_Assessment', form_name: "Adult_Assessment", age: 19 };
                            } else if (assessmentType === 'Adolescent Assessment') {
                                form = { name: 'Adolescent_Assessment', form_name: "Adolescent_Assessment", age: 17 };
                            }
                    
                            this.showFormModal(event, form);
                        }}
                        />
                        <h4 style={{ color: "#89BC3B" }}>State Assessment Update Forms</h4>
                        <DataTable
                          columns={this.state.stateAssessmentUpdatesHistoryColumns}
                          data={this.state.stateAssesmentUpdateHistoryData}
                          customStyles={global.customTableStyles}
                          striped={true}
                          pagination
                          paginationComponentOptions={global.paginationComponentOptions}
                          highlightOnHover
                          pointerOnHover
                          noDataComponent={<div className="no_record_found">{global.no_record_found_text}</div>}
                          onRowClicked={(event) => {
                            const assessmentType = event.form_type; // Extracting Assessment Type from event
                            let form = {};
                    
                            if (assessmentType === 'Adult Assessment Update') {
                                form = { name: 'Adult_updateAdmh', form_name: "Adult_updateAdmh", age: 19 };
                            } else if (assessmentType === 'Adolescent Assessment Update') {
                                form = { name: 'Adolescent_updateAdmh', form_name: "Adolescent_updateAdmh", age: 17 };
                            }
                    
                            this.showFormModal(event, form);
                        }}
                        />
                        {/* <h4 style={{ color: "#89BC3B" }}>Screening Forms</h4>
                        <DataTable
                          columns={this.state.screeningHistoryColumns}
                          data={this.state.screeningHistoryData}
                          customStyles={global.customTableStyles}
                          striped={true}
                          pagination
                          paginationComponentOptions={global.paginationComponentOptions}
                          highlightOnHover
                          pointerOnHover
                          noDataComponent={<div className="no_record_found">{global.no_record_found_text}</div>}
                          onRowClicked={(event) => {
                            const assessmentType = event.form_type; // Extracting Assessment Type from event
                            let form = {};
                    
                            if (assessmentType === 'UNCOPE Screening') {
                                form = { name: 'uncopeScreening', form_name: "uncopeScreening", age: 19 };
                            } else if (assessmentType === 'CRAFFT Screening') {
                                form = { name: 'crafftScreening', form_name: "crafftScreening", age: 17 };
                            }
                    
                            this.showFormModal(event, form);
                        }}
                        /> */}
                        <h4 style={{ color: "#89BC3B" }}>Upload Assesment Forms</h4>
                        <DataTable
                          columns={this.state.completedAssessmentsHistoryColumns}
                          data={this.state.changeParticipantionsHistoryData}
                          customStyles={global.customTableStyles}
                          striped={true}
                          pagination
                          paginationComponentOptions={global.paginationComponentOptions}
                          highlightOnHover
                          pointerOnHover
                          noDataComponent={<div className="no_record_found">{global.no_record_found_text}</div>}
                        />
                      </div>
                      <div className="card-footer ">
                        {/* <div className="float-right"><button type="submit" className="btn btn-primary float-right" value="proceed" onClick={() => window.location.href = global.traversArray['final_assessment']}><img src="dist/img/save-proceed_icon.png" alt="" style={{ pointerEvents: "none" }} /> Save &amp; Proceed </button>
                          <input type="hidden" id="clicked" value="" readOnly />
                        </div> */}
                        <button type="submit" className="btn btn-primary float-right" value="proceed" onClick={() => window.location.href = global.traversArray['state_assessment']}><img src="dist/img/save-proceed_icon.png" alt="" style={{ pointerEvents: "none" }} /> Save &amp; Proceed </button>
                      </div>
                      <Modal
                        showOverlay={true}
                        show={this.state.showAddAssessmentsModal}
                        onClose={this.closeAddAssessmentModal}
                        disableOverlayClick={true}
                        showCloseButton={false}
                        size="sm"
                        className="modal-md modal-dialog"
                      >
                        <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                          <Modal.Title>
                            <div className="row" >
                              <div className="col-md-6">
                                <h4 className="modal-title float-left text-green">Add Assessment</h4>
                              </div>
                            </div>
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <form id="assessmentsForm" method="post" action="javascript:void(0)">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <LabelField {...context.state.assessment_date} />(MM/DD/YYYY)
                                </div>
                              </div>
                              <div className="col-md-6 ">
                                <div className="form-group formfield">
                                  <div className="input-group-append" style={{ height: '38px', float: 'left', marginRight: '-3px' }}>
                                    <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
                                  </div>
                                  <div className="input-group date timepicker" style={{ width: "calc(100% - 41px)" }}>
                                    <span >
                                      <DateTime
                                        onChange={(date) => this.handleTimer(date, 'assessment_date')}
                                        onBlur={(date) => this.handleTimeronBlur(date, 'assessment_date')}
                                        inputProps={{ value: typeof context.state.assessmentsData.assessment_date === 'string' ? context.state.assessmentsData.assessment_date : moment.utc(context.state.assessmentsData.assessment_date).format('MM/DD/YYYY'), required: this.state.mandatoryInfo.assessment_date, name: 'assessment_date', placeholder: 'Date' }}
                                        defaultValue={moment.utc().format('MM/DD/YYYY')}
                                        dateFormat="MM/DD/YYYY" timeFormat={false}
                                        isValidDate={this.validDate}
                                        input={true}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <LabelField {...context.state.visitType} />
                                </div>
                              </div>
                              <div className="col-md-6 formfield">
                                <select required={context.state.assessed_level.mandatory} name={context.state.fieldName.visitType} className="custom-select" aria-hidden="true" onChange={this.handleAssessmentSelectChange} >
                                  {this.state.visitTypeOptions}
                                </select>
                              </div>
                            </div>
                            {/* Code Added By Ashraf End */}

                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <LabelField {...context.state.assessed_level} />
                                </div>
                              </div>
                              <div className="col-md-6 formfield">
                                <select required={context.state.assessed_level.mandatory} name={context.state.fieldName.assessed_level} className="custom-select" aria-hidden="true" onChange={this.handleAssessmentSelectChange} >
                                  {this.state.assessed_levelSelectOptions}
                                </select>
                              </div>
                            </div>

                            {context.state.assessmentsData.assessed_level === "Other" ? (
                              <div className="row">
                                <div className="col-md-6" />
                                <div className="col-md-6 ">
                                  <div className="form-group formfield">
                                    <input type="text" className="form-control" name={context.state.fieldName.other_assessed_level} id={context.state.fieldName.other_assessed_level} placeholder={this.state.labelText.other_assessed_level} defaultValue={this.state.assessmentsData.other_assessed_level} required={this.state.mandatoryInfo.other_assessed_level} maxLength={this.state.other_assessed_level.columnLength} onChange={this.handleAssessmentSelectChange} />
                                  </div>
                                </div>
                              </div>
                            ) : <input type="hidden" name="other_assessed_level" value="" />}

                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <LabelField {...context.state.placed_level} />
                                </div>
                              </div>
                              <div className="col-md-6 formfield">
                                <select required={context.state.placed_level.mandatory} name={context.state.fieldName.placed_level} className="custom-select" aria-hidden="true" onChange={this.handleAssessmentSelectChange} >
                                  {this.state.placed_levelSelectOptions}
                                </select>
                              </div>
                            </div>

                            {context.state.assessmentsData.placed_level === "Other" ? (
                              <div className="row">
                                <div className="col-md-6" />
                                <div className="col-md-6 ">
                                  <div className="form-group formfield">
                                    <input type="text" className="form-control" name={context.state.fieldName.other_placed_level} id={context.state.fieldName.other_placed_level} placeholder={this.state.labelText.other_placed_level} defaultValue={this.state.assessmentsData.other_placed_level} required={this.state.mandatoryInfo.other_placed_level} maxLength={this.state.other_placed_level.columnLength} onChange={this.handleAssessmentSelectChange} />
                                  </div>
                                </div>
                              </div>
                            ) : <input type="hidden" name="other_placed_level" value="" />}

                            <div className="row">

                            </div>

                            {context.state.assessmentsData.referral_target && context.state.assessmentsData.referral_target.filter(function (e) { return e.label === 'Other (Please Specify Below)'; }).length > 0 ? (
                              <div className="row">
                                <div className="col-md-6" />
                                <div className="col-md-6 ">
                                  <div className="form-group formfield">
                                    <input type="text" className="form-control" name={context.state.fieldName.other_referral_target} id={context.state.fieldName.other_referral_target} placeholder={this.state.labelText.other_referral_target} defaultValue={this.state.assessmentsData.other_referral_target} required={this.state.mandatoryInfo.other_referral_target} maxLength={this.state.other_referral_target.columnLength} onChange={this.handleAssessmentSelectChange} />
                                  </div>
                                </div>
                              </div>
                            ) : <input type="hidden" name="other_referral_target" value="" />}

                            <div className="row">
                              <div className="col-lg-12 col-md-12 ">
                                <div className="form-group formfield">
                                  <LabelField {...context.state.reason_for_difference} />
                                  <textarea className="form-control" id={context.state.fieldName.reason_for_difference} name={context.state.fieldName.reason_for_difference} defaultValue={context.state.assessmentsData.reason_for_difference} rows="3" onChange={this.handleAssessmentSelectChange} ></textarea>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-12 col-md-12 ">
                                <LabelField {...context.state.assets} />
                                <br />
                                <a id="upload" className="form-group btnmargin" >


                                  <input type="file" multiple hidden="" id="file_upload" accept=".pdf" onChange={this.onChangeHandler} />
                                </a>
                              </div>
                            </div>

                            <div className="row" style={{ marginTop: '10px' }}>
                              <div className="col-lg-12 col-md-12 ">
                                <div className="form-group formfield">
                                  <LabelField {...context.state.notes} />
                                  <textarea className="form-control" id={context.state.fieldName.notes} name={context.state.fieldName.notes} defaultValue={context.state.assessmentsData.notes} rows="3" onChange={this.handleAssessmentSelectChange} ></textarea>
                                </div>
                              </div>
                            </div>
                          </form>
                        </Modal.Body>
                        <Modal.Footer style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="justify-content-between" >
                                <Button className="btn btn-default float-left" id="closeButton" onClick={this.closeAddAssessmentModal} type="button" form="myForm" key="submit" htmlType="submit">Close</Button>
                              </div>
                            </div>
                            <div className="col-md-6" >
                              <button id="saveButton" type="submit" className="btn btn-success float-right btnmargin" htmlType="submit" disabled={this.state.buttonDisabled} form="assessmentsForm"> <i className="fas fa-save"></i> Save</button>
                            </div>
                          </div>
                        </Modal.Footer>
                      </Modal>
                      <Modal
                        showOverlay={true}
                        show={this.state.showNextActionModal}
                        onClose={this.closeNextActionModal}
                        disableOverlayClick={true}
                        showCloseButton={true}
                        size="sm"
                        className="modal-md modal-dialog"
                      >
                        <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                          <Modal.Title>
                            <div className="row" >
                              <div className="col-md-8">
                                <h4 className="modal-title float-left text-green">Next Actions you can take</h4>
                              </div>
                            </div>
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="modal-body">
                            <div className="row">
                              <p style={{ paddingLeft: "10px" }}>Your referral is added, you can choose to do any one of the following or click X above, to do nothing</p>
                            </div>
                            <div className="row" style={{ margin: "10px 0px 50px 0px", width: "100%" }}>


                              <div className="col-md-4">
                                <div className="btn btn-app btn-green btn-success" type="button" style={{ width: "133px" }} onClick={() => this.route('Follow-Up')}><i className="fas fa-calendar-check"></i> Add Follow-Up</div>
                              </div>

                              <div className="col-md-4">
                                <div className="btn btn-app btn-green btn-success" type="button" style={{ width: "133px" }} onClick={() => this.route('Routing')} ><i className="fas fa-medkit"></i> Add Routing</div>
                              </div>

                              <div className="col-md-4">
                                <div className="btn btn-app btn-success" type="button" style={{ width: "133px" }} onClick={() => this.route('Referral')}><i className="fas fa-prescription"></i> Add Referral</div>
                              </div>
                            </div>
                          </div>

                        </Modal.Body>

                      </Modal>
                      <Modal
                        showOverlay={true}
                        show={this.state.showAssessmentDetailsModal}
                        onClose={this.closeAssessmentDetailsModal}
                        disableOverlayClick={true}
                        showCloseButton={false}
                        size="sm"
                        className="modal-md modal-dialog"
                      >
                        <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                          <Modal.Title>
                            <div className="row" >
                              <div className="col-md-12">
                                <h4 className="modal-title float-left text-green">Assessment</h4>
                              </div>
                            </div>
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <p className="boldText">{context.state.labelText.assessment_date}</p>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <p >{context.state.assessmentsData.assessment_date}</p>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <p className="boldText">{context.state.labelText.visitType}</p>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <p >{context.state.assessmentsData.visitType}</p>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <p className="boldText">{context.state.labelText.assessed_level}</p>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <p >{context.state.assessmentsData.assessed_level}</p>
                                  {/* context.state.assessmentsData.other_assessed_level !== '' ? context.state.assessmentsData.other_assessed_level : context.state.assessmentsData.assessed_level */}
                                </div>
                              </div>
                            </div>


                            { context.state.assessmentsData.assessed_level === "Other" ?
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <p className="boldText">{context.state.labelText.other_assessed_level}</p>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <p >{context.state.assessmentsData.other_assessed_level}</p>
                                  </div>
                                </div>
                              </div> : ""
                            }

                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <p className="boldText">{context.state.labelText.placed_level}</p>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <p >{context.state.assessmentsData.placed_level}</p>
                                  {/* context.state.assessmentsData.other_placed_level !== '' ? context.state.assessmentsData.other_placed_level : context.state.assessmentsData.placed_level */}
                                </div>
                              </div>
                            </div>

                            { context.state.assessmentsData.placed_level === "Other" ?
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <p className="boldText">{context.state.labelText.other_placed_level}</p>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <p >{context.state.assessmentsData.other_placed_level}</p>
                                  </div>
                                </div>
                              </div> : ""
                            }

                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <p className="boldText">{context.state.labelText.reason_for_difference}</p>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <p>{context.state.assessmentsData.reason_for_difference}</p>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <p className="boldText">Attachments</p>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <p><ol>{attachments}</ol></p>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <p className="boldText">{context.state.labelText.notes}</p>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <p>{context.state.assessmentsData.notes}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="justify-content-between" >
                                <button className="btn btn-default float-left" id="closeButton" onClick={this.closeAssessmentDetailsModal} type="button">Close</button>
                              </div>
                            </div>
                          </div>
                        </Modal.Footer>
                      </Modal>

                      {
                        // this.state.formType=="craftScreening",
                        // this.state.formType=="uncopeScreening"?<>
                      }

                      {/* {this.state.uncopeScreening &&
                        <UncopeScrrening closeModal={this.closeModal}
                          age={this.state.age}
                          form_type={this.state.formType}
                          updateModal_uncope={this.state.uncope_form_type == "uncopeScreening" ? true : false}
                          updateModal_craft={this.state.craft_form_type == "craftScreening" ? true : false}
                          getScreeningList={this.getScreeningList}
                          saveComplete={this.state.formType == "uncopeScreening" ? this.state.issaveAndComplete_uncope : this.state.issaveAndComplete_craft}
                        />} */}

                      {/* // issaveAndComplete_uncope:false,
                        // issaveAndComplete_craft:false, */}
                      {/* saveComplete={this.state.formType == "Adult_Assessment" ? this.state.issaveAndComplete_adult_assesment : this.state.issaveAndComplete_adolecent_assesment} */}

                    </div>
                  </div>
                </div>
              </section> :
              !this.state.updateAdmh ?
                <section className="content">
                  <div className='container-fluid'>
                    {/* <Admh closeModal={this.closeModal} saveComplete={this.state.saveComplete} form_type={this.state.formType} updateModal={this.state.assement_form_type=="Adolescent_Assessment" || this.state.assement_form_type=="Adult_Assessment" ? "true": "false" }  closeSaveComplete={()=>this.setState({saveComplete: false})} closeAdmh={this.closeAdmh} age={this.state.age} /> */}
                    {/* <Admh closeModal={this.closeModal} saveComplete={this.state.saveComplete} form_type={this.state.formType} updateModal={this.state.assement_form_type == undefined ? false : true} closeSaveComplete={() => this.setState({ saveComplete: false })} closeAdmh={this.closeAdmh} age={this.state.age} /> */}
                    <Admh closeModal={this.closeModal}
                      saveComplete={this.state.formType == "Adult_Assessment" ? this.state.issaveAndComplete_adult_assesment : this.state.issaveAndComplete_adolecent_assesment}
                      savePreview={this.state.formType == "Adult_Assessment" ? this.state.is_draft_adult : this.state.is_draft_adolescent}
                      form_type={this.state.formType}
                      closeSaveComplete={() => this.setState({ saveComplete: false })}
                      closeAdmh={this.closeAdmh} age={this.state.age}
                      // updateModal={this.state.assement_form_type == undefined ? false : true}
                      updateModal_adult={this.state.adult_assement_form_type == "Adult_Assessment" ? true : false}
                      updateModal_adolecent={this.state.adolescent_assement_form_type == "Adolescent_Assessment" ? true : false}
                    />
                    {/* update_form_type */}


                  </div>

                </section> :
                <section className="content">
                  <div className='container-fluid'>
                    <UpdateAdmh
                      closeModal={this.closeModal}
                      saveComplete={this.state.formType == "Adult_updateAdmh" ? this.state.issaveAndComplete_adult_update_assesment : this.state.issaveAndComplete_adolecent_update_assesment}
                      savePreview={this.state.formType == "Adult_updateAdmh" ? this.state.is_draft_adult_update : this.state.is_draft_adolescent_update}
                      form_type={this.state.formType}
                      updateModal={this.state.update_form_type == "Adolescent_updateAdmh" || this.state.update_form_type == "Adult_updateAdmh" ? true : false}
                      closeSaveComplete={() => this.setState({ saveComplete: false })}
                      closeAdmh={this.closeAdmh}
                      age={this.state.age}
                    />

                  </div>
                </section>
            }
          </div>
        </div>
        {this.state.showLoader ? (<Loader />) : ''}
        {/* <Footer /> */}
        {this.state.openPrintModal && <>
          <Modal
            showOverlay={true}
            show={this.state.openPrintModal}
            onClose={this.closeNextActionModal}
            disableOverlayClick={true}
            showCloseButton={true}
            size="sm"
            className="modal-md modal-dialog"
          >
            <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
              <Modal.Title>
                <div className="row" >
                  <div className="col-md-8">
                    <h4 className="modal-title float-left text-green">Print Forms</h4>
                  </div>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="modal-body">
                <strong>Please select the forms to print.</strong>
                <div className='row'>
                  <div className='col-md-12'>
                    <input type="checkbox" className='mr-2' disabled={this.state.issaveAndComplete_adult_update_assesment || this.state.issaveAndComplete_adolecent_update_assesment || this.state.is_draft_adult_update || this.state.is_draft_adolescent_update ? false : true} onChange={(e) => this.handleCheckbox(e)} name="update_assesment" />State Assessment Updates <br />
                  </div>
                  <div className='col-md-12'>
                  <input type="checkbox" className='mr-2' disabled={this.state.issaveAndComplete_adult_assesment || this.state.issaveAndComplete_adolecent_assesment  || this.state.is_draft_adult ||this.state.is_draft_adolescent ? false : true} onChange={(e) => this.handleCheckbox(e)} name="assesments" />State Assessment <br />
                  </div>
                  <div className='col-md-12'>
                    <input type="checkbox" className='mr-2'disabled={((this.state.issaveAndComplete_adolecent_assesment && this.state.issaveAndComplete_craft && !this.state.issaveAndComplete_uncope) || (this.state.is_draft_adolescent && this.state.is_draft_craft)) ? false :true} onChange={(e) => this.handleCheckbox(e)} name="asais"/> ASAIS Data <br />
                  </div>

                  <div className='col-md-12'>
                    <input type="checkbox" className='mr-2' disabled={((this.state.issaveAndComplete_adult_assesment && this.state.issaveAndComplete_uncope) || (this.state.is_draft_adult && this.state.is_draft_uncope )) ? false : true} onChange={(e) => this.handleCheckbox(e)} name="profile_discharge" />Profile – Discharge <br />
                  </div>
                  {/* <div className='col-md-12'>
                    <input type="checkbox" className='mr-2' disabled={this.state.issaveAndComplete_uncope || this.state.issaveAndComplete_craft || this.state.is_draft_uncope || this.state.is_draft_craft ? false : true} onChange={(e) => this.handleCheckbox(e)} name="screening" />Screening  <br />
                  </div> */}
                  <div className='col-md-12'>
                    <b>Upload document(s)</b>
                  </div>
                  <div className='col-md-12'>
                    <button className='btn btn-outline-succes' onClick={() => { this.setState({ uploadModule: !this.state.uploadModule }) }}>Upload document(s)</button>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>

            <div className="row">
                {/* <div className="col-md-6">
                  <div className="justify-content-between" >
                    <button className="btn btn-default float-left" disabled={!this.state.printCount > 0 ? true : false} id="closeButton" onClick={(e) => { this.handleDownloadModules() }} type="button">Download document(s)</button>
                  </div>
                </div> */}
                <div className="col-md-12">
                  <div className="float-right" >
                    <button className='btn btn-primary float-right' onClick={() => { this.setState({ IsPrintAssesmentModalOpen: true }) }} disabled={!this.state.printCount > 0 ? true : false} >Preview</button>
                  </div>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        </>}

        {/* Upload Module Start */}

        <Modal
          showOverlay={true}
          show={this.state.uploadModule}
          // onClose={this.closeAssessmentDetailsModal}
          disableOverlayClick={true}
          showCloseButton={false}
          className="modal-xl modal-dialog"
          ariaHideApp={false}
          size="md"

        >
          <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
            <Modal.Title>
              <div className="row" >
                <div className="col-6">
                  <h4 className="modal-title float-left text-green">Upload document(s)</h4>
                </div>
                <div className="col-6">
                  <div className="row" style={{ display: "flex", gridTemplateColumns: "auto auto", gridTemplateRows: "auto auto", justifyContent: "end", gridGap: "6px" }}>
                    <div className="">
                    </div>
                    <div className="justify-content-between" >
                      <button className="btn btn-default float-left" id="closeButton" onClick={(e) => { this.setState({ uploadModule: false }) }} type="button">Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FileUpload files={this.state.files} setFiles={this.setFiles} removeFile={this.removeFile} />
            <FileList files={this.state.files} removeFile={this.removeFile} />

          </Modal.Body>
          <Modal.Footer style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
            <div className="row">
              <div className="col-md-6">
                <div className="justify-content-between" >
                  <button className="btn btn-default float-left" id="closeButton" onClick={(e) => { this.setState({ uploadModule: false }) }} type="button">Close</button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="float-right" >
                  <button className="btn btn-default float-left" id="closeButton" disabled={this.state.files.length > 0 ? false : true} onClick={(e) => { this.handleDownloadModules() }} type="button">Download document(s)</button>
                </div>
              </div>
            </div>
          </Modal.Footer>
        </Modal>


        {/* Upload Module End */}


        {/* Print Assesment Start */}
        <Modal
          showOverlay={true}
          show={this.state.IsPrintAssesmentModalOpen}
          // onClose={this.closeAssessmentDetailsModal}
          disableOverlayClick={true}
          showCloseButton={false}
          className="modal-xl modal-dialog"
          ariaHideApp={false}

        >
          <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
            <Modal.Title>
              <div className="row" >
                <div className="col-6">
                  <h4 className="modal-title float-left text-green">Print Assessment</h4>
                </div>
                <div className="col-6">
                  <div className="row" style={{ display: "flex", gridTemplateColumns: "auto auto", gridTemplateRows: "auto auto", justifyContent: "end", gridGap: "6px" }}>
                    <div className="">
                      <ReactToPrint
                        trigger={() => {
                          return <button className="btn btn-success " id="closeButton" type="button">Print</button>;
                        }}
                        content={() => this.printAssesmentdata}
                        documentTitle={this.state.PDFName}
                      />
                    </div>
                    <div className="justify-content-between" >
                      <button className="btn btn-default float-left" id="closeButton" onClick={(e) => { this.setState({ IsPrintAssesmentModalOpen: false }) }} type="button">Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div ref={el => (this.printAssesmentdata = el)}>
              {/* {this.state.printModule.filter((val) => val.name == "screening" && val.val).length > 0 && <PrintScreening />} */}
              {this.state.printModule.filter((val) => val.name == "assesments" && val.val).length > 0 && <PrintAssesment />}
              {this.state.printModule.filter((val) => val.name == "update_assesment" && val.val).length > 0 && <PrintUpdateAssesment />}
              {this.state.printModule.filter((val) => val.name == "asais" && val.val).length > 0 && <Printasias />}
              {this.state.printModule.filter((val) => val.name == "profile_discharge" && val.val).length > 0 && <Printdischarge />}
              
            </div>
          </Modal.Body>
          <Modal.Footer style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
            <div className="row">
              <div className="col-md-6">
                <div className="justify-content-between" >
                  <button className="btn btn-default float-left" id="closeButton" onClick={(e) => { this.setState({ IsPrintAssesmentModalOpen: false }) }} type="button">Close</button>
                </div>
              </div>
              <div className="col-md-6">
                <div className="float-right" >
                  <ReactToPrint
                    trigger={() => {
                      return <button className="btn btn-success float-left" id="closeButton" type="button">Print</button>;
                    }}
                    content={() => this.printAssesmentdata}
                    documentTitle={this.state.PDFName}
                  />
                </div>
              </div>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Print Profile Discharge End*/}
      </div >
    );
  }

}

export default Assessments;