import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Topnav from './Topnav';
import Footer from './Footer';
import Patientinfoheader from './Patientinfoheader';
import Sidenav from './Sidenav';
import { validationParser } from '../ReusableComponents/validationParser2';
import { formObjDataMapper, formObjDataMapper2 } from '../ReusableComponents/formObjDataMapper';
import TextAreaAutosize from 'react-textarea-autosize';
import ReactTooltip from 'react-tooltip';
import Tooltip from '../ReusableComponents/Tooltip';
import DataTable, { defaultThemes } from 'react-data-table-component';
import Modal from './Modals/Modal/index';
import moment from 'moment-timezone';
import '../global.js';
import $ from 'jquery';

import { can_add } from '../ReusableComponents/urlGenerator';
import { encryptStorage } from './LocalStorageInterceptor';
var validationObj = {};
var context;

const axios = require('axios').default;

const customStyles = {
    table: {
        style: {
            color: defaultThemes.default.text.primary,
            backgroundColor: defaultThemes.default.background.default,
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "#F9F9F9"
        },
    },
    header: {
        style: {
            minHeight: '1px',
        },
    },
    headRow: {
        style: {
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: defaultThemes.default.divider.default,
            backgroundColor: "#F9F9F9",
            minHeight: "40px"
        },
    },
    headCells: {
        style: {
            '&:not(:last-of-type)': {
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                borderRightColor: defaultThemes.default.divider.default,
                fontWeight: "bold",
                fontSize: "0.91rem"
            },
        },
    },
    row: {
        style: {
            minHeight: '20px',
            borderRightStyle: 'solid',
            borderRightWidth: '10px',
            borderRightColor: defaultThemes.default.divider.default,
        }
    },
    cells: {
        style: {
            '&:not(:last-of-type)': {
                // borderStyle: 'solid',
                // borderWidth: '1px',
                // borderColor: defaultThemes.default.divider.default,
                borderLeftStyle: 'solid',
                borderLeftWidth: '1px',
                borderLeftColor: defaultThemes.default.divider.default,
                fontSize: '0.9rem'
            },
        },
    },
};

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: isDisabled
                ? null
                : isSelected
                    ? "green"
                    : isFocused
                        ? "#0074F0"
                        : null,
            color: isDisabled
                ? '#ccc'
                : isFocused
                    ? "white"
                    : isSelected
                        ? "white"
                        : "black",

            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled && (isSelected ? "grey" : "red"),
            },
        };
    },
    multiValue: (styles, { data }) => {
        // const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: "#007BFF",
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: "white",
        whiteSpace: "inherit"
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: "white",
        ':hover': {
            backgroundColor: "red",
            color: 'white',
        },
    }),
};

class Notes extends Component {

    constructor() {
        super();
        context = this;

        this.state = {

            notesData: this.getModal("notesData"),
            mandatoryInfo: this.getModal("notesData"),
            labelText: this.getModal("notesData"),
            tooltips: this.getModal("notesData"),
            tooltipsVisibility: this.getModal("notesData"),
            fieldName: this.getModal("notesData"),
            fieldType: this.getModal("notesData"),

            notesHistoryData: [],
            interviewers: [],
            notesHistoryColumns: this.getModal("notesHistoryColumns"),

            //Modals
            showAddNotesModal: sessionStorage.getItem('modalOpen') ? true : false,
            showNotesDetailsModal: false
        };
    }

    getModal(type) {
        let dataModel = '';
        switch (type) {
            case "notesData":
                dataModel = {
                    title: '',
                    note: '',
                    client_id: '',
                    version: '',
                    updated_on: '',
                    updated_by: '',
                }
                break;

            case "resetData":
                dataModel = {
                    title: '',
                    note: '',
                    client_id: '',
                    version: '',
                    updated_on: '',
                    updated_by: '',
                }
                break;

            case "notesHistoryColumns":
                dataModel = [
                    {
                        name: 'Title',
                        selector: 'title',
                        sortable: true,


                    },
                    {
                        name: 'Added On',
                        selector: 'updated_on',
                        sortable: true,
                        cell: (row) => {
                            return moment.unix(row.updated_on).tz("America/New_York").format("MM/DD/YYYY hh:mm a");
                        },
                    },
                    {
                        name: 'Added By',
                        selector: 'updated_by',
                        sortable: true,
                    },
                    {
                        name: 'View',
                        sortable: false,
                        cell: (row) => (
                            <div>
                                <Link to="#"><i id={row.id} lang="none" className="fas fa-eye" aria-hidden="true" data-toggle="tooltip" title="view" onClick={this.handleNotesDetails}></i></Link>
                            </div>
                        ),
                        ignoreRowClick: true,
                        allowOverflow: true,
                        button: true,
                    },
                ];
                break;
        }
        return dataModel;
    }

    componentWillMount() {
        // for fetching layout definition
        axios.get(global.restApiURL + 'webservice/rest/class/id/43?apikey=' + sessionStorage.getItem('__CL_TOKEN__'))
            .then(res => {
                var response = res.data;

                if (response.success && res.status==200) {
                    validationObj = validationParser(response.data.layoutDefinitions.childs[0].childs);
                    console.log("validation : ", validationObj);

                    this.setState({
                        labelText: validationObj.title,
                        mandatoryInfo: validationObj.mandatory,
                        tooltips: validationObj.tooltip,
                        tooltipsVisibility: validationObj.tooltipVisibility,
                        fieldName: validationObj.name,
                        fieldType: validationObj.fieldtype,
                    });

                }
                else if ("error_code" in response) {
                    alert(response.msg);
                    window.location.href = global.domain + global.traversArray['out'];
                }
                else {
                    alert(response.msg);
                }

            })
            .catch(err => { // log request error and prevent access to undefined state
                this.setState({ loading: false, error: true });
                console.error(err);
            });
    }

    componentDidMount() {
        if (context.state.interviewers.length === 0) {
            let getInterviewersListBody = {
                apikey: localStorage.getItem('__TOKEN__'),
            };

            axios.post(global.restApiURL + 'customRest/getAttendants', JSON.stringify(getInterviewersListBody))
                .then(res => {

                    if (res.data.success && res.status==200) {
                        // const response = res.data.data.elements;
                        console.log("Attendies data : ", res.data.data);
                        // context.parseFollowupHistory(res.data.data);

                        context.setState({
                            interviewers: res.data.data,
                        })

                        // this.overallResponseCalculator();    

                        console.log("interviewers : ", this.state.interviewers)
                    }
                    else if ("error_code" in res) {
                        alert(res.msg);
                        window.location.href = global.domain + global.traversArray['out'];
                    }
                    else {
                        alert(res.msg);
                    }
                })
                .catch(err => { // log request error and prevent access to undefined state
                    this.setState({ loading: false, error: true });
                    console.error(err);
                })
        }

        if (sessionStorage.getItem('clientId') !== '') {

            let getNoteListBody = {
                apikey: localStorage.getItem('__TOKEN__'),
                client_id: sessionStorage.getItem('clientId'),
            };

            axios.post(global.restApiURL + 'customRest/getNotesList', JSON.stringify(getNoteListBody))
                .then(res => {

                    if (res.data.success && res.status==200) {
                        // const response = res.data.data.elements;

                        setTimeout(function () {

                            context.setState({
                                notesHistoryData: context.parseNoteHistory(res.data.data)
                            });
                            console.log("obj his data : ", context.state.notesHistoryData);
                        }, 50)

                    }
                    else if ("error_code" in res) {
                        alert(res.msg);
                        window.location.href = global.domain + global.traversArray['out'];
                    }
                    else {
                        alert(res.msg);
                    }


                })
                .catch(err => { // log request error and prevent access to undefined state
                    this.setState({ loading: false, error: true });
                    console.error(err);
                })
        }

    }

    parseNoteHistory(historyDataArray) {
        let parsedData = [];

        parsedData = historyDataArray.length > 0 && historyDataArray.map((item, i) => {

            var op = {};

            item.map((val) => {
                op[val.name] = val.value;
            })

            // console.log("op : " , op );

            return op;

        }, this);

        // console.log("parsed Data : " , parsedData);

        return parsedData;
    }

    getAssignedInterviewerName(staffId) {
        let name = '';
        context.state.interviewers.map((val) => {

            if (Number(val.id) === Number(staffId))
                name = val.name;

        });

        return name;
    }

    handleNotesDetails(event) {
        let historyID = event.target.id;

        console.log("history : ", historyID);

        context.setState({
            notesData: context.getModal("notesData")
        })

        axios.get(global.restApiURL + 'webservice/rest/object/id/' + historyID + '?apikey=' + localStorage.getItem('__TOKEN__'))
            .then(res => {

                const response = res.data.data.elements;
                //console.log("Obj data , res : " , formObjDataMapper2(response , context.getModal("followupdata")) , response );

                let parsedNotesDetails = formObjDataMapper2(response, context.getModal("notesData"));

                context.setState({
                    notesData: parsedNotesDetails,
                    showNotesDetailsModal: true
                })

                console.log(context.state.notesData);


                // context.onFollowUpDetailsModalOpen();

            })
            .catch(err => { // log request error and prevent access to undefined state
                context.setState({ loading: false, error: true });
                console.error(err);
            })
    }

    openAddNoteModal() {
        context.setState({
            showAddNotesModal: true, notesData: context.getModal('resetData')
        })
    }

    closeAddNoteModal() {
        sessionStorage.removeItem('modalOpen');
        context.setState({
            showAddNotesModal: false,
            notesData: context.getModal('resetData')
        })
    }

    closeNotesDetailsModal() {
        context.setState({ showNotesDetailsModal: false, notesData: context.getModal('resetData') });
    }

    handleNoteChange = (event) => {
        let notesDataCopy = context.state.notesData;
        notesDataCopy[event.target.name] = event.target.value;
        context.setState({ notesData: notesDataCopy });
    }

    saveNotesdetails() {
        var jsonData = '';
        if (!context.state.notesHistoryData || context.state.notesHistoryData.length === 0) {
            jsonData = { 'className': 'Notes', 'key': (sessionStorage.getItem('clientId') + "-1"), 'published': true };
        } else {

            jsonData = { 'className': 'Notes', 'key': (sessionStorage.getItem('clientId') + "-" + (context.state.notesHistoryData.length + 1)), 'published': true };
        }

        jsonData.elements = [];
        jsonData.elements.push({ name: 'title', value: context.state.notesData.title });
        jsonData.elements.push({ name: 'note', value: context.state.notesData.note });
        jsonData.elements.push({ name: 'updated_on', value: moment().format("MM/DD/YYYY HH:mm:ss") });
        jsonData.elements.push({ name: 'updated_by', value: sessionStorage.getItem('loggedinUserID') });
        jsonData.elements.push({ name: 'client_id', value: sessionStorage.getItem('clientId') });

        var json = JSON.stringify(jsonData);

        console.log("Data to send : ", json);

        //context.onModalClose();

        axios.post(global.save_url, json)
            .then(function (res) {
                var response = res.data;
                // console.log("save response" , response.success );
                if (response.success && res.status==200) {
                    sessionStorage.setItem('success_msg', "Note added successfully.");
                    window.location.href = "";
                }
                else if ("error_code" in response) {
                    alert(response.msg);
                    window.location.href = global.domain + global.traversArray['out'];
                }
                else {
                    alert(response.msg);
                    context.closeAddNoteModal();
                }

                console.log("Msg : ", sessionStorage.getItem('success_msg'));
            })
            .catch(function (err) {
                console.log(err.message);
            });



        sessionStorage.removeItem('modalOpen');

    }
    

    render() {

        $(document).ready(function () {
            $('#Notesform').validate({

                submitHandler: function () {
                    context.saveNotesdetails();
                },

                errorElement: 'span',
                errorPlacement: function (error, element) {
                    error.addClass('invalid-feedback');
                    element.closest('.form-group').append(error);
                },
                highlight: function (element, errorClass, validClass) {
                    $(element).addClass('is-invalid');
                },
                unhighlight: function (element, errorClass, validClass) {
                    $(element).removeClass('is-invalid');
                }
            });
        });
        sessionStorage.setItem('formname', 'Notes');

        

        return (

            <div className="hold-transition sidebar-mini text-sm">
                <ReactTooltip place="right" type="dark" effect="solid" />
                <div className="wrapper">
                    <Topnav />
                    <div className="content-wrapper">

                        <Patientinfoheader clientId={sessionStorage.getItem('clientId')} dob={encryptStorage.getItem('dob')}
                            email={encryptStorage.getItem('email')} phone={encryptStorage.getItem('phone')}
                            peer_support_specialist={encryptStorage.getItem('peer_support_specialist')} next_followup_date={encryptStorage.getItem('month_1_followup_date')} intake_date={encryptStorage.getItem('intake_date')} initiated_by={encryptStorage.getItem('last_updated_by')} formname={sessionStorage.getItem('formname')} client_name={encryptStorage.getItem('client_first_name') + " " + encryptStorage.getItem('client_middle_initial') + " " + encryptStorage.getItem('client_last_name')} />
                        <section className="content">
                            <div className="row">
                                <Sidenav />

                                <div className="container-fluid col-md-9 col-lg-10">
                                    <div className="card card-default">
                                        <div className="card-header">
                                            <p className="lead float-left"><b className="text-green">Notes</b></p>
                                            <button type="submit" className="btn btn-primary float-right" value="proceed" onClick={() => window.location.href = global.traversArray['notes']}><img src="dist/img/save-proceed_icon.png" alt="" style={{ pointerEvents: "none" }} /> Save &amp; Proceed </button>
                                            {(can_add('Notes')) ? <button type="submit" className="btn btn-success float-sm-right btnmargin" value="all" onClick={this.openAddNoteModal} lang="all" ><i className="fas fa-plus" aria-hidden="true"></i> Add Note</button> : ''}

                                            <input type="hidden" id="clicked" value="" readOnly />
                                            <input type="text" id="client_id" name="client_id" style={{ display: "none" }} defaultValue={sessionStorage.getItem('clientId')} />
                                        </div>

                                        <div className="card-body">
                                            <DataTable
                                                columns={this.state.notesHistoryColumns}
                                                data={this.state.notesHistoryData}
                                                customStyles={global.customTableStyles}
                                                striped={true}

                                                pagination
                                                paginationComponentOptions={global.paginationComponentOptions}
                                                highlightOnHover
                                                pointerOnHover
                                                noDataComponent={<div className="no_record_found">{global.no_record_found_text}</div>}
                                            />
                                        </div>
                                        <div class="card-footer">
                                            <div class="float-right">
                                                <button type="submit" className="btn btn-primary float-right" value="proceed" onClick={() => window.location.href = global.traversArray['notes']}><img src="dist/img/save-proceed_icon.png" alt="" style={{ pointerEvents: "none" }} /> Save &amp; Proceed </button>
                                                {can_add('Notes') ? <button type="submit" className="btn btn-success float-sm-right btnmargin" value="all" onClick={this.openAddNoteModal} lang="all" ><i className="fas fa-plus" aria-hidden="true"></i> Add Note</button> : ''}
                                            </div>
                                        </div>

                                        <Modal
                                            showOverlay={true}
                                            show={this.state.showAddNotesModal}
                                            onClose={this.closeAddNoteModal}
                                            disableOverlayClick={true}
                                            showCloseButton={false}
                                            size="sm"
                                            className="modal-md modal-dialog"
                                        >
                                            <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                                                <Modal.Title>
                                                    <div className="row" >
                                                        <div className="col-md-12">
                                                            <h4 className="modal-title float-left text-green">Add Note <small style={{ color: "#000" }}>Capture quick notes here</small></h4>
                                                        </div>

                                                    </div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <form id="Notesform" method="post" action="javascript:void(0)">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <input type="text" className="form-control" placeholder={context.state.mandatoryInfo.title ? "Title *" : "Title"}  id={context.state.fieldName.title} name={context.state.fieldName.title} defaultValue={context.state.notesData.title} onChange={this.handleNoteChange} required={context.state.mandatoryInfo.title} />
                                                            </div>
                                                            <div className="form-group">
                                                                <TextAreaAutosize className="form-control" placeholder={context.state.mandatoryInfo.note ? "Add Note *" : "Add Note"} id={context.state.fieldName.note} name={context.state.fieldName.note} defaultValue={context.state.notesData.note} rows={1} onChange={this.handleNoteChange} required={context.state.mandatoryInfo.note}></TextAreaAutosize>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </Modal.Body>
                                            <Modal.Footer style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="justify-content-between" >
                                                            <button className="btn btn-default float-left" id="closeButton" onClick={this.closeAddNoteModal} type="button">Close</button>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6" >
                                                        <button id="saveButton" className="btn btn-success float-right btnmargin" htmlType="submit" form="Notesform"> <i className="fas fa-save"></i> Save</button>
                                                    </div>
                                                </div>
                                            </Modal.Footer>
                                        </Modal>

                                        <Modal
                                            showOverlay={true}
                                            show={this.state.showNotesDetailsModal}
                                            onClose={this.closeNotesDetailsModal}
                                            disableOverlayClick={true}
                                            showCloseButton={false}
                                            size="sm"
                                            className="modal-md modal-dialog"
                                        >
                                            <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                                                <Modal.Title>
                                                    <div className="row" >
                                                        <div className="col-md-6">
                                                            <h4 className="modal-title float-left text-green">Note</h4>
                                                        </div>
                                                    </div>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <p className="boldText">{context.state.labelText.title}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <p >{context.state.notesData.title}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <p className="boldText">{context.state.labelText.note}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <p >{context.state.notesData.note}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <p className="boldText">{context.state.labelText.updated_on}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <p >{context.state.notesData.updated_on}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <p className="boldText">{context.state.labelText.updated_by}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <p >{this.getAssignedInterviewerName(context.state.notesData.updated_by)}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                                                <div className="row">
                                                    <div className="col-md-6">

                                                        <div className="justify-content-between" >
                                                            <button className="btn btn-default float-left" id="closeButton" onClick={this.closeNotesDetailsModal} type="button">Close</button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal.Footer>
                                        </Modal>

                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

                <Footer />

            </div>
        );
    }

}

export default Notes;