import React, { Component, useCallback } from 'react';
import { useStore } from "../store/store";
import $ from 'jquery';
import { Alert } from "./toastify";
import { Link } from 'react-router-dom'
//import SessionHelper from 'session-helper'
import '../global.js';
import Modal from './Modals/Modal/index';
import BreachlockImage from '../assests/images/BreachlockImage.png';
import { Base64 } from 'js-base64';


const axios = require('axios').default;
var context;


class Login extends Component {
  constructor() {
    // const { state, dispatch } = useStore();
    // const setKey = useCallback(() => dispatch({ type: "setKey" }), [dispatch]);
    super();
    context = this;
    this.state = {
      email: '',
      password: '',
      key: '',
      emailModal: false,
      hover: false,
      showLoader: false,
      logOutURL: ''
    };
    this.sendEmail = this.sendEmail.bind(this);
  }

  componentDidMount() {
    // localStorage.setItem('lastKeyPressTime', new Date().getTime() / 1000);
    let path = new URLSearchParams(this.props.location.search).get("page");
    if (path == "forget") {
      this.setState({ emailModal: true });
    }
    if(localStorage.getItem('username')){
      console.log(localStorage.getItem('username') + 'ne Loging kr rkha hai');
      // let WS02Body = {
      //   userName : localStorage.getItem('username')
      // };
      // axios.post(global.restApiURL + 'customRest/WS02Logout', JSON.stringify(WS02Body)).then(res => {
      //   if (res.data.success && res.status == 200) {
      //     // alert('You have logged out.');
      //     localStorage.clear();
      //     this.setState({logOutURL:res.data.url})
      //     // window.location.href = res.data.url;//global.domain;
      //   } else if ("error_code" in res) {
      //     Alert('error', 'Error occured while Logging out from WS02. Try again!');
      //     window.location.href = global.domain + global.traversArray['out'] + '?sp=CHIPSS&state=logout';
      //   }
      //   else {
      //     Alert('error', 'Error occured while Logging out from WS02. Try again!');
      //     window.location.href = global.domain + global.traversArray['out'] + '?sp=CHIPSS&state=logout';
      //   }
      // }).catch(err => {
      //   alert(err);
      //   console.log(err);
      // })
      // sessionStorage.clear();
     // window.location.href = global.domain;
    }
  }

  onModalClose = () => {
    this.setState({ emailModal: false, email: '' });
  }

  changeHandler = (e) => {
    let name = e.target.name;
    this.setState({ [name]: e.target.value });
  }

  sendEmail = () => {
    const payload = {
      'email': Base64.encode(this.state.email)
    };
    context.setState({ showLoader: true });
    axios.post(global.restApiURL + 'customRest/forgetPassword', JSON.stringify(payload)).then((res) => {
      var response = res.data;
      if (response.success) {
        context.setState({ showLoader: false });
        alert(response.msg);
        context.setState({ emailModal: false, email: "" });
      }
      else if (response.success == false) {
        context.setState({ showLoader: false });
        alert(response.msg);
        context.setState({ emailModal: false, email: '' });
      }
    }).catch(function (error) {
      context.setState({ showLoader: true });
      console.log(error);
      alert("User doesn't exist!");
      context.setState({ emailModal: false, email: "" });
    });
  }

  forgetPassword = () => {
    this.setState({ emailModal: true });
  }

  hover = () => {
    this.setState({ hover: true });
  }

  render() {
    return (
      <div>
        <iframe src={this.state.logOutURL} referrerpolicy="unsafe-url" hidden="true"></iframe>
        <div className="hold-transition login-page">
          <div className="login-box" >
            <div className="login-logo">
              <a href="#">
                <img src={window.location.origin + '/images/dcaris-logo-green.png'} alt="DCARIS" />
              </a><br /> <br />
              <h5 style={{ fontSize: '1.35rem', color: '#89bc3b' }}>Collaboration Health Information<br />for Peer Support Specialists</h5>
            </div>
            <div className="card">
              <div className="card-body login-card-body">
                <div id="message" className="invalid-feedback">dssdf</div>
                <form action="javascript:void(0);" method="post" id="loginform">
                  <div className="row">
                    <div className="col-12">
                      <a href="https://apidev.chipss.org/customRest/wso2Authenication" className="btn btn-primary btn-block" style={{ color: '#fff', backgroundColor: '#89bc3b', borderColor: 'rgb(24 167 56)' }} id="wso2_auth">Sign In to CHIPSS</a>
                    </div>
                  </div>
                </form>
                <div style={{ textAlign: 'center', position: 'relative', top: '15px' }}>
                  <a className={this.state.hover ? "hoverforget" : ""} style={{ color: '#2aabe4' }} onMouseLeave={this.hover} onClick={this.forgetPassword}>Forgot Password?</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {this.state.showLoader && <Loader /> } */}
        <div style={{ marginTop: "-145px", display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
          <img width={"150px"} height={"150px"} src={BreachlockImage} alt="Breachlock"></img>
        </div>
        <Modal
          showOverlay={true}
          show={this.state.emailModal}
          onClose={this.onModalClose}
          disableOverlayClick={true}
          showCloseButton={false}
          size="xs"
          className="modal-md modal-dialog"
        >
          <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
            <Modal.Title>
              <div className="row" >
                <div className="col-md-6">
                  <h4 className="modal-title float-left text-green">Forgot Password</h4>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-body input-group mb-3">
              <input type="email" name="email" className="form-control" value={this.state.email} placeholder="Email" onChange={this.changeHandler} />
            </div>
          </Modal.Body>
          <Modal.Footer style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
            <div className="row">
              <div className="col-md-12">
                <div className="justify-content-between">
                  <button className="btn btn-default float-left" onClick={this.onModalClose} type="button">Close</button>
                  <button className="btn btn-success" onClick={this.sendEmail}>Send Email</button>
                </div>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default Login
