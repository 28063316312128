import React, { Component } from 'react';
import $ from 'jquery';
import ReactToPrint from 'react-to-print';
import PrintScreening from './Print/printScreening.js';
import Topnav from './Topnav.js';
import Patientinfoheader from './Patientinfoheader.js';
import Sidenav from './Sidenav.js';
import { formObjDataMapper2 } from '../ReusableComponents/formObjDataMapper.js';
import { multiSelectOptionGenerator, createMultiselectDataToSend, selectOptionsGenerator, interviewersOptionsGenerator } from '../ReusableComponents/OptionsGenerator.js';
import Modal from './Modals/Modal/index';
import { Alert } from './toastify.js';
import DataTable from 'react-data-table-component';
import moment from 'moment-timezone';
import '../../node_modules/react-datetime/css/react-datetime.css';
import '../global.js';
import UncopeScrrening from './uncopeScrrening.js';
// Module Upload File
import { encryptStorage } from '../LocalStorageInterceptor.js';

var validationObj = {};
var context;

const axios = require('axios').default;

class CraftScreen extends Component {

    constructor(props) {
        super(props);
        context = this;
        this.Print_assesmentRef = React.createRef();
        this.state = {
            uncopModal: false,
            craftModal: false,
            uncopeScreening: false,
            screeningHistoryData: [],
            screeningHistoryColumns: this.getModal("screeningHistoryColumns"),
            issaveAndComplete_uncope: false,
            issaveAndComplete_craft: false,
            is_draft_uncope: false,
            is_draft_craft: false,
            printModule: { screening: false },
            printCount: false,
            IsPrintAssesmentModalOpen: false,
            formType : ''
        };
    }

    getModal(type) {
        let dataModel = '';
        switch (type) {
            case "screeningHistoryColumns":
                dataModel = [
                    {
                        name: 'Date Completed',
                        selector: 'created_date',
                        sortable: true,
                    },
                    {
                        name: 'Created By',
                        selector: 'created_by',
                        sortable: true,
                    },
                    {
                        name: 'Screening Type',
                        selector: "form_type",
                        sortable: true,
                    },
                    {
                        name: 'Modification Date',
                        selector: "modification_date",
                        sortable: true,
                    },
                    {
                        name: 'Status',
                        selector: "status",
                        sortable: true,
                    },
                ];
            break;
        }
        return dataModel;
    }

    closeModal = () => {
        this.setState({ uncopeScreening: false })
    }

    componentDidMount() {
        this.getScreeningDetails();
        this.getScreeningList();
        this.getScreeningScrore();
    }

    // +++++++++++++++++++++++++++++++++++---getAssessmentsList----End--++++++++++++++++++++++++++++

    // =====================================################===========================================

    // +++++++++++++++++++++++++++++++++++---getScreeningList----Start--++++++++++++++++++++++++++++


    getScreeningList = () => {
        if (sessionStorage.getItem('clientId') !== '') {
            let getScreeningListBody = {
                apikey: localStorage.getItem('__TOKEN__'),
                client_id: sessionStorage.getItem('clientId'),
                form_type : 'craftScreening'
            };
            axios.post(global.restApiURL + 'customRest/getUncofeCraftListNew', JSON.stringify(getScreeningListBody))
                .then(res => {
                    if (res.data.success && res.status == 200) {
                        Object.keys(sessionStorage).forEach(key => {
                            if (key.startsWith("UNCOPE Screening_form_type_status") || key.startsWith("CRAFFT Screening_form_type_status")) {
                                sessionStorage.setItem(key, "");
                            }
                        });
                        context.parseAssessmentHistory(res.data.data).map((myData) => {
                            if (myData.form_type == 'UNCOPE Screening') {
                                sessionStorage.setItem("UNCOPE Screening_form_type_status", myData.status)
                            }
                            if (myData.form_type == 'CRAFFT Screening') {
                                sessionStorage.setItem("CRAFFT Screening_form_type_status", myData.status)
                            }
                        })
                        setTimeout(function () {
                            context.setState({
                                screeningHistoryData: context.parseAssessmentHistory(res.data.data)
                            });
                            context.setState({ xyz: res.data.data })
                        }, 50)
                    }
                    else if ("error_code" in res) {
                        alert(res.msg);
                        window.location.href = global.domain + global.traversArray['out'];
                    }
                    else {
                        alert(res.msg);
                    }
                })
                .catch(err => { // log request error and prevent access to undefined state
                    this.setState({ loading: false, error: true });
                    console.error(err);
                }
            )
        }
    }

    saveAndComplete = async () => {
        let final_data = {
            //  form_type: this.props.form_type,
            client_id: sessionStorage.getItem('clientId'),
            apikey: localStorage.getItem('__TOKEN__'),
        }
        let res = await axios.post(global.restApiURL + "customrest/checkIfAssessmentCompleted", JSON.stringify(final_data));
        if (res.data.success && res.status == 200) {
            if (res.data.data.is_completed_adult_assessment == "1") {
                this.setState({ issaveAndComplete_adult_assesment: true })
            } if (res.data.data.is_completed_adolescent_assessment == "1") {
                this.setState({ issaveAndComplete_adolecent_assesment: true })
            } if (res.data.data.is_completed_adult_update == "1") {
                this.setState({ issaveAndComplete_adult_update_assesment: true })
            } if (res.data.data.is_completed_adolescent_update == "1") {
                this.setState({ issaveAndComplete_adolecent_update_assesment: true })
            } if (res.data.data.is_completed_craft == "1") {
                this.setState({ issaveAndComplete_craft: true })
            } if (res.data.data.is_completed_uncope == "1") {
                this.setState({ issaveAndComplete_uncope: true })
            } if (res.data.data.is_draft_adolescent == "1") {
                this.setState({ is_draft_adolescent: true })
            } if (res.data.data.is_draft_adolescent_update == "1") {
                this.setState({ is_draft_adolescent_update: true })
            } if (res.data.data.is_draft_adult == "1") {
                this.setState({ is_draft_adult: true })
            } if (res.data.data.is_draft_adolescent == "1") {
                this.setState({ is_draft_adolescent: true })
            } if (res.data.data.is_draft_uncope == "1") {
                this.setState({ is_draft_uncope: true })
            } if (res.data.data.is_draft_craft == "1") {
                this.setState({ is_draft_craft: true })
            } if (res.data.data.is_draft_adult_update == "1") {
                this.setState({ is_draft_adult_update: true })
            }
        }
    }

    // +++++++++++++++++++++++++++++++++++---getScreeningList----End--++++++++++++++++++++++++++++
    // =====================================################===========================================
    // +++++++++++++++++++++++++++++++++++---getScreeningDetails----Start--++++++++++++++++++++++++++++
    getScreeningDetails = () => {
        var dataListBody = {
            apikey: localStorage.getItem('__TOKEN__'),
            client_id: sessionStorage.getItem("clientId"),
        }
        axios.post(global.restApiURL + 'customRest/getClientAssessmentFrominfo', JSON.stringify(dataListBody))
            .then(res => {
                if (res.data.success && res.status == 200) {
                    this.setState({
                        issaveAndComplete_uncope: res.data.data.is_completed_uncope,
                        issaveAndComplete_craft: res.data.data.is_completed_craft,
                        is_draft_uncope: res.data.data.is_draft_uncope,
                        is_draft_craft: res.data.data.is_draft_craft
                    });
                }
            }).catch(() => {
                alert("Failed");
            }
        )
    }
    // +++++++++++++++++++++++++++++++++++---getScreeningDetails----End--++++++++++++++++++++++++++++
    // ===============================================================================================
    // ====================== Justification API call for Fiiling the Assesment--START========================
    getScreeningScrore = async () => {
        let payload = {
            apikey: localStorage.getItem('__TOKEN__'),
            client_id: sessionStorage.getItem("clientId"),
        }
        let res = await axios.post(global.restApiURL + 'customRest/getScreenScore', JSON.stringify(payload))
        if (res.data.success) {
            sessionStorage.setItem("uncopeScore", res.data.data.uncope_score)
            sessionStorage.setItem("craftScore", res.data.data.craft_score)
        }
    }

    // ====================== Justification API call for Fiiling the Assesment--END========================
    parseAssessmentHistory(historyDataArray) {
        let parsedData = [];
        parsedData = historyDataArray.length > 0 && historyDataArray.map((item, i) => {
            var op = {};
            item.map((val) => {
                op[val.name] = val.value;
            })
            return op;
        }, this);
        return parsedData;
    }

    createSelectOptions() {
        if (validationObj !== "") {
            this.setState({
                assessed_levelSelectOptions: selectOptionsGenerator(validationObj.options.assessed_level, context.state.assessmentsData.assessed_level),
                placed_levelSelectOptions: selectOptionsGenerator(validationObj.options.placed_level, context.state.assessmentsData.placed_level),
                visitTypeOptions: selectOptionsGenerator(validationObj.options.visitType, this.state.assessmentsData.visitType),
                referral_targetMultiOptions: multiSelectOptionGenerator(validationObj.options.referral_target),
            });
        }
    }

    handleAssessmentDetails(objId) {
        let historyID = objId;
        context.setState({
            assessmentsData: context.getModal("assessmentsData")
        })
        axios.get(global.restApiURL + 'webservice/rest/object/id/' + historyID + '?apikey=' + localStorage.getItem('__TOKEN__'))
            .then(res => {
                const response = res.data.data.elements;
                let parsedAssessmentDetails = formObjDataMapper2(response, context.getModal("assessmentsData"));
                context.setState({
                    assessmentsData: parsedAssessmentDetails,
                    showAssessmentDetailsModal: true
                })
                context.createSelectOptions();
            })
            .catch(err => { // log request error and prevent access to undefined state
                context.setState({ loading: false, error: true });
                console.error(err);
            })
        if (sessionStorage.getItem('viewDetail') > 0) {
            sessionStorage.removeItem('viewDetail')
        }
    }

    openAddAssessmentModal() {
        context.setState({
            showAddAssessmentsModal: true,
            showAssessmentDetailsModal: false,
            showNextActionModal: false
        })
    }

    closeAddAssessmentModal = () => {
        context.setState({
            assessmentsData: context.getModal('resetData'),
            showAddAssessmentsModal: false
        })
        setTimeout(() => context.createSelectOptions(), 100);
        sessionStorage.removeItem('modalOpen');
    }

    handleAssessmentSelectChange(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState((prevState) => ({
            ...prevState,
            assessmentsData: {
                ...prevState.assessmentsData,
                [name]: value
            }
        }));
    }

    handleMultiSelectChanges = (selectedOption, event) => {
        var updatedState = this.state.assessmentsData;
        updatedState[event.name] = selectedOption;
        this.setState(prevState => ({
            ...prevState,
            assessmentsData: updatedState
        }));
    };

    saveAssessmentsdetails1 = (event) => {
        if (sessionStorage.getItem('clientId') !== '') {
            var jsonData = '';
            if (sessionStorage.getItem('AssessmentListData_length') === 0) {
                jsonData = { 'className': 'FinalAssessments', 'key': (sessionStorage.getItem('clientId') + "-1"), 'published': true };
            } else {
                jsonData = { 'className': 'FinalAssessments', 'key': (sessionStorage.getItem('clientId') + "-" + (parseInt(sessionStorage.getItem('AssessmentListData_length')) + 1)), 'published': true };
            }
            jsonData.elements = [];
            let jsonData_validation = [];
            let keys = Object.keys(context.getModal("assessmentsData"));
            keys.map((key) => {
                if (key !== 'referral_target') {
                    jsonData.elements.push({ name: context.state.fieldName[key], value: context.state.assessmentsData[key] });
                    if (context.state.assessmentsData[key] !== '') {
                        jsonData_validation.push(key);
                    }
                }
            })
            jsonData.elements.push({ name: context.state.fieldName.referral_target, value: createMultiselectDataToSend(context.state.assessmentsData.referral_target) });
            if (context.state.assessmentsData.referral_target.length > 0) {
                jsonData_validation.push('referral_target');
            }
            var client_id = sessionStorage.getItem('clientId');
            jsonData.elements.push({ "name": 'client_id', 'value': client_id });
            if (jsonData_validation.length === 0) {
                sessionStorage.setItem('error_msg', 'Empty Information is not saved');
                window.location.href = "";
            } else {
                var json = JSON.stringify(jsonData);
                axios.post(global.save_url, json)
                    .then(function (res) {
                        var response = res.data;
                        if (response.success && res.status == 200) {
                            sessionStorage.setItem('success_msg', "Assessment added successfully.");
                            sessionStorage.setItem('AssessmentListData_length', (parseInt(sessionStorage.getItem('AssessmentListData_length')) + 1));
                            context.closeAddAssessmentModal();
                        }
                        else if ("error_code" in response) {
                            alert(response.msg);
                            window.location.href = global.domain + global.traversArray['out'];
                        }
                        else {
                            alert(response.msg);
                            context.closeAddAssessmentModal();
                        }
                    })
                    .catch(function (err) {
                        console.log(err.message);
                    }
                    );
            }
        }
        sessionStorage.removeItem('modalOpen');
    }

    closeNextActionModal() {
        // context.getAssessmentsList();
        context.setState({
            showNextActionModal: false,
            openPrintModal: false
        });
    }

    openNextActionModal() {
        // context.getAssessmentsList();
        context.setState({
            showAddAssessmentsModal: false,
            showAssessmentDetailsModal: false,
            showNextActionModal: true
        });
    }

    closeAssessmentDetailsModal() {
        context.setState({
            assessmentsData: context.getModal('resetData'),
            showAssessmentDetailsModal: false
        });
        setTimeout(() => context.createSelectOptions(), 100);
    }

    openAssessmentDetailsModal() {
        context.setState({
            showAddAssessmentsModal: false,
            showAssessmentDetailsModal: true,
            showNextActionModal: false
        });
    }

    onChangeHandler = event => {
        if (event.target.files.length > 0) {

        } else {
            return false;
        }
        let file_size = event.target.files[0].size;
        let file_name = event.target.files[0].name;
        let file_type = event.target.files[0].type;
        let selectedFile = [];
        for (let i = 0; i < event.target.files.length; i++) {
            selectedFile.push(event.target.files[i]);
        }
        this.setState({
            selectedFile,
            loaded: 0,
        })
    }

    saveAssessmentsdetails = async () => {
        this.setState({ buttonDisabled: true });
        this.setState({ showLoader: true })
        let buttonType = 'save';
        let today = new Date();
        var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        const data = new FormData();
        Object.entries(context.state.assessmentsData).map(([key, value]) => {
            if (key === 'referral_target') {
                for (let i = 0; i < this.state.assessmentsData.referral_target.length; i++) {
                    data.append(`referral_target[${i}]`, this.state.assessmentsData.referral_target[i].label)
                }
            } else if (key === 'assessment_date') {
                data.append('assessment_date', value !== '' && value !== null && value !== undefined ? moment(value).format('YYYY-MM-DD') : '');
            } else {
                data.append(key, value);
            }
        })
        let emptyFormData = true;
        for (var val of data.values()) {
            if (val !== '' && val !== 'null' && val !== null) {
                emptyFormData = false;
            } else {
                // No Code;
            }
        }
        if (this.state.selectedFile === null && emptyFormData) {
            sessionStorage.setItem('error_msg', 'Empty Information is not saved')
            context.closeAddAssessmentModal();
            window.location.href = "";
            return false;
        }
        if (sessionStorage.getItem('AssessmentListData_length') === 0) {
            data.append('key', sessionStorage.getItem('clientId') + "-1");
        } else {
            data.append('key', sessionStorage.getItem('clientId') + "-" + (parseInt(sessionStorage.getItem('AssessmentListData_length')) + 1))
        }
        if (this.state.selectedFile !== null) {
            for (let i = 0; i < this.state.selectedFile.length; i++) {
                if (this.vlidateFileSelection(this.state.selectedFile[i])) {
                    await data.append(`attachments[${i}]`, this.state.selectedFile[i]);
                } else {
                    return false;
                }
            }
            await this.setState({ selectedFile: null });
        }
        data.append('client_id', sessionStorage.getItem('clientId'))
        data.append('apikey', localStorage.getItem('__TOKEN__'))
        data.append('created_by', sessionStorage.getItem('username'))
        data.append('version', "1")
        try {
            axios.post(global.restApiURL + "customRest/uploadFinalAssessmentAttachments", data)
                .then(res => {
                    this.setState({ buttonDisabled: false });
                    this.setState({ showLoader: false })
                    let response = res.data;
                    if (response.success && res.status == 200) {
                        sessionStorage.setItem('success_msg', "Assessment added successfully.");
                        sessionStorage.setItem('AssessmentListData_length', (parseInt(sessionStorage.getItem('AssessmentListData_length')) + 1));
                        context.closeAddAssessmentModal();
                        Alert('success', "Assessment saved successfully.");
                    }
                    else if ("error_code" in response) {
                        alert(response.msg);
                        window.location.href = global.domain + global.traversArray['out'];
                    }
                    else {
                        alert(response.msg);
                        context.closeAddAssessmentModal();
                    }
                }).catch(err => {
                    console.error(err);
                    this.setState({ buttonDisabled: false });
                    this.setState({ showLoader: false }) // Re-enable the button if there's an error
                });
        } catch (error) {
            console.error(error);
            this.setState({ buttonDisabled: false });
            this.setState({ showLoader: false })// Re-enable the button if there's an error
        }
    }

    vlidateFileSelection(file) {
        if ((file.size / 1024 / 1024) > 25) {
            alert(file.name + " : File size too large !");
            return false;
        }
        else {
            return true;
        }
    }

    uploadImage = (e) => {
        let file = e.target.files[0];
        if (file && !file.name) {
            window.alert("Please select a file");
            return false;
        }
        if (file.size > 25e6) {
            window.alert("Please upload a file smaller than 25 MB");
            return false;
        }
    }

    Filevalidation = () => {
        const fi = document.getElementById('file');
        if (fi.files.length > 0) {
            for (var i = 0; i <= fi.files.length - 1; i++) {
                const fsize = fi.files.item(i).size;
                const file = Math.round((fsize / 1024));
                if (file >= 4096) {
                    alert(
                        "File too Big, please select a file less than 4mb");
                } else if (file < 2048) {
                    alert(
                        "File too small, please select a file greater than 2mb");
                } else {
                    document.getElementById('size').innerHTML = '<b>'
                        + file + '</b> KB';
                }
            }
        }
    }

    handleDateChange = name => date => {
        var stateCopy = Object.assign({}, this.state);
        stateCopy.assessmentsData[name] = date;
        this.setState(stateCopy);
    };

    route = (route) => {
        sessionStorage.setItem('modalOpen', true);
        switch (route) {
            case 'Follow-Up':
                window.location.href = global.domain + 'followup';
                break;
            case 'Routing':
                window.location.href = global.domain + 'routing';
                break;
            case 'Referral':
                window.location.href = global.domain + 'referrals';
                break;
        }
    }

    handleTimer(date, field) {
        let assessmentsData_temp = this.state.assessmentsData;
        assessmentsData_temp[field] = date._d !== undefined ? moment(date).format('MM/DD/YYYY') : date;
        context.setState({ assessmentsData: assessmentsData_temp });
    };

    handleTimeronBlur(date, field) {
        date = date ? date : context.state.assessmentsData[field];
        let assessmentsData_temp = this.state.assessmentsData;
        assessmentsData_temp[field] = typeof date === 'string' && typeof date !== 'object' && date !== "" ? moment().format('MM/DD/YYYY') : (typeof date == 'object' && date !== null ? moment(date._d).format('MM/DD/YYYY') : "");
        context.setState({ assessmentsData: assessmentsData_temp, unSaved: true });
    };

    validDate(current, selectedDate) {
        return current.isBefore(moment());
    };

    showFormModal = (e, form) => {
        if (form.name == 'uncopeScreening') {
            this.setState({ age: 19, uncopeScreening: true, formType: "uncopeScreening" });
        } else if (form.name == 'crafftScreening') {
            this.setState({ age: 17, uncopeScreening: true, formType: "craftScreening" })
        }
        else if (form.name == 'Adult_Assessment') {
            this.setState({ age: 19, Adth: true, formType: "Adult_Assessment" });
        } else if (form.name == "Adolescent_Assessment") {
            this.setState({ age: 17, Adth: true, formType: "Adolescent_Assessment" });
        } else if (form.name == "Adult_updateAdmh") {
            this.setState({ age: 19, updateAdmh: true, formType: "Adult_updateAdmh" });
        } else if (form.name == "Adolescent_updateAdmh") {
            this.setState({ age: 17, updateAdmh: true, formType: "Adolescent_updateAdmh" });
        }
        else {
            this.setState({ [form.name]: true, age: form.age });
        }
    }

    closeAdmh = () => {
        this.setState({ Adth: false, updateAdmh: false });
        window.location.reload();
    }

    handleCheckbox = (e) => {
        this.setState({ printModule: e.target.checked, printCount: e.target.checked });
    }

    canAccessToView = (component_name) => {
        if (global.is_admin || global.is_clinical_specialist) {
            return true
        } else {
            return false
        }
    }

    removeFile = async (filename) => {
        if (window.confirm("Are you sure ? You are deteting Uploaded File..")) {
            let dataListBody = {
                apikey: localStorage.getItem('__TOKEN__'),
                client_id: sessionStorage.getItem("clientId"),
                file_name: filename
            }
            let del_res = await axios.post(global.restApiURL + "customrest/deleteClientDocs", JSON.stringify({ dataListBody: dataListBody }))
            if (del_res.data.success && del_res.status == 200) {
                this.getAllUploadedFiles();
            }
        }
    }

    setFiles = (filename) => {
        this.setState({ files: filename })
    }

    handleDownloadModules = async () => {
        this.setState({ showLoader: true })
        let dataListBody = {
            apikey: localStorage.getItem('__TOKEN__'),
            client_id: sessionStorage.getItem("clientId"),
        }
        try {
            let del_res = await axios.post(global.restApiURL + "customrest/downloadClientDocZip", JSON.stringify({ dataListBody: dataListBody }))
            if (del_res.data.success && del_res.status == 200) {
                this.setState({ showLoader: false })
                window.location = del_res.data.file;
            }
        } catch {
            alert("Something going wrong Please try again...")
            this.setState({ showLoader: false })
        }
    }

    // addScreening = (screeningType) => {
    //     if (screeningType.form_type == 'uncopeScreening') {
    //         this.setState({ age: 19, uncopeScreening: true, formType: "uncopeScreening" })
    //     } else if (screeningType.form_type == 'craftScreening') {
    //         this.setState({ age: 17, uncopeScreening: true, formType: "craftScreening" })
    //     }
    // }

    render() {
        return (
            <div className="hold-transition sidebar-mini text-sm">
                <div className="wrapper">
                    <Topnav />
                    <div className="content-wrapper">
                        <Patientinfoheader
                            clientId={sessionStorage.getItem('clientId')}
                            dob={encryptStorage.getItem('dob')}
                            email={encryptStorage.getItem('email')}
                            phone={encryptStorage.getItem('phone')}
                            peer_support_specialist={encryptStorage.getItem('peer_support_specialist')}
                            next_followup_date={encryptStorage.getItem('month_1_followup_date')}
                            intake_date={encryptStorage.getItem('intake_date')}
                            initiated_by={encryptStorage.getItem('last_updated_by')}
                            formname={sessionStorage.getItem('formname')}
                            client_name={encryptStorage.getItem('client_first_name') + " " + encryptStorage.getItem('client_middle_initial') + " " + encryptStorage.getItem('client_last_name')}
                        />
                        <div className="row">
                            <Sidenav />
                            <div className="container-fluid col-md-9 col-lg-10">
                                <div className="card card-default">
                                    <div className="card-header">
                                        <button className="btn btn-primary float-sm-right btnmargin" onClick={() => this.setState({ openPrintModal: true })} value="all" lang="all" >Print</button>
                                        <button type="submit" className="btn btn-success float-sm-right btnmargin" disabled={this.state.screeningHistoryData.length > 0} onClick={() => {  this.setState({ age: 17, uncopeScreening: true, formType: "craftScreening" }) }}> Add</button>
                                        <h3 style={{ color: "#89BC3B" }}>{"Crafft Screening"}</h3>
                                        <input type="hidden" id="clicked" value="" readOnly />
                                    </div>
                                    <div className="card-body">
                                        {/* <h4 style={{ color: "#89BC3B" }}>Screening forms</h4> */}
                                        <DataTable
                                            columns={this.state.screeningHistoryColumns}
                                            data={this.state.screeningHistoryData}
                                            customStyles={global.customTableStyles}
                                            striped={true}
                                            // pagination
                                            paginationComponentOptions={global.paginationComponentOptions}
                                            highlightOnHover
                                            pointerOnHover
                                            noDataComponent={<div className="no_record_found">{global.no_record_found_text}</div>}
                                            onRowClicked={(event) => {
                                                const assessmentType = event.form_type; // Extracting Assessment Type from event
                                                let form = {};

                                                if (assessmentType === 'UNCOPE Screening') {
                                                    form = { name: 'uncopeScreening', form_name: "uncopeScreening", age: 19 };
                                                } else if (assessmentType === 'CRAFFT Screening') {
                                                    form = { name: 'crafftScreening', form_name: "crafftScreening", age: 17 };
                                                }
                                                this.showFormModal(event, form);
                                            }}
                                        />
                                    </div>
                                    {this.state.uncopeScreening &&
                                        <UncopeScrrening closeModal={this.closeModal}
                                            age={this.state.age}
                                            form_type={this.state.formType}
                                            updateModal_uncope={this.state.uncope_form_type == "uncopeScreening" ? true : false}
                                            updateModal_craft={this.state.craft_form_type == "craftScreening" ? true : false}
                                            getScreeningList={this.getScreeningList}
                                            saveComplete={this.state.formType == "uncopeScreening" ? this.state.issaveAndComplete_uncope : this.state.issaveAndComplete_craft}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                        {this.state.openPrintModal && <>
                            <Modal
                                showOverlay={true}
                                show={this.state.openPrintModal}
                                onClose={this.closeNextActionModal}
                                disableOverlayClick={true}
                                showCloseButton={true}
                                size="sm"
                                className="modal-md modal-dialog"
                            >
                                <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                                    <Modal.Title>
                                        <div className="row" >
                                            <div className="col-md-8">
                                                <h4 className="modal-title float-left text-green">Print Forms</h4>
                                            </div>
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="modal-body">
                                        <strong>Please select the forms to print.</strong>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <input type="checkbox" className='mr-2 mt-3' disabled={this.state.issaveAndComplete_craft == '1' || this.state.is_draft_craft == '1' ? false : true} onChange={(e) => this.handleCheckbox(e)} name="screening" />Screening  <br />
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="float-right" >
                                                <button className='btn btn-primary float-right' onClick={() => { this.setState({ IsPrintAssesmentModalOpen: true }) }} disabled={!this.state.printCount ? true : false} >Preview</button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Footer>
                            </Modal>
                        </>}
                    </div>
                </div>
                <Modal
                    showOverlay={true}
                    show={this.state.IsPrintAssesmentModalOpen}
                    // onClose={this.closeAssessmentDetailsModal}
                    disableOverlayClick={true}
                    showCloseButton={false}
                    className="modal-xl modal-dialog"
                    ariaHideApp={false}
                >
                    <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                        <Modal.Title>
                            <div className="row" >
                                <div className="col-6">
                                    <h4 className="modal-title float-left text-green">Print Assessment</h4>
                                </div>
                                <div className="col-6">
                                    <div className="row" style={{ display: "flex", gridTemplateColumns: "auto auto", gridTemplateRows: "auto auto", justifyContent: "end", gridGap: "6px" }}>
                                        <div className="">
                                            <ReactToPrint
                                                trigger={() => {
                                                    return <button className="btn btn-success " id="closeButton" type="button">Print</button>;
                                                }}
                                                content={() => this.printAssesmentdata}
                                                documentTitle={this.state.PDFName}
                                            />
                                        </div>
                                        <div className="justify-content-between" >
                                            <button className="btn btn-default float-left" id="closeButton" onClick={(e) => { this.setState({ IsPrintAssesmentModalOpen: false }) }} type="button">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div ref={el => (this.printAssesmentdata = el)}>
                            {this.state.printCount && <PrintScreening />}
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="justify-content-between" >
                                    <button className="btn btn-default float-left" id="closeButton" onClick={(e) => { this.setState({ IsPrintAssesmentModalOpen: false }) }} type="button">Close</button>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="float-right" >
                                    <ReactToPrint
                                        trigger={() => {
                                            return <button className="btn btn-success float-left" id="closeButton" type="button">Print</button>;
                                        }}
                                        content={() => this.printAssesmentdata}
                                        documentTitle={this.state.PDFName}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div >
        );
    }

}

export default CraftScreen;