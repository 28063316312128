import React, { Component } from 'react';
import $ from 'jquery';
import Topnav from './Topnav';
import Footer from './Footer';
import Patientinfoheader from './Patientinfoheader';
import Sidenav from './Sidenav';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectOptionsGenerator, multiSelectOptionGenerator, createMultiselectDataToSend } from '../ReusableComponents/OptionsGenerator';
import Select from 'react-select';
//Tooltip imports
import ReactTooltip from 'react-tooltip';
import Tooltip from '../ReusableComponents/Tooltip';
import InputMask from 'react-input-mask';
import { can_edit } from '../ReusableComponents/urlGenerator';
import { disable_button } from '../ReusableComponents/validationParser2';
import { encryptStorage } from './LocalStorageInterceptor';
import { InputTextField, LabelField } from '../ReusableComponents/LabelnInput'
import { ConfirmBeforeLeave } from './ConfirmBeforeLeave';
import { Alert } from "./toastify";
import LocationSearchInput from '../Components/AddressAutocomplete';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import {  toast } from 'react-toastify';

const vparser = require('../ReusableComponents/validationParser2');
var context;
const axios = require('axios').default;
const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? "green"
          : isFocused
            ? "#0074F0"
            : null,
      color: isDisabled
        ? '#ccc'
        : isFocused
          ? "white"
          : isSelected
            ? "white"
            : "black",

      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? "grey" : "red"),
      },
    };
  },
  multiValue: (styles, { data }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: "#007BFF",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "white",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "white",
    ':hover': {
      backgroundColor: "red",
      color: 'white',
    },
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      fontSize: '16px',
      color: '#495057'
    }
  }
};


var validatorPareserobj = "";

class Otheragency extends Component {
  constructor() {
    super();
    context = this;
    this.handleId = this.handleId.bind(this);
    this.handleDataChange = this.handleDataChange.bind(this);
    this.validationmapper = this.validationmapper.bind(this);
    this.createDropdownOptions = this.createDropdownOptions.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
    var now = new Date();
    this.state = {
      errorMessage: '',
      // disable: false,
      none: "none",
      tooltipOpen: false,
      startDateintakedate: new Date,
      month1followupdate: new Date(now.getFullYear(), now.getMonth() + 1, now.getDate()),
      sixmonthfollow: new Date(now.getFullYear(), now.getMonth() + 6, now.getDate()),
      dob: '',
      agency_name_list: [],
      leave_message_options: [],
      state_list: [],
      otheragencydata: this.getModal(),
      required: this.getModal(),
      labelText: this.getModal(),
      tooltips: this.getModal(),
      tooltipVisibility: this.getModal(),
      unSaved: false,
      maxLength: this.getModal(),
    };
  }

  getModal() {
    var otherAgencyModal = {
      address: '',
      agency_name: '',
      othr_agency: '',
      city: '',
      contact_job_title: '',
      contact_name: '',
      // contact_person:'',
      county: '',
      leave_msg: '',
      phone: '',
      state: "",//encryptStorage.getItem('state_lns') && encryptStorage.getItem('state_lns') !== '' ? encryptStorage.getItem('state_lns') : '',
      zip_code: '',
    };
    return otherAgencyModal;
  }

  toggleTooltip() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    })
  }

  handleChange = date => {
    this.setState({ startDateintakedate: date });
  };

  handleDataChange = (event) => {
    $("#saveButton").attr("disabled", false);
    $("#saveButton1").attr("disabled", false);
    $("#saveButton2").attr("disabled", false);
    $("#saveButton3").attr("disabled", false);
    event.persist();
    var stateCopy = Object.assign({}, this.state);
    stateCopy.otheragencydata[event.target.name] = event.target.value;
    // stateCopy['unSaved'] = true;
    this.setState({ stateCopy, unSaved: true, disable: false });
  }


  handleChange_gpi = address => {
    var stateCopy = Object.assign({}, this.state);
    stateCopy.otheragencydata['address'] = address
    stateCopy.otheragencydata.city = '';
    stateCopy.otheragencydata.state = '';
    stateCopy.otheragencydata.zip_code = '';
    stateCopy.otheragencydata.county = ''
    stateCopy['unSaved'] = true;
    this.setState(stateCopy);
  };

  handleSelect = address => {
    this.handleChange_gpi(address)
    geocodeByAddress(address).then((results) => {
      var stateCopy = Object.assign({}, this.state);
      for (var i = 0; i < results[0].address_components.length; i++) {
        if (results[0].address_components[i].types[0] === "locality") {
          stateCopy.otheragencydata['city'] = results[0].address_components[i].long_name;
          stateCopy.otheragencydata['address'] = stateCopy.otheragencydata['address'].replace(results[0].address_components[i].long_name + ',', '');
        }
        if (results[0].address_components[i].types[0] === "administrative_area_level_1") {
          stateCopy.otheragencydata['state'] = results[0].address_components[i].short_name;
          stateCopy.otheragencydata['address'] = stateCopy.otheragencydata['address'].replace(results[0].address_components[i].short_name + ',', '');
        }
        if (results[0].address_components[i].types[0] === "administrative_area_level_2") {
          stateCopy.otheragencydata['county'] = results[0].address_components[i].long_name;
          stateCopy.otheragencydata['address'] = stateCopy.otheragencydata['address'].replace(results[0].address_components[i].long_name + ',', '');
        }
        if (results[0].address_components[i].types[0] === "postal_code") {
          stateCopy.otheragencydata['zip_code'] = results[0].address_components[i].long_name;
          stateCopy.otheragencydata['address'] = stateCopy.otheragencydata['address'].replace(results[0].address_components[i].long_name + ',', '');
        }
      }
      stateCopy['unSaved'] = true;
      this.setState(stateCopy);
    }).catch(error => console.error('Error', error));
  };

  handlemonth1followupdate = date => {
    this.setState({ month1followupdate: date, unSaved: true });
  };

  handleChangesixmonthfollow = date => {
    this.setState({ sixmonthfollow: date, unSaved: true });
  };

  handleChangedob = date => {
    this.setState({ dob: date, unSaved: true });
  };

  componentWillMount() {
    axios.get(global.restApiURL + 'webservice/rest/class/id/3?apikey=' + sessionStorage.getItem('__CL_TOKEN__'))
      .then(res => {
        var responce = res.data;
        validatorPareserobj = vparser.validationParser(responce.data.layoutDefinitions.childs[0].childs);
        this.setState({
          required: validatorPareserobj.mandatory,
          labelText: validatorPareserobj.title,
          tooltips: validatorPareserobj.tooltip,
          tooltipVisibility: validatorPareserobj.tooltipVisibility,
          maxLength: validatorPareserobj.columnLength,
        })
        this.createDropdownOptions(validatorPareserobj);
      })
      .catch(err => { // log request error and prevent access to undefined state
        this.setState({ loading: false, error: true });
        console.error(err);
      }
      )
  }

  componentDidMount() {
    // this.setState({ disable: true })
    disable_button('otheragencyinfoform');
    var prevExist = false;
    sessionStorage.setItem('responceId', '');
    // for client info
    if (sessionStorage.getItem('clientId') !== '') {
      axios.get(global.restApiURL + 'webservice/rest/object-list?apikey=' + localStorage.getItem('__TOKEN__') + '&limit=1&objectClass=OtherAgency&q={"client_id":"' + sessionStorage.getItem('clientId') + '"}')
        .then(res => {
          const responce = res.data.data; // get the data array instead of object
          if (responce[0].id !== '') {
            prevExist = true;
            axios.get(global.restApiURL + 'webservice/rest/object/id/' + responce[0].id + '?apikey=' + localStorage.getItem('__TOKEN__'))
              .then(res => {
                sessionStorage.setItem('responceId', res.data.data.id);
                const responce = res.data.data.elements;
                this.datamapper(responce)
              })
              .catch(err => { // log request error and prevent access to undefined state
                this.setState({ loading: false, error: true });
                console.error(err);
              })
          } else {
            prevExist = false;
          }
        })
        .catch(err => { // log request error and prevent access to undefined state
          this.setState({ loading: false, error: true });
          console.error(err);
        }
        )
    }
    $.validator.setDefaults({
      submitHandler: function () {
        var jsonData = '';
        if (prevExist === true) {
          jsonData = { 'className': 'OtherAgency', 'key': sessionStorage.getItem('clientId'), 'id': sessionStorage.getItem('responceId'), 'published': true };
        } else {
          jsonData = { 'className': 'OtherAgency', 'key': sessionStorage.getItem('clientId'), 'published': true };
        }
        jsonData.elements = [];
        var formData = $("#otheragencyinfoform").serializeArray();
        $.each(formData, function () {
          if (this.name !== "agency_name") {
            jsonData.elements.push({ name: this.name, value: this.value });
          }
        });
        jsonData.elements.push({ name: "agency_name", value: createMultiselectDataToSend(context.state.otheragencydata.agency_name) });
        var json = JSON.stringify(jsonData);
        axios.post(sessionStorage.getItem('other_agency_save_url'), json)
          .then(function (res) {
            var responce = res.data;
            sessionStorage.setItem('success_msg', 'Other Agency Details saved successfully')
            if ($('#clicked').val() === 'proceed') {
              window.location.href = global.domain + global.traversArray['otheragency'];
            } else {
              context.setState({ unSaved: false });
              if (!sessionStorage.getItem('responceId')) {
                sessionStorage.setItem('responceId', responce.id)
              }
              prevExist = true;
              Alert('success', 'Other Agency Details saved successfully')
            }
          })
          .catch(function (err) {
            console.log(err.message);
          }
          );
      }
    });
  }

  saveChanges(e) {
    const formData = $("#otheragencyinfoform").serializeArray();
    const isFormValid = formData.some(field => field.name !== "client_id" && field.value !== "");
    if ( !isFormValid) {
      toast.error('Empty Information is not saved!', { toastId: "Empty Information is not saved!" }, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
        return false;
    }
    if (0 < this.state.otheragencydata.phone.length && this.state.otheragencydata.phone.length < 14) {
      // e.preventDefault();
      this.setState({ errorMessage: 'Please enter at least 10 digit.' });
      return;
    } else {
      $('#clicked').val('save');
      $('#otheragencyinfoform').submit();
    }
  }

  datamapper(arr) {
    let obj = this.getModal();
    $.each(arr, function (index, value) {
      if (value.name === 'address') {
        obj.address = value.value;
      }
      if (value.name === 'agency_name') {
        obj.agency_name = value.value;
        let multiSelectOptions = value.value !== null && value.value.length > 0 && value.value.map((item, i) => {
          var op = { value: item, label: item };
          return op;
        }, this);
        obj.agency_name = multiSelectOptions;
      }
      if (value.name === 'city') {
        obj.city = value.value;
      }
      if (value.name === 'othr_agency') {
        obj.othr_agency = value.value;
      }
      if (value.name === 'contact_job_title') {
        obj.contact_job_title = value.value;
      }
      if (value.name === 'contact_name') {
        obj.contact_name = value.value;
      }
      if (value.name === 'county') {
        obj.county = value.value;
      }
      if (value.name === 'leave_msg') {
        obj.leave_msg = value.value;
      }
      if (value.name === 'phone') {
        obj.phone = value.value;
      }
      if (value.name === 'state') {
        obj.state = value.value ? value.value : encryptStorage.getItem('state_lns') && encryptStorage.getItem('state_lns') !== '' ? encryptStorage.getItem('state_lns') : '';//value.value;
      }
      if (value.name === 'zip_code') {
        obj.zip_code = value.value;
      }
    });
    this.setState({ otheragencydata: obj });
    if (validatorPareserobj !== "") {
      this.createDropdownOptions(validatorPareserobj);
    }
  }

  validationmapper(arr) {
    let obj = this.getModal();
    obj.address = arr['address'].mandatory;
    obj.agency_name = arr['agency_name'].mandatory;
    obj.city = arr['city'].mandatory;
    obj.contact_job_title = arr['contact_job_title'].mandatory;
    obj.contact_name = arr['contact_name'].mandatory;
    //obj.contact_person= arr['contact_person'].mandatory;
    obj.county = arr['county'].mandatory;
    obj.leave_msg = arr['leave_msg'].mandatory;
    obj.phone = arr['phone'].mandatory;
    obj.state = arr['state'].mandatory;
    obj.zip_code = arr['zip_code'].mandatory;
    let labelText = this.getModal();
    labelText.address = arr['address'].title;
    labelText.agency_name = arr['agency_name'].title;
    labelText.city = arr['city'].title;
    labelText.contact_job_title = arr['contact_job_title'].title;
    labelText.contact_name = arr['contact_name'].title;
    //labelText.contact_person= arr['contact_person'].title;
    labelText.county = arr['county'].title;
    labelText.leave_msg = arr['leave_msg'].title;
    labelText.phone = arr['phone'].title;
    labelText.state = arr['state'].title;
    labelText.zip_code = arr['zip_code'].title;
    let tooltips = this.getModal();
    tooltips.address = arr['address'].tooltip;
    tooltips.agency_name = arr['agency_name'].tooltip;
    tooltips.city = arr['city'].tooltip;
    tooltips.contact_job_title = arr['contact_job_title'].tooltip;
    tooltips.contact_name = arr['contact_name'].tooltip;
    //tooltips.contact_person= arr['contact_person'].tooltip;
    tooltips.county = arr['county'].tooltip;
    tooltips.leave_msg = arr['leave_msg'].tooltip;
    tooltips.phone = arr['phone'].tooltip;
    tooltips.state = arr['state'].tooltip;
    tooltips.zip_code = arr['zip_code'].tooltip;
    this.setState({ required: obj, labels: labelText, tooltips: tooltips });
  }

  handleId = (e) => {
    const formData = $("#otheragencyinfoform").serializeArray();
    const isFormValid = formData.some(field => field.name !== "client_id" && field.value !== "");
    if ( !isFormValid) {
      toast.error('Empty Information is not saved!', { toastId: "Empty Information is not saved!" }, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      e.preventDefault();
      return false;
    }
    if (0 < this.state.otheragencydata.phone.length && this.state.otheragencydata.phone.length < 14) {
      e.preventDefault();
      this.setState({ errorMessage: 'Please enter at least 10 digits.' });
      return;
    } else {
      //isko bhi dekhna hai
      $('#clicked').val(e.target.value);
      $('#clicked').val(e.currentTarget.value);
    }
  }

  createDropdownOptions(rawOptions) {
    if (validatorPareserobj !== "") {
      this.setState({
        agency_name_list: multiSelectOptionGenerator(validatorPareserobj.options.agency_name),
        leave_message_options: selectOptionsGenerator(validatorPareserobj.options.leave_msg, this.state.otheragencydata.leave_msg),
        state_list: selectOptionsGenerator(validatorPareserobj.options.state, this.state.otheragencydata.state),
      });
    }
  }

  createMultiselectDataToSend(selectedOptions) {
    var structuredResponse = [];
    if (selectedOptions) {
      if (selectedOptions.length !== 0) {
        selectedOptions.map((val) => {
          structuredResponse.push(val.label);
        });
      }
    }
    return structuredResponse;
  }

  handlePhoneChange = (event) => {
    event.persist();
    var stateCopy = Object.assign({}, this.state);
    stateCopy.otheragencydata[event.target.name] = event.target.value;
    // stateCopy['unSaved'] = true;
    this.setState({ stateCopy, errorMessage: '', unSaved: true });
  }

  beforeMaskedValueChange2 = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;
    // keep minus if entered by user
    if (value.endsWith('-') && userInput !== '-' && !this.state.otheragencydata.phone.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }
    return { value, selection };
  }

  handleMultiSelectChanges = (selectedOption, event) => {
    var updatedState = this.state.otheragencydata;
    updatedState[event.name] = selectedOption;
    this.setState(prevState => ({
      ...prevState,
      otheragencydata: updatedState,
      unSaved: true
    }));
  };

  render() {
    sessionStorage.setItem('formname', 'Other Agency Details')
    $(document).ready(function () {
      var NumRegex = /^[0-9]+$|^$|^\s$/;
      $.validator.addMethod('NumRegex', function (value, element, parameter) {
        return value.match(NumRegex);
      }, '');


      // Start  code commented by Kuldeep Singh on requirement of the clients on otherAgency in address city field should not be mandatory. <<<<<<<<< ==================================================================.

      // var cityRegEx = /^[a-zA-Z\s\-']+$/;
      // $.validator.addMethod('cityRegEx', function (value, element, parameter) {
      //   return value.match(cityRegEx);                                                                 
      // }, 'Please enter a valid city name');

      // End  code commented by Kuldeep Singh on requirement of the clients on otherAgency in address city field should not be mandatory.  <<<<<<<<< ==================================================================.



      $('#otheragencyinfoform').validate({
        rules: {
          zip_code: {
            NumRegex: true
          },
          // city: {
          //   cityRegEx: true                    code commented by Kuldeep Singh on requirement of the clients on otherAgency in address city field should not be mandatory.   <<<<<<<<< ==================================================================.
          // }
        },
        errorElement: 'span',
        errorPlacement: function (error, element) {
          error.addClass('invalid-feedback');
          element.closest('.form-group').append(error);
        },
        highlight: function (element, errorClass, validClass) {
          $(element).addClass('is-invalid');
        },
        unhighlight: function (element, errorClass, validClass) {
          $(element).removeClass('is-invalid');
        },
        onkeyup: function (element) {
          $('#zip_code').valid();
        }
      });
    });

    return (
      <div className="hold-transition sidebar-mini text-sm">
        <ConfirmBeforeLeave onRef={ref => (this.childForm = ref)} unSaved={context.state.unSaved} onSaveRequest={(e) => context.saveChanges(e)} />
        <ReactTooltip place="right" type="dark" effect="solid" />
        <div className="wrapper">
          <Topnav />
          <div className="content-wrapper">
            <Patientinfoheader clientId={sessionStorage.getItem('clientId')} dob={encryptStorage.getItem('dob')} email={encryptStorage.getItem('email')} phone={encryptStorage.getItem('phone')} peer_support_specialist={encryptStorage.getItem('peer_support_specialist')} next_followup_date={encryptStorage.getItem('month_1_followup_date')} intake_date={encryptStorage.getItem('intake_date')} initiated_by={encryptStorage.getItem('last_updated_by')} formname={sessionStorage.getItem('formname')} client_name={encryptStorage.getItem('client_first_name') + " " + encryptStorage.getItem('client_middle_initial') + " " + encryptStorage.getItem('client_last_name')} />
            <section className="content">
              <div className="row" >
                <Sidenav />
                <fieldset disabled={!can_edit('OtherAgency')} className="container-fluid col-md-9 col-lg-10">
                  <form action="javascript:void(0);" method="post" id="otheragencyinfoform">
                    <div className="">
                      <div className="card card-default">
                        <div className="card-header">
                          <p className="lead float-left"><b className="text-green">Other Agency Details</b> <small>Details of other agencies that you may be receiving services at </small></p>
                          <button type="submit" className="btn btn-primary float-right" value="proceed" id="saveButton1" onClick={this.handleId}>
                            <img src="dist/img/save-proceed_icon.png" alt="" /> Save &amp; Proceed </button>
                          <button type="submit" value='save' id="saveButton" className="btn btn-success float-right btnmargin" onClick={this.handleId}> <i className="fas fa-save"></i> Save</button>
                          <input type="hidden" id="clicked" value="" readOnly />
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-lg-6 col-md-12">
                              <div className="row">
                                <div className="col-lg-12 col-md-4">
                                  <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                      <div className="form-group">
                                        <LabelField title={this.state.labelText.agency_name} mandatory={this.state.required.agency_name} name="agency_name" tooltip={this.state.tooltips.agency_name} id="agency_name" />
                                        <Select
                                          name="agency_name"
                                          isMulti
                                          closeMenuOnSelect={false}
                                          value={this.state.otheragencydata.agency_name}
                                          onChange={this.handleMultiSelectChanges}
                                          options={this.state.agency_name_list}
                                          styles={colourStyles}
                                          placeholder="Select"
                                          required={this.state.required.agency_name_list}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {this.state.otheragencydata.agency_name && context.state.otheragencydata.agency_name.filter(function (e) { return e.label === 'Other (Please Specify Below)'; }).length > 0 ?
                                  <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                      <InputTextField placeholder={this.state.labelText.othr_agency} type="text" maxLength={this.state.maxLength.othr_agency} className="form-control" id="othr_agency" name="othr_agency" defaultValue={this.state.otheragencydata.othr_agency} required={this.state.required.othr_agency} tooltipText={this.state.tooltips.othr_agency} tooltipVisibility={this.state.tooltipVisibility.othr_agency} onChange={this.handleDataChange} />
                                    </div>
                                  </div> : <input type="hidden" name="othr_agency" value="" />
                                }
                                <div className="col-lg-6 col-md-4">
                                  <div className="form-group">
                                    <InputTextField labelText={this.state.labelText.contact_name} placeholder={this.state.labelText.contact_name} type="text" maxLength={this.state.maxLength.contact_name} className="form-control" id="contact_name" name="contact_name" defaultValue={this.state.otheragencydata.contact_name} required={this.state.required.contact_name} tooltipText={this.state.tooltips.contact_name} tooltipVisibility={this.state.tooltipVisibility.contact_name} onChange={this.handleDataChange} />
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-4">
                                  <div className="form-group">
                                    <InputTextField labelText={this.state.labelText.contact_job_title} placeholder={this.state.labelText.contact_job_title} type="text" maxLength={this.state.maxLength.contact_job_title} className="form-control" id="contact_job_title" name="contact_job_title" defaultValue={this.state.otheragencydata.contact_job_title} required={this.state.required.contact_job_title} tooltipText={this.state.tooltips.contact_job_title} tooltipVisibility={this.state.tooltipVisibility.contact_job_title} onChange={this.handleDataChange} />
                                  </div>
                                </div>
                              </div>
                              <div className="row" >
                                <div className="col-md-12">
                                  <div className="card">
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <LabelField title={this.state.labelText.phone} mandatory={this.state.required.phone} name="phone" tooltip={this.state.tooltips.phone} id="phone" />
                                            <InputMask id="phone" name="phone" placeholder={this.state.labelText.phone} className="form-control" mask="(999) 999-9999" maskChar={null} value={this.state.otheragencydata.phone} beforeMaskedValueChange={this.beforeMaskedValueChange2} onChange={this.handlePhoneChange} />
                                            {this.state.errorMessage != "" ? <span style={{ color: 'red', fontSize: "80%" }}>{this.state.errorMessage}</span> : ""}
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <LabelField title={this.state.labelText.leave_msg} mandatory={this.state.required.leave_msg} name="leave_msg" tooltip={this.state.tooltips.leave_msg} id="leave_msg" />
                                            <select className="custom-select" id="leave_msg" name="leave_msg" disabled={this.state.otheragencydata.phone.length !== 14}
                                              required={this.state.required.leave_msg}
                                              onChange={this.handleDataChange}>
                                              {this.state.leave_message_options}
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-12 float-right">
                              <div className="card">
                                <div className="card-header">
                                  <div className="row">
                                    <div className="col-md-8">
                                      <h3 className="card-title">Agency Contact Address</h3>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <LocationSearchInput
                                      value={this.state.otheragencydata.address}
                                      onChange={this.handleChange_gpi}
                                      onSelect={this.handleSelect}
                                      placeholder={this.state.labelText.address}
                                      id="address"
                                      name="address"
                                      defaultValue={this.state.otheragencydata.address}
                                      required={this.state.required.address}
                                      tooltipText={this.state.tooltips.address}
                                      tooltipVisibility={this.state.tooltipVisibility.address}
                                    />
                                    <div className="col-md-8 form-group">
                                      <InputTextField placeholder={this.state.labelText.city} type="text" maxLength={this.state.maxLength.city} className="form-control" id="city" name="city" defaultValue={this.state.otheragencydata.city} required={this.state.required.city} tooltipText={this.state.tooltips.city} tooltipVisibility={this.state.tooltipVisibility.city} onChange={this.handleDataChange} />
                                    </div>
                                    <div className="col-md-4 form-group">
                                      <select className="custom-select" id="state" name="state" onChange={this.handleDataChange} required={this.state.required.city} value={this.state.otheragencydata.state} >
                                        {this.state.state_list}
                                      </select>
                                    </div>
                                    <div className="col-md-8 form-group">
                                      <InputTextField placeholder={this.state.labelText.county} type="text" maxLength={this.state.maxLength.county} className="form-control" id="county" name="county" defaultValue={this.state.otheragencydata.county} required={this.state.required.county} tooltipText={this.state.tooltips.county} tooltipVisibility={this.state.tooltipVisibility.county} onChange={this.handleDataChange} />
                                    </div>
                                    <div className="col-md-4 form-group">
                                      <input type="text" placeholder="ZIP code" maxLength={this.state.maxLength.zip_code} className="form-control" id="zip_code" name="zip_code" defaultValue={this.state.otheragencydata.zip_code} required={this.state.required.zip_code} tooltipText={this.state.tooltips.zip_code} tooltipVisibility={this.state.tooltipVisibility.zip_code} onChange={this.handleDataChange} />
                                      <input type="hidden" id="client_id" name="client_id" defaultValue={sessionStorage.getItem('clientId')} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-footer float-right" >
                          <div className="float-right">
                            <button type="submit" className="btn btn-primary float-right" value="proceed" id="saveButton2" onClick={this.handleId}>
                              <img src="dist/img/save-proceed_icon.png" alt="" /> Save &amp; Proceed </button>
                            <button type="submit" value='save' className="btn btn-success float-right btnmargin" id="saveButton3" onClick={this.handleId}> <i className="fas fa-save"></i> Save</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </fieldset>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default Otheragency;
