import React, { Component } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom'
import Topnav from './Topnav';
import Footer from './Footer';
import Patientinfoheader from './Patientinfoheader';
import Sidenav from './Sidenav';
import DatePicker from "react-datepicker";
import InputMask from 'react-input-mask';
import { validationParser } from '../ReusableComponents/validationParser2';
import { validationParser1 } from '../ReusableComponents/validationParser';
import { getFormattedDate, convertDate } from '../ReusableComponents/dataFormater';
import { LabelField } from '../ReusableComponents/LabelnInput';
import { formObjDataMapper, formObjDataMapper2, parseMultiSelectField } from '../ReusableComponents/formObjDataMapper';
import TextAreaAutosize from 'react-textarea-autosize';
import Select from 'react-select';
import ReactToPrint from "react-to-print";
import { multiSelectOptionGenerator, createMultiselectDataToSend, selectOptionsGenerator, interviewersOptionsGenerator } from '../ReusableComponents/OptionsGenerator';
//Tooltip imports
import ReactTooltip from "react-tooltip";
import Tooltip from '../ReusableComponents/Tooltip';
import DataTable, { defaultThemes } from 'react-data-table-component';
import Modal from './Modals/Modal/index';
import DateTime from 'react-datetime';
import { Button } from './Modals/Buttons/index';
import moment from 'moment-timezone';
import '../../node_modules/react-datetime/css/react-datetime.css';
import '../global.js';
import { can_edit, can_add } from '../ReusableComponents/urlGenerator';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import ReactDropzone from 'react-dropzone'
import { encryptStorage } from './LocalStorageInterceptor';

var validationObj = {};
var context;

const axios = require('axios').default;

const customStyles = {
  table: {
    style: {
      color: defaultThemes.default.text.primary,
      backgroundColor: defaultThemes.default.background.default,
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "#F9F9F9"
    },
  },
  header: {
    style: {
      minHeight: '1px',
    },
  },
  headRow: {
    style: {
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: defaultThemes.default.divider.default,
      backgroundColor: "#F9F9F9",
      minHeight: "40px"
    },
  },
  headCells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
        fontWeight: "bold",
        fontSize: "0.91rem"
      },
    },
  },
  row: {
    style: {
      minHeight: '20px',
      borderRightStyle: 'solid',
      borderRightWidth: '10px',
      borderRightColor: defaultThemes.default.divider.default,
    }
  },
  cells: {
    style: {
      '&:not(:last-of-type)': {
        borderLeftStyle: 'solid',
        borderLeftWidth: '1px',
        borderLeftColor: defaultThemes.default.divider.default,
        fontSize: '0.9rem'
      },
    },
  },
};

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? "green"
          : isFocused
            ? "#0074F0"
            : null,
      color: isDisabled
        ? '#ccc'
        : isFocused
          ? "white"
          : isSelected
            ? "white"
            : "black",

      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? "grey" : "red"),
      },
    };
  },

  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#007BFF",
    };
  },

  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "white",
    whiteSpace: "inherit"
  }),

  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "white",
    ':hover': {
      backgroundColor: "red",
      color: 'white',
    },
  }),
};

class Consent_for_ROI extends Component {
  constructor() {
    super();
    context = this;
    this.state = {
      errorMessage: "",
      errorFaxNumber: "",
      errorSocial_security:"",
      ...this.getModal("AuthorizationData"),
      AuthorizationData: this.getModal("AuthorizationData"),
      mandatoryInfo: this.getModal("AuthorizationData"),
      labelText: this.getModal("AuthorizationData"),
      tooltips: this.getModal("AuthorizationData"),
      tooltipsVisibility: this.getModal("AuthorizationData"),
      fieldName: this.getModal("AuthorizationData"),
      fieldType: this.getModal("AuthorizationData"),
      AuthorizationHistoryColumns: this.getModal("AuthorizationHistoryColumns"),
      //Modals
      showAddAuthorizationModal: false,
      showAuthorizationDetailsModal: false,
      //currentView
      currentView: 'Add',
      all_uploaded_documents: [],
      DocumentsColumns: this.getModal("DocumentsColumns"),
      print: false,
      historyID: 0,
      min_dob: 14,
      //Authorization Forms Mapping Class
      Authorization_forms_mappings: {}
    };
    this.handleAuthorizationelectChange = this.handleAuthorizationelectChange.bind(this);
    this.textInputRefRRC_phone= React.createRef();
    this.textInputRefRRC_fax = React.createRef();
    this.textInputRefsocial_security = React.createRef();
  }

  getModal(type) {
    let dataModel = '';
    var now = new Date();
    let client_name = '';
    if (encryptStorage.getItem('client_first_name') && encryptStorage.getItem('client_first_name') !== '' && encryptStorage.getItem('client_first_name') !== null && encryptStorage.getItem('client_first_name') !== undefined) {
      client_name = client_name + encryptStorage.getItem('client_first_name') + ' ';
    }
    if (encryptStorage.getItem('client_middle_name') && encryptStorage.getItem('client_middle_name') !== '' && encryptStorage.getItem('client_middle_name') !== null && encryptStorage.getItem('client_middle_name') !== undefined) {
      client_name = client_name + encryptStorage.getItem('client_middle_name') + ' ';
    }
    if (encryptStorage.getItem('client_last_name') && encryptStorage.getItem('client_last_name') !== '' && encryptStorage.getItem('client_last_name') !== null && encryptStorage.getItem('client_last_name') !== undefined) {
      client_name = client_name + encryptStorage.getItem('client_last_name') + ' ';
    }
    switch (type) {
      case "AuthorizationData":
        dataModel = {
          selectedFile: null,
          e_sign: 'No',
          toggleSSN: true,
          patient_name: client_name.trim(),
          rrc_org: sessionStorage.getItem('selected_organization'),
          form_selection_type: '',
          types_of_records: '',
          other_type_of_record: '',
          covering_period_type: '',
          specific_time_records: moment(),
          specific_time_records_to: moment().add(1, 'month'),
          authorization_confirmation: '',
          RRC_person: '',
          RRC_phone: '',
          RRC_email: '',
          RRC_fax: '',
          RRC_address: '',
          RRC_city: '',
          RRC_state: '',
          RRC_zip: '',
          reason: '',
          purpose: '',
          other_purpose: '',
          fax_info: '',
          is_patient_legally_incapacitated: '',
          expiry_period_from: '',
          expiry_period_to: '',
          expiry_condition_or_event: '',
          authorization_copy_received: '',
          patient_name_in_sign: '',
          social_security: encryptStorage.getItem('ssn'), //sessionStorage.getItem('ssn'),
          dob_in_sign: encryptStorage.getItem('dob'),
          patient_sign: '',
          date_in_sign: moment(),
          witness_sign: '',
          patient_personal_representative_name: '',
          patient_personal_representative_sign: '',
          legal_authority: '',
          personal_representative_docs_received: '',
          authorization_status: 'Active',
          documents: null,
          updated_on: '',
          revoked_on: '',
          updated_by: ''

        }
        break;

      case "resetData":
        dataModel = {
          selectedFile: null,
          e_sign: 'No',
          patient_name: client_name.trim(),
          toggleSSN: true,
          rrc_org: sessionStorage.getItem('selected_organization'),
          form_selection_type: '',
          types_of_records: '',
          other_type_of_record: '',
          covering_period_type: '',
          specific_time_records: moment(),
          specific_time_records_to: moment().add(1, 'month'),
          authorization_confirmation: '',
          RRC_person: '',
          RRC_phone: '',
          RRC_email: '',
          RRC_fax: '',
          RRC_address: '',
          RRC_city: '',
          RRC_state: '',
          RRC_zip: '',
          reason: '',
          purpose: '',
          other_purpose: '',
          fax_info: '',
          is_patient_legally_incapacitated: '',
          expiry_period_from: '',
          expiry_period_to: '',
          expiry_condition_or_event: '',
          authorization_copy_received: '',
          patient_name_in_sign: '',
          social_security:encryptStorage.getItem('ssn'), //sessionStorage.getItem('ssn'), 
          dob_in_sign: encryptStorage.getItem('dob'),
          patient_sign: '',
          date_in_sign: moment(),
          witness_sign: '',
          patient_personal_representative_name: '',
          patient_personal_representative_sign: '',
          legal_authority: '',
          personal_representative_docs_received: '',
          authorization_status: 'Active',
          documents: null,
          updated_on: '',
          revoked_on: '',
          updated_by: ''
        }
        break;

      case "DocumentsColumns":
        dataModel = [
          {
            name: 'File name',
            sortable: true,
            cell: (row) => (
              <div>
                <a href={row.url} target="_blank" onClick={(e) => { e.preventDefault(); context.handleAttachmentClick(row.url, row.name) }} alt={row.name}>{row.name}</a>
              </div>
            ),
          },
          {
            name: 'Date',
            selector: 'time',
            sortable: false,
          },
          {
            name: 'Uploaded by',
            selector: 'uploaded_by',
            sortable: true,
          },
          {
            name: 'Remarks',
            selector: 'remarks',
            sortable: true,
          }
        ];
        break;

      case "AuthorizationHistoryColumns":
        dataModel = [
          {
            name: 'Approved by',
            selector: 'approved_by',
            sortable: true,

          },
          {
            name: 'Date',
            selector: 'date',
            sortable: true,
            cell: (row) => {
              return moment.unix(row.date).tz("America/New_York").format("MM/DD/YYYY");
            },
          },

          {
            name: 'View',
            sortable: false,
            cell: (row) => (
              <div>
                <Link to="#"><i id={row.id} lang="none" className="fas fa-eye" aria-hidden="true" data-toggle="tooltip" title="view" onClick={this.handleAuthorizationDetails.bind(this, 'Update')}></i></Link>
              </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
          },

        ];
        break;
    }
    return dataModel;
  }

  componentWillMount() {
    axios.get(global.restApiURL + 'webservice/rest/class/id/47?apikey=' + sessionStorage.getItem('__CL_TOKEN__'))
      .then(res => {
        var response = res.data;
        if (response.success && res.status == 200) {
          validationObj = validationParser(response.data.layoutDefinitions.childs[0].childs);
          let validationObj_old = validationParser1(response.data.layoutDefinitions.childs[0].childs);
          this.setState({
            labelText: validationObj.title,
            mandatoryInfo: validationObj.mandatory,
            tooltips: validationObj.tooltip,
            tooltipsVisibility: validationObj.tooltipVisibility,
            fieldName: validationObj.name,
            fieldType: validationObj.fieldtype,
            ...validationObj_old
          });
          this.createSelectOptions();
        } else if ("error_code" in response) {
          alert(response.msg);
          window.location.href = global.domain + global.traversArray['out'];
        } else {
          alert(response.msg);
        }
      })
      .catch(err => { // log request error and prevent access to undefined state
        this.setState({ loading: false, error: true });
        console.error(err);
      }
      );

    //Authorization Forms Mapping
    axios.get(global.restApiURL + 'webservice/rest/class/id/48?apikey=' + sessionStorage.getItem('__CL_TOKEN__'))
      .then(res => {
        var response = res.data;
        if (response.success && res.status == 200) {
          let FormsMappingObjects = validationParser1(response.data.layoutDefinitions.childs[0].childs);
          this.setState({ Authorization_forms_mappings: { ...FormsMappingObjects } });
        } else if ("error_code" in response) {
          alert(response.msg);
          window.location.href = global.domain + global.traversArray['out'];
        } else {
          alert(response.msg);
        }
      })
      .catch(err => { // log request error and prevent access to undefined state
        this.setState({ loading: false, error: true });
        console.error(err);
      }
      );
  }

  componentDidMount() {
    this.getAuthorizationList();
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  getAuthorizationList = () => {
    if (sessionStorage.getItem('clientId') !== '') {
      let getAuthorizationListBody = {
        apikey: localStorage.getItem('__TOKEN__'),
        client_id: sessionStorage.getItem('clientId'),
      };
      axios.post(global.restApiURL + 'customRest/getAuthorizationList', JSON.stringify(getAuthorizationListBody))
        .then(res => {
          if (res.data.success && res.status == 200) {
            setTimeout(function () {
              context.setState({
                changeParticipantionsHistoryData: context.parseAuthorizationHistory(res.data.data)
              });
              sessionStorage.setItem('AuthorizationListData_length', res.data.data.length)
            }, 50)
          } else if ("error_code" in res) {
            alert(res.msg);
            window.location.href = global.domain + global.traversArray['out'];
          } else {
            alert(res.msg);
          }
        })
        .catch(err => { // log request error and prevent access to undefined state
          this.setState({ loading: false, error: true });
          console.error(err);
        }
        )
    }
  }

  resetDataField = (fieldName) => {
    let AuthorizationDataCopy = this.state.AuthorizationData;
    let resetCopy = this.getModal('resetData');
    if (resetCopy[fieldName] !== undefined) {
      AuthorizationDataCopy[fieldName] = resetCopy[fieldName];
    }
    if (fieldName === 'specific_time_records') {
      AuthorizationDataCopy['specific_time_records_to'] = resetCopy['specific_time_records_to'];
    } else if (fieldName === 'is_patient_legally_incapacitated') {
      AuthorizationDataCopy['patient_personal_representative_name'] = resetCopy['patient_personal_representative_name'];
      AuthorizationDataCopy['patient_personal_representative_sign'] = resetCopy['patient_personal_representative_sign'];
      AuthorizationDataCopy['patient_personal_representative_sign_date'] = resetCopy['patient_personal_representative_sign_date'];
      AuthorizationDataCopy['legal_authority'] = resetCopy['legal_authority'];
    }
    this.setState({ AuthorizationData: AuthorizationDataCopy });
  }

  parseAuthorizationHistory(historyDataArray) {
    let parsedData = [];
    parsedData = historyDataArray.length > 0 && historyDataArray.map((item, i) => {
      var op = {};
      item.map((val) => {
        op[val.name] = val.value;
      })
      return op;
    }, this);
    return parsedData;
  }

  createSelectOptions() {
    if (validationObj !== "") {
      this.setState({
        purpose_MultiOptions: multiSelectOptionGenerator(validationObj.options.purpose),
      });
    }
  }

  handleAuthorizationDetails(type, event) {
    if (type !== '' && ['Update', 'Revoke'].includes(type)) {
      this.setState({ currentView: type });
    } else {
      window.location.href = ''
    }
    let historyID = event.target.id;
    this.setState({ historyID: historyID });
    context.setState({
      AuthorizationData: context.getModal("AuthorizationData")
    })
    axios.get(global.restApiURL + 'webservice/rest/object/id/' + historyID + '?apikey=' + localStorage.getItem('__TOKEN__'))
      .then(res => {
        const response = res.data.data.elements;
        let documents_uploaded = [];
        let parsedAuthorizationDetails = formObjDataMapper2(response, context.getModal("AuthorizationData"));
        //values are commonly stored in single variable to be shown in Datatable
        // if (parsedAuthorizationDetails.documents !== '') {
        //   let docs = JSON.parse(parsedAuthorizationDetails.documents);
        //   documents_uploaded = docs !== null && docs.length > 0 ? documents_uploaded.concat(docs) : documents_uploaded;
        // }
        if (parsedAuthorizationDetails.documents !== '') {
          let docs = JSON.parse(parsedAuthorizationDetails.documents);
          if (docs !== null && docs.length > 0) {
            const formattedDocs = docs.map(doc => {
              const dateTime = new Date(doc.time);
              const day = dateTime.getDate();
              const month = dateTime.getMonth() + 1; // Months are zero-based, so add 1
              const year = dateTime.getFullYear();
              let hours = dateTime.getHours();
              const minutes = dateTime.getMinutes();
              const ampm = hours >= 12 ? 'PM' : 'AM';
              // Convert hours from 24-hour to 12-hour format
              hours = hours % 12;
              hours = hours ? hours : 12; // the hour '0' should be '12'
              // Format minutes and seconds to be two digits
              const formattedTime = hours + ':' + (minutes < 10 ? '0' + minutes : minutes) + ' ' + ampm;
              // Combine date and time into a single string
              const formattedDateTime = (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day) + '/' + year + ' ' + formattedTime;
              return {
                ...doc, // Spread operator to keep existing document data
                time: formattedDateTime // Replace the original time with the formatted date and time
              };
            });

            // Concatenate the formatted documents
            documents_uploaded = documents_uploaded.concat(formattedDocs);
          }
        }
        parsedAuthorizationDetails['documents'] = null;
        parsedAuthorizationDetails['selectedFile'] = null;
        Object.entries(parsedAuthorizationDetails).map(([key, value]) => {
          if (['dob_in_sign', 'date_in_sign', 'patient_personal_representative_sign_date', 'updated_on'].includes(key)) {
            parsedAuthorizationDetails[key] = (value !== '' && value !== null && value !== undefined && value !== 'Invalid date' ? new Date(value) : '');
            console.log('date converted', key, value, parsedAuthorizationDetails[key], parsedAuthorizationDetails)
          }
        })
        context.setState({
          AuthorizationData: parsedAuthorizationDetails,
          all_uploaded_documents: documents_uploaded,
          showAddAuthorizationModal: true
        })
        context.createSelectOptions();
      })
      .catch(err => { // log request error and prevent access to undefined state
        context.setState({ loading: false, error: true });
        console.error(err);
      }
      )
  }

  openAddAuthorizationModal() {
    context.setState({
      currentView: 'Add',
      all_uploaded_documents: [],
      historyID: 0,
      AuthorizationData: context.getModal('resetData'),
      showAddAuthorizationModal: true
    })
  }

  closeAddAuthorizationModal = () => {
    context.getAuthorizationList();
    context.setState({
      AuthorizationData: context.getModal('resetData'),
      showAddAuthorizationModal: false,
      all_uploaded_documents: []
    })
    setTimeout(() => context.createSelectOptions(), 100);
    sessionStorage.removeItem('modalOpen');
  }

  handleAuthorizationelectChange(event) {
    let name = event.target.name;
    let value = event.target.value;
    this.setState((prevState) => ({
      ...prevState,
      AuthorizationData: {
        ...prevState.AuthorizationData,
        [name]: value
      }
    }));
  }

  handlePhoneChange = (event) => {
    if (event.target !== null) {
      let test = event.target.value;
      if (event.target.name === "RRC_phone") {
        if (this.state.AuthorizationData.RRC_phone !== "") {
          this.setState({
            errorMessage: ''
          });
        }
        this.setState(prevState => ({
          ...prevState,
          AuthorizationData: {
            ...prevState.AuthorizationData,
            RRC_phone: test
          },
        }));
      }
      if (event.target.name === "RRC_fax") {
        if (this.state.AuthorizationData.RRC_fax !== "") {
          this.setState({
            errorFaxNumber: ''
          });
        }
        this.setState(prevState => ({
          ...prevState,
          AuthorizationData: {
            ...prevState.AuthorizationData,
            RRC_fax: test
          },
        }));
      }
      if (event.target.name === "social_security") {
        if (this.state.AuthorizationData.social_security !== "") {
          this.setState({
            errorSocial_security: ''
          });
        }
        this.setState(prevState => ({
          ...prevState,
          unSaved: true,
          AuthorizationData: {
            ...prevState.AuthorizationData,
            social_security: test
          }
        }));
      }
    }
  }

  beforeMaskedValueChange = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;
    // keep minus if entered by user
    if (value.endsWith('-') && userInput !== '-' && !this.state.AuthorizationData.RRC_fax.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }
    return { value, selection };
  }

  beforeMaskedValueChange2 = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;
    // keep minus if entered by user
    if (value.endsWith('-') && userInput !== '-' && !this.state.AuthorizationData.RRC_phone.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }
    return {
      value,
      selection
    };
  }

  handleMultiSelectChanges = (selectedOption, event) => {
    let updatedState = { ...this.state.AuthorizationData };
    if (event.name === "purpose") {
      updatedState.other_purpose = "";
    }
    updatedState[event.name] = selectedOption;
    this.setState(prevState => ({
      ...prevState,
      AuthorizationData: updatedState
    }));
  };



  closeAuthorizationDetailsModal() {
    context.setState({
      AuthorizationData: context.getModal('resetData'),
      showAddAuthorizationModal: false
    });
    setTimeout(() => context.createSelectOptions(), 100);
  }

  openAuthorizationDetailsModal() {
    context.setState({
      showAddAuthorizationModal: false,
      showAuthorizationDetailsModal: true,
    });
  }

  onChangeHandler = event => {
    let file_size = event.target.files[0].size;
    //or if you like to have name and type
    let file_name = event.target.files[0].name;
    let file_type = event.target.files[0].type;
    //do whatever operation you want to do here
    let selectedFile = [];
    for (let i = 0; i < event.target.files.length; i++) {
      selectedFile.push(event.target.files[i]);
    }
    this.setState({
      selectedFile,
      loaded: 0,
    })
  }


  saveAuthorizationdetails = () => {
    let { dob_in_sign } = this.state.AuthorizationData
    const formattedDate = moment(dob_in_sign, "MM/DD/YYYY", true);
    if (!formattedDate.isValid() && !dob_in_sign=="" ) {
      alert("Invalid Date");
      this.setState((prev) => ({
        AuthorizationData: { ...prev.AuthorizationData, dob_in_sign: "" }
      }));
      return false;
    }
    if ((this.state.AuthorizationData.RRC_phone.length < 14 && this.state.AuthorizationData.RRC_phone.length > 0) || (this.state.AuthorizationData.RRC_fax.length < 14 && this.state.AuthorizationData.RRC_fax.length > 0) || (this.state.AuthorizationData.social_security.length < 11 && this.state.AuthorizationData.social_security.length > 0)) {
      if (this.state.AuthorizationData.RRC_phone.length < 14 && this.state.AuthorizationData.RRC_phone.length > 0) {
        this.textInputRefRRC_phone.focus();
        this.setState({ errorMessage: 'Please enter at least 10 digits.' });
      }
      if (this.state.AuthorizationData.RRC_fax.length < 14 && this.state.AuthorizationData.RRC_fax.length > 0) {
        this.textInputRefRRC_fax.focus();
        this.setState({ errorFaxNumber: 'Please enter at least 10 digits Fax Number.' });
      }
      if (this.state.AuthorizationData.social_security.length < 11 && this.state.AuthorizationData.social_security.length > 0) {
        this.textInputRefsocial_security.focus();
        this.setState({ errorSocial_security: 'Please enter at least 9 digits social security number.' });
        // return;
      }
      return;
    } else {
      let buttonType = 'save';//event.target.value ;
      let today = new Date();
      var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      const data = new FormData();
      Object.entries(context.state.AuthorizationData).map(([key, value]) => {
        if (key === 'types_of_records') {
          if (this.state.AuthorizationData.types_of_records !== undefined && this.state.AuthorizationData.types_of_records !== null && this.state.AuthorizationData.types_of_records !== '') {
            for (let i = 0; i < this.state.AuthorizationData.types_of_records.length; i++) {
              data.append(`types_of_records[${i}]`, this.state.AuthorizationData.types_of_records[i].label)
            }
          } else {
            data.append(`types_of_records`, '')
          }
        } else if (key === 'purpose') {
          if (this.state.AuthorizationData.purpose !== undefined && this.state.AuthorizationData.purpose !== null && this.state.AuthorizationData.purpose !== '') {
            for (let i = 0; i < this.state.AuthorizationData.purpose.length; i++) {
              data.append(`purpose[${i}]`, this.state.AuthorizationData.purpose[i].label)
            }
          } else {
            data.append(`purpose`, '')
          }
        } else if (['specific_time_records', 'specific_time_records_to', 'date_in_sign', 'patient_personal_representative_sign_date', 'revoked_on', 'expiry_period_from', 'expiry_period_to', 'dob_in_sign'].includes(key)) {
          if (key === 'expiry_period_from') {
            value = context.state.AuthorizationData.date_in_sign;
          }
          data.append(key, value !== '' && value !== null && value !== undefined ? moment(value).format('YYYY-MM-DD') : '');
        } else {
          data.append(key, value);
        }
      })
      let emptyFormData = true;
      for (var val of data.values()) {
        if (val !== '' && val !== 'null' && val !== null) {
          emptyFormData = false;
        } else {
          console.log('entries', data.values(), data.entries(), this.state.selectedFile);
        }
      }
      if (sessionStorage.getItem('AuthorizationListData_length') === 0) {
        data.append('key', sessionStorage.getItem('clientId') + "-1");
      } else {
        data.append('key', sessionStorage.getItem('clientId') + "-" + (parseInt(sessionStorage.getItem('AuthorizationListData_length')) + 1))
      }
      if (this.state.AuthorizationData.selectedFile !== null) {
        for (let i = 0; i < this.state.AuthorizationData.selectedFile.length; i++) {
          console.log('representative_docs length', this.state.AuthorizationData.selectedFile.length)
          if (this.vlidateFileSelection(this.state.AuthorizationData.selectedFile[i])) {
            data.append(`representative_docs[${i}]`, this.state.AuthorizationData.selectedFile[i])
          } else {
            return false;
          }
        }
      }
      if (this.state.AuthorizationData.documents !== null) {
        for (let i = 0; i < this.state.AuthorizationData.documents.length; i++) {
          console.log('representative_docs length', this.state.AuthorizationData.documents.length)
          if (this.vlidateFileSelection(this.state.AuthorizationData.documents[i])) {
            data.append(`attachments[${i}]`, this.state.AuthorizationData.documents[i]);
          } else {
            return false;
          }
        }
      }
      data.append('client_id', sessionStorage.getItem('clientId'))
      data.append('updated_by', sessionStorage.getItem('loggedinUserID'))
      data.append('updated_on', getFormattedDate(new Date(), true))
      data.append('apikey', localStorage.getItem('__TOKEN__'))
      data.append('version', "1")
      data.append('historyID', this.state.historyID);
      axios.post(global.restApiURL + "customRest/uploadAuthorizationAttachments", data).then(res => {
        let response = res.data;
        if (response.success && res.status == 200) {
          let state_of_success = '';
          switch (context.state.currentView) {
            case 'Add':
              state_of_success = 'added';
              break;

            case 'Update':
              state_of_success = 'updated';
              break;
          }
          sessionStorage.setItem('success_msg', "Consent for ROI " + state_of_success + " successfully.");
          sessionStorage.setItem('AuthorizationListData_length', (parseInt(sessionStorage.getItem('AuthorizationListData_length')) + 1));
          context.closeAddAuthorizationModal();
          window.location.href = '';
        } else if ("error_code" in response) {
          alert(response.msg);
          window.location.href = global.domain + global.traversArray['out'];
        } else {
          alert(response.msg);
          context.closeAddAuthorizationModal();
        }
      }).catch(err => { // log request error and prevent access to undefined state
        console.error(err);
      });
    }
  }

  vlidateFileSelection(file) {
    if ((file.size / 1024 / 1024) >= 5) {
      alert(file.name + " : The maximum allowed size is 5MB.");
      return false;
    } else {
      return true;
    }
  }

  uploadImage = (e) => {
    let file = e.target.files[0];
    if (file && !file.name) {
      window.alert("Please select a file");
      return false;
    }
    if (file.size > 10e6) {
      window.alert("Please upload a file smaller than 10 MB");
      return false;
    }
  }

  Filevalidation = () => {
    const fi = document.getElementById('file');
    // Check if any file is selected. 
    if (fi.files.length > 0) {
      for (var i = 0; i <= fi.files.length - 1; i++) {
        const fsize = fi.files.item(i).size;
        const file = Math.round((fsize / 1024));
        // The size of the file. 
        if (file >= 4096) {
          alert(
            "File too Big, please select a file less than 4mb");
        } else if (file < 2048) {
          alert(
            "File too small, please select a file greater than 2mb");
        } else {
          document.getElementById('size').innerHTML = '<b>'
            + file + '</b> KB';
        }
      }
    }
  }

  getOneYearIntervalDateFromDateOfSigning() {
    const date_of_signing = this.state.AuthorizationData.date_in_sign;
    let date_after_one_yr_of_sign = new Date(this.state.AuthorizationData.date_in_sign);
    date_after_one_yr_of_sign = new Date(date_after_one_yr_of_sign.setFullYear(date_after_one_yr_of_sign.getFullYear() + 1));
    return '(' + convertDate(date_of_signing, 'MM/DD/YYYY') + ' - ' + convertDate(date_after_one_yr_of_sign, 'MM/DD/YYYY') + ')';
  }

  handleDateChange = name => date => {
    var stateCopy = Object.assign({}, this.state);
    if (name === 'date_in_sign') {
      let expiry_date = new Date(date);
      expiry_date = new Date(expiry_date.setFullYear(expiry_date.getFullYear() + 1));
      stateCopy.AuthorizationData['expiry_period_to'] = expiry_date;
      stateCopy.AuthorizationData[name] = date;
    } else {
      stateCopy.AuthorizationData[name] = date;
    }
    this.setState(stateCopy);
  };

  handleRadioChange = (event) => {
    let radio_options = context.state[event.target.name]['options'];
    if (event.target.name === 'is_patient_legally_incapacitated') {
      context.resetDataField('is_patient_legally_incapacitated');
    } else {
      radio_options && radio_options !== null && radio_options !== undefined && radio_options.map((option) =>
        context.resetDataField(option.value)
      )
    }
    let tempOb = context.state.AuthorizationData;
    if (event.target.name === 'authorization_period_type' && event.target.value === 'one_year_from_sign') {
      let expiry_date = new Date(convertDate(tempOb.date_of_signing, 'MM/DD/YYYY'));
      expiry_date = new Date(expiry_date.setFullYear(expiry_date.getFullYear() + 1));
      tempOb['expiry_period_to'] = expiry_date;
    }
    tempOb[event.target.name] = event.target.value;
    this.setState((prevState) => ({
      ...prevState,
      AuthorizationData: tempOb
    })
    );
  }

  handleCheckboxChange = (event) => {
    let tempOb = context.state.AuthorizationData;
    tempOb[event.target.name] = event.target.checked;
    this.setState((prevState) => ({
      ...prevState,
      AuthorizationData: tempOb
    })
    );
  }

  route = (route) => {
    sessionStorage.setItem('modalOpen', true);
    switch (route) {
      case 'Follow-Up':
        window.location.href = global.domain + 'followup';
        break;

      case 'Routing':
        window.location.href = global.domain + 'routing';
        break;

      case 'Referral':
        window.location.href = global.domain + 'referrals';
        break;
    }
  }

  handleEvent(event, picker) {
    let tempOb = context.state.AuthorizationData;
    tempOb['specific_time_records'] = picker.startDate;
    tempOb['specific_time_records_to'] = picker.endDate;
    context.setState((prevState) => ({
      ...prevState,
      AuthorizationData: tempOb
    })
    );
  }

  onDrop(files) {
    this.setState(prevState => ({
      ...prevState,
      AuthorizationData: {
        ...prevState.AuthorizationData,
        selectedFile: files
      },
    }));
  }

  onDrop_docs(files) {
    this.setState(prevState => ({
      ...prevState,
      AuthorizationData: {
        ...prevState.AuthorizationData,
        documents: files
      },
    }));
  }

  enablePrintStyles = () => {
    if ((this.state.AuthorizationData.RRC_phone.length < 14 && this.state.AuthorizationData.RRC_phone.length > 0) || (this.state.AuthorizationData.RRC_fax.length < 14 && this.state.AuthorizationData.RRC_fax.length > 0) || (this.state.AuthorizationData.social_security.length < 11 && this.state.AuthorizationData.social_security.length > 0)) {
      if (this.state.AuthorizationData.RRC_phone.length < 14 && this.state.AuthorizationData.RRC_phone.length > 0) {
        this.textInputRefRRC_phone.focus();
        this.setState({ errorMessage: 'Please enter at least 10 digits.' });
      }
      if (this.state.AuthorizationData.RRC_fax.length < 14 && this.state.AuthorizationData.RRC_fax.length > 0) {
        this.textInputRefRRC_fax.focus();
        this.setState({ errorFaxNumber: 'Please enter at least 10 digits.' });
      }
      if (this.state.AuthorizationData.social_security.length < 11 && this.state.AuthorizationData.social_security.length > 0) {
        this.textInputRefsocial_security.focus();
        this.setState({ errorSocial_security: 'Please enter at least 9 digits social security number.' });
      }
    } else {
      this.setState({ print: true })
      return Promise.resolve();
    }
  }

  contenctReadyToPrint = () => {
    if ((this.state.AuthorizationData.RRC_phone.length < 14 && this.state.AuthorizationData.RRC_phone.length > 0) || (this.state.AuthorizationData.RRC_fax.length < 14 && this.state.AuthorizationData.RRC_fax.length > 0) || (this.state.AuthorizationData.social_security.length < 11 && this.state.AuthorizationData.social_security.length > 0)) {
      if (this.state.AuthorizationData.RRC_phone.length < 14 && this.state.AuthorizationData.RRC_phone.length > 0) {
        this.textInputRefRRC_phone.focus();
        this.setState({ errorMessage: 'Please enter at least 10 digits.' });
      }
      if (this.state.AuthorizationData.RRC_fax.length < 14 && this.state.AuthorizationData.RRC_fax.length > 0) {
        this.textInputRefRRC_fax.focus();
        this.setState({ errorFaxNumber: 'Please enter at least 10 digits.' });
      }
      if (this.state.AuthorizationData.social_security.length < 11 && this.state.AuthorizationData.social_security.length > 0) {
        this.textInputRefsocial_security.focus();
        this.setState({ errorSocial_security: 'Please enter at least 9 digits social security number.' });
      }
    } else {
      return this.componentRef;
    }
  }

  disablePrintStyles = () => {
    this.setState({ print: false })
  }

  handleTimer(date, field) {
    let AuthorizationData_temp = this.state.AuthorizationData;
    let dateString = typeof date === 'string' ? date : moment(date._d).format('MMDDYYYY');
    if (!/^[0-9\/]*$/.test(dateString)) {
        alert("Please enter only numeric values for the date.");
        //  this.setState((prev)=>({AuthorizationData:{...prev.AuthorizationData,dob_in_sign:""}}));
        return false; 
    }
    if (field === 'date_in_sign') {
      AuthorizationData_temp[field] = date._d !== undefined ? moment(date._d).format('MM/DD/YYYY') : date;
      AuthorizationData_temp['expiry_period_to'] = moment.utc(date._d).add(1, 'years').format('MM/DD/YYYY');
    } else {
      AuthorizationData_temp[field] = date._d !== undefined ? moment.utc(date._d).format('MM/DD/YYYY') : date;
    }
    context.setState({ AuthorizationData: AuthorizationData_temp, unSaved: true });
  };

  handleTimeronBlur(date, field) {
    if (this.state.AuthorizationData.dob_in_sign === "" && field === "dob_in_sign" ||this.state.AuthorizationData.date_in_sign === "" && field === "date_in_sign" ) {
      if(field==="dob_in_sign"){
        this.setState((prev) => ({
          AuthorizationData: { ...prev.AuthorizationData, dob_in_sign: "", }
        }));
      }else{
        this.setState((prev) => ({
          AuthorizationData: { ...prev.AuthorizationData, date_in_sign: "", }
        }));
      }
      return;
    }
    const formattedDate = moment(date, "MM/DD/YYYY", true); 
    if (!formattedDate.isValid() && date !== "") {
      alert("Invalid Date");
      if(field==="dob_in_sign"){
        this.setState((prev) => ({
          AuthorizationData: { ...prev.AuthorizationData, dob_in_sign: "", }
        }));
      }else{
        this.setState((prev) => ({
          AuthorizationData: { ...prev.AuthorizationData, date_in_sign: "", }
        }));
      }
      return false;
    }
    date = date ? date : context.state.AuthorizationData[field];
    if(!/^[0-9\/]*$/.test(date)){
      return false
    }
    let AuthorizationData_temp = this.state.AuthorizationData;
    if (field == "dob_in_sign") {
      let dateDiff = moment().diff(moment(date), 'years');
      if (dateDiff < 14) {
        AuthorizationData_temp[field] = typeof date === 'string' && typeof date !== 'object' && date !== "" ? moment.utc().subtract(context.state.min_dob, 'years') : (typeof date == 'object' && date !== null ? moment.utc(date._d).subtract(context.state.min_dob, 'years').format('MM/DD/YYYY') : "");
      } else {
        AuthorizationData_temp[field] = typeof date === 'string' && typeof date !== 'object' && date !== "" ? moment.utc().subtract(context.state.min_dob, 'years') : (typeof date == 'object' && date !== null ? moment.utc(date._d).format('MM/DD/YYYY') : "");
      }
    } else {
      AuthorizationData_temp[field] = typeof date === 'string' && typeof date !== 'object' && date !== "" ? moment.utc().format('MM/DD/YYYY') : (typeof date == 'object' && date !== null ? moment.utc(date._d).format('MM/DD/YYYY') : "");
    }
    context.setState({ AuthorizationData: AuthorizationData_temp, unSaved: true });
  };

  validDobDate(current, selectedDate) {
    return current.isBefore(moment().subtract(context.state.min_dob, 'years')) && current.isAfter(moment().subtract(120, 'years'));
  };

  validExpiryDate(current, selectedDate) {
    return current.isAfter(moment(context.state.AuthorizationData.date_in_sign));
  };

  toggleSSN = () => {
    this.setState({ toggleSSN: !this.state.toggleSSN });
  }

  beforeMaskedValueChangeSSN = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;
    if (value.endsWith('-') && userInput !== '-' && !this.state.AuthorizationData.social_security.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }
    return {
      value,
      selection
    };
  }

  handleAttachmentClick(url, fileName) {
    const path = new URL(url).pathname;
    console.log("path", path)
    const payload = {
      path: path,
      apikey: localStorage.getItem('__TOKEN__')
    };
    axios.post(global.restApiURL + "customRest/FileDownloadAuthenticated", payload, {
      responseType: 'blob'
    })
      .then((res) => {
        const blob = new Blob([res.data], { type: res.data.type });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); // specify the file name and extension
        document.body.appendChild(link);
        link.click();

        // Clean up
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error downloading the file:', error);
      });
  }



  render() {
    $(document).ready(function () {
      var NumRegex = /^[0-9]+$|^$|^\s$/;
      var EmailRegex = /^$|^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
      $.validator.addMethod('NumRegex', function (value, element, parameter) {
        return value.match(NumRegex);
      }, 'Please enter a valid zipcode.');
      $.validator.addMethod('EmailRegex', function (value, element, parameter) {
        return value.match(EmailRegex);
      }, 'Please enter a valid email address.');
      $('#AuthorizationForm').validate({
        rules: {
          RRC_zip: {
            NumRegex: true
          },
          RRC_email: {
            EmailRegex: true
          }
        },
        submitHandler: function () {
          context.saveAuthorizationdetails();
        },
        errorElement: 'span',
        errorPlacement: function (error, element) {
          error.addClass('invalid-feedback');
          element.closest('.form-group').append(error);
        },
        highlight: function (element, errorClass, validClass) {
          $(element).addClass('is-invalid');
        },
        unhighlight: function (element, errorClass, validClass) {
          $(element).removeClass('is-invalid');
        },
      });
      $('form input').keydown(function (e) {
        if (e.keyCode == 13) {
          var inputs = $(this).parents("form").eq(0).find(":input");
          if (inputs[inputs.index(this) + 1] != null) {
            inputs[inputs.index(this) + 1].focus();
          }
          e.preventDefault();
          return false;
        }
      });
    });

    sessionStorage.setItem('formname', 'Authorization for ROI');
    console.log('specific time', this.state.AuthorizationData);
    let min_dob = new Date();
    min_dob.setFullYear(min_dob.getFullYear() - this.state.min_dob);
    let label = '';
    let start = this.state.AuthorizationData.specific_time_records !== "" && this.state.AuthorizationData.specific_time_records !== null && this.state.AuthorizationData.specific_time_records !== undefined ? this.state.AuthorizationData.specific_time_records.format('MM/DD/YYYY') : "";
    let end = this.state.AuthorizationData.specific_time_records_to !== "" && this.state.AuthorizationData.specific_time_records_to !== null && this.state.AuthorizationData.specific_time_records_to !== undefined ? this.state.AuthorizationData.specific_time_records_to.format('MM/DD/YYYY') : "";
    label = start + ' - ' + end;
    if (start === end) {
      label = start;
    }
    let width_md_6 = {}
    let width_md_5 = {}
    let width_md_4 = {}
    let width_md_3 = {}
    let width_md_2 = {}
    let width_md_10 = {}
    let width_md_1 = {}
    let floatLeft = {}
    let checkboxMargin = {}
    let bodyMargin = {}
    let displayNone = {}
    let table_print = {}
    let td_print = {}
    let th_print = {}
    let pageBreak = {}

    if (this.state.print === true) {

      floatLeft = {
        float: "left",
        padding: "0px"
      }

      checkboxMargin = {
        padding: "3px",
        display: "inline"
      }

      displayNone = {
        display: "none"
      }

      bodyMargin = {
        margin: "30px"
      }

      width_md_6 = {
        width: "50%",
        display: "inline",
        float: "left"
      }

      width_md_5 = {
        width: "41.666667%",
        display: "inline",
        float: "left"
      }

      width_md_4 = {
        width: "33.333333%",
        display: "inline",
        float: "left"
      }

      width_md_3 = {
        width: "25%",
        display: "inline",
        float: "left"
      }

      width_md_2 = {
        width: "16.666667%",
        display: "inline",
        float: "left"
      }

      width_md_10 = {
        width: "83.33333333%",
        display: "inline",
        float: "left"
      }

      width_md_1 = {
        width: "8.333333%",
        display: "inline",
        float: "left"
      }

      table_print = {
        borderCollapse: "collapse",
        width: "100%"
      }

      td_print = {
        border: "1px solid #ddd",
        padding: "8px",
      }

      th_print = {
        paddingTop: "12px",
        paddingBottom: "12px",
        textAlign: "left",
        backgroundColor: "#4CAF50",
        color: "white",
        border: "1px solid #ddd",
        padding: "8px",
      }
      pageBreak = { height: "120vh", paddingTop: "15vh" }
    }
    let locale = {
      format: 'MM/DD/YYY',
      separator: ' - ',
      applyLabel: 'Apply',
      cancelLabel: 'Clear',
      weekLabel: 'W',
      customRangeLabel: 'Custom Range',
      daysOfWeek: moment.weekdaysMin(),
      monthNames: moment.monthsShort(),
      firstDay: moment.localeData().firstDayOfWeek(),
    };
    let fax_info = [];
    {
      validationObj.options !== undefined && validationObj.options.fax_info.map((option) => {

        fax_info.push(
          <div className=" col-lg-1  col-md-1 form-group d-inline">
            <input className="" type="radio" name={context.state.fieldName.fax_info} value={option.value} defaultChecked={context.state.AuthorizationData.fax_info === option.value} onChange={this.handleRadioChange} /> <label> {option.key} </label>
          </div>
        )

      })
    }
    let docs_content_for_printing = '';
    if (this.state.all_uploaded_documents !== null && this.state.all_uploaded_documents !== undefined && this.state.all_uploaded_documents.length > 0) {
      let docs_content_headers = <tr><th style={th_print}>File</th><th style={th_print}>Remarks</th><th style={th_print}>Uploaded On</th></tr>;
      let docs_content_body = [];
      this.state.all_uploaded_documents.map(doc => docs_content_body.push(<tr><td style={td_print}>{doc.name}</td><td style={td_print}>{doc.remarks}</td><td style={td_print}>{doc.time}</td></tr>))
      docs_content_for_printing = <table style={table_print}>{docs_content_headers}{docs_content_body}</table>;
    }
    return (
      <div className="hold-transition sidebar-mini text-sm">
        <div className="wrapper">
          <Topnav />
          <div className="content-wrapper">
            <Patientinfoheader
              clientId={sessionStorage.getItem('clientId')}
              dob={encryptStorage.getItem('dob')}
              email={encryptStorage.getItem('email')} phone={encryptStorage.getItem('phone')}
              peer_support_specialist={encryptStorage.getItem('peer_support_specialist')}
              next_followup_date={encryptStorage.getItem('month_1_followup_date')}
              intake_date={encryptStorage.getItem('intake_date')}
              initiated_by={encryptStorage.getItem('last_updated_by')}
              formname={sessionStorage.getItem('formname')}
              client_name={encryptStorage.getItem('client_first_name') + " " + encryptStorage.getItem('client_middle_initial') + " " + encryptStorage.getItem('client_last_name')}
            />
            <section className="content">
              <div className="row">
                <Sidenav />
                <div className="container-fluid col-md-9 col-lg-10">
                  <div className="card card-default">
                    <div className="card-header">
                      <p className="lead float-left"><b className="text-green">Consent for Release of Information</b></p>
                      <button type="submit" className="btn btn-primary float-right" value="proceed" onClick={() => window.location.href = "./verbal-consent"}><img src="dist/img/save-proceed_icon.png" alt="" style={{ pointerEvents: "none" }} /> Save &amp; Proceed </button>
                      {can_add('Authorization') ? <button type="submit" className="btn btn-success float-sm-right btnmargin" value="all" onClick={this.openAddAuthorizationModal} lang="all" ><i className="fas fa-plus" aria-hidden="true"></i> Add New</button> : ""}
                      <input type="hidden" id="clicked" value="" readOnly />
                    </div>
                    <div className="card-body">
                      <DataTable
                        columns={this.state.AuthorizationHistoryColumns}
                        data={this.state.changeParticipantionsHistoryData}
                        customStyles={global.customTableStyles}
                        striped={true}
                        pagination
                        paginationComponentOptions={global.paginationComponentOptions}
                        highlightOnHover
                        pointerOnHover
                        noDataComponent={<div className="no_record_found">{global.no_record_found_text}</div>}
                      />
                    </div>
                    <div className="card-footer ">
                      <div className="float-right"><button type="submit" className="btn btn-primary float-right" value="proceed" onClick={() => window.location.href = "./verbal-consent"}><img src="dist/img/save-proceed_icon.png" alt="" style={{ pointerEvents: "none" }} /> Save &amp; Proceed </button>
                        {can_add('Authorization') ? <button type="submit" className="btn btn-success float-sm-right btnmargin" value="all" onClick={this.openAddAuthorizationModal} lang="all" ><i className="fas fa-plus" aria-hidden="true"></i> Add New</button> : ""}
                        <input type="hidden" id="clicked" value="" readOnly />
                      </div>
                    </div>
                    <Modal
                      showOverlay={true}
                      show={this.state.showAddAuthorizationModal}
                      onClose={this.closeAddAuthorizationModal}
                      disableOverlayClick={true}
                      showCloseButton={false}
                      className="modal-lg modal-dialog"
                    >
                      <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                        <Modal.Title>
                          <div className="row" >
                            <div className="col-md-6">
                              <h4 className="modal-title float-left text-green">{this.state.currentView} Authorization for ROI</h4>
                            </div>
                          </div>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <form id="AuthorizationForm" method="post" action="javascript:void(0)" ref={el => (this.componentRef = el)}>
                          <fieldset style={bodyMargin}>
                            <div className=" edit_allowed" >
                              <div className="row " >
                                <div className="col-lg-12 col-lg-12 form-group">
                                  <span style={width_md_1} className="col-lg-1 col-md-1">I, </span><input type="text" style={width_md_5} className="col-lg-5 col-md-5 form-control d-inline" name={context.state.fieldName.patient_name} id={context.state.fieldName.patient_name} defaultValue={this.state.AuthorizationData.patient_name} required={this.state.mandatoryInfo.patient_name} maxLength={this.state.patient_name.columnLength} disabled={true} /><span style={width_md_1} className="col-lg-1 col-md-1"> authorize the </span><input type="text" style={width_md_5} className="col-lg-5 col-md-5 form-control d-inline" name={context.state.fieldName.rrc_org} id={context.state.fieldName.rrc_org} defaultValue={this.state.AuthorizationData.rrc_org} required={this.state.mandatoryInfo.rrc_org} maxLength={this.state.rrc_org.columnLength} disabled={true} />
                                </div>
                              </div>
                              <div className="row " >
                                <div className="col-lg-12 col-lg-12 form-group">
                                  or its individual representative(s) to send and receive information as described below.
                                </div>
                              </div>
                            </div>
                            <div className=" edit_allowed">
                              <div className="row " >
                                <div className=" col-lg-12  col-md-12 form-group">
                                  <strong>PERSON OR ORGANIZATION TO WHICH DISCLOSURE IS TO BE MADE:</strong>
                                </div>
                              </div>
                              <div className="row " >
                                <div style={width_md_6} className="col-lg-6  col-md-6 form-group">
                                  <LabelField style={floatLeft} {...context.state.RRC_person} />
                                </div>
                                <div style={width_md_6} className="col-lg-6  col-md-6 form-group">
                                  <input type="text" className="form-control" placeholder="Name" name={context.state.fieldName.RRC_person} id={context.state.fieldName.RRC_person} defaultValue={this.state.AuthorizationData.RRC_person} required={this.state.mandatoryInfo.RRC_person} maxLength={this.state.RRC_person.columnLength} onChange={this.handleRadioChange} />
                                </div>
                              </div>
                              <div className="row " >
                                <div style={width_md_6} className="col-lg-6  col-md-6 form-group">
                                  <LabelField style={floatLeft} {...context.state.RRC_address} />
                                </div>
                                <div style={width_md_6} className="col-lg-6  col-md-6 form-group">
                                  <TextAreaAutosize
                                    type="text"
                                    className="form-control"
                                    placeholder="Full Address"
                                    name={context.state.fieldName.RRC_address}
                                    id={context.state.fieldName.RRC_address}
                                    defaultValue={this.state.AuthorizationData.RRC_address}
                                    required={this.state.mandatoryInfo.RRC_address}
                                    maxLength={this.state.RRC_address.columnLength}
                                    style={{ overflow: "hidden", textAlign: "justify" }}
                                    maxRows={this.state.print ? (this.state.AuthorizationData.RRC_address?.length / 58) + this.state.AuthorizationData.RRC_address?.split(/\r\n|\r|\n/).length : (this.state.AuthorizationData.RRC_address?.length / 45) + this.state.AuthorizationData.RRC_address?.split(/\r\n|\r|\n/).length}
                                    onChange={this.handleRadioChange}
                                  />
                                </div>
                              </div>
                              <div className="row ">
                                <div style={width_md_6} className="col-lg-6  col-md-6 form-group">
                                  <LabelField style={floatLeft} {...context.state.RRC_phone} />
                                </div>
                                <div style={width_md_6} className="col-lg-6  col-md-6 form-group">
                                  <InputMask   inputRef={(input) => { this.textInputRefRRC_phone = input; }} id="RRC_phone" name="RRC_phone" placeholder="Phone" className="form-control" mask="(999) 999-9999" maskChar={null} value={this.state.AuthorizationData.RRC_phone} beforeMaskedValueChange={this.beforeMaskedValueChange2} onChange={this.handlePhoneChange} required={this.state.mandatoryInfo.RRC_phone} maxLength={this.state.RRC_phone.columnLength} />
                                  {<span style={{ color: 'red', fontSize: "75%" }}>{this.state.AuthorizationData.RRC_phone === "" ? "" : this.state.errorMessage}</span>}
                                </div>
                              </div>
                              <div className="row" >
                                <div style={width_md_6} className="col-lg-6  col-md-6 form-group">
                                  <LabelField style={floatLeft} {...context.state.fax_info} />
                                </div>
                                <div style={width_md_6} className="col-lg-6  col-md-6 form-group">
                                  {fax_info}
                                </div>
                              </div>
                              {context.state.AuthorizationData.fax_info === 'Yes' ? (
                                <>
                                  <div className="row" >
                                    <div style={width_md_6} className="col-lg-6  col-md-6 form-group">
                                      <LabelField style={floatLeft} {...context.state.RRC_fax} />
                                    </div>
                                    <div style={width_md_6} className="col-lg-6  col-md-6 form-group">
                                      <InputMask  inputRef={(input) => { this.textInputRefRRC_fax = input; }} id="RRC_fax" placeholder="Fax Number" name="RRC_fax" className="form-control" mask="(999) 999-9999" maskChar={null} value={this.state.AuthorizationData.RRC_fax} beforeMaskedValueChange={this.beforeMaskedValueChange} onChange={this.handlePhoneChange} required={this.state.mandatoryInfo.RRC_fax} maxLength={this.state.RRC_fax.columnLength} />
                                      <span style={{ color: 'red', fontSize: "75%" }}>{this.state.AuthorizationData.RRC_fax === "" ? "" : this.state.errorFaxNumber}</span>
                                    </div>
                                  </div>
                                </>) : ""}
                              <div className="row" >
                                <div style={width_md_6} className="col-lg-6  col-md-6 form-group">
                                  <LabelField style={floatLeft} {...context.state.patient_name_in_sign} />
                                </div>
                                <div style={width_md_6} className="col-lg-6  col-md-6 form-group">
                                  <input type="text" placeholder="Clients Full Name" className="form-control" name={context.state.fieldName.patient_name_in_sign} id={context.state.fieldName.patient_name_in_sign} defaultValue={this.state.AuthorizationData.patient_name} required={this.state.mandatoryInfo.patient_name_in_sign} maxLength={this.state.patient_name_in_sign.columnLength} onChange={this.handleRadioChange} />
                                </div>
                              </div>
                              <div className="row" >
                                <div style={width_md_6} className="col-lg-6  col-md-6 form-group">
                                  <LabelField style={floatLeft} {...context.state.social_security} />
                                </div>
                                <div style={width_md_6} className="col-lg-6  col-md-6 form-group">
                                  <div className="input-with-icon">
                                    {!can_edit('Authorization') || (this.state.toggleSSN && this.state.AuthorizationData.social_security !== null && this.state.AuthorizationData.social_security !== undefined && this.state.AuthorizationData.social_security !== '' && typeof this.state.AuthorizationData.social_security =='string'? this.state.AuthorizationData.social_security.replace(/-/g, "").length >= 9:""
                                    ) ? (
                                      <>
                                        <input type="text" placeholder="Social Security Number" id="social_security_view" name="social_security_text" className="form-control" value={`***-**-` + this.state.AuthorizationData.social_security.substr(this.state.AuthorizationData.social_security.length - 4)} onFocus={this.toggleSSN} defaultValue={this.state.AuthorizationData.social_security} />
                                        <input type="hidden" id="social_security" name="social_security" className="form-control" value={this.state.AuthorizationData.social_security} />
                                      </>) : (
                                      <InputMask inputRef={(input) => { this.textInputRefsocial_security = input; }} id="social_security" placeholder="Social Security Number" name="social_security" className="form-control" mask="999-99-9999" maskChar={null} value={this.state.AuthorizationData.social_security} beforeMaskedValueChange={this.beforeMaskedValueChangeSSN} onChange={this.handlePhoneChange} required={this.state.mandatoryInfo.social_security} maxLength={this.state.social_security.columnLength} onBlur={this.toggleSSN} onMouseLeave={this.toggleSSN} />)
                                    }
                                    <span style={{ color: 'red', fontSize: "75%" }}>{this.state.AuthorizationData.social_security === "" ? "" : this.state.errorSocial_security}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="row ">
                                <div style={width_md_6} className="col-lg-6  col-md-6 form-group">
                                  <LabelField style={floatLeft} {...context.state.dob_in_sign} /> (MM/DD/YYYY)
                                </div>
                                <div style={width_md_6} className="col-lg-6  col-md-6 form-group">
                                  <div className="formfield">
                                    <div className="input-group-append" style={{ height: '38px', float: 'left', marginRight: '-3px' }}>
                                      <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
                                    </div>
                                    <div className="input-group date timepicker" style={{ width: "calc(100% - 41px)" }}>
                                      <span style={{ width: '100%' }}>
                                        <DateTime
                                          onBlur={(date) => this.handleTimeronBlur(date, 'dob_in_sign')}
                                          onChange={(date) => this.handleTimer(date, 'dob_in_sign')}
                                          inputProps={{maxLength: 10, value: typeof context.state.AuthorizationData.dob_in_sign === 'string' ? context.state.AuthorizationData.dob_in_sign : moment.utc(context.state.AuthorizationData.dob_in_sign).format('MM/DD/YYYY'), required: this.state.mandatoryInfo.dob_in_sign, name: 'dob_in_sign', placeholder: 'Date of birth' }}
                                          // defaultValue={moment.utc(this.state.AuthorizationData.dob_in_sign).format('MM/DD/YYYY')}
                                          defaultValue={moment.utc().subtract(14, 'years').format('MM/DD/YYYY')}
                                          dateFormat="MM/DD/YYYY" timeFormat={false}
                                          isValidDate={this.validDobDate}
                                          input={true}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row edit_allowed ">
                                <div style={width_md_6} className="col-lg-6  col-md-6 form-group">
                                  <LabelField style={floatLeft} {...context.state.reason} />
                                </div>
                                <div style={width_md_6} className="col-lg-6  col-md-6 form-group">
                                  <TextAreaAutosize
                                    type="text"
                                    placeholder="Purpose or need for this disclosure"
                                    className="form-control screenOnly"
                                    name={context.state.fieldName.reason}
                                    id={context.state.fieldName.reason}
                                    required={this.state.mandatoryInfo.reason}
                                    maxLength={this.state.reason.columnLength}
                                    style={{ overflow: "hidden", textAlign: "justify" }}
                                    maxRows={this.state.print ? (this.state.AuthorizationData.reason?.length / 58) + this.state.AuthorizationData.reason?.split(/\r\n|\r|\n/).length : (this.state.AuthorizationData.reason?.length / 45) + this.state.AuthorizationData.reason?.split(/\r\n|\r|\n/).length}
                                    onChange={this.handleRadioChange}>
                                    {this.state.AuthorizationData.reason}
                                  </TextAreaAutosize>
                                </div>
                                <div className="col-md-12" >
                                  <div className="form-control col-6    heightRemoveAuthorization printOnly PdfBackgroundColorAuthorization " style={{ marginTop: "-54px" }} name="other_purpose" >{this.state.AuthorizationData.reason}</div>
                                </div>
                              </div>
                              <div className="row mt-2 edit_allowed">
                                <div style={width_md_6} className="col-lg-6  col-md-6 specific_forms_selected form-group" >
                                  <LabelField style={floatLeft} {...context.state.purpose} />
                                </div>
                                <div style={width_md_6} className="col-lg-6  col-md-6 form-group specific_forms_selected" >
                                  <Select
                                    name={context.state.fieldName.purpose}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    value={this.state.AuthorizationData.purpose}
                                    onChange={this.handleMultiSelectChanges}
                                    options={this.state.purpose_MultiOptions}
                                    styles={colourStyles}
                                    required={this.state.purpose.mandatory}
                                  />
                                  {this.state.AuthorizationData.purpose && context.state.AuthorizationData.purpose.filter(function (e) { return e.label === 'Other (Please Specify Below)'; }).length > 0 ? (
                                    <TextAreaAutosize
                                      type="text"
                                      className="form-control screenOnly  mt-2"
                                      name="other_purpose"
                                      id="other_purpose"
                                      placeholder={this.state.labelText.other_purpose}
                                      defaultValue={this.state.AuthorizationData.other_purpose}
                                      required={this.state.other_purpose.mandatory}
                                      onChange={this.handleRadioChange}
                                      style={{ overflow: "hidden" }}
                                      maxRows={this.state.print ? (this.state.AuthorizationData.other_purpose?.length / 58) + this.state.AuthorizationData.other_purpose?.split(/\r\n|\r|\n/).length : (this.state.AuthorizationData.other_purpose?.length / 45) + this.state.AuthorizationData.other_purpose?.split(/\r\n|\r|\n/).length}
                                    />
                                  ) : ""}
                                </div>
                                <div className="col-md-12">
                                  {this.state.AuthorizationData.purpose && context.state.AuthorizationData.purpose.filter(function (e) { return e.label === 'Other (Please Specify Below)'; }).length > 0 ? (
                                    <div className="form-control  col-6 heightRemoveAuthorization printOnly PdfBackgroundColorAuthorization" name="other_purpose">{this.state.AuthorizationData.other_purpose}</div>
                                  ) : ""}
                                </div>
                              </div>
                              <div className="row ">
                                <div className=" col-lg-12  col-md-12 form-group">
                                  I understand that my records are protected under Federal Confidentiality Regulations (Federal Register, 42 CFR Part 2) and cannot be disclosed or re-disclosed by the receiving party without my written consent unless otherwise provided for in the regulations. I understand that I might be denied services if I refuse to consent to a disclosure for purposes of treatment, payment, care coordination, quality improvement, or health care operations, if permitted by state law. I will not be denied services if I refuse to consent to a disclosure for other purposes. I also understand that I may revoke this consent at any time (orally or in writing) except to the extent that action has been taken in reliance on it (e.g. information released, etc.) and that in any event, this consent expires automatically not to exceed one year from the signing date:
                                </div>
                              </div>
                              <div className="row ">
                                <div style={width_md_2} className="col-lg-2  col-md-2 form-group">
                                  <LabelField style={floatLeft} {...context.state.date_in_sign} /> (MM/DD/YYYY)
                                </div>
                                <div style={width_md_4} className="col-lg-4  col-md-4 form-group">
                                  <div className=" formfield">
                                    <div className="input-group-append" style={{ height: '38px', float: 'left', marginRight: '-3px' }}>
                                      <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
                                    </div>
                                    <div className="input-group date timepicker" style={{ width: "calc(100% - 41px)" }}>
                                      <span style={{ width: '100%' }}>
                                        <DateTime
                                          onBlur={(date) => this.handleTimeronBlur(date, 'date_in_sign')}
                                          onChange={(date) => this.handleTimer(date, 'date_in_sign')}
                                          inputProps={{ maxLength: 10, value: typeof context.state.AuthorizationData.date_in_sign === 'string' ? context.state.AuthorizationData.date_in_sign : moment(context.state.AuthorizationData.date_in_sign).format('MM/DD/YYYY'), required: this.state.mandatoryInfo.date_in_sign, name: 'date_in_sign' }}
                                          defaultValue={moment(this.state.AuthorizationData.date_in_sign).format('MM/DD/YYYY')}
                                          dateFormat="MM/DD/YYYY" timeFormat={false}
                                          input={true}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row ">
                                <div style={width_md_2} className="col-lg-2  col-md-2 form-group">
                                  <LabelField style={floatLeft} {...context.state.patient_sign} />
                                </div>
                                <div style={width_md_4} className="col-lg-4  col-md-4 form-group">
                                  <input type="text" className="form-control" placeholder="Client's Signature" name={context.state.fieldName.patient_sign} id={context.state.fieldName.patient_sign} defaultValue={this.state.AuthorizationData.patient_sign} required={this.state.mandatoryInfo.patient_sign} maxLength={this.state.patient_sign.columnLength} onChange={this.handleRadioChange} />
                                </div>
                              </div>
                              <div className="row ">
                                <div style={width_md_2} className="col-lg-2  col-md-2 form-group">
                                  <LabelField style={floatLeft} {...context.state.witness_sign} />
                                </div>
                                <div style={width_md_4} className="col-lg-4  col-md-4 form-group">
                                  <input type="text" className="form-control" placeholder="Witness's Signature" name={context.state.fieldName.witness_sign} id={context.state.fieldName.witness_sign} defaultValue={this.state.AuthorizationData.witness_sign} required={this.state.mandatoryInfo.witness_sign} maxLength={this.state.witness_sign.columnLength} onChange={this.handleRadioChange} />
                                </div>
                                <div style={width_md_6} className="col-lg-6  col-md-6"></div>
                              </div>
                            </div>
                          </fieldset>
                          {this.state.print ? "" : (
                            <>
                              <div className="mt-2">
                                <div className="row ">
                                  <div className="col-lg-12  col-md-12 form-group">
                                    <label><strong>Documents</strong></label>
                                  </div>
                                  <label className='sm'>Upload files under 5MB (.jpg, .png, .pdf supported)</label>
                                </div>
                                <div className="row ">
                                  <div className="col-lg-12  col-md-12 form-group app">
                                    <ReactDropzone onDrop={this.onDrop_docs.bind(this)} >
                                      {({ getRootProps, getInputProps }) => (
                                        <section >
                                          <div {...getRootProps()} className="dropzone">
                                            <input {...getInputProps()} name="documents" />
                                            <div className="card-text file-upload-message">
                                              <i className="fas fa-cloud-upload-alt"></i>
                                              <p>Drag and drop files here</p>
                                            </div>
                                          </div>
                                        </section>
                                      )}
                                    </ReactDropzone>
                                    {this.state.AuthorizationData.documents !== null && this.state.AuthorizationData.documents !== '' && this.state.AuthorizationData.documents !== undefined ? (
                                      <aside className="mt-2">
                                        <strong>Selected File</strong>
                                        <ul>
                                          {Array.isArray(this.state.AuthorizationData.documents) ? this.state.AuthorizationData.documents.map(f => (
                                            <li key={f.name}>
                                              {f.name} - {f.size} bytes
                                            </li>
                                          )) : this.state.AuthorizationData.documents}
                                        </ul>
                                      </aside>) : ""}
                                  </div>
                                </div>
                                <div className="row ">
                                  <div className="col-lg-12  col-md-12 form-group personal_docs_div" >
                                    <div className="personal_docs">
                                      <DataTable
                                        columns={this.state.DocumentsColumns}
                                        data={this.state.all_uploaded_documents}
                                        customStyles={global.customTableStyles}
                                        striped={true}
                                        pagination
                                        paginationComponentOptions={global.paginationComponentOptions}
                                        highlightOnHover
                                        pointerOnHover
                                        noDataComponent={<div className="no_record_found">{global.no_document_found_text}</div>}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          <div className="row edit_allowed">
                            <div className="col-lg-12  col-md-12 form-group" style={{ marginTop: "30px", border: "1px dashed #cec5c5", padding: "20px", backgroundColor: "#f2f2f2", fontSize: "11px" }}>
                              <strong>To Information Recipient:</strong><br />
                              This information has been disclosed to you from records protected by Federal Confidentiality Rules (42CFR Part 2) and HIPAA (45 CFR Parts 160 and 164). The Federal Rules prohibit you from making any further disclosure of this information unless further discloser is expressly permitted by the written consent of the person to whom it pertains or as otherwise permitted by 42CFR Part 2 and CFR Parts 160 and 164. A general authorization for release of medical or other information is not sufficient for this purpose. The Federal Rules restrict any use of the information to criminally investigate or prosecute any alcohol or drug use client.
                            </div>
                          </div>
                        </form>
                      </Modal.Body>
                      <Modal.Footer style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="justify-content-between" >
                              <Button className="btn btn-default float-left" id="closeButton" onClick={this.closeAddAuthorizationModal} type="button" form="myForm" key="submit" htmlType="submit">Close</Button>
                            </div>
                          </div>
                          <div className="col-md-6" >
                            {this.state.currentView === 'Revoke' || !can_edit ? "" : (
                              <button type="submit" id="saveButton" className="btn btn-success float-right btnmargin" htmlType="submit" form="AuthorizationForm"> <i className="fas fa-save"></i> Save</button>
                            )}
                            <ReactToPrint
                              documentTitle="Authorization Form"
                              trigger={() => <button type="button" className="btn btn-primary float-right btnmargin"><i className="fa fa-print" aria-hidden="true"></i>&nbsp;Print</button>}
                              onBeforeGetContent={() => this.enablePrintStyles()}
                              content={this.contenctReadyToPrint}
                              onAfterPrint={() => this.disablePrintStyles()}
                            />
                          </div>
                        </div>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Consent_for_ROI;