import { MenuItem, Select, FormControl, makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MinusIcon from "../../assests/images/minus.svg";
import EmptyStateIcon from "../../assests/images/empty-fullrow.png";
import TrashIcon from "../../assests/images/trash-2.svg";
import PlusIcon from "../../assests/images/plus.svg";
import MoveIcon from "../../assests/images/move.svg";

import GDByRace from "./GDByRace";

import GdByRacePieChart from "./GdByRacePieChart";
import BlankState from "./BlankState";

import axios from "axios";
import { createHashHistory } from "history";
import { API_URL } from "../../commonFunctions/ApiUrl";
import { toast } from "react-toastify";

import PieChartFollowup from "./PieChartFollowup";

// import OrgId from "./ReuseableComponents/ResubaleComponents";

const ThirdContainer = styled.section`
  display: flex;

  justify-content: space-between;
  width: 100%;
  height: 330px;
  // border:1px solid red;

 
`;

const MainContainer = styled.div`
  width: 97.5%;
  margin: 0 auto;
  height: ${(props) =>
    props.expand ? "400px" : props.blankState ? "400px" : "70px"};

  border: 1px solid #f0f0f0;
  margin-top: 20px;
  margin-right: 10px;
  background-color: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  // border: 1px solid red;

`;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-size:16px;
`;

const HeadContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${(props) => (props.expand ? "fit-content" : "70px")};

  padding-left: 16px;
`;

const ActionIconContainer = styled.div`
  display: flex;
  margin-right: 10px;
`;

const ActionIcon = styled.img`
  margin-right: 5px;
  cursor: pointer;
`;

const AddToDashBoard = styled.button`
  color: #7bb731;
  margin-right: 10px;
  border: none;
  background: none;
  outline: none;
`;

const MainView = styled.div``;

const useStyles = makeStyles({
  root: {
    backgroundColor: "white",
    border: "none",
    fontSize: "14px",
    marginTop: "2px",
    "&::before": {
      border: "none",
    },
    "&:hover": {
      border: "none",
    },
  },
});

function FollowupAttempPieChart(props) {
  const { addToDashBoard ,isHome} = props;

  const [blankState, setBlankState] = React.useState(false);
  const [expand, setExpand] = React.useState(true);

  const [year, setYear] = React.useState('2022');

  const [getFollowAttemptsPieChart, setFollowAttemptsPieChart] = useState([]);
  const [getChart, setChart] = useState([]);

  const [getCategories, setCategories] = useState([]);
  // const [getGdByRacedata, setgetGdByRacedata] = useState([]);

  const [final_AddToDashBoard,setfinal_AddToDashBoard]=useState(addToDashBoard);
  const [followUpAttemppiechart_in_Local, setfollowUpAttemppiechart_in_local] = useState(false);
  const generalDis = sessionStorage.getItem("followUpAttemppiechart");
  // 
  const OrgId=sessionStorage.getItem("organization_id");

  // dummy json data  

const getCategories1 = 
 

  // const OrgId = 5184;


  //  end dummy json data 
  useEffect((year) => {
    fetchgetGdByRacePieChart(year);
    
  }, []);

  const fetchgetGdByRacePieChart = (year) => {
    if(sessionStorage.getItem("followUpAttemppiechart")!==null){
      let bool=sessionStorage.getItem("followUpAttemppiechart")
      if(bool=="true"){
        setfollowUpAttemppiechart_in_local(true)
      }else{
        setfollowUpAttemppiechart_in_local(false)
      }
    }

    if (year == undefined) {
      year = "2022";
    } else {
      year = year;
    }
    axios
      .get(API_URL + `follow_up_attempts_by_year?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${year}`)
      .then((response) => {
        setChart(response.data.data.pie_data);
        
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // console.log("Add to dashboard : " + addToDashBoard);
  const classes = useStyles(props);

  const handleChange = (event) => {
    setYear(event.target.value);
    var options = event.target.value;
    axios
    .get(API_URL + `follow_up_attempts_by_year?Token=${localStorage.getItem('__TOKEN__')}&OrgId=${OrgId}&year=${event.target.value}`)
    .then((response) => {
      // setFollowAttemptsPieChart(response.data.data.gd_by_race_pie_chart);
      // setFollowAttemptsPieChart(response.data.data.followup_attemp_pie_chart);
        setChart(response.data.data.pie_data);
      sessionStorage.setItem("followUpAttemppiechart", true);
     
    })
    .catch((error) => {
      console.log(error);
    });
  };

  const handleBlankState = (e) => {
    setBlankState(true);
    sessionStorage.removeItem("followUpAttemppiechart");
    setfinal_AddToDashBoard(true);


   
   
  };
  const handleExpand = (e) => setExpand(!expand);

  function FollowUpAttemps() {
    sessionStorage.setItem("followUpAttemppiechart", true);
    setfinal_AddToDashBoard(false);
    toast.success("Added dashlets to the dashboard successfully");

    setTimeout(() => {
      if(sessionStorage.getItem("followUpAttemppiechart")!==null){
        let bool=sessionStorage.getItem("followUpAttemppiechart")
        if(bool=="true"){
          setfollowUpAttemppiechart_in_local(true);
          window.location.reload();
        }else{
          setfollowUpAttemppiechart_in_local(false);
        }
      }
    }, 1000);
   
  }

//   console.log("props are ");
// console.log(props);
const catArray=[];
if(getCategories.length>0)
{
  for(let i=0;i<getCategories.length;i++)
  {
    catArray.push(getCategories[i].country);
    // console.log(getCategories[i].country);
  }
}
  return (
    <MainContainer expand={expand} blankState={blankState}>
      {blankState ? (
        <BlankState emptyIcon={EmptyStateIcon} />
      ) : (
        <MainView>
          <HeadContainer>
            <Title>
            Follow-Ups Attempts - Year         
              <FormControl
                sx={{ m: 1, minWidth: 120 }}
                className={classes.root}
                style={{ marginLeft: "5px" }}
              >
                <Select
                  value={year}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  className={classes.root}
                >
                  <MenuItem value="2022">2022</MenuItem>
                  <MenuItem value="2021">2021</MenuItem>
                  <MenuItem value="2020">2020</MenuItem>
                  <MenuItem value="2019">2019</MenuItem>
                  <MenuItem value="2018">2018</MenuItem>
                </Select>
              </FormControl>
            </Title>
            {/* {final_AddToDashBoard ? (
              <AddToDashBoard onClick={FollowUpAttemps}>
                + Add to Dashboard
              </AddToDashBoard>
            ) : (
              <ActionIconContainer>
                <ActionIcon
                  src={expand ? MinusIcon : PlusIcon}
                  onClick={handleExpand}
                />
                <ActionIcon src={MoveIcon} />
                <ActionIcon src={TrashIcon} onClick={handleBlankState} />
              </ActionIconContainer>
            )} */}

                {final_AddToDashBoard ? (

followUpAttemppiechart_in_Local == true ? (
                <>
                  {" "}
                  <AddToDashBoard > + Added</AddToDashBoard>
                </>
                ) : (
                <>
                {" "}
                  <AddToDashBoard onClick={FollowUpAttemps}>
                    + Add to Dashboard
                  </AddToDashBoard>

                </>
                )
                ) : (
                <ActionIconContainer>
                <ActionIcon
                  src={expand ? MinusIcon : PlusIcon}
                  onClick={handleExpand}
                />
                <ActionIcon src={MoveIcon} id="drag" />
                <ActionIcon src={TrashIcon} onClick={handleBlankState} />
                </ActionIconContainer>
                )}

            {/*  */}
          </HeadContainer>
          {expand && (
            <ThirdContainer>
           
             
              <PieChartFollowup
                // gdPiaChart={getFollowAttemptsPieChart}
                gdchart={getChart}
              />
              
            </ThirdContainer>
          )}
        </MainView>
      )}
    </MainContainer>
  );
}

export default FollowupAttempPieChart;
