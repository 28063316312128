import React, { useState,useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Topnav from "../../Topnav";
import AdminSideNav from '../../UserManagement/AdminSideNav';
import DataTable from 'react-data-table-component'
import Footer from '../../Footer';
import Modal from '../../Modals/Modal/index';
import TextAreaAutosize from 'react-textarea-autosize';
// import  '../global.js';
import { useHistory } from "react-router-dom";
import axios from 'axios';

const EConsent = () => {
    const history = useHistory();

    const [formData, setFormData] = useState({
        name: '',
        vilidity: '',
        type: '',
        description: '',
        autoRenewal: false,
        user:157,
        client_id: "CL-123214-24234",
        lastUpdatedBy: "Admin",
    });

    const [show, setShow] = useState("");
    const [rowData, setRowData] = useState([
        // {
        //     id: 1,
        //     eConsentTemplate: 'Template A',
        //     createdBy: 'John Doe',
        //     lastUpdatedBy: 'Jane Smith',
        //     createdDate: '2025-01-01',
        //     validityInDays: 30,
        //     status: 'Active',
        // },
        // {
        //     id: 2,
        //     eConsentTemplate: 'Template B',
        //     createdBy: 'Alice Brown',
        //     lastUpdatedBy: 'Bob Johnson',
        //     createdDate: '2025-01-05',
        //     validityInDays: 60,
        //     status: 'Inactive',
        // },
    ]);

    const columnsNames = [
        {
            name: 'eConsent Templates',
            selector: 'eConsentTemplate',
            sortable: true,
        },
        {
            name: 'Created by',
            selector: 'createdBy',
            sortable: true,
        },
        {
            name: 'Last updated by',
            selector: 'lastUpdatedBy',
            sortable: true,
        },
        {
            name: 'Created Date',
            selector: 'createdDate',
            sortable: true,
        },
        {
            name: 'Validity in Days',
            selector: 'validityInDays',
            sortable: true,
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            cell: (row) => (
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <button
                        className={`btn ${row.status === 'Active' ? 'btn-success' : 'btn-light'}`}
                        onClick={() => handleToggleStatus(row.id, 'Active')}
                    >
                        Active
                    </button>
                    <button
                        className={`btn ${row.status === 'Inactive' ? 'btn-danger' : 'btn-light'}`}
                        onClick={() => handleToggleStatus(row.id, 'Inactive')}
                    >
                        Inactive
                    </button>
                </div>
            ),
        },
        {
            name: 'Action',
            selector: 'action',
            sortable: false,
            cell: (row) => (
                <div>
                    <i
                        className="fas fa-edit"
                        style={{ color: '#007bff', cursor: 'pointer' }}
                        onClick={() => handleActionClick(row.id)}
                        aria-hidden="true"
                    ></i>
                </div>
            ),
        },
    ];




    const handleToggleStatus = (id, newStatus) => {
        setRowData((prevData) =>
            prevData.map((row) =>
                row.id === id ? { ...row, status: newStatus } : row
            )
        );
    };

    const handleActionClick = (id) => {
        //debugger
        history.push("/EConsentEditor");
    };



    const AddConsent = () => {
        setShow(true);
    }
    const closeModal1 = () => {
        setShow(false);
    }
    const onChange = () => {
        // setShow(t rue);
    }
    const handleId = () => {
        history.push({
            pathname: "/EConsentEditor",
            state: { formData } // Pass the data here
        });

    }

    const handleChange = (e) => {
        // //debugger
        const { name, value, type, checked } = e.target;
        console.log(name,"namename");
        console.log(value,"valuevalue");
        console.log(type,"typetype");
        console.log(checked,"chcheckedecked");
        setFormData((prevData) => ({
          ...prevData,
          [name]: type === 'checkbox' ? checked : value,
        }));
      };

      useEffect(() => {
        // Fetch data from the API
        // //debugger
        const fetchData = async () => {
             //debugger
          try {
            const fetchedData = await axios.get('https://econsent.tactionclients.com/consenttemplates/list?user=155');
            console.log(fetchedData.data.consentForms,"fetchedDatafetchedDatafetchedDatafetchedData")
            const mappedData =fetchedData.data.consentForms.map((item) => ({
                id: item._id,
                eConsentTemplate: item.name,
                createdBy: item.user,
                lastUpdatedBy: item.lastUpdatedBy,
                createdDate: new Date(item.createdAt).toLocaleDateString(),
                validityInDays: item.validity, 
                status: item.status,
              }));
          
              setRowData(mappedData);
          } catch (error) {
            console.error('Error fetching data:', error);
            // setLoading(false);
          }
        };
    
        fetchData();
      }, []);

    
    

    return (
        <>{
            console.log(formData,"formDataformData")
        }
            <div className="hold-transition sidebar-mini text-sm">
                <div className="wrapper">
                    <Topnav />
                    <div className="content-wrapper">
                        <section class="content-header"></section>
                        <div className='row m-0'>
                            <div className='col-md-2'>
                                <AdminSideNav />
                            </div>
                            <div className='col-md-10'>
                                <section className="content">
                                    <form method='post' >
                                        <div className="card">
                                            <div className="card-header" >
                                                <div>
                                                    <h4 className='float-left'>eConsent Templates</h4>
                                                    <Button variant="secondary" onClick={AddConsent} className="btn btn-success float-right d-flex align-items-center">
                                                        <h5> <i className="fas fa-user-plus"></i>&nbsp; Add eConsent Templates   </h5>
                                                    </Button>
                                                </div>
                                            </div>
                                            {/* <div className="card-header" >
                                                <h4>eConsent Templates</h4>
                                            </div> */}
                                            <div className='card-body'>
                                                <DataTable
                                                    columns={columnsNames}
                                                    data={rowData} // Provide row data
                                                    customStyles={global.customTableStyles}
                                                    striped={true}
                                                    // pagination
                                                    // paginationComponentOptions={global.paginationComponentOptions}
                                                    highlightOnHover
                                                    pointerOnHover
                                                    noDataComponent={
                                                        <div className="no_record_found">{global.no_record_found_text}</div>
                                                    }
                                                />
                                                <br />
                                            </div>
                                        </div>
                                    </form>
                                </section>
                                {
                                    show === true ?
                                        <form>
                                            <Modal
                                                showOverlay={show === true ? true : false}
                                                // show={this.state.editModal}
                                                disableOverlayClick={true}
                                                showCloseButton={false}
                                                size="lg" // Increased size
                                                className="modal-dialog"
                                                style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px", minWidth: "600px", minheight: '1000px' }} // Ensure a minimum width
                                            >
                                                <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                                                    <Modal.Title>
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <p className="lead float-left" style={{ margin: 0 }}>
                                                                    <b className="text-green">Select eConsent</b>
                                                                </p>
                                                                <button type="button" className="close" onClick={closeModal1} style={{ background: "none", border: "none", fontSize: "30px", cursor: "pointer", }}>&times;</button>
                                                            </div>
                                                        </div>
                                                    </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className='row'>
                                                        <div className='col-md-12 form-group'>
                                                            <div className='col-md-4 float-left'><label>eConsent Name</label></div>
                                                            <div className='col-md-8 float-right'><input type="text" className='form-control' placeholder='eConsent Name' name="name" id="name"  value={formData.name}  onChange={handleChange} /></div>
                                                        </div>

                                                        <div className='col-md-12 form-group'>
                                                            <div className='col-md-4 float-left'><label>Validity</label></div>
                                                            <div className='col-md-8 float-right'><input type="number" className='form-control' placeholder='No of days' name="vilidity" id="vilidity" value={formData.vilidity}   onChange={handleChange}/></div>
                                                        </div>

                                                        <div className='col-md-12 form-group'>
                                                            <div className='col-md-4 float-left'><label>Type</label></div>
                                                            <div className='col-md-8 float-right'>
                                                                <select   onChange={handleChange} value={formData.type} className='form-control' name="type" id='type'>
                                                                    <option value="0">Select Type</option>
                                                                    <option value="r_o_i">ROI</option>
                                                                    <option value="consent_to_services">Consent to services</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div className='col-md-12 form-group'>
                                                            <div className='col-md-4 float-left'><label>Description</label></div>
                                                            <div className='col-md-8 float-right'>
                                                                <TextAreaAutosize type="text" className='form-control' value={formData.description} minRows={3} id='description' name="description"   onChange={handleChange}></TextAreaAutosize>
                                                            </div>
                                                        </div>

                                                        <div className='col-md-12 form-group'>
                                                            <div className='col-md-4 float-left'></div>
                                                            <div className='col-md-8 float-right'>
                                                                <input type='checkbox' id='auto_send' name='autoRenewal' value={formData.autoRenewal}  onChange={handleChange}></input>&nbsp;&nbsp;<span>Auto send renewal</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Modal.Body>
                                                <Modal.Footer style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                                                    <div className='row'>
                                                        <div className='col-md-12 '>
                                                            <button type=" " id="nextButton" name='nextButton' className="btn btn-success float-right btnmargin" onClick={handleId} value="Next"> Next</button>
                                                        </div>
                                                    </div>
                                                </Modal.Footer>
                                            </Modal>
                                        </form>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>

    )
}

export default EConsent;