import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

export default class AdminSideNav extends Component {
    render() {
        return (
            <div className="sidenav">
                <ul className="nav nav-pills nav-sidebar flex-column nav-compact text-sm" data-widget="treeview" role="menu" data-accordion="false">
                    <li className="nav-item" >
                        <div className="card">
                            <NavLink to="/userManagement" className="nav-link">
                                <div className="card-body p-0">
                                     User Management
                                </div>
                            </NavLink>
                        </div>
                    </li>
                    <li className="nav-item" >
                        <div className="card">
                            <NavLink to="/clientManagement" className="nav-link">
                                <div className="card-body p-0">
                                     Client Management
                                </div>
                            </NavLink>
                        </div>
                    </li>
                    <li className="nav-item" >
                        <div className="card">
                            <NavLink to="/eConsent" className="nav-link">
                                <div className="card-body p-0">
                                     eConsent Templates
                                </div>
                            </NavLink>
                        </div>
                    </li>
                </ul>
            </div>
        )
    }
}
