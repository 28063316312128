import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Topnav from './Topnav';
import Footer from './Footer';
import Patientinfoheader from './Patientinfoheader';
import Sidenav from './Sidenav';
import { validationParser } from '../ReusableComponents/validationParser2';
import { formObjDataMapper } from '../ReusableComponents/formObjDataMapper';
import Select from 'react-select';
import { multiSelectOptionGenerator, createMultiselectDataToSend } from '../ReusableComponents/OptionsGenerator';
//Tooltip imports
import ReactTooltip from 'react-tooltip';
import Tooltip from '../ReusableComponents/Tooltip';
import DataTable, { defaultThemes } from 'react-data-table-component';
import Modal from './Modals/Modal/index';
import { Button } from './Modals/Buttons/index';
import moment from 'moment';
import {  toast } from 'react-toastify';
import $ from 'jquery';

var validationObj = {};

var context;

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: isDisabled
                ? null
                : isSelected
                    ? "green"
                    : isFocused
                        ? "#0074F0"
                        : null,
            color: isDisabled
                ? '#ccc'
                : isFocused
                    ? "white"
                    : isSelected
                        ? "white"
                        : "black",

            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled && (isSelected ? "grey" : "red"),
            },
        };
    },
    multiValue: (styles, { data }) => {
        // const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: "#007BFF",
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: "white",
        whiteSpace: "inherit"
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: "white",
        ':hover': {
            backgroundColor: "red",
            color: 'white',
        },
    }),
};

const customStyles = {
    table: {
        style: {
            color: defaultThemes.default.text.primary,
            backgroundColor: defaultThemes.default.background.default,
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "#F9F9F9"
        },
    },
    header: {
        style: {
            minHeight: '1px',
        },
    },
    headRow: {
        style: {
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: defaultThemes.default.divider.default,
            backgroundColor: "#F9F9F9",
            minHeight: "40px"
        },
    },
    headCells: {
        style: {
            '&:not(:last-of-type)': {
                borderRightStyle: 'solid',
                borderRightWidth: '1px',
                borderRightColor: defaultThemes.default.divider.default,
                fontWeight: "bold",
                fontSize: "0.91rem"
            },
        },
    },
    row: {
        style: {
            minHeight: '20px',
            borderRightStyle: 'solid',
            borderRightWidth: '10px',
            borderRightColor: defaultThemes.default.divider.default,
        }
    },
    cells: {
        style: {
            '&:not(:last-of-type)': {
                // borderStyle: 'solid',
                // borderWidth: '1px',
                // borderColor: defaultThemes.default.divider.default,
                borderLeftStyle: 'solid',
                borderLeftWidth: '1px',
                borderLeftColor: defaultThemes.default.divider.default,
                fontSize: '0.9rem'
            },
        },
    },
};

const axios = require('axios').default;

var products = [{
    id: 1,
    name: "Item name 1",
    price: 100
}, {
    id: 2,
    name: "Item name 2",
    price: 100
},];


class GadScreening extends Component {
    constructor(props) {
        super(props);
        context = this;
        this.handleGadView = this.handleGadView.bind(this);
        this.onModalClose = this.onModalClose.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.onModalOpen = this.onModalOpen.bind(this);
        this.save = this.save.bind(this);

        this.state = {

            gadData: this.props.data ? this.props.data : this.getModal(),
            totalScore: 0,
            mandatoryInfo: this.getModal(),
            labelText: this.getModal(),
            tooltips: this.getModal(),
            tooltipsVisibility: this.getModal(),
            fieldName: this.getModal(),
            fieldType: this.getModal(),

            wryExcessFreqTopicsMultiOptions: [],

            overAllResponseCountTable1: {
                counter1: 0,
                counter2: 0,
                counter3: 0,
                counter4: 0,
                counter5: 0,
                counter6: 0,
                counter7: 0,
                counter8: 0,
                counter9: 0,
                totalScore: 0,
                result: this.condition_result(0)
            },

            overAllResponseCountTable2: {
                counter1: 0,
                counter2: 0,
                counter3: 0,
                counter4: 0,
                counter5: 0,
                counter6: 0,
                counter7: 0,
                counter8: 0,
                counter9: 0,
                totalScore: 0
            },

            formAccessMode: "",

            gadHistoryData: [],
            gadDataArray: [],
            gadHistoryColumns: [
                {
                    name: 'Date Time',
                    selector: 'o_modificationDate',
                    sortable: true,
                    cell: (row) => (
                        <div>
                            {(new Date(row.o_modificationDate * 1000)).toLocaleString()}
                        </div>
                    ),

                },
                {
                    name: 'Interviewer',
                    selector: 'o_userModificationName',
                    sortable: true,

                },
                {
                    name: 'Action',
                    sortable: false,
                    cell: (row) => (
                        <div>
                            <Link to="#"><i id={row.o_modificationDate} lang="none" className="fas fa-eye" aria-hidden="true" data-toggle="tooltip" title="view" onClick={this.handleGadView}></i></Link>
                        </div>
                    ),
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                },
            ],
            showModal: false,

        };
    }

    componentWillMount() {
        //for validation

        axios.get(global.restApiURL + 'webservice/rest/class/id/11?apikey=' + sessionStorage.getItem('__CL_TOKEN__'))
            .then(res => {
                var response = res.data;

                if (response.success && res.status == 200) {
                    validationObj = validationParser(response.data.layoutDefinitions.childs[0].childs);
                    console.log("validation", validationObj);

                    this.setState({
                        labelText: validationObj.title,
                        mandatoryInfo: validationObj.mandatory,
                        tooltips: validationObj.tooltip,
                        tooltipsVisibility: validationObj.tooltipVisibility,
                        fieldName: validationObj.name,
                        fieldType: validationObj.fieldtype,
                    });

                    this.createSelectOptions();

                }
                else if ("error_code" in response) {
                    alert(response.msg);
                    window.location.href = global.domain + global.traversArray['out'];
                }
                else {
                    alert(response.msg);
                }

            })
            .catch(err => { // log request error and prevent access to undefined state
                this.setState({ loading: false, error: true });
                console.error(err);
            });
    }



    componentWillUnmount() {
        this.props.onRef(null)
    }

    historyDataParser(rawHistoryData) {
        console.log("raw history Data : ", rawHistoryData);

        let objKeys = Object.keys(this.getModal());
        let gadDataArray = [];

        rawHistoryData.map((val) => {

            let gadDataObj = this.getModal();

            gadDataObj["o_modificationDate"] = val["o_modificationDate"];

            objKeys.map((obj) => {

                if (obj in val) {
                    if (this.state.fieldType[obj] === 'input' || this.state.fieldType[obj] === "select" ||
                        this.state.fieldType[obj] === "numeric" || this.state.fieldType[obj] === "textarea" || this.state.fieldType[obj] === "checkbox") {
                        gadDataObj[obj] = val[obj];
                    }
                    else if (this.state.fieldType[obj] === "multiselect") {
                        let multiSelectOptions = val[obj] !== null && val[obj].length > 0 && val[obj].map((item, i) => {

                            var op = { value: item, label: item };

                            return op;

                        }, this);

                        gadDataObj[obj] = multiSelectOptions;
                    }
                    else if (this.state.fieldType[obj] === "date") {
                        gadDataObj[obj] = new Date(val[obj]);
                    }
                }
            });

            gadDataArray.push(gadDataObj);
        });

        // console.log("parsed history Array : " , gadDataArray ) ;

        return gadDataArray;

    }

    createSelectOptions() {
        /*if(validationObj !== "")
        {
          this.setState({
              wryExcessFreqTopicsMultiOptions : multiSelectOptionGenerator(validationObj.options.wry_excess_freq_topics),
          });
  
        }*/
    }

    handleMultiSelectChanges = (selectedOption, event) => {

        var updatedState = this.state.gadData;

        updatedState[event.name] = selectedOption;

        this.setState(prevState => ({
            ...prevState,
            gadData: updatedState
        }));

        // console.log( event.name ," : ", this.state.lastNightStayData);
    };

    handleGadView(event) {

        let historyID = event.target.id;

        // console.log("history : " , historyID);

        let gadData = '';

        this.state.gadDataArray.map((obj) => {
            // console.log("OBJ : " , obj.o_modificationDate , historyID) ;
            if (obj.o_modificationDate === Number(historyID))
                gadData = obj;
        });

        // console.log("map : " , gadData) ;

        this.setState({
            gadData: gadData
        })

        setTimeout(function () { //Start the timer
            this.overallResponseCalculator();
        }.bind(this), 500);

        this.createSelectOptions();

        this.onModalOpen(event);
    }

    onModalOpen(event) {
        let formType = "";
        // console.log( "event : " , event.target );
        formType = event.target.lang;
        // console.log( "event : " , formType );

        if (formType === 'none') {
            this.setState({
                showModal: true,
                formAccessMode: formType
            })
        }
        else {
            this.setState({
                showModal: true,
                formAccessMode: formType,
                gadData: this.getModal(),
                overAllResponseCountTable1: this.resetCounters(),
                overAllResponseCountTable2: this.resetCounters()
            })
        }
    }

    onModalClose() {
        this.setState({
            showModal: false
        })
    }

    getModal() {
        var gadDataModal = {

            experience_worry: '',
            wry_excess_in_freq: '',
            wry_difficult_to_ctrl: '',
            wry_excess_minor_things: '',
            wry_excess_freq_topics: '',
            in_6m_worry_more: '',
            restless_or_keyed_up: '',
            irritability: '',
            difficult_concentrating: '',
            easily_fatigued: '',
            con_mind_blank: '',
            muscle_tension: '',
            wry_symptoms_interfere: '',
            wry_symptoms_bother: '',
            client_id: '',
            version: '',

        };

        return gadDataModal;
    }

    resetCounters() {
        let initialCounters = {
            counter1: 0,
            counter2: 0,
            counter3: 0,
            counter4: 0,
            counter5: 0,
            counter6: 0,
            counter7: 0,
            counter8: 0,
            counter9: 0,
            totalScore: 0
        };

        return initialCounters;
    }

    condition_result(score) {
        console.log(typeof (score), score);
        switch (true) {
            case (score < 5):
                return "Minimal Anxiety";
                break;

            case (score < 10):
                return "Mild Anxiety";
                break;

            case (score < 15):
                return "Moderate Anxiety";
                break;

            case (score >= 15):
                return "Severe Anxiety";
                break;

            default: return "";
        }
    }

    handleRadioChange(event) {

        // console.log("event name : " , event.target.name , event.target.value) ;

        let tempOb = this.state.gadData;

        // console.log("before : " , this.state.gadData);

        tempOb[event.target.name] = event.target.value;

        this.setState((prevState) => ({
            ...prevState,
            gadData: tempOb
        })
        );



        // console.log("after  : " , this.state.gadData);

        this.overallResponseCalculator();

        // console.log("ql data : " , this.state.overAllResponseCount)

    }

    overallResponseCalculator() {
        let keyArray = Object.keys(this.getModal());
        let t1counter1 = 0;
        let t1counter2 = 0;
        let t1counter3 = 0;
        let t1counter4 = 0;
        let t1counter5 = 0;
        let t1counter6 = 0;
        let t1counter7 = 0;
        let t1counter8 = 0;
        let t1counter9 = 0;
        let t1totalScore = 0;

        let t2counter1 = 0;
        let t2counter2 = 0;
        let t2counter3 = 0;
        let t2counter4 = 0;
        let t2counter5 = 0;
        let t2counter6 = 0;
        let t2counter7 = 0;
        let t2counter8 = 0;
        let t2counter9 = 0;
        let t2totalScore = 0;


        keyArray.map((key) => {

            // console.log("table: " , this.state.gadData[key]  );

            if (key === this.state.fieldName.restless_or_keyed_up || key === this.state.fieldName.irritability || key === this.state.fieldName.difficult_concentrating ||
                key === this.state.fieldName.easily_fatigued || key === this.state.fieldName.con_mind_blank || key === this.state.fieldName.muscle_tension || key === this.state.fieldName.in_6m_worry_more) {

                if (this.state.gadData[key] !== "") {
                    // console.log("table 1 : " , this.state.gadData[key]  );
                    if (this.state.gadData[key] === "0")
                        t1counter1++;
                    if (this.state.gadData[key] === "1")
                        t1counter2++;
                    if (this.state.gadData[key] === "2")
                        t1counter3++;
                    if (this.state.gadData[key] === "3")
                        t1counter4++;
                    if (this.state.gadData[key] === "4")
                        t1counter5++;
                    if (this.state.gadData[key] === "5")
                        t1counter6++;
                    if (this.state.gadData[key] === "6")
                        t1counter7++;
                    if (this.state.gadData[key] === "7")
                        t1counter8++;
                    if (this.state.gadData[key] === "8")
                        t1counter9++;
                }
                t1totalScore = (t1counter1 * 0) + (t1counter2 * 1) + (t1counter3 * 2) + (t1counter4 * 3) + (t1counter5 * 4) + (t1counter6 * 5) + (t1counter7 * 6) + (t1counter8 * 7) + (t1counter9 * 8);
            }

            if (key === this.state.fieldName.wry_symptoms_interfere || key === this.state.fieldName.wry_symptoms_bother) {

                if (this.state.gadData[key] !== "") {
                    // console.log("table 2 : " , this.state.gadData[key] );
                    if (this.state.gadData[key] === "0")
                        t2counter1++;
                    if (this.state.gadData[key] === "1")
                        t2counter2++;
                    if (this.state.gadData[key] === "2")
                        t2counter3++;
                    if (this.state.gadData[key] === "3")
                        t2counter4++;
                    if (this.state.gadData[key] === "4")
                        t2counter5++;
                    if (this.state.gadData[key] === "5")
                        t2counter6++;
                    if (this.state.gadData[key] === "6")
                        t2counter7++;
                    if (this.state.gadData[key] === "7")
                        t2counter8++;
                    if (this.state.gadData[key] === "8")
                        t2counter9++;
                }
                t2totalScore = (t2counter1 * 0) + (t2counter2 * 1) + (t2counter3 * 2) + (t2counter4 * 3) + (t2counter5 * 4) + (t2counter6 * 5) + (t2counter7 * 6) + (t2counter8 * 7) + (t2counter9 * 8);
            }

        })
        setTimeout(()=>{
            this.setState(prevState => ({
                ...prevState,
                overAllResponseCountTable1: {
                    counter1: t1counter1,
                    counter2: t1counter2,
                    counter3: t1counter3,
                    counter4: t1counter4,
                    counter5: t1counter5,
                    counter6: t1counter6,
                    counter7: t1counter7,
                    counter8: t1counter8,
                    counter9: t1counter9,
                    totalScore: t1totalScore,
                    result: context.condition_result(t1totalScore)
                },
                overAllResponseCountTable2: {
                    counter1: t2counter1,
                    counter2: t2counter2,
                    counter3: t2counter3,
                    counter4: t2counter4,
                    counter5: t2counter5,
                    counter6: t2counter6,
                    counter7: t2counter7,
                    counter8: t2counter8,
                    counter9: t2counter9,
                    totalScore: t2totalScore
                },
                totalScore: t1totalScore,
            }));
        },500)   

    }

    componentDidMount() {

        this.props.onRef(this)
        var prevExist = false;
        sessionStorage.setItem('ObectID', '');

        if (sessionStorage.getItem('clientId') !== '') {
            axios.get(global.restApiURL + 'webservice/rest/object-list?apikey=' + localStorage.getItem('__TOKEN__') + '&objectClass=GADScreeningTool&q={"client_id":"' + sessionStorage.getItem('clientId') + '"}')
                .then(res => {

                    const response = res.data; // get the data array instead of object

                    if (response.success && res.status == 200) {
                        console.log("object fetcher : ", response);

                        if (response.data.length !== 0) {
                            prevExist = true;
                            console.log("prevExist : ", prevExist);

                            this.setState({
                                gadHistoryData: res.data.data
                            })

                        } else {
                            prevExist = false;
                        }
                    }
                    else if ("error_code" in response) {
                        alert(response.msg);
                        window.location.href = global.domain + global.traversArray['out'];
                    }
                    else {
                        alert(response.msg);
                    }
                })
                .catch(err => { // log request error and prevent access to undefined state
                    this.setState({ loading: false, error: true });
                    console.error(err);
                })

            if (this.props.obj_id && this.props.obj_id > 0) {
                axios.get(global.restApiURL + 'webservice/rest/object/id/' + this.props.obj_id + '?apikey=' + localStorage.getItem('__TOKEN__'))
                    .then(res => {

                        sessionStorage.setItem('ObectID', this.props.obj_id);
                        sessionStorage.setItem('Obectkey', res.data.data.key);
                        const response = res.data.data.elements;
                        console.log("Obj data : ", response);

                        // this.setState({
                        //     gadData: formObjDataMapper(response, this.getModal())
                        // })

                        // this.overallResponseCalculator();

                        setTimeout(()=>{
                            this.setState({
                                gadData: formObjDataMapper(response, this.getModal())
                            },()=>{this.overallResponseCalculator()})
                        },500)
                    })
                    .catch(err => { // log request error and prevent access to undefined state
                        this.setState({ loading: false, error: true });
                        console.error(err);
                    })
            }
        }

    }

    save = (event) => {
        let saveType = 'save';
        var jsonData = '';
        if (sessionStorage.getItem('ObectID') && sessionStorage.getItem('Obectkey')) {
            jsonData = { 'className': 'GADScreeningTool', 'key': sessionStorage.getItem('Obectkey'), 'id': sessionStorage.getItem('ObectID'), 'published': true };
        } else {
            jsonData = { 'className': 'GADScreeningTool', 'key': sessionStorage.getItem('clientId') + '-' + this.state.gadHistoryData.length, 'published': true };
        }

        jsonData.elements = [];

        // var formData = $("#phq9form").serializeArray();    	

        // console.log("formdata : " , formData) ;

        let keys = Object.keys(context.getModal());

        keys.map((key) => {

            if (key === 'client_id')
                jsonData.elements.push({ name: context.state.fieldName[key], value: sessionStorage.getItem('clientId') });
            /* else if( key === 'wry_excess_freq_topics')
                 jsonData.elements.push({name: context.state.fieldName[key] , value: createMultiselectDataToSend(context.state.gadData[key]) }) ;*/
            else
                jsonData.elements.push({ name: context.state.fieldName[key], value: context.state.gadData[key] });

        })

        jsonData.elements.push({ name: 'interviewer', value: sessionStorage.getItem('loggedinUserID') });
        jsonData.elements.push({ name: 'score', value: context.state.totalScore });
        jsonData.elements.push({ name: 'total1_0', value: context.state.overAllResponseCountTable1.counter1 });
        jsonData.elements.push({ name: 'total1_1', value: context.state.overAllResponseCountTable1.counter2 });
        jsonData.elements.push({ name: 'total1_2', value: context.state.overAllResponseCountTable1.counter3 });
        jsonData.elements.push({ name: 'total1_3', value: context.state.overAllResponseCountTable1.counter4 });
        jsonData.elements.push({ name: 'total1_4', value: context.state.overAllResponseCountTable1.counter5 });
        jsonData.elements.push({ name: 'total1_5', value: context.state.overAllResponseCountTable1.counter6 });
        jsonData.elements.push({ name: 'total1_6', value: context.state.overAllResponseCountTable1.counter7 });
        jsonData.elements.push({ name: 'total1_7', value: context.state.overAllResponseCountTable1.counter8 });
        jsonData.elements.push({ name: 'total1_8', value: context.state.overAllResponseCountTable1.counter9 });
        jsonData.elements.push({ name: 'total2_0', value: context.state.overAllResponseCountTable2.counter1 });
        jsonData.elements.push({ name: 'total2_1', value: context.state.overAllResponseCountTable2.counter2 });
        jsonData.elements.push({ name: 'total2_2', value: context.state.overAllResponseCountTable2.counter3 });
        jsonData.elements.push({ name: 'total2_3', value: context.state.overAllResponseCountTable2.counter4 });
        jsonData.elements.push({ name: 'total2_4', value: context.state.overAllResponseCountTable2.counter5 });
        jsonData.elements.push({ name: 'total2_5', value: context.state.overAllResponseCountTable2.counter6 });
        jsonData.elements.push({ name: 'total2_6', value: context.state.overAllResponseCountTable2.counter7 });
        jsonData.elements.push({ name: 'total2_7', value: context.state.overAllResponseCountTable2.counter8 });
        jsonData.elements.push({ name: 'total2_8', value: context.state.overAllResponseCountTable2.counter9 });
        jsonData.elements.push({ name: 'total1_score', value: context.state.overAllResponseCountTable1.totalScore });
        jsonData.elements.push({ name: 'total2_score', value: context.state.overAllResponseCountTable2.totalScore });



        jsonData.elements.push({ name: 'updated_time', value: moment().format('MM/DD/YYYY HH:mm:ss') });
        var json = JSON.stringify(jsonData);

        console.log("Data to send : ", json);

        axios.post(global.save_url, json)
            .then(function (res) {
                var response = res.data;
                console.log("save response", response.success);
                if (response.success && res.status == 200) {
                    sessionStorage.setItem('success_msg', "GAD saved successfully.");
                    if (saveType === 'proceed') {
                        window.location.href = global.domain + global.traversArray['gadscreening'];
                    }
                    else {
                        window.location.href = "";
                    }
                }
                else if ("error_code" in response) {
                    alert(response.msg);
                    window.location.href = global.domain + global.traversArray['out'];
                }
                else {
                    alert(response.msg);
                }
                sessionStorage.removeItem('Obectkey');
                sessionStorage.removeItem('ObectID');
                console.log("Msg : ", sessionStorage.getItem('success_msg'));
            })
            .catch(function (err) {
                console.log(err.message);
            });

    }

    render() {
        sessionStorage.setItem('formname', 'GAD Screenings');
        return (
            <form action="javascript:void(0);" method="post" id="phq9form"  >

                <div className="row">
                    <div className="container-fluid col-md-12">
                        <div className="">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="table-responsive p-0 form-group">
                                        <label style={{ fontWeight: "bold" }}>During the past six months, have you often been bothered by any of the following symptoms? Check one square next to each symptom that you have had more days than not</label>
                                        <table className="table table-bordered table-striped table-center">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '45%' }}></th>
                                                    <th style={{ width: '12%' }}>Not at all (0)</th>
                                                    <th style={{ width: '14%' }}>Several days (1)</th>
                                                    <th style={{ width: '14%' }}>More than<br />half the days (2)</th>
                                                    <th style={{ width: '14%' }}>Nearly<br></br> every day<br /> (3)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{this.state.labelText.restless_or_keyed_up}</td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.restless_or_keyed_up} checked={this.state.gadData.restless_or_keyed_up === "0"} onChange={this.handleRadioChange} value="0" />
                                                            {/*<label className="form-check-label">0</label>*/}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.restless_or_keyed_up} checked={this.state.gadData.restless_or_keyed_up === "1"} onChange={this.handleRadioChange} value="1" />
                                                            {/*<label className="form-check-label">1</label>*/}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.restless_or_keyed_up} checked={this.state.gadData.restless_or_keyed_up === "2"} onChange={this.handleRadioChange} value="2" />
                                                            {/*<label className="form-check-label">2</label>*/}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.restless_or_keyed_up} checked={this.state.gadData.restless_or_keyed_up === "3"} onChange={this.handleRadioChange} value="3" />
                                                            {/*<label className="form-check-label">3</label>*/}
                                                        </div>
                                                    </td>

                                                </tr>

                                                <tr>
                                                    <td>{this.state.labelText.irritability}</td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.irritability} checked={this.state.gadData.irritability === "0"} onChange={this.handleRadioChange} value="0" />
                                                            {/*<label className="form-check-label">0</label>*/}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.irritability} checked={this.state.gadData.irritability === "1"} onChange={this.handleRadioChange} value="1" />
                                                            {/*<label className="form-check-label">1</label>*/}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.irritability} checked={this.state.gadData.irritability === "2"} onChange={this.handleRadioChange} value="2" />
                                                            {/*<label className="form-check-label">2</label>*/}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.irritability} checked={this.state.gadData.irritability === "3"} onChange={this.handleRadioChange} value="3" />
                                                            {/*<label className="form-check-label">3</label>*/}
                                                        </div>
                                                    </td>

                                                </tr>

                                                <tr>
                                                    <td>{this.state.labelText.difficult_concentrating}</td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.difficult_concentrating} checked={this.state.gadData.difficult_concentrating === "0"} onChange={this.handleRadioChange} value="0" />
                                                            {/*<label className="form-check-label">0</label>*/}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.difficult_concentrating} checked={this.state.gadData.difficult_concentrating === "1"} onChange={this.handleRadioChange} value="1" />
                                                            {/*<label className="form-check-label">1</label>*/}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.difficult_concentrating} checked={this.state.gadData.difficult_concentrating === "2"} onChange={this.handleRadioChange} value="2" />
                                                            {/*<label className="form-check-label">2</label>*/}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.difficult_concentrating} checked={this.state.gadData.difficult_concentrating === "3"} onChange={this.handleRadioChange} value="3" />
                                                            {/*<label className="form-check-label">3</label>*/}
                                                        </div>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td>{this.state.labelText.easily_fatigued}</td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.easily_fatigued} checked={this.state.gadData.easily_fatigued === "0"} onChange={this.handleRadioChange} value="0" />
                                                            {/*<label className="form-check-label">0</label>*/}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.easily_fatigued} checked={this.state.gadData.easily_fatigued === "1"} onChange={this.handleRadioChange} value="1" />
                                                            {/*<label className="form-check-label">1</label>*/}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.easily_fatigued} checked={this.state.gadData.easily_fatigued === "2"} onChange={this.handleRadioChange} value="2" />
                                                            {/*<label className="form-check-label">2</label>*/}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.easily_fatigued} checked={this.state.gadData.easily_fatigued === "3"} onChange={this.handleRadioChange} value="3" />
                                                            {/*<label className="form-check-label">3</label>*/}
                                                        </div>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td>{this.state.labelText.con_mind_blank}</td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.con_mind_blank} checked={this.state.gadData.con_mind_blank === "0"} onChange={this.handleRadioChange} value="0" />
                                                            {/*<label className="form-check-label">0</label>*/}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.con_mind_blank} checked={this.state.gadData.con_mind_blank === "1"} onChange={this.handleRadioChange} value="1" />
                                                            {/*<label className="form-check-label">1</label>*/}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.con_mind_blank} checked={this.state.gadData.con_mind_blank === "2"} onChange={this.handleRadioChange} value="2" />
                                                            {/*<label className="form-check-label">2</label>*/}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.con_mind_blank} checked={this.state.gadData.con_mind_blank === "3"} onChange={this.handleRadioChange} value="3" />
                                                            {/*<label className="form-check-label">3</label>*/}
                                                        </div>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td>{this.state.labelText.muscle_tension}</td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.muscle_tension} checked={this.state.gadData.muscle_tension === "0"} onChange={this.handleRadioChange} value="0" />
                                                            {/*<label className="form-check-label">0</label>*/}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.muscle_tension} checked={this.state.gadData.muscle_tension === "1"} onChange={this.handleRadioChange} value="1" />
                                                            {/*<label className="form-check-label">1</label>*/}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.muscle_tension} checked={this.state.gadData.muscle_tension === "2"} onChange={this.handleRadioChange} value="2" />
                                                            {/*<label className="form-check-label">2</label>*/}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.muscle_tension} checked={this.state.gadData.muscle_tension === "3"} onChange={this.handleRadioChange} value="3" />
                                                            {/*<label className="form-check-label">3</label>*/}
                                                        </div>
                                                    </td>

                                                </tr>

                                                <tr>
                                                    <td>{this.state.labelText.in_6m_worry_more}</td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.in_6m_worry_more} checked={this.state.gadData.in_6m_worry_more === "0"} onChange={this.handleRadioChange} value="0" />
                                                            {/*<label className="form-check-label">0</label>*/}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.in_6m_worry_more} checked={this.state.gadData.in_6m_worry_more === "1"} onChange={this.handleRadioChange} value="1" />
                                                            {/*<label className="form-check-label">1</label>*/}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.in_6m_worry_more} checked={this.state.gadData.in_6m_worry_more === "2"} onChange={this.handleRadioChange} value="2" />
                                                            {/*<label className="form-check-label">2</label>*/}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.in_6m_worry_more} checked={this.state.gadData.in_6m_worry_more === "3"} onChange={this.handleRadioChange} value="3" />
                                                            {/*<label className="form-check-label">3</label>*/}
                                                        </div>
                                                    </td>

                                                </tr>
                                            </tbody>
                                            <tfoot style={{ backgroundColor: 'transparent  !important' }}>
                                                <tr>
                                                    <th>Total</th>
                                                    <th>{this.state.overAllResponseCountTable1.counter1 * 0}</th>
                                                    <th>{this.state.overAllResponseCountTable1.counter2 * 1}</th>
                                                    <th>{this.state.overAllResponseCountTable1.counter3 * 2}</th>
                                                    <th>{this.state.overAllResponseCountTable1.counter4 * 3}</th>
                                                </tr>
                                                <tr>
                                                    <th>Total Score</th>
                                                    <th colspan="9">{this.state.overAllResponseCountTable1.totalScore}</th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>




                                {/* Start code commented by Kuldeep Singh on the basis of client's requirement on 23 july 2024  in jira ticket >>  CHP-1173*/}
                                {/* <div className="col-md-12">
                                    <div className="table-responsive p-0 form-group">
                                        <p><strong className="form-group">Optional questions not included in final score, but may help assess overall quality of life enjoyment and satisfaction</strong></p>
                                        <table className="table table-bordered table-striped table-center">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '45%' }}></th>
                                                    <th style={{ width: '12%' }}>Not at all (0)</th>
                                                    <th style={{ width: '14%' }}>Several days (1)</th>
                                                    <th style={{ width: '14%' }}>More than<br />half the days (2)</th>
                                                    <th style={{ width: '14%' }}>Nearly<br></br> every day<br /> (3)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{this.state.labelText.wry_symptoms_interfere}</td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.wry_symptoms_interfere} checked={this.state.gadData.wry_symptoms_interfere === "0"} onChange={this.handleRadioChange} value="0" />
                                                            <label className="form-check-label">0</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.wry_symptoms_interfere} checked={this.state.gadData.wry_symptoms_interfere === "1"} onChange={this.handleRadioChange} value="1" />
                                                            <label className="form-check-label">1</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.wry_symptoms_interfere} checked={this.state.gadData.wry_symptoms_interfere === "2"} onChange={this.handleRadioChange} value="2" />
                                                            <label className="form-check-label">2</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.wry_symptoms_interfere} checked={this.state.gadData.wry_symptoms_interfere === "3"} onChange={this.handleRadioChange} value="3" />
                                                            <label className="form-check-label">3</label>
                                                        </div>
                                                    </td>

                                                </tr>
                                                <tr>
                                                    <td>{this.state.labelText.wry_symptoms_bother}</td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.wry_symptoms_bother} checked={this.state.gadData.wry_symptoms_bother === "0"} onChange={this.handleRadioChange} value="0" />
                                                            <label className="form-check-label">0</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.wry_symptoms_bother} checked={this.state.gadData.wry_symptoms_bother === "1"} onChange={this.handleRadioChange} value="1" />
                                                            <label className="form-check-label">1</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.wry_symptoms_bother} checked={this.state.gadData.wry_symptoms_bother === "2"} onChange={this.handleRadioChange} value="2" />
                                                            <label className="form-check-label">2</label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name={this.state.fieldName.wry_symptoms_bother} checked={this.state.gadData.wry_symptoms_bother === "3"} onChange={this.handleRadioChange} value="3" />
                                                            <label className="form-check-label">3</label>
                                                        </div>
                                                    </td>

                                                </tr>

                                            </tbody>
                                            <tfoot style={{backgroundColor:'#fff !important'}}>
                                        <tr>
                                            <th>Total</th>
                                            <th>{this.state.overAllResponseCountTable2.counter1}</th>
                                            <th>{this.state.overAllResponseCountTable2.counter2}</th>
                                            <th>{this.state.overAllResponseCountTable2.counter3}</th>
                                            <th>{this.state.overAllResponseCountTable2.counter4}</th>
                                            <th>{this.state.overAllResponseCountTable2.counter5}</th>
                                            <th>{this.state.overAllResponseCountTable2.counter6}</th>
                                            <th>{this.state.overAllResponseCountTable2.counter7}</th>
                                            <th>{this.state.overAllResponseCountTable2.counter8}</th>
                                            <th>{this.state.overAllResponseCountTable2.counter9}</th>
                                        </tr>
                                        <tr>
                                            <th>Total Score</th>
                                            <th colspan="9">{this.state.overAllResponseCountTable2.totalScore}</th>
                                          </tr>
                                    </tfoot>
                                        </table>
                                    </div>
                                </div> */}



                                {/* End code commented by Kuldeep Singh on the basis of client's requirement on 23 july 2024  in jira ticket >>  CHP-1173*/}





                                <div className=" col-lg-12 col-md-12">
                                    <div className="bg-green" style={{ textAlign: "center", borderRight: "1px solid #fff", paddingRight: "30px", paddingBottom: "10px" }}>
                                        <p className="lead" style={{ marginBottom: "0px", paddingTop: "10px" }}><b>Final Score</b> = <span style={{ fontSize: "1.6rem", fontWeight: "400" }}>{this.state.totalScore}</span><b>{this.state.overAllResponseCountTable1.result !== '' ? ` - ` + this.state.overAllResponseCountTable1.result : ''}</b></p>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12" style={{ marginTop: "30px", border: "1px dashed #cec5c5", padding: "20px 20px 0px 20px", backgroundColor: "#f2f2f2" }}>
                                    <p className=" text-gray"><strong>Source:</strong> Generalized Anxiety Disorder 7-item (GAD-7) [Internet]. National HIV Curriculum. [cited 2020Aug5]. Available from: https://www.hiv.uw.edu/page/mental-health-screening/gad-7</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </form>



        );
    }
}

export default GadScreening;