import React , {Component} from 'react';
import $ from 'jquery';
import {Link} from 'react-router-dom'
import Topnav from './Topnav';	
import Footer from './Footer';
import Patientinfoheader from './Patientinfoheader';
import Sidenav from './Sidenav';
import DatePicker from "react-datepicker";
import InputMask from 'react-input-mask' ;
import {validationParser} from '../ReusableComponents/validationParser2' ;
import {validationParser1} from '../ReusableComponents/validationParser' ;
import {getFormattedDate, convertDate} from '../ReusableComponents/dataFormater';
import {LabelField} from '../ReusableComponents/LabelnInput' ;
import {formObjDataMapper, formObjDataMapper2, parseMultiSelectField} from '../ReusableComponents/formObjDataMapper' ;


import Select from 'react-select';

import ReactToPrint from "react-to-print";
import { multiSelectOptionGenerator , createMultiselectDataToSend , selectOptionsGenerator , interviewersOptionsGenerator} from '../ReusableComponents/OptionsGenerator' ;
//Tooltip imports

import ReactTooltip from "react-tooltip";
import Tooltip from '../ReusableComponents/Tooltip' ;
import DataTable , { defaultThemes } from 'react-data-table-component';
import Modal from './Modals/Modal/index';
import  DateTime  from 'react-datetime';
import { Button } from './Modals/Buttons/index';
import moment from 'moment-timezone';
import '../../node_modules/react-datetime/css/react-datetime.css';
import '../global.js';
import {can_edit, can_add } from '../ReusableComponents/urlGenerator' ;
import DateRangePicker  from 'react-bootstrap-daterangepicker';
import ReactDropzone  from 'react-dropzone'
// import TimePicker from 'rc-time-picker';
// import 'rc-time-picker/assets/index.css';


var validationObj = {} ;
var context ;


const axios = require('axios').default;

const customStyles = {
    table: {
      style: {
        color: defaultThemes.default.text.primary,
        backgroundColor: defaultThemes.default.background.default,
        borderStyle : "solid",
        borderWidth : "1px",
        borderColor : "#F9F9F9"
      },
    },
    header: {
      style: {
        minHeight: '1px',
      },
    },
    headRow: {
      style: {
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: defaultThemes.default.divider.default,
        backgroundColor : "#F9F9F9",
        minHeight : "40px"
      },
    },
    headCells: {
      style: {
        '&:not(:last-of-type)': {
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          borderRightColor: defaultThemes.default.divider.default,
          fontWeight : "bold",
          fontSize : "0.91rem"
        },
      },
    },
    row: {
      style: {
          minHeight : '20px'  ,
          borderRightStyle: 'solid',
          borderRightWidth: '10px',
          borderRightColor: defaultThemes.default.divider.default,
      }
    },
    cells: {
      style: {
        '&:not(:last-of-type)': {
          // borderStyle: 'solid',
          // borderWidth: '1px',
          // borderColor: defaultThemes.default.divider.default,
          borderLeftStyle: 'solid',
          borderLeftWidth: '1px',
          borderLeftColor: defaultThemes.default.divider.default,
          fontSize : '0.9rem'
        },
      },
    },
  };

const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white'  }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "green"
          : isFocused
          ? "#0074F0"
          : null,
        color: isDisabled
          ? '#ccc'
          :isFocused
          ? "white"
          : isSelected
          ? "white"
          :"black",
  
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? "grey" : "red"),
        },
      };
    },
    multiValue: (styles, { data }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: "#007BFF",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "white",
      whiteSpace: "inherit"
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "white",
      ':hover': {
        backgroundColor: "red",
        color: 'white',
      },
    }),
  };

class Authorization extends Component{

    constructor () {
        super();
        context = this ;

        this.state = {
            ...this.getModal("AuthorizationData"),
            AuthorizationData : this.getModal("AuthorizationData"),
            mandatoryInfo : this.getModal( "AuthorizationData" ),
            labelText : this.getModal( "AuthorizationData" ),
            tooltips : this.getModal( "AuthorizationData"),
            tooltipsVisibility : this.getModal( "AuthorizationData"),
            fieldName : this.getModal("AuthorizationData"),
            fieldType : this.getModal("AuthorizationData"),

            AuthorizationHistoryColumns : this.getModal("AuthorizationHistoryColumns"),

            //Modals
            showAddAuthorizationModal : false,
            showAuthorizationDetailsModal : false,

            //currentView
            currentView : 'Add',
            all_uploaded_documents : [],
            DocumentsColumns : this.getModal("DocumentsColumns"),
            print:false,
            historyID: 0,
            min_dob : 14,

            //Authorization Forms Mapping Class
            Authorization_forms_mappings: {}


        };


        this.handleAuthorizationelectChange = this.handleAuthorizationelectChange.bind(this);
    }

    

    getModal( type )
    {
        let dataModel = '' ;
        var now = new Date();
        let client_name = '';
        if(sessionStorage.getItem('client_first_name') && sessionStorage.getItem('client_first_name') !== '' && sessionStorage.getItem('client_first_name') !== null && sessionStorage.getItem('client_first_name') !== undefined){
          client_name = client_name + sessionStorage.getItem('client_first_name') + ' ';
        }
        if(sessionStorage.getItem('client_middle_name') && sessionStorage.getItem('client_middle_name') !== '' && sessionStorage.getItem('client_middle_name') !== null && sessionStorage.getItem('client_middle_name') !== undefined){
          client_name = client_name + sessionStorage.getItem('client_middle_name') + ' ';
        }
        if(sessionStorage.getItem('client_last_name') && sessionStorage.getItem('client_last_name') !== '' && sessionStorage.getItem('client_last_name') !== null && sessionStorage.getItem('client_last_name') !== undefined){
          client_name = client_name + sessionStorage.getItem('client_last_name') + ' ';
        }
        

        switch(type)
        {
            case "AuthorizationData" :
                dataModel = {
                    selectedFile : null,
                    e_sign:'No',
                    patient_name:client_name.trim(),
                    rrc_org:sessionStorage.getItem('selected_organization'),
                    form_selection_type : '',
                    types_of_records : '',
                    other_type_of_record : '',
                    covering_period_type : '',
                    specific_time_records : moment(),
                    specific_time_records_to: moment().add(1, 'month'),
                    authorization_confirmation : '',
                    RRC_person : '',
                    RRC_phone : '',
                    RRC_email : '',
                    RRC_fax : '',
                    RRC_address: '',
                    RRC_city : '',
                    RRC_state:'',
                    RRC_zip:'',
                    purpose:'',
                    other_purpose:'',
                    is_patient_minor:'',
                    is_patient_legally_incapacitated:'',
                    expiry_period_from:'',
                    expiry_period_to:'',
                    expiry_condition_or_event:'',
                    authorization_copy_received:'',
                    patient_name_in_sign:'',
                    dob_in_sign:'',
                    patient_sign:'',
                    date_in_sign:moment(),
                    patient_unique_ID:'',
                    patient_personal_representative_name:'',
                    patient_personal_representative_sign:'',
                    legal_authority:'',
                    personal_representative_docs_received:'',
                    authorization_status:'Active',
                    documents:null,
                    updated_on:'',
                    revoked_on:'',
                    updated_by:''
                   
                }
                break;

          case "resetData" :
                dataModel = {
                   selectedFile : null,
                   e_sign:'No',
                   patient_name:client_name.trim(),
                   rrc_org:sessionStorage.getItem('selected_organization'),
                    form_selection_type : '',
                    types_of_records : '',
                    other_type_of_record : '',
                    covering_period_type : '',
                     specific_time_records : moment(),
                    specific_time_records_to: moment().add(1, 'month'),
                    authorization_confirmation : '',
                    RRC_person : '',
                    RRC_phone : '',
                    RRC_email : '',
                    RRC_fax : '',
                    RRC_address: '',
                    RRC_city : '',
                    RRC_state:'',
                    RRC_zip:'',
                    purpose:'',
                    other_purpose:'',
                    is_patient_minor:'',
                    is_patient_legally_incapacitated:'',
                    expiry_period_from:'',
                    expiry_period_to:'',
                    expiry_condition_or_event:'',
                    authorization_copy_received:'',
                    patient_name_in_sign:'',
                    dob_in_sign:'',
                    patient_sign:'',
                    date_in_sign:moment(),
                    patient_unique_ID:'',
                    patient_personal_representative_name:'',
                    patient_personal_representative_sign:'',
                    legal_authority:'',
                    personal_representative_docs_received:'',
                    authorization_status:'Active',
                    documents:null,
                    updated_on:'',
                    revoked_on:'',
                    updated_by:''
                   
                }
                break;

              case "DocumentsColumns" :
                  dataModel = [
                      {
                        name: 'File name',
                        sortable: true,
                        cell : (row) => (
                          <div>
                            <a href={row.url} target="_blank" alt={row.name}>{row.name}</a>
                          </div>
                        ) ,
                        
                      },  
                      {
                          name: 'Date',
                          selector: 'time',
                          sortable: false,
                      },
                      {
                          name: 'Uploaded by',
                          selector: 'uploaded_by',
                          sortable: true,
                      },
                      {
                        name: 'Remarks',
                        selector: 'remarks',
                        sortable: true,
                      }
                    ];
              break;

            case "AuthorizationHistoryColumns" :
                  dataModel = [
                      {
                        name: 'Approved by',
                        selector: 'approved_by',
                        sortable: true,
                        
                      },	
                      {
                          name: 'Date',
                          selector: 'date',
                          sortable: true,
                          cell : (row) => {
                          return moment.unix(row.date).tz("America/New_York").format("MM/DD/YYYY");
                        } ,
                      },
                      {
                          name: 'Expiry Date',
                          selector: 'expiry_period_to',
                          sortable: true,
                          cell : (row) => {
                          return moment.unix(row.expiry_period_to).tz("America/New_York").format("MM/DD/YYYY");
                        } ,
                      },
                      {
                          name: 'Status',
                          selector: 'status',
                          sortable: true,
                      },
                      {
                        name: 'View',
                        sortable: false,
                        cell : (row) => (
                          <div>
                            <Link to="#"><i id={row.id} lang="none" className="fas fa-eye" aria-hidden="true" data-toggle="tooltip" title="view" onClick={this.handleAuthorizationDetails.bind(this,'Update')}></i></Link>
                          </div>
                        ) ,
                        ignoreRowClick: true,
                        allowOverflow: true,
                        button: true,
                      },
                      {
                        name: 'Revoke',
                        sortable: false,
                        cell : (row) => (
                          <div>
                            <Link to="#"><i id={row.id} lang="none" className="fas fa-times" aria-hidden="true" data-toggle="tooltip" title="view" onClick={this.handleAuthorizationDetails.bind(this, 'Revoke')}></i></Link>
                          </div>
                        ) ,
                        ignoreRowClick: true,
                        allowOverflow: true,
                        button: true,
                      },
                    ];
                  break;
        }
        return dataModel ;
    }

    componentWillMount()
     {
         // for fetching layout definition
        axios.get(global.restApiURL+'webservice/rest/class/id/47?apikey='+sessionStorage.getItem('__CL_TOKEN__'))
        .then(res => {
        var response = res.data;   

        if(response.success && res.status==200)
        {
            validationObj = validationParser(response.data.layoutDefinitions.childs[0].childs);
            let validationObj_old = validationParser1(response.data.layoutDefinitions.childs[0].childs);
            console.log("validation : " , validationObj) ;

            this.setState({
                labelText : validationObj.title,
                mandatoryInfo : validationObj.mandatory,
                tooltips : validationObj.tooltip,
                tooltipsVisibility : validationObj.tooltipVisibility,
                fieldName : validationObj.name,
                fieldType : validationObj.fieldtype,
                ...validationObj_old
            });

            this.createSelectOptions();
            
        }
        else if ( "error_code" in response )
        {
            alert( response.msg ) ;
            window.location.href = global.domain+global.traversArray['out'];
        }
        else
        {
            alert( response.msg ) ;
        }
        
        })
        .catch(err => { // log request error and prevent access to undefined state
            this.setState({ loading: false, error: true });
            console.error(err); 
        });

        //Authorization Forms Mapping
         axios.get(global.restApiURL+'webservice/rest/class/id/48?apikey='+sessionStorage.getItem('__CL_TOKEN__'))
        .then(res => {
        var response = res.data;   

        if(response.success && res.status==200)
        {
            let FormsMappingObjects = validationParser1(response.data.layoutDefinitions.childs[0].childs);

            this.setState({
                Authorization_forms_mappings: {...FormsMappingObjects},                
            });
            
        }
        else if ( "error_code" in response )
        {
            alert( response.msg ) ;
            window.location.href = global.domain+global.traversArray['out'];
        }
        else
        {
            alert( response.msg ) ;
        }
        
        })
        .catch(err => { // log request error and prevent access to undefined state
            this.setState({ loading: false, error: true });
            console.error(err); 
        });
     }

     componentDidMount()
    {
        this.getAuthorizationList();
            
    }

    componentDidUpdate() {
      ReactTooltip.rebuild();
    }

    getAuthorizationList = () => {
      if(sessionStorage.getItem('clientId')!=='')
        {
           
            let getAuthorizationListBody = {
                apikey: localStorage.getItem('__TOKEN__'),
                client_id: sessionStorage.getItem('clientId'),
                } ;
            
            axios.post(global.restApiURL + 'customRest/getAuthorizationList' , JSON.stringify(getAuthorizationListBody))
            .then(res => {

            if(res.data.success && res.status==200)
            {
                // const response = res.data.data.elements;

                setTimeout(function() { 

                    context.setState({
                        changeParticipantionsHistoryData : context.parseAuthorizationHistory(res.data.data)
                    });
                    console.log("obj his data : " , context.state.changeParticipantionsHistoryData);

                    sessionStorage.setItem('AuthorizationListData_length', res.data.data.length)
                }, 50)
               
            }
            else if ( "error_code" in res )
            {
                alert( res.msg ) ;
                window.location.href = global.domain+global.traversArray['out'];
            }
            else
            {
                alert( res.msg ) ;
            }
                
                
            })
            .catch(err => { // log request error and prevent access to undefined state
                this.setState({ loading: false, error: true });
                console.error(err); 
            })
        }
    }

    resetDataField = (fieldName) => {
      let AuthorizationDataCopy = this.state.AuthorizationData;
      let resetCopy = this.getModal('resetData');
      if(resetCopy[fieldName] !== undefined){
        AuthorizationDataCopy[fieldName] = resetCopy[fieldName];
      }

      if(fieldName === 'specific_time_records'){
        AuthorizationDataCopy['specific_time_records_to'] = resetCopy['specific_time_records_to'];
      }else if(fieldName === 'is_patient_legally_incapacitated'){

        AuthorizationDataCopy['patient_personal_representative_name'] = resetCopy['patient_personal_representative_name'];
        AuthorizationDataCopy['patient_personal_representative_sign'] = resetCopy['patient_personal_representative_sign'];
        AuthorizationDataCopy['patient_personal_representative_sign_date'] = resetCopy['patient_personal_representative_sign_date'];
        AuthorizationDataCopy['legal_authority'] = resetCopy['legal_authority'];
        console.log('is_patient_legally_incapacitated', AuthorizationDataCopy)
      }
      console.log('resetting data', fieldName, resetCopy, resetCopy[fieldName])

      this.setState({AuthorizationData: AuthorizationDataCopy});
    }

    parseAuthorizationHistory(historyDataArray)
    {
        let parsedData = [] ;
        
        parsedData = historyDataArray.length > 0  && historyDataArray.map((item, i) => {
       
            var op = {} ;

            item.map((val) => {
              op[val.name] = val.value ;
            })
   
            // console.log("op : " , op );

            return op ;
   
        }, this);

        // console.log("parsed Data : " , parsedData);

        return parsedData ;
    }

  createSelectOptions()
  {
      if(validationObj !== "")
      {
        this.setState({

            //Select options
            authorization_status_SelectOptions : selectOptionsGenerator(validationObj.options.authorization_status , context.state.AuthorizationData.authorization_status),
            // Multiselect
            types_of_records_MultiOptions : multiSelectOptionGenerator(validationObj.options.types_of_records),
            purpose_MultiOptions : multiSelectOptionGenerator(validationObj.options.purpose),
            
          });

      }
    }

    handleAuthorizationDetails(type, event)
    {   
        if(type !== '' && ['Update', 'Revoke'].includes(type)){
          this.setState({currentView : type});
        }else{
          window.location.href= ''
        }
        let historyID = event.target.id ;
        this.setState({historyID : historyID});

        console.log("history : " , historyID);

        context.setState({
            AuthorizationData : context.getModal("AuthorizationData")
        })

        axios.get(global.restApiURL + 'webservice/rest/object/id/'+ historyID +'?apikey='+localStorage.getItem('__TOKEN__'))
            .then(res => {

                const response = res.data.data.elements;
                let documents_uploaded = [];
                //console.log("Obj data , res : " , formObjDataMapper2(response , context.getModal("followupdata")) , response );

                let parsedAuthorizationDetails = formObjDataMapper2(response , context.getModal("AuthorizationData")) ;
                //parseMultiSelect Options again
                parsedAuthorizationDetails['types_of_records'] = parseMultiSelectField(parsedAuthorizationDetails.types_of_records, context.state.types_of_records_MultiOptions);
                if(parsedAuthorizationDetails.specific_time_records !== ''){
                  parsedAuthorizationDetails['specific_time_records'] = moment(parsedAuthorizationDetails.specific_time_records);
                }

                if(parsedAuthorizationDetails.specific_time_records_to !== ''){
                  parsedAuthorizationDetails['specific_time_records_to'] = moment(parsedAuthorizationDetails.specific_time_records_to);
                }
                //values are commonly stored in single variable to be shown in Datatable
                if(parsedAuthorizationDetails.documents !== ''){
                  let docs = JSON.parse(parsedAuthorizationDetails.documents);

                  documents_uploaded = docs !==null && docs.length > 0 ? documents_uploaded.concat(docs) : documents_uploaded;
                }

                console.log('docuemnts uploaded', documents_uploaded)

                if(parsedAuthorizationDetails.personal_representative_docs !== ''){
                  let docs2 = JSON.parse(parsedAuthorizationDetails.personal_representative_docs);
                 documents_uploaded =  docs2 !==null && docs2.length > 0 ? documents_uploaded.concat(docs2) : documents_uploaded;
                }

                parsedAuthorizationDetails['documents'] = null;  
                parsedAuthorizationDetails['selectedFile'] = null;

                Object.entries(parsedAuthorizationDetails).map(([key,value])=>{
                  if(['expiry_period_from', 'expiry_period_to', 'dob_in_sign', 'date_in_sign', 'patient_personal_representative_sign_date', 'revoked_on', 'updated_on'].includes(key)){
                    parsedAuthorizationDetails[key] = (value !== '' && value !== null && value !== undefined && value !== 'Invalid date' ? new Date(value) : '');
                    console.log('date converted', key, value, parsedAuthorizationDetails[key], parsedAuthorizationDetails)
                  }
                })

                console.log('documents 2', documents_uploaded)

                context.setState({
                    AuthorizationData : parsedAuthorizationDetails ,
                    all_uploaded_documents: documents_uploaded,
                    showAddAuthorizationModal : true
                })

                console.log(context.state.AuthorizationData);

                context.createSelectOptions();
                
                // context.onFollowUpDetailsModalOpen();
                
            })
            .catch(err => { // log request error and prevent access to undefined state
                context.setState({ loading: false, error: true });
                console.error(err); 
            })          
    }

    openAddAuthorizationModal()
    {
      context.setState({
            currentView:'Add',
            all_uploaded_documents : [],
            historyID : 0,
            AuthorizationData: context.getModal('resetData'),
            showAddAuthorizationModal: true
      })
    }

    closeAddAuthorizationModal = () =>
    {
      context.getAuthorizationList();
      context.setState({
        AuthorizationData : context.getModal('resetData'),
        showAddAuthorizationModal : false,
        all_uploaded_documents : []
      })
      setTimeout(() => context.createSelectOptions(), 100);

      sessionStorage.removeItem('modalOpen');
    }

    handleAuthorizationelectChange( event )
    {
        console.log("selectchange : " , event.target.name);
        let name = event.target.name ;
        let value = event.target.value ;

            this.setState((prevState) => ({
                ...prevState,
                AuthorizationData : {
                    ...prevState.AuthorizationData,
                    [name] : value
                }
            }));
        
    }

    handlePhoneChange = (event) => {

    // //console.log(event.target.value)
    if(event.target !== null)
    {
      let test = event.target.value ;
      
      if( event.target.name === "RRC_phone")
      {
        //console.log("Test : " , event.target.name ) ;
        
        this.setState(prevState => ({
          ...prevState,
          AuthorizationData:{
            ...prevState.AuthorizationData,
            RRC_phone : test
          },
        }));
      }
      if( event.target.name === "RRC_fax")
      {
        //console.log("Test : " , event.target.name ) ;
        
        this.setState(prevState => ({
          ...prevState,
          AuthorizationData:{
            ...prevState.AuthorizationData,
            RRC_fax : test
          },
        }));
      }         
    }
      

  }

  beforeMaskedValueChange = (newState, oldState, userInput) => {

    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;
 
    // keep minus if entered by user
    if (value.endsWith('-') && userInput !== '-' && !this.state.AuthorizationData.RRC_fax.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }
 
    return {
      value,
      selection
    };
  }

  beforeMaskedValueChange2 = (newState, oldState, userInput) => {

    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;
 
    // keep minus if entered by user
    if (value.endsWith('-') && userInput !== '-' && !this.state.AuthorizationData.RRC_phone.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }
 
    return {
      value,
      selection
    };
  }

    handleMultiSelectChanges = (selectedOption , event) => {

      var updatedState = this.state.AuthorizationData;
  
          updatedState[event.name] = selectedOption ;
      
      this.setState(prevState => ({
          ...prevState,
          AuthorizationData : updatedState
      }));
  
      // console.log( event.name ," : ", this.state.lastNightStayData);
      };



      closeAuthorizationDetailsModal()
      {

        context.setState({
          AuthorizationData : context.getModal('resetData'),
          showAddAuthorizationModal : false
        });
        setTimeout(() => context.createSelectOptions(), 100);
      }

      openAuthorizationDetailsModal()
      {
        context.setState({
          showAddAuthorizationModal: false,
            showAuthorizationDetailsModal: true,
        });
      }

    onChangeHandler=event=>{

      console.log('uploaded files', event.target.files);
    let file_size = event.target.files[0].size;
  
    //or if you like to have name and type
    let file_name = event.target.files[0].name;
    let file_type = event.target.files[0].type;
   //do whatever operation you want to do here
   console.log("file size : ", file_size , " file type : " , file_type) ;
    let selectedFile = [];
    for (let i = 0; i < event.target.files.length; i++) {
        selectedFile.push(event.target.files[i]);
    }

    console.log('select files', selectedFile)
      this.setState({
        selectedFile,
        loaded: 0,
      })

    }

    
    saveAuthorizationdetails = () => {

    let buttonType = 'save';//event.target.value ;

      let today = new Date();

      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

      console.log("date : " , date ) ;

      
        console.log("date : " , date ) ;
        const data = new FormData();
        Object.entries(context.state.AuthorizationData).map(([key,value])=>{
           if(key ==='types_of_records' ){
            if(this.state.AuthorizationData.types_of_records !== undefined && this.state.AuthorizationData.types_of_records !== null && this.state.AuthorizationData.types_of_records !== ''){
              for (let i = 0; i < this.state.AuthorizationData.types_of_records.length; i++) {
                data.append(`types_of_records[${i}]`, this.state.AuthorizationData.types_of_records[i].label)
              }
            }else{
              data.append(`types_of_records`, '')
            }
            
          }else if(key ==='purpose'){
            if( this.state.AuthorizationData.purpose !== undefined && this.state.AuthorizationData.purpose !== null && this.state.AuthorizationData.purpose !== ''){
              for (let i = 0; i < this.state.AuthorizationData.purpose.length; i++) {
                data.append(`purpose[${i}]`, this.state.AuthorizationData.purpose[i].label)
              }
            }else{
              data.append(`purpose`, '')
            }
            
         /* }else if(['expiry_period_from', 'expiry_period_to', 'dob_in_sign'].includes(key)){
            data.append(key, getFormattedDate(value));
          }else if(['date_in_sign', 'patient_personal_representative_sign_date', 'revoked_on'].includes(key)){
            console.log('formatting date : ',key)
            data.append(key, getFormattedDate(value, true));*/
          }else if(['specific_time_records', 'specific_time_records_to', 'date_in_sign', 'patient_personal_representative_sign_date', 'revoked_on','expiry_period_from', 'expiry_period_to', 'dob_in_sign'].includes(key)){
            
            if(key === 'expiry_period_from'){
              value = context.state.AuthorizationData.date_in_sign;
            }

            data.append(key, value !== '' && value !== null && value !== undefined ? moment(value).format('YYYY-MM-DD') : '');
          }else{
            data.append(key, value);
          }
          
        })
        let emptyFormData = true;
        for (var val of data.values()) {
          if(val !== '' && val !== 'null' && val !== null){
            emptyFormData = false;
          }else{
            console.log('entries', data.values(), data.entries(), this.state.selectedFile);
          }
        }

        /*if(this.state.AuthorizationData.personal_representative_docs_received === "on" && this.state.AuthorizationData.selectedFile === null && emptyFormData){
          sessionStorage.setItem('error_msg', 'Please upload Personal Representative’s related documents')
          context.closeAddAuthorizationModal();
          window.location.href="";
          return false;
        }*/

         if(sessionStorage.getItem('AuthorizationListData_length') === 0){
            data.append('key', sessionStorage.getItem('clientId') + "-1");
          }else{
            data.append('key', sessionStorage.getItem('clientId') + "-" + (parseInt(sessionStorage.getItem('AuthorizationListData_length'))+1))
        }
        
        if(this.state.AuthorizationData.selectedFile !== null){
          for (let i = 0; i < this.state.AuthorizationData.selectedFile.length; i++) {
            console.log('representative_docs length', this.state.AuthorizationData.selectedFile.length)
            if(this.vlidateFileSelection(this.state.AuthorizationData.selectedFile[i]))
            {
              data.append(`representative_docs[${i}]`, this.state.AuthorizationData.selectedFile[i])
            }else{
              return false;
            }
            
          }
        }

        if(this.state.AuthorizationData.documents !== null){
          for (let i = 0; i < this.state.AuthorizationData.documents.length; i++) {
            console.log('representative_docs length', this.state.AuthorizationData.documents.length)
             if(this.vlidateFileSelection(this.state.AuthorizationData.documents[i]))
            {
              data.append(`attachments[${i}]`, this.state.AuthorizationData.documents[i]);
            }else{
              return false;
            }
          }
        }
        

        data.append('client_id' , sessionStorage.getItem('clientId') )
        data.append('updated_by' , sessionStorage.getItem('loggedinUserID') )
        data.append('updated_on' , getFormattedDate(new Date(), true) )
        data.append('apikey' , localStorage.getItem('__TOKEN__') )
        data.append('version' , "1" )
        data.append('historyID', this.state.historyID);

        console.log('data', data)
        
        axios.post(global.restApiURL + "customRest/uploadAuthorizationAttachments", data )
       .then(res => { 
          // console.log(res) ;
          // alert(res.data.msg);
          let response = res.data ;
          if( response.success && res.status==200 )
                {
                  let state_of_success = '';
                  switch(context.state.currentView){
                    case 'Add':
                    state_of_success = 'added';
                    break;

                    case 'Update':
                    state_of_success = 'updated';
                    break;
                  }
                  sessionStorage.setItem('success_msg',"Authorization "+state_of_success+" successfully.");
                  sessionStorage.setItem('AuthorizationListData_length', (parseInt(sessionStorage.getItem('AuthorizationListData_length')) + 1));

                  context.closeAddAuthorizationModal();
                  window.location.href= '';
                }
                else if ( "error_code" in response )
                {
                    alert( response.msg ) ;
                    window.location.href = global.domain+global.traversArray['out'];
                }
                else
                {
                    alert( response.msg ) ;
                    context.closeAddAuthorizationModal();
                }
        }).catch(err => { // log request error and prevent access to undefined state
            
            console.error(err); 
        });

   }

   vlidateFileSelection(file)
   {
   
     if ((file.size/ 1024 / 1024) > 5)
     {
       alert(file.name + " : File size too large !");
       return false;
     }
     else
     {
       return true ;
     }
   }
  
   uploadImage=(e)=>{
    let file = e.target.files[0];
      if (file && !file.name) {
         window.alert("Please select a file");
         return false;
      }
      if (file.size > 10e6) {
        window.alert("Please upload a file smaller than 10 MB");
        return false;
      }
  }
  
  Filevalidation = () => { 
    const fi = document.getElementById('file'); 
    // Check if any file is selected. 
    if (fi.files.length > 0) { 
        for (var i = 0; i <= fi.files.length - 1; i++) { 
  
            const fsize = fi.files.item(i).size; 
            const file = Math.round((fsize / 1024)); 
            // The size of the file. 
            if (file >= 4096) { 
                alert( 
                  "File too Big, please select a file less than 4mb"); 
            } else if (file < 2048) { 
                alert( 
                  "File too small, please select a file greater than 2mb"); 
            } else { 
                document.getElementById('size').innerHTML = '<b>'
                + file + '</b> KB'; 
            } 
        } 
    } 
  }

  getOneYearIntervalDateFromDateOfSigning(){ 
    const date_of_signing = this.state.AuthorizationData.date_in_sign;
    let date_after_one_yr_of_sign = new Date(this.state.AuthorizationData.date_in_sign);
    date_after_one_yr_of_sign = new Date(date_after_one_yr_of_sign.setFullYear(date_after_one_yr_of_sign.getFullYear() + 1));   
    console.log('date of signing', this.state.AuthorizationData.date_in_sign, date_of_signing, date_after_one_yr_of_sign)
      
    return '(' + convertDate(date_of_signing, 'MM/DD/YYYY') + ' - ' + convertDate(date_after_one_yr_of_sign, 'MM/DD/YYYY') + ')';
  }

    handleDateChange = name => date => {
      //console.log("Selected date is : " + date ) ;
      var stateCopy = Object.assign({}, this.state);

      if(name === 'date_in_sign'){
        let expiry_date = new Date(date);
        expiry_date = new Date(expiry_date.setFullYear(expiry_date.getFullYear() + 1));
        stateCopy.AuthorizationData['expiry_period_to']= expiry_date;
        stateCopy.AuthorizationData[name]= date;
      }else{
        stateCopy.AuthorizationData[name]= date;
      }
      this.setState(stateCopy);
    };

    handleRadioChange = (event) => {

      let radio_options = context.state[event.target.name]['options'];
      if(event.target.name === 'is_patient_legally_incapacitated'){
        context.resetDataField('is_patient_legally_incapacitated');
      }else{
        radio_options && radio_options !== null && radio_options !== undefined && radio_options.map((option) => 
          context.resetDataField(option.value)
        )
      }
      

      console.log("event name : " , event.target.name , event.target.value) ;

      let tempOb = context.state.AuthorizationData ;

      // console.log("before : " , this.state.phq9Data);
      if(event.target.name === 'authorization_period_type' && event.target.value === 'one_year_from_sign'){
        
        let expiry_date = new Date(convertDate(tempOb.date_of_signing, 'MM/DD/YYYY'));
        expiry_date = new Date(expiry_date.setFullYear(expiry_date.getFullYear() + 1));
        tempOb['expiry_period_to']= expiry_date;
      }
      tempOb[event.target.name] = event.target.value ;
      
      

      this.setState((prevState) => ({
          ...prevState,
          AuthorizationData : tempOb
      })
      );

  }

  handleCheckboxChange = (event) => {
    let tempOb = context.state.AuthorizationData ;

      // console.log("before : " , this.state.phq9Data);

      tempOb[event.target.name] = event.target.checked ;

      this.setState((prevState) => ({
          ...prevState,
          AuthorizationData : tempOb
      })
      );

  }

    route = (route) => {
      sessionStorage.setItem('modalOpen', true);
      switch(route){
        case 'Follow-Up':
          window.location.href = global.domain+'followup';
        break;

        case 'Routing':
          window.location.href = global.domain+'routing';
        break;

        case 'Referral':
          window.location.href = global.domain+'referrals';
        break;

      }
    }

    handleEvent(event, picker) {
    

      let tempOb = context.state.AuthorizationData ;

     // console.log("before : " , tempOb);

      tempOb['specific_time_records'] = picker.startDate ;
      tempOb['specific_time_records_to'] = picker.endDate ;

      context.setState((prevState) => ({
          ...prevState,
          AuthorizationData : tempOb
      })
      );
      
    } 

    onDrop(files) {
      this.setState(prevState => ({
          ...prevState,
          AuthorizationData:{
            ...prevState.AuthorizationData,
            selectedFile : files
          },
        }));
  }

  onDrop_docs(files) {
      this.setState(prevState => ({
          ...prevState,
          AuthorizationData:{
            ...prevState.AuthorizationData,
            documents : files
          },
        }));
  }

  enablePrintStyles = () => {
    console.log('printing')
    this.setState({print : true})
    return Promise.resolve();
    console.log('printing state', this.state)
  }

  disablePrintStyles = () => {
    console.log('printing close')
    this.setState({print : false})
  }
    
  handleTimer( date, field){
      let AuthorizationData_temp = this.state.AuthorizationData;
      if(field === 'date_in_sign'){
        AuthorizationData_temp[field] = moment(date._d).format('MM/DD/YYYY');
        AuthorizationData_temp['expiry_period_to'] = moment(date._d).add(1, 'years').format('MM/DD/YYYY');
      }else{
        AuthorizationData_temp[field] = moment(date._d).format('MM/DD/YYYY');
      }
        
        context.setState({AuthorizationData : AuthorizationData_temp, unSaved: true});      
  };

  validDobDate( current, selectedDate ){
    console.log('validate', current, selectedDate, current.isBefore(moment().subtract(context.state.min_dob, 'years')) && current.isAfter(moment().subtract(120, 'years')))
    return current.isBefore(moment().subtract(context.state.min_dob, 'years')) && current.isAfter(moment().subtract(120, 'years'));
  };

  validExpiryDate( current, selectedDate ){
    return current.isAfter(moment(context.state.AuthorizationData.date_in_sign));
  };


    render()
    {

      $(document).ready(function () {


      var NumRegex = /^[0-9]+$|^$|^\s$/;
      var EmailRegex = /^$|^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/

       $.validator.addMethod('NumRegex', function (value, element, parameter) {
           return value.match(NumRegex);
       }, 'Please enter a valid zipcode.');

       $.validator.addMethod('EmailRegex', function (value, element, parameter) {
           return value.match(EmailRegex);
       }, 'Please enter a valid email address.');
      
        $.validator.addMethod('DobRegex', function (value, element, parameter) {
        return context.validDobDate(moment(value), moment(value));
      }, 'Age should be >= 14 and <= 120  .');

      $('#AuthorizationForm').validate({
        rules: {  
            RRC_zip: {
                 NumRegex: true
             },
             RRC_email: {
                 EmailRegex: true
             },
          dob: {
            DobRegex: true
          }
          }, 

      submitHandler: function() {
          context.saveAuthorizationdetails();
        },

           
      errorElement: 'span',
      errorPlacement: function (error, element) {
        error.addClass('invalid-feedback');
        element.closest('.form-group').append(error);
      },
      highlight: function (element, errorClass, validClass) {
        $(element).addClass('is-invalid');
      },
      unhighlight: function (element, errorClass, validClass) {
        $(element).removeClass('is-invalid');
      },
      onkeyup: function (element) {
             $('#RRC_zip').valid();
             $('#RRC_email').valid();
            $('#dob').valid();
         }
    });


      $('form input').keydown(function (e) {
      if (e.keyCode == 13) {
          var inputs = $(this).parents("form").eq(0).find(":input");
          if (inputs[inputs.index(this) + 1] != null) {                    
              inputs[inputs.index(this) + 1].focus();
          }
          e.preventDefault();
          return false;
      }
  });
    });

        sessionStorage.setItem('formname','Authorization');

    console.log('specific time', this.state.AuthorizationData);

    let min_dob = new Date();
    min_dob.setFullYear( min_dob.getFullYear() - this.state.min_dob );

    let label = '';
    let start = this.state.AuthorizationData.specific_time_records !== "" && this.state.AuthorizationData.specific_time_records !== null && this.state.AuthorizationData.specific_time_records !== undefined ? this.state.AuthorizationData.specific_time_records.format('MM/DD/YYYY') : "";
    
    let end = this.state.AuthorizationData.specific_time_records_to !== "" && this.state.AuthorizationData.specific_time_records_to !== null && this.state.AuthorizationData.specific_time_records_to !== undefined ? this.state.AuthorizationData.specific_time_records_to.format('MM/DD/YYYY') : "";
    label = start + ' - ' + end;
    if (start === end) {
      label = start;
    }
    let width_md_6={}
    let width_md_5={}
    let width_md_4={}
    let width_md_3={}
    let width_md_2={}
    let width_md_1={}
    let floatLeft = {}
    let checkboxMargin={}
    let bodyMargin = {}
    let displayNone ={}
    let table_print = {}
    let td_print = {}
    let th_print = {}
    let pageBreak = {}

    if(this.state.print === true){

      floatLeft={
        float: "left",
        padding: "8px 2px 0px 2px"
      }

      checkboxMargin={
        padding: "3px",
        display:"inline"
      }

      displayNone = {
        display:"none"
      }

      bodyMargin={
        margin:"30px"
      }

      width_md_6 = {
      width : "50%",
      display: "inline",
      float: "left"
    }

    width_md_5 = {
      width : "41.666667%",
      display: "inline",
      float: "left"
    }

    width_md_4 = {
      width : "33.333333%",
      display: "inline",
      float: "left"
    }

    width_md_3 = {
      width : "25%",
      display: "inline",
      float: "left"
    }

    width_md_2 = {
      width : "16.666667%",
      display: "inline",
      float: "left"
    }

    width_md_1 = {
      width : "8.333333%",
      display: "inline",
      float: "left"
    }

    table_print = {
      borderCollapse: "collapse",
      width: "100%"
    }

    td_print={
      border: "1px solid #ddd",
      padding: "8px",
    }

    th_print={
      paddingTop: "12px",
      paddingBottom: "12px",
      textAlign: "left",
      backgroundColor: "#4CAF50",
      color: "white",
       border: "1px solid #ddd",
      padding: "8px",
    }
    pageBreak={height: "120vh", paddingTop:"15vh" }
    

    }
    
    

    let locale = {
      format: 'MM/DD/YYY',
      separator: ' - ',
      applyLabel: 'Apply',
      cancelLabel: 'Clear',
      weekLabel: 'W',
      customRangeLabel: 'Custom Range',
      daysOfWeek: moment.weekdaysMin(),
      monthNames: moment.monthsShort(),
      firstDay: moment.localeData().firstDayOfWeek(),
    };

    

    let form_selection_type_fields = [];
    let types_of_records = (
                                  <Select
                                        name = {context.state.fieldName.types_of_records}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        value={this.state.AuthorizationData.types_of_records}
                                        onChange={this.handleMultiSelectChanges}
                                        options={this.state.types_of_records_MultiOptions}
                                        styles={colourStyles}
                                        required={this.state.types_of_records.mandatory}
                                    />
                        )

    let other_type_of_record = (
                         <input type="text" className="form-control" name={context.state.fieldName.other_type_of_record} id={context.state.fieldName.other_type_of_record} defaultValue={this.state.AuthorizationData.other_type_of_record} required={this.state.mandatoryInfo.other_type_of_record} maxLength={this.state.other_type_of_record.columnLength} onChange={this.handleRadioChange}/>
                      )
     {validationObj.options !== undefined && validationObj.options.form_selection_type.map((option) => {
                                        if(context.state.fieldName[option.value] === undefined){
                                          form_selection_type_fields.push(
                                            <div  className="row">
                                            <div className="col-lg-12  col-md-12">
                                             <input className="" type="radio" name={context.state.fieldName.form_selection_type} value={option.value} defaultChecked={context.state.AuthorizationData.form_selection_type === option.value} onChange={this.handleRadioChange} /> <label> {option.key} </label>
                                            </div>
                                          </div>
                                            )
                                        }else{
                                          let radio_field = [];
                                          radio_field.push(
                                            <div    style={width_md_6} className="col-lg-6  col-md-6">
                                             <input className=""  type="radio" name={context.state.fieldName.form_selection_type} value={option.value} defaultChecked={context.state.AuthorizationData.form_selection_type === option.value} onChange={this.handleRadioChange} /> <label> {option.key} </label>
                                            </div>);
                                          let related_field = '';
                                          if(option.value === context.state.AuthorizationData.form_selection_type){
                                            switch(option.value){
                                              case 'types_of_records':
                                              radio_field.push(<div    style={width_md_6} className="col-lg-6  col-md-6">{types_of_records}</div>);

                                              break;

                                              case 'other_type_of_record':
                                              radio_field.push(<div  style={width_md_6} className="col-lg-6  col-md-6">{other_type_of_record}</div>);
                                              break;
                                            }
                                          }

                                          form_selection_type_fields.push(<div  className="row"> {radio_field}</div>)
                                            
                                        }
                                       })}


    let covering_period_type_fields = [];
    let  specific_time_records = (
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text"><i className="far fa-calendar-alt"></i></span>
                                    </div>
                                    <div className="datetimepicker_custom">
                                    <DateRangePicker
                                            autoUpdateInput={false}
                                            showDropdowns
                                            locale={locale}
                                            startDate={context.state.AuthorizationData.specific_time_records}
                                            endDate={context.state.AuthorizationData.specific_time_records_to}
                                            onEvent={this.handleEvent}
                                            className={'fullWidth'}
                                          >
                                        <div className="input-group"  >
                                          <input type="text" className="form-control" value={label} id="datetime_picker" />
                                            
                                        </div>
                                  </DateRangePicker>
                                  </div>
                                  </div>
                        )

   
     {validationObj.options !== undefined && validationObj.options.covering_period_type.map((option) => {
                                        if(context.state.fieldName[option.value] === undefined){
                                          covering_period_type_fields.push(
                                            <div  className="row">

                                            <div className="col-lg-12  col-md-12">
                                             <input className="" type="radio" name={context.state.fieldName.covering_period_type} value={option.value} defaultChecked={context.state.AuthorizationData.covering_period_type === option.value} onChange={this.handleRadioChange} /> <label> {option.key} </label>
                                            </div>
                                          </div>
                                            )
                                        }else{
                                          let radio_field = [];
                                          radio_field.push(
                                            <div  style={width_md_6} className="col-lg-6  col-md-6">
                                             <input className="" type="radio" name={context.state.fieldName.covering_period_type} value={option.value} defaultChecked={context.state.AuthorizationData.covering_period_type === option.value} onChange={this.handleRadioChange} /> <label> {option.key} </label>
                                            </div>);
                                          let related_field = '';
                                          if(option.value === context.state.AuthorizationData.covering_period_type){
                                            switch(option.value){
                                              case 'specific_time_records':
                                              radio_field.push(<div  style={width_md_6} className="col-lg-6  col-md-6">{specific_time_records}</div>);
                                              break;

                                             
                                            }
                                          }

                                          covering_period_type_fields.push(<div  className="row"> {radio_field}</div>)
                                            
                                        }
                                       })}
      let is_patient_minor_field = [];
      
      {validationObj.options !== undefined && validationObj.options.is_patient_minor.map((option) => {
                                        
                                          is_patient_minor_field.push(
                                            <div className=" col-lg-1  col-md-1 form-group d-inline">
                                             <input className="" type="radio" name={context.state.fieldName.is_patient_minor} value={option.value} defaultChecked={context.state.AuthorizationData.is_patient_minor === option.value} onChange={this.handleRadioChange} /> <label> {option.key} </label>
                                            </div>
                                            )
                                        
                                      })}

    let is_patient_legally_incapacitated_field = [];
    {validationObj.options !== undefined && validationObj.options.is_patient_legally_incapacitated.map((option) => {
                                        
                                          is_patient_legally_incapacitated_field.push(
                                            <div className=" col-lg-1  col-md-1 form-group d-inline">
                                             <input className="" type="radio" name={context.state.fieldName.is_patient_legally_incapacitated} value={option.value} defaultChecked={context.state.AuthorizationData.is_patient_legally_incapacitated === option.value} onChange={this.handleRadioChange} /> <label> {option.key} </label>
                                            </div>
                                            )
                                        
                                      })}
    let selected_forms = []
    if(context.state.AuthorizationData.form_selection_type !== null && context.state.AuthorizationData.form_selection_type !== '' && context.state.AuthorizationData.form_selection_type !== undefined && context.state.Authorization_forms_mappings !== null && context.state.Authorization_forms_mappings !== '' && context.state.Authorization_forms_mappings !== undefined){
      switch(context.state.AuthorizationData.form_selection_type){
        case 'all_forms':
          context.state.Authorization_forms_mappings.All.options.map(form => selected_forms.push(<li>{form.key}</li>))
        break;

        case 'types_of_records':
          context.state.AuthorizationData.types_of_records !== null && context.state.AuthorizationData.types_of_records !=='' && context.state.AuthorizationData.types_of_records !==undefined && context.state.AuthorizationData.types_of_records.map(form_selected => 
          {
            console.log('forms_selected', form_selected, context.state.AuthorizationData.types_of_records)
            context.state.Authorization_forms_mappings[form_selected.value].options.map(form =>  {
              if(selected_forms.includes(form.key)){
                console.log('present', form.key,  selected_forms)
                 
              }else{
                console.log('not present', form.key,  selected_forms)
                selected_forms.push(form.key)
                console.log('new list',   selected_forms)
              }
            }  )

            
          })
        break;

        case 'other_type_of_record':
        break;
      }


      console.log('selectedOption',selected_forms)
    }

    let docs_content_for_printing = '';
    if(this.state.all_uploaded_documents !== null && this.state.all_uploaded_documents !== undefined && this.state.all_uploaded_documents.length > 0){
      let docs_content_headers = <tr><th style={th_print}>File</th><th style={th_print}>Remarks</th><th style={th_print}>Uploaded On</th></tr>;
      let docs_content_body = [];
      this.state.all_uploaded_documents.map(doc => docs_content_body.push(<tr><td style={td_print}>{doc.name}</td><td style={td_print}>{doc.remarks}</td><td style={td_print}>{doc.time}</td></tr>))

      docs_content_for_printing = <table style={table_print}>{docs_content_headers}{docs_content_body}</table>;
    }

    console.log('documents uploaded' , this.state.all_uploaded_documents,  docs_content_for_printing)
    
        return(

            <div className="hold-transition sidebar-mini text-sm">

  		        <div className="wrapper">
                    <Topnav />  
                    <div className="content-wrapper">	
  
                        <Patientinfoheader clientId={sessionStorage.getItem('clientId')} dob={sessionStorage.getItem('dob') } 
                            email={sessionStorage.getItem('email') } phone={sessionStorage.getItem('phone')} 
                            peer_support_specialist={sessionStorage.getItem('peer_support_specialist')} next_followup_date={sessionStorage.getItem('month_1_followup_date')} intake_date={sessionStorage.getItem('intake_date')} initiated_by={sessionStorage.getItem('last_updated_by')} formname={sessionStorage.getItem('formname')} client_name={sessionStorage.getItem('client_first_name')+" "+sessionStorage.getItem('client_middle_initial')+" "+sessionStorage.getItem('client_last_name')}/>
                            <section className="content">
                                <div  className="row">
                                    <Sidenav />

                                  <div className="container-fluid col-md-9 col-lg-10">
                                      <div className="card card-default">      
                                            <div className="card-header">
                                                <p className="lead float-left"><b className="text-green">Authorization</b></p>
                                                <button type="submit" className="btn btn-primary float-right" value="proceed" onClick={() => window.location.href = global.traversArray['authorization']}><img src="dist/img/save-proceed_icon.png" alt="" style={{ pointerEvents : "none" }} /> Save &amp; Proceed </button>
                                                
                                                {can_add('Authorization') ? <button type="submit" className="btn btn-success float-sm-right btnmargin" value="all" onClick={this.openAddAuthorizationModal} lang="all" ><i className="fas fa-plus" aria-hidden="true"></i> Add Authorization</button> : "" }
                                                <input type="hidden" id="clicked" value="" readOnly/>
                                                
                                            </div>
                                            <div className="card-body">
                                                <DataTable
                                                    columns={this.state.AuthorizationHistoryColumns}
                                                    data={this.state.changeParticipantionsHistoryData}
                                                    customStyles={global.customTableStyles}
                                                    striped = {true}
                                                    pagination
                                                    paginationComponentOptions = {global.paginationComponentOptions}
                                                    highlightOnHover
                                                    pointerOnHover   
                      noDataComponent=<div className="no_record_found">{global.no_record_found_text}</div>    
                                                />
                                            </div>
                                            <div className="card-footer ">

                                                <div className="float-right"><button type="submit" className="btn btn-primary float-right" value="proceed" onClick={() => window.location.href = global.traversArray['authorization']}><img src="dist/img/save-proceed_icon.png" alt="" style={{ pointerEvents : "none" }} /> Save &amp; Proceed </button>
                                                                                          
                                                {can_add('Authorization') ? <button type="submit" className="btn btn-success float-sm-right btnmargin" value="all" onClick={this.openAddAuthorizationModal} lang="all" ><i className="fas fa-plus" aria-hidden="true"></i> Add Authorization</button> : "" }
                                                <input type="hidden" id="clicked" value="" readOnly/>
      
                                                </div>
                                          </div>  

                                            <Modal 
                                                showOverlay={true}
                                                show = {this.state.showAddAuthorizationModal}
                                                onClose = {this.closeAddAuthorizationModal}
                                                disableOverlayClick = {true}
                                                showCloseButton = {false}
                                                className="modal-xl modal-dialog"
                                                >
                                                <Modal.Header style={{ borderTopLeftRadius : "10px" , borderTopRightRadius : "10px"}}>
                                                    <Modal.Title>
                                                        <div  className="row" >
                                                            <div className="col-md-6">
                                                                <h4 className="modal-title float-left text-green">{this.state.currentView} Authorization</h4>
                                                            </div>
                                                            {/*<div className="col-md-6"  > 
                                                                <button id="Open" type="submit" className="btn btn-success float-right btnmargin"  htmlType="submit" form="AuthorizationForm" > <i className="fas fa-save"></i> Save</button>
                                                            </div>*/}
                                                        </div>
                                                    </Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                <form id="AuthorizationForm"  method="post" action="javascript:void(0)" ref={el => (this.componentRef = el)}>
                                                    <fieldset disabled = {this.state.currentView === 'Revoke'}  style={bodyMargin}>
                                                        <div className="row">
                                                          <div style={this.state.print ? displayNone : {textAlign:"right"}} className="col-lg-6 col-lg-6 form-group" >
                                                            <input type="radio" name="e_sign" value="Yes" checked={this.state.AuthorizationData.e_sign === "Yes"} onChange={this.handleRadioChange}/> <label>Display for e-signature. </label>
                                                          </div>
                                                          <div style={displayNone} className="col-lg-6 col-lg-6 form-group">
                                                            <input type="radio" name="e_sign" value="No" checked={this.state.AuthorizationData.e_sign === "No"} onChange={this.handleRadioChange}/> <label>Display for paper signature. </label>
                                                          </div>
                                                          {this.state.AuthorizationData.e_sign === 'Yes' || this.state.print === true ? (
                                                          <div>
                                                          <div className="col-lg-12 col-lg-12 form-group e-sign" style={pageBreak}>

                                                          <div className="form-group" style={{textAlign: "center"}}><strong>Authorization</strong> - Authorization for the Release of Information under 42 C.F.R. PART 2 Confidentiality of Substance Use Disorder Client Records</div>
                                                          

                                                          <h3 style={{textAlign: "center"}}>Notice of Federal Requirements Regarding the Confidentiality of Substance Use Disorder Client Information</h3>
                                                          <br/><div className="form-group" style={{fontSize:"16px"}}>The confidentiality of substance use disorder client records maintained by this program is protected by federal law and regulations. Generally, the program may not say to a person outside the program that a client attends the program, or discloseany information identifying a client as an alcohol or drug abuser <u>unless</u>:<br/><ol>
                                                          <li>The client authorizes in writing;<strong>or</strong></li>
                                                          <li>The disclosure is allowed by a court order accompanied by a subpoena;<strong>or</strong></li>
                                                          <li>The disclosure is made to medical personnel in a medical emergency or to qualified personnel for research, audit, or program evaluation;<strong>or</strong></li>
                                                          <li>The client commits or threatens to commit a crime either at the programor against any person who works for theprogram,</li>
                                                          </ol></div>
                                                          <br/>
                                                          
                                                          <div className="form-group" style={{fontSize:"16px"}}>Violation of federal law and regulations by a program is a crime. Suspected violations may be reported to the United States Attorney in the district where the violation occurs.</div><div className="form-group" style={{fontSize: "16px"}}>Federal law and regulations do not protect any information about suspected child abuse or neglect from being reported under state law to appropriate state or local authorities.</div>
                                                          
                                                          <div className="form-group" style={{fontSize:"18px", fontWeight:"bold", textDecoration:"underline"}}>The releases of information will remain active and valid for one year from the date of signature OR until 90 days after discharge (whichever comes first) OR until a specific date, event, or condition as listed on the form. There are two ways to revoke a release of information: Come in to the {sessionStorage.getItem('selected_organization')} facility where you were scheduled to receive treatment and sign the revocation, or fax in a written statement with your name, signature, date and release(s) you would like to be revoked.
                                                          </div>
                                                          <br/><div className="form-group" style={{fontSize:"16px"}}>(See 42 U.S.C. §290dd-2 for federal law and 42 C.F.R. Part 2 for federal regulations governing Confidentiality of Substance Use Disorder Client Records.)</div></div>
                                                      
                                                          </div>) : ""}
                                                        </div>
                                                    
                                                        <div  className="row edit_allowed" >
                                                            <div className="col-lg-12 col-lg-12 form-group">
                                                              <span style={width_md_1} className="col-lg-1 col-md-1">I, </span><input type="text" style={width_md_5} className="col-lg-5 col-md-5 form-control d-inline" name={context.state.fieldName.patient_name} id={context.state.fieldName.patient_name} defaultValue={this.state.AuthorizationData.patient_name} required={this.state.mandatoryInfo.patient_name} maxLength={this.state.patient_name.columnLength} disabled={true}/><span style={width_md_1} className="col-lg-1 col-md-1"> authorize </span><input type="text"  style={width_md_5} className="col-lg-5 col-md-5 form-control d-inline" name={context.state.fieldName.rrc_org} id={context.state.fieldName.rrc_org} defaultValue={this.state.AuthorizationData.rrc_org} required={this.state.mandatoryInfo.rrc_org} maxLength={this.state.rrc_org.columnLength} disabled={true}/>
                                                              </div>
                                                              <div className="col-lg-12 col-lg-12 form-group">
                                                              <strong>Information to be disclosed</strong> I understand the information to be released or disclosed may include information relating to sexually transmitted diseases, acquired immunodeficiency syndrome (AIDS), or human immunodeficiency virus (HIV), mental health and substance use. I authorize the release or disclosure of the substance use disorder records below:
                                                            </div>
                                                            </div>
                                                            {form_selection_type_fields}
                                                            
                                                            {selected_forms.length > 0 ? (
                                                            <div  className="row ">
                                                              <div className="col-lg-12  col-md-12 form-group forms_selected form-group">
                                                                <strong>The following form(s) are subject to release by the provider:</strong>
                                                                <ul id="selected_forms">
                                                                  {selected_forms.map(form => <li>{form}</li>)}
                                                                </ul>
                                                              </div>
                                                            </div>
                                                            ) : ''}
                                                              
                                                              <div  className="row">
                                                                <div className="col-lg-12 col-md-12 mt-2">
                                                                  <LabelField style={floatLeft} {...context.state.covering_period_type} />
                                                                </div>
                                                              </div>
                                                              
                                                              {covering_period_type_fields}

                                                            <div  className="row edit_allowed">
                                                            
                                                            <div className=" col-lg-12  col-md-12 form-group">
                                                              <input type="checkbox" style={floatLeft} name="authorization_confirmation" checked={context.state.AuthorizationData.authorization_confirmation} onClick={this.handleCheckboxChange}/> <LabelField style={checkboxMargin} {...context.state.authorization_confirmation}  />
                                                            </div>

                                                            <div className=" col-lg-12  col-md-12 form-group">
                                                              <strong>I request that my protected health information (PHI) from the {sessionStorage.getItem('selected_organization')} be disclosed to:</strong>
                                                            </div>

                                                            <div style={width_md_2} className="col-lg-2  col-md-2 form-group">
                                                              <LabelField style={floatLeft} {...context.state.RRC_person} />
                                                            </div>
                                                            <div style={width_md_4} className="col-lg-4  col-md-4 form-group">
                                                              <input type="text" className="form-control" name={context.state.fieldName.RRC_person} id={context.state.fieldName.RRC_person} defaultValue={this.state.AuthorizationData.RRC_person} required={this.state.mandatoryInfo.RRC_person} maxLength={this.state.RRC_person.columnLength} onChange={this.handleRadioChange}/>
                                                            </div>

                                                            <div style={width_md_1} className="col-lg-1  col-md-1 form-group">
                                                              <LabelField style={floatLeft} {...context.state.RRC_phone} />
                                                            </div>
                                                            <div style={width_md_5} className="col-lg-5  col-md-5 form-group">
                                                             
                                                              <InputMask id="RRC_phone" name="RRC_phone" className="form-control" mask="(999) 999-9999" maskChar={null}  id="RRC_phone" name="RRC_phone" value={this.state.AuthorizationData.RRC_phone} beforeMaskedValueChange={this.beforeMaskedValueChange2} onChange={this.handlePhoneChange} required={this.state.mandatoryInfo.RRC_phone} maxLength={this.state.RRC_phone.columnLength}/>
              
                                                            </div>
                                                                      
                                                            <div style={width_md_2} className="col-lg-2  col-md-2 form-group">
                                                              <LabelField style={floatLeft} {...context.state.RRC_email} />
                                                            </div>
                                                            <div style={width_md_4} className="col-lg-4  col-md-4 form-group">
                                                              <input type="email" className="form-control" name={context.state.fieldName.RRC_email} id={context.state.fieldName.RRC_email} defaultValue={this.state.AuthorizationData.RRC_email} required={this.state.mandatoryInfo.RRC_email} maxLength={this.state.RRC_email.columnLength} onChange={this.handleRadioChange}/>
                                                            </div>

                                                            <div style={width_md_1} className="col-lg-1  col-md-1 form-group">
                                                              <LabelField style={floatLeft} {...context.state.RRC_fax} />
                                                            </div>
                                                            <div style={width_md_5} className="col-lg-5  col-md-5 form-group">
                                                              <InputMask id="RRC_fax" name="RRC_fax" className="form-control" mask="(999) 999-9999" maskChar={null}  id="RRC_fax" name="RRC_fax" value={this.state.AuthorizationData.RRC_fax} beforeMaskedValueChange={this.beforeMaskedValueChange} onChange={this.handlePhoneChange} required={this.state.mandatoryInfo.RRC_fax} maxLength={this.state.RRC_fax.columnLength}/>
                                                            </div>
                                                            <div style={width_md_2} className="col-lg-2  col-md-2 form-group">
                                                              <LabelField style={floatLeft} {...context.state.RRC_address} />
                                                            </div>
                                                            <div style={width_md_4} className="col-lg-4  col-md-4 form-group">
                                                              <input type="text" className="form-control" name={context.state.fieldName.RRC_address} id={context.state.fieldName.RRC_address} defaultValue={this.state.AuthorizationData.RRC_address} required={this.state.mandatoryInfo.RRC_address} maxLength={this.state.RRC_address.columnLength} onChange={this.handleRadioChange}/>                                                           
                                                           </div>

                                                            <div style={width_md_1} className="col-lg-1  col-md-1 form-group">
                                                              <LabelField style={floatLeft} {...context.state.RRC_city} />
                                                            </div>
                                                            <div style={width_md_5} className="col-lg-5  col-md-5 form-group">
                                                              <input type="text" className="form-control" name={context.state.fieldName.RRC_city} id={context.state.fieldName.RRC_city} defaultValue={this.state.AuthorizationData.RRC_city} required={this.state.mandatoryInfo.RRC_city} maxLength={this.state.RRC_city.columnLength} onChange={this.handleRadioChange}/> 
                                                            </div>

                                                            <div style={width_md_2} className="col-lg-2  col-md-2 form-group">
                                                              <LabelField style={floatLeft} {...context.state.RRC_state} />
                                                            </div>
                                                            <div style={width_md_4} className="col-lg-4  col-md-4 form-group">
                                                              <input type="text" className="form-control" name={context.state.fieldName.RRC_state} id={context.state.fieldName.RRC_state} defaultValue={this.state.AuthorizationData.RRC_state} required={this.state.mandatoryInfo.RRC_state} maxLength={this.state.RRC_state.columnLength} onChange={this.handleRadioChange}/> 
                                                            </div>

                                                            <div style={width_md_1} className="col-lg-1  col-md-1 form-group">
                                                              <LabelField style={floatLeft} {...context.state.RRC_zip} />
                                                            </div>
                                                            <div style={width_md_5} className="col-lg-5  col-md-5 form-group">
                                                              <input type="text" className="form-control" name={context.state.fieldName.RRC_zip} id={context.state.fieldName.RRC_zip} defaultValue={this.state.AuthorizationData.RRC_zip} required={this.state.mandatoryInfo.RRC_zip} maxLength={this.state.RRC_zip.columnLength} onChange={this.handleRadioChange}/> 
                                                            </div>


                                                            <div  style={width_md_6} className="col-lg-6  col-md-6 specific_forms_selected form-group" >
                                                                <LabelField style={floatLeft} {...context.state.purpose} />
                                                            </div>
                                                            
                                                            <div  style={width_md_6} className="col-lg-6  col-md-6 form-group specific_forms_selected" >
                                                              <Select
                                                                    name = {context.state.fieldName.purpose}
                                                                    isMulti
                                                                    closeMenuOnSelect={false}
                                                                    value={this.state.AuthorizationData.purpose}
                                                                    onChange={this.handleMultiSelectChanges}
                                                                    options={this.state.purpose_MultiOptions}
                                                                    styles={colourStyles}
                                                                    required={this.state.purpose.mandatory}
                                                                />
                                                                {this.state.AuthorizationData.purpose && context.state.AuthorizationData.purpose.filter(function(e) { return e.label === 'Other (Please Specify Below)'; }).length > 0 ? (
                      
                                                                          <input type="text" className="form-control mt-2" name="other_purpose" id="other_purpose" placeholder={this.state.labelText.other_purpose} defaultValue={this.state.AuthorizationData.other_purpose} required={this.state.other_purpose.mandatory} onChange={this.handleRadioChange}/>
                                                                       
                                                                
                                                                ) : "" }
                                                                
                                                              </div>
                                                              <div className=" col-lg-6  col-md-6 form-group"><LabelField style={floatLeft} {...context.state.is_patient_minor} /></div>
                                                              <div className=" col-lg-6  col-md-12 form-group ">
                                                                {is_patient_minor_field}
                                                              </div>
                                                              <div className=" col-lg-6  col-md-6 form-group"><LabelField style={floatLeft} {...context.state.is_patient_legally_incapacitated} /></div>
                                                              <div className=" col-lg-6  col-md-12 form-group ">
                                                                {is_patient_legally_incapacitated_field}
                                                              </div>

                                                              <div className=" col-lg-12  col-md-12 form-group">
                                                                <strong>By signing this authorization form, I understand that:</strong><br/>
                                                                <ul>
                                                                  <li>I understand that my substance use disorder records are protected under the Federal regulations governing Confidentiality and Substance Use Disorder Client Records, 42 C.F.R. Part 2, and the Health Insurance Portability and Accountability Act of 1996 (“HIPAA”), 45 C.F.R. pts 160 & 164, and cannot be disclosed without my written authorization unless otherwise provided for by the regulations.</li>
                                                                  <li>I have the right to revoke this authorization at any time except to the extent that action has been taken in reliance on it. Revocation will not apply to information that has already been disclosed in response to this authorization.</li> 
                                                                  <li>Requests for copies of medical records are subject to reproduction fees in accordance with federal/state regulations.</li>
                                                                  <li>I may be denied services if I refuse to authorize a disclosure for purposes of treatment, payment, or health care operations, if permitted by state law. I will not be denied services if I refuse to authorize a disclosure for other purposes.</li>
                                                                  <li>Treatment, payment, enrollment or eligibility for benefits may not be conditioned on whether I sign this authorization.</li>
                                                                  <li>Any disclosure of information carries with it potential for unauthorized redisclosure, and the information  may not be protected by federal confidentiality rules.</li>
                                                                  <li>Unless otherwise revoked, this authorization will expire automatically as follows: </li>
                                                                </ul> 
                                                              </div>


                                                              <div className=" col-lg-12  col-md-12 ">
                                                                
                                                                  <input type="radio" name="authorization_period_type" value="one_year_from_sign" checked={this.state.AuthorizationData.authorization_period_type === "one_year_from_sign"} required={this.state.mandatoryInfo.authorization_period_type}  onChange={this.handleRadioChange}/> <label className="d-inline">In one year from the date of signature </label><strong> {this.getOneYearIntervalDateFromDateOfSigning()} </strong> : <strong><u>OR</u></strong>
                                                              </div>
                                                              
                                                              
                                                              <div className=" col-lg-12  col-md-12">
                                                                  <input type="radio" name="authorization_period_type" value="specific_date_or_cond" checked={this.state.AuthorizationData.authorization_period_type === "specific_date_or_cond"} required={this.state.mandatoryInfo.authorization_period_type} onChange={this.handleRadioChange}/> <label className="d-inline">Upon a specific date, event, <strong>OR</strong> condition. <strong>If you fail to specify an expiration date, event or condition, this authorization will expire one year from the date signed.</strong></label> 

                                                              </div>
                                                              {this.state.AuthorizationData.authorization_period_type === 'specific_date_or_cond' ? (
                                                              <div  className="row" style={{width: "100%"}}>
                                                              <div className=" col-lg-4  col-md-4 specific_time_or_event form-group authorization_interval_time ml-3">
                                                                  <LabelField style={floatLeft} {...context.state.expiry_period_to} />
                                                                  <br/>
                                                                <div className="input-group-append" style={{height:'38px', float:'left', marginRight:'-3px'}}>
                                                                    <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
                                                                </div>
                                                                <div className="input-group date timepicker" style={{width:"calc(100% - 41px)"}}>
                                                                                                        
                                                                  <span style={{width:'100%'}}>
                                                                      
                                                                      {/* <input type="text" className="form-control datetimepicker-input" data-target=".timepicker"/> */}
                                                                      {/*<DateTime />*/}
                                                                      <DateTime 
                                                                          onChange = {(date) => this.handleTimer(date, 'expiry_period_to')}
                                                                          inputProps = {{value: this.state.AuthorizationData.expiry_period_to !== '' ? moment(this.state.AuthorizationData.expiry_period_to).format('MM/DD/YYYY') : '',required: this.state.mandatoryInfo.expiry_period_to, name:'expiry_period_to'  }}
                                                                          defaultValue = {moment().format('MM/DD/YYYY')}
                                                                          dateFormat="MM/DD/YYYY" timeFormat={false} 
                                                                          isValidDate={this.validExpiryDate}
                                                                          
                                                                          />
                                                                  </span>
                                                              </div>
                                                                
                                                              </div>

                                                              <div className=" col-lg-6  col-md-6 form-group authorization_interval_time" >
                                                                <LabelField style={floatLeft} {...context.state.expiry_condition_or_event} />
                                                                <br/>
                                                                <input type="text" className="form-control" placeholder="Please explain event or condition." name="expiry_condition_or_event" defaultValue={this.state.AuthorizationData.expiry_condition_or_event} required={this.state.mandatoryInfo.expiry_condition_or_event}  onChange={this.handleRadioChange}/>
                                                              </div>
                                                              </div>
                                                              ) : ""}
                                                              <div className=" col-lg-12  col-md-12">
                                                                  <input type="radio" name="authorization_period_type" value="specific_date_and_cond" checked={this.state.AuthorizationData.authorization_period_type === "specific_date_and_cond"} required={this.state.mandatoryInfo.authorization_period_type}  onChange={this.handleRadioChange}/> <label className="d-inline">Upon a specific date, event, <strong>AND</strong> condition. <strong>If you fail to specify an expiration date, event or condition, this authorization will expire one year from the date signed.</strong></label>
                                                                  
                                                                
                                                              </div>
                                                              {this.state.AuthorizationData.authorization_period_type === 'specific_date_and_cond' ? (
                                                              <div  className="row" style={{width: "100%"}}>
                                                              <div className=" col-lg-4  col-md-4 specific_time_and_event form-group authorization_interval_time ml-3"  >
                                                                  <LabelField style={floatLeft} {...context.state.expiry_period_to} />
                                                                  <br/>
                                                                  <div className="form-group formfield">
                  
                                                                  
                                                                  <div className="input-group-append" style={{height:'38px', float:'left', marginRight:'-3px'}}>
                                                                    <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
                                                                </div>
                                                                <div className="input-group date timepicker" style={{width:"calc(100% - 41px)"}}>
                                                                                                        
                                                                  <span style={{width:'100%'}}>
                                                                      
                                                                      {/* <input type="text" className="form-control datetimepicker-input" data-target=".timepicker"/> */}
                                                                      {/*<DateTime />*/}
                                                                      <DateTime 
                                                                          onChange = {(date) => this.handleTimer(date, 'expiry_period_to')}
                                                                          inputProps = {{value: this.state.AuthorizationData.expiry_period_to !== '' ? moment(this.state.AuthorizationData.expiry_period_to).format('MM/DD/YYYY') : '',required: this.state.mandatoryInfo.expiry_period_to, name:'expiry_period_to'  }}
                                                                          defaultValue = {moment().format('MM/DD/YYYY')}
                                                                          dateFormat="MM/DD/YYYY" timeFormat={false} 
                                                                          isValidDate={this.validExpiryDate}
                                                                          
                                                                          />
                                                                  </span>
                                                              </div>
                                                                </div>
                                                                
                                                              </div>

                                                              <div className=" col-lg-6  col-md-6  specific_time_and_event form-group authorization_interval_time" >
                                                                <LabelField style={floatLeft} {...context.state.expiry_condition_or_event} />
                                                                <br/>
                                                                <input type="text" className="form-control" placeholder="Please explain sevent or condition." name="authorization_specific_time" name="expiry_condition_or_event" defaultValue={this.state.AuthorizationData.expiry_condition_or_event} required={this.state.AuthorizationData.authorization_period_type === 'specific_date_and_cond'}  onChange={this.handleRadioChange}/>
                                                              </div>
                                                              </div>
                                                              ) : ""}
                                                              <div className=" col-lg-12  col-md-12 form-group mt-1">
                                                                
                                                                  <input type="checkbox" style={floatLeft} name="authorization_copy_received" checked={context.state.AuthorizationData.authorization_copy_received} onClick={this.handleCheckboxChange}/> <LabelField style={checkboxMargin} {...context.state.authorization_copy_received} />
                                                              </div>

                                                              <div style={width_md_2} className="col-lg-2  col-md-2 form-group">
                                                                <LabelField style={floatLeft} {...context.state.patient_name_in_sign} />
                                                              </div>
                                                              <div style={width_md_4} className="col-lg-4  col-md-4 form-group">
                                                                <input type="text" className="form-control" name={context.state.fieldName.patient_name_in_sign} id={context.state.fieldName.patient_name_in_sign} defaultValue={this.state.AuthorizationData.patient_name_in_sign} required={this.state.mandatoryInfo.patient_name_in_sign} maxLength={this.state.patient_name_in_sign.columnLength} onChange={this.handleRadioChange}/>
                                                              </div>

                                                              <div style={width_md_2} className="col-lg-2  col-md-2 form-group">
                                                                <LabelField style={floatLeft} {...context.state.dob_in_sign} />
                                                              </div>
                                                              <div style={width_md_4} className="col-lg-4  col-md-4 form-group">
                                                                <div className="form-group formfield">
                  
                                                                  
                                                                  <div className="input-group-append" style={{height:'38px', float:'left', marginRight:'-3px'}}>
                                                                    <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
                                                                </div>
                                                                <div className="input-group date timepicker" style={{width:"calc(100% - 41px)"}}>
                                                                                                        
                                                                  <span style={{width:'100%'}}>
                                                                      
                                                                      {/* <input type="text" className="form-control datetimepicker-input" data-target=".timepicker"/> */}
                                                                      {/*<DateTime />*/}
                                                                      <DateTime 
                                                                          onChange = {(date) => this.handleTimer(date, 'dob_in_sign')}
                                                                          inputProps = {{id:'dob', value: this.state.AuthorizationData.dob_in_sign !== '' ? moment(this.state.AuthorizationData.dob_in_sign).format('MM/DD/YYYY') : '',required: this.state.mandatoryInfo.dob_in_sign, name:'dob_in_sign'  }}
                                                                          defaultValue = {moment().format('MM/DD/YYYY')}
                                                                          dateFormat="MM/DD/YYYY" timeFormat={false} 
                                                                          isValidDate={this.validDobDate}
                                                                          
                                                                          />
                                                                  </span>
                                                              </div>
                                                                </div>
                                                              </div>  

                                                              <div style={width_md_2} className="col-lg-2  col-md-2 form-group">
                                                                <LabelField style={floatLeft} {...context.state.patient_sign} />
                                                              </div>
                                                              <div style={width_md_4} className="col-lg-4  col-md-4 form-group">
                                                                <input type="text" className="form-control" name={context.state.fieldName.patient_sign} id={context.state.fieldName.patient_sign} defaultValue={this.state.AuthorizationData.patient_sign} required={this.state.mandatoryInfo.patient_sign} maxLength={this.state.patient_sign.columnLength} onChange={this.handleRadioChange}/>
                                                              </div>

                                                              <div style={width_md_2} className="col-lg-2  col-md-2 form-group">
                                                                <LabelField style={floatLeft} {...context.state.date_in_sign} />
                                                              </div>
                                                              <div style={width_md_4} className="col-lg-4  col-md-4 form-group">
                                                                <div className="form-group formfield">
                  
                                                                   <div className="input-group-append" style={{height:'38px', float:'left', marginRight:'-3px'}}>
                                                                    <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
                                                                </div>
                                                                <div className="input-group date timepicker" style={{width:"calc(100% - 41px)"}}>
                                                                                                        
                                                                  <span style={{width:'100%'}}>
                                                                      
                                                                      {/* <input type="text" className="form-control datetimepicker-input" data-target=".timepicker"/> */}
                                                                      {/*<DateTime />*/}
                                                                      <DateTime 
                                                                          onChange = {(date) => this.handleTimer(date, 'date_in_sign')}
                                                                          inputProps = {{value: this.state.AuthorizationData.date_in_sign !== '' ? moment(this.state.AuthorizationData.date_in_sign).format('MM/DD/YYYY') : '',required: this.state.mandatoryInfo.date_in_sign, name:'date_in_sign'  }}
                                                                          defaultValue = {moment().format('MM/DD/YYYY')}
                                                                          dateFormat="MM/DD/YYYY" timeFormat={false} 
                                                                          /*isValidDate={this.validDobDate}*/
                                                                          
                                                                          />
                                                                  </span>
                                                              </div>
                                                                </div>
                                                              </div>  

                                                              <div style={width_md_2} className="col-lg-2  col-md-2 form-group">
                                                                <LabelField style={floatLeft} {...context.state.patient_unique_ID} />
                                                              </div>
                                                              <div style={width_md_4} className="col-lg-4  col-md-4 form-group">
                                                                <input type="text" className="form-control" name={context.state.fieldName.patient_unique_ID} id={context.state.fieldName.patient_unique_ID} defaultValue={this.state.AuthorizationData.patient_unique_ID} required={this.state.mandatoryInfo.patient_unique_ID} maxLength={this.state.patient_unique_ID.columnLength} onChange={this.handleRadioChange}/>
                                                              </div>
                                                            </div>

                                                            {context.state.AuthorizationData.is_patient_legally_incapacitated === 'Yes' ? (
                                                            <div  className="row edit_allowed border-top border-bottom pt-4" id="personal_representative_info">
                                                              
                                                              <div className="col-lg-12  col-md-12 form-group">
                                                                If the individual is unable to sign due to legal incapacity, the signature of the individual’s personal representative is required. Documentation of the personal representative’s legal authority must be attached.
                                                              </div>

                                                              <div  style={width_md_6} className="col-lg-6  col-md-6 form-group">
                                                                <LabelField style={floatLeft} {...context.state.patient_personal_representative_name} />
                                                              </div>
                                                              <div  style={width_md_6} className="col-lg-6  col-md-6 form-group">
                                                                <input type="text" className="form-control" name={context.state.fieldName.patient_personal_representative_name} id={context.state.fieldName.patient_personal_representative_name} defaultValue={this.state.AuthorizationData.patient_personal_representative_name} required={this.state.mandatoryInfo.patient_personal_representative_name} maxLength={this.state.patient_personal_representative_name.columnLength} onChange={this.handleRadioChange}/>
                                                              </div>

                                                              <div  style={width_md_6} className="col-lg-6  col-md-6 form-group">
                                                                <LabelField style={floatLeft} {...context.state.patient_personal_representative_sign} />
                                                              </div>
                                                              <div style={width_md_3} className="col-lg-3  col-md-3 form-group">
                                                                <input type="text" className="form-control" name={context.state.fieldName.patient_personal_representative_sign} id={context.state.fieldName.patient_personal_representative_sign} defaultValue={this.state.AuthorizationData.patient_personal_representative_sign} required={this.state.mandatoryInfo.patient_personal_representative_sign} maxLength={this.state.patient_personal_representative_sign.columnLength} onChange={this.handleRadioChange}/>
                                                              </div>

                                                              <div style={width_md_1} className="col-lg-1  col-md-1 form-group">
                                                                <LabelField style={floatLeft} {...context.state.patient_personal_representative_sign_date} />
                                                              </div>
                                                              <div style={width_md_2} className="col-lg-2  col-md-2 form-group">
                                                                <div className="form-group formfield">
                  
                                                                  <div className="input-group-append" style={{height:'38px', float:'left', marginRight:'-3px'}}>
                                                                    <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
                                                                </div>
                                                                <div className="input-group date timepicker" style={{width:"calc(100% - 41px)"}}>
                                                                                                        
                                                                  <span style={{width:'100%'}}>
                                                                      
                                                                      {/* <input type="text" className="form-control datetimepicker-input" data-target=".timepicker"/> */}
                                                                      {/*<DateTime />*/}
                                                                      <DateTime 
                                                                          onChange = {(date) => this.handleTimer(date, 'patient_personal_representative_sign_date')}
                                                                          inputProps = {{value: this.state.AuthorizationData.patient_personal_representative_sign_date !== '' ? moment(this.state.AuthorizationData.patient_personal_representative_sign_date).format('MM/DD/YYYY') : '',required: this.state.mandatoryInfo.patient_personal_representative_sign_date, name:'patient_personal_representative_sign_date'  }}
                                                                          defaultValue = {moment().format('MM/DD/YYYY')}
                                                                          dateFormat="MM/DD/YYYY" timeFormat={false} 
                                                                          /*isValidDate={this.validDobDate}*/
                                                                          
                                                                          />
                                                                  </span>
                                                              </div>
                                                                </div>
                                                              </div>

                                                              <div  style={width_md_6} className="col-lg-6  col-md-6 form-group">
                                                                <LabelField style={floatLeft} {...context.state.legal_authority} />
                                                              </div>
                                                              <div  style={width_md_6} className="col-lg-6  col-md-6 form-group">
                                                                <input type="text" className="form-control" name={context.state.fieldName.legal_authority} id={context.state.fieldName.legal_authority} defaultValue={this.state.AuthorizationData.legal_authority} required={this.state.mandatoryInfo.legal_authority} maxLength={this.state.legal_authority.columnLength} onChange={this.handleRadioChange}/>
                                                              </div>

                                                              {this.state.print ? "" : (
                                                              <>
                                                              <div className="col-lg-12  col-md-12 form-group">
                                                                Please upload documentation of Personal Representative’s Legal Authority.
                                                              </div>
                                                              <div className="col-lg-12  col-md-12 form-group">
                                                                <ReactDropzone onDrop={this.onDrop.bind(this)} styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }}>
                                                                  {({getRootProps, getInputProps}) => (
                                                                    <section>
                                                                      <div {...getRootProps()} className="dropzone">
                                                                        <input {...getInputProps()} name="selectedFile" />
                                                                        <div className="card-text file-upload-message">
                                                                            <i className="fas fa-cloud-upload-alt"></i>
                                                                            <p>Drag and drop files here</p>
                                                                        </div>
                                                                      </div>
                                                                    </section>
                                                                  )}
                                                                </ReactDropzone>
                                                                {this.state.AuthorizationData.selectedFile !==null && this.state.AuthorizationData.selectedFile !== "" && this.state.AuthorizationData.selectedFile !== undefined ? (
                                                                <aside className="mt-2">
                                                                  <strong>Selected File</strong>
                                                                  <ul>
                                                                    { this.state.AuthorizationData.selectedFile.map(f => (
                                                                      <li key={f.name}>
                                                                        {f.name} - {f.size} bytes
                                                                      </li>
                                                                    ))}
                                                                  </ul>
                                                                </aside>) : ""}
                                                              </div>
                                                              </>)}
                                                              <div className="col-lg-12  col-md-12 form-group">
                                                                <input type="checkbox" style={floatLeft} name="personal_representative_docs_received" checked={context.state.AuthorizationData.personal_representative_docs_received} onClick={this.handleCheckboxChange}/> <LabelField style={checkboxMargin} {...context.state.personal_representative_docs_received} />
                                                              </div>
                                                             
                                                            </div>
                                                            ) : ''}

                                                        </fieldset>
                                                            {context.state.currentView === 'Revoke' ? (
                                                            <div   className="row on_revoke mt-2">
                                                              <div className="col-lg-12  col-md-12 form-group ">
                                                                <label><strong>By signing below, I am revoking this Authorization for the Release of Confidential Health Information.</strong></label>
                                                              </div>

                                                              <div style={width_md_3} className="col-lg-3  col-md-3 form-group">
                                                                <label>Patient’s Signature</label>
                                                              </div>
                                                              <div style={width_md_3} className="col-lg-3  col-md-3 form-group">
                                                                <input type="text" className="form-control" name=""/>
                                                              </div>

                                                              <div style={width_md_3} className="col-lg-3  col-md-3 form-group">
                                                                <LabelField style={floatLeft} {...context.state.revoked_on} />
                                                              </div>
                                                              <div style={width_md_3} className="col-lg-3  col-md-3 form-group">
                                                                
                                                                <DatePicker  dateFormat="MM/dd/yyyy"  required={context.state.mandatoryInfo.revoked_on === true}   className="form-control pull-right" selected={this.state.AuthorizationData.revoked_on} onChange={this.handleDateChange('revoked_on')} id="revoked_on" name="revoked_on" showYearDropdown required={this.state.mandatoryInfo.revoked_on}/>
                                                              </div>
                                                        
                                                            </div>
                                                            ) : ''}

                                                            <div  className="row  mt-2">
                                                              <div className="col-lg-12  col-md-12 form-group">
                                                                <label><strong>Documents</strong></label>

                                                              </div>
                                                              {this.state.print ? docs_content_for_printing : (
                                                              <>
                                                              <div className="col-lg-12  col-md-12 form-group app">
                                                               <ReactDropzone onDrop={this.onDrop_docs.bind(this)} >
                                                                  {({getRootProps, getInputProps}) => (
                                                                    <section >
                                                                      <div {...getRootProps()} className="dropzone">
                                                                        <input {...getInputProps()} name="documents" />
                                                                        <div className="card-text file-upload-message">
                                                                            <i className="fas fa-cloud-upload-alt"></i>
                                                                            <p>Drag and drop files here</p>
                                                                        </div>
                                                                      </div>
                                                                    </section>
                                                                  )}
                                                                </ReactDropzone>
                                                                {this.state.AuthorizationData.documents !==null && this.state.AuthorizationData.documents !== '' && this.state.AuthorizationData.documents !== undefined ? (
                                                                <aside className="mt-2">
                                                                  <strong>Selected File</strong>
                                                                  <ul>
                                                                    { Array.isArray(this.state.AuthorizationData.documents) ? this.state.AuthorizationData.documents.map(f => (
                                                                      <li key={f.name}>
                                                                        {f.name} - {f.size} bytes
                                                                      </li>
                                                                    )) : this.state.AuthorizationData.documents}
                                                                  </ul>
                                                                </aside>) : ""}
                                                              </div>
                                                              
                                                            <div className="col-lg-12  col-md-12 form-group personal_docs_div" >
                                                                <div className="personal_docs">
                                                                <DataTable
                                                                  columns={this.state.DocumentsColumns}
                                                                  data={this.state.all_uploaded_documents}
                                                                  customStyles={global.customTableStyles}
                                                                  striped = {true}
                                                                  pagination
                                                                  paginationComponentOptions = {global.paginationComponentOptions}
                                                                  highlightOnHover
                                                                  pointerOnHover   
                      noDataComponent=<div className="no_record_found">{global.no_record_found_text}</div>    
                                                              />
                                                                </div>
                                                              </div>
                                                              </>
                                                              )}
                                                            </div>
                                                            <div  className="row edit_allowed">
                                                              <div className="col-lg-12  col-md-12 form-group" style={{marginTop:"30px", border:"1px dashed #cec5c5", padding: "20px", backgroundColor: "#f2f2f2", fontSize: "11px"}}>
                                                                Sources:
                                                                <br/>
                                                                American Health Information Management Association. (2013, November). Sample Authorization Form.<br/>
                                                                https://library.ahima.org/PdfView?oid=107108
                                                                <br/>
                                                                BrightView. (n.d.). Consent for the Release of Information under 42 C.F.R. PART2 Confidentiality of Substance Use Disorder Patient Records. Retrieved from https://brightviewhealth.com/wp-content/uploads/2019/04/BrightView-Release-of-Information-Form-3.6.19.pdf
                                                                <br/>
                                                                West Virginia Department Health & Human Resources Bureau for Behavioral Health. (n.d.). Consent To Release Records Containing Substance Abuse Information 42 CFR Part 2 and HIPAA. Retrieved from https://dhhr.wv.gov/bhhf/Documents/MAT 2017/M118 CFR 42 Consent for Release.pdf


                                                                </div>
                                                             
                                                        
                                                          
                                                        </div>

                                                    </form>
                                                </Modal.Body>
                                                <Modal.Footer style={{ borderBottomLeftRadius : "10px" , borderBottomRightRadius : "10px" }}>
                                                    <div  className="row">
                                                        <div className="col-md-6">
                                                            <div className="justify-content-between" >
                                                                <Button className="btn btn-default float-left" onClick={this.closeAddAuthorizationModal} type="button" form="myForm" key="submit" htmlType="submit">Close</Button>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6" > 
                                                                {this.state.currentView === 'Revoke' || !can_edit ? "" :(
                                                                <button id="Open" type="submit" className="btn btn-success float-right btnmargin"   htmlType="submit" form="AuthorizationForm"> <i className="fas fa-save"></i> Save</button>
                                                                )}

                                                                <ReactToPrint
                                                                documentTitle="Authorization Form"
                                                                  trigger={() => <button type="button" className="btn btn-primary float-right btnmargin"><i className="fa fa-print" aria-hidden="true"></i>&nbsp;Print</button>}
                                                                  
                                                                  onBeforeGetContent = {() => this.enablePrintStyles()}
                                                                  content={() => this.componentRef}
                                                                  onAfterPrint = {() => this.disablePrintStyles()}
                                                                /> 
                                                               
                                                            </div>
                                                    </div>
                                                </Modal.Footer>
                                            </Modal>

                                        </div>
                                  </div>                                  
                                </div>
                            </section>
                    </div>
                </div>

                <Footer/>

            </div>
      );
    }

}

export default Authorization ;