import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useState ,useEffect} from 'react'
// import Level_of_care from './level_of_care'
import MentalHealthDisorder from './mentalHealthDisorder'
import OtherDisorder from './otherDisorder'
import Psychological_enviornmental_problems from './psychological_enviornmental_problems'
import SubstanceUseDisorder from './substanceUseDisorder'

export default function Summary(props) {
  const[substanceUseArrow, setSubstanceUseArrow] = useState(true);
  const[mentalHealthDisorderArrow, setmentalHealthDisorderArrow] = useState(false);
  const[otherDisorderArrow, setotherDisorderArrow] = useState(false);
  const[psychologicalArrow, setpsychologicalArrow] = useState(false);

  const specifier = [
    { value: 'In_Early_Remission', label: 'In Early Remission' },
    { value: 'In_Sustained_Remission', label: 'In Sustained Remission' },
    { value: 'On_Maintenance_Therapy', label: 'On Maintenance Therapy' },
    { value: 'In_a_Controlled_Environment', label: 'In a Controlled Environment' },
    { value: 'N/A', label: 'N/A' },
  ]

  return (
    <div className="accordion" id="accordionExample">
      <div className="card">
        <div className="card-header" id="headingOne">
        <a className="btn-link w-100 text-left accordain-heading" onClick={()=>{setSubstanceUseArrow(!substanceUseArrow);setmentalHealthDisorderArrow(false);setotherDisorderArrow(false);setpsychologicalArrow(false)}} type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          <h5>Substance Use Disorders
            <i className={substanceUseArrow ? "fas fa-angle-up rotate-icon  float-right" : "fas fa-angle-down"}></i>
          </h5>
        </a>
        </div>
        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
            <SubstanceUseDisorder
              tabEditOrNot={props.tabEditOrNot}
              saveAndExit={props.saveAndExit}
              childToParent={props.childToParent[0]}
              isSaveAndComplete={props.isSaveAndComplete}
              route_of_admin={props.route_of_admin}
              frequency_of_use={props.frequency_of_use}
              specifier={specifier}
              form_type={props.form_type}
              // tabtabEdittedOrNot = {props.tabEditOrNot}
              // saveAndExitSummary = {props.saveAndExit}
            />
        </div>
      </div>
      <div className="card">
        <div className="card-header" id="headingTwo">
        <a className=" btn-link w-100 text-left accordain-heading" onClick={()=>{setSubstanceUseArrow(false);setmentalHealthDisorderArrow(!mentalHealthDisorderArrow);setotherDisorderArrow(false);setpsychologicalArrow(false)}} type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
          <h5>Mental Health Disorders <i className={mentalHealthDisorderArrow ? "fas fa-angle-up rotate-icon  float-right" : "fas fa-angle-down"}></i></h5></a>
        </div>
        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
            <MentalHealthDisorder
              mentalHealthDisorderData={props.mentalHealthDisorderData}
              mentaldisorderOptions={props.mentaldisorderOptions}
              childToParent={props.childToParent[1]}
              isSaveAndComplete={props.isSaveAndComplete}
              form_type={props.form_type}
              tabtabEdittedOrNot = {props.tabEditOrNot}
              saveAndExitSummary = {props.saveAndExit}
            />
        </div>
      </div>
      <div className="card">
        <div className="card-header" id="headingThree">
        <a className="btn-link w-100 text-left accordain-heading" onClick={()=>{setSubstanceUseArrow(false);setmentalHealthDisorderArrow(false);setotherDisorderArrow(!otherDisorderArrow);setpsychologicalArrow(false)}} type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
          <h5>Other (Includes Medical) <i className={otherDisorderArrow ? "fas fa-angle-up rotate-icon  float-right" : "fas fa-angle-down"}></i></h5></a>
        </div>
        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
            <OtherDisorder
              childToParent={props.childToParent[2]}
              isSaveAndComplete={props.isSaveAndComplete}
              form_type={props.form_type}
              tabtabEdittedOrNot = {props.tabEditOrNot}
              saveAndExitSummary = {props.saveAndExit}
            />
        </div>
      </div>
      <div className="card">
        <div className="card-header" id="headingOne">
        <a className="btn-link w-100 text-left accordain-heading" onClick={()=>{setSubstanceUseArrow(false);setmentalHealthDisorderArrow(false);setotherDisorderArrow(false);setpsychologicalArrow(!psychologicalArrow)}} type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
          <h5>Psychological and Environmental Problems <i className={psychologicalArrow ? "fas fa-angle-up rotate-icon  float-right" : "fas fa-angle-down"}></i></h5></a>
        </div>
        <div id="collapseFour" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
            <Psychological_enviornmental_problems
              childToParent={props.childToParent[3]}
              isSaveAndComplete={props.isSaveAndComplete}
              form_type={props.form_type}
              tabtabEdittedOrNot = {props.tabEditOrNot}
              saveAndExitSummary = {props.saveAndExit}
            />
        </div>
      </div>
    </div>
  )
}
