import React  from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import Main from './Components/Main';
import {BrowserRouter} from 'react-router-dom'
import './global.js';
import { StoreProvider } from "./store/store";
import './App.css';
import { ToastContainer } from 'react-toastify';
//import HttpsRedirect from 'react-https-redirect';

ReactDOM.render(<BrowserRouter><StoreProvider><ToastContainer/> <Main /></StoreProvider></BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
//console.log('hihihihih', !/\.?www./g.test(location.host), location.href.replace("://","://www."))
/*if(	location.protocol !== "https:"){

	if( !/\.?www./g.test(location.host) ){
		location.href = window.location.href.replace("http://","https://www.")
	}else{
		location.href = window.location.href.replace("http://","https://")
	}
	
}

/*if (location.protocol !== "https:"){
 location.replace(window.location.href.replace("http:", "https:"));
}
if( !/\.?www./g.test(location.host) ){
		location.href = window.location.href.replace("://","://www.")
	}*/